import React from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import ButtonRade from "../../../components/RadeButtons";
import styles from "./index.module.scss";
import { useState } from "react";
import { RETURN_USER_STAKING } from "../../../enums/returnUserStaking";
import Modal from "react-modal";
import ReturnUserPopUp from "../ReturnUserPopUp/ReturnUserPop";
import ReturnUserConfirm from "../ReturnUserConfirmPopUp/ReturnUserConfirmPop";
import ReturnUserSuccess from "../ReturnUserSuccessful/ReturnUserSuccess";
import { ethers } from "ethers";
import { useEffect } from "react";
import { formatUserStaking } from "../ReturnUserStakeInvestment";
import useStakedUserDetails from "../../../Hooks/useStakedUserDetails/useStakedUserDetails";
import Spinner from "../../../components/Spinner/Spinner";
import AlertForStakeInLock from "../AlertForStakeInLock/AlertforStakeInLock";
import StakeDetails from "../StakeDetails/StakeDetails";
import TableCard from "../../../components/TableCard/TableCard";
import useRADEInWallet from "../../../Hooks/useRADEVestDetails/useRADEVestDetails";
/*global BigInt*/
const provider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum)
  : null;
const MAX_DECIMAL_PLACE = 2;
const StakedUserDetails = ({ setStakedRUD }) => {
  const { account, setAccrRewards, acrrRewards } = useContext(UserContext);
  // State variable to open the open the pop up
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // state variable for setting page number for models
  const [modalPageNumber, setModalPageNumber] = useState(null);
  //State variable to set error message if there is any in backend
  const [isError, setIsError] = useState(false);
  //State variable to set apppropriate error message accordingly
  const [errorMessage, setErrorMessage] = useState(false);
  // Assigning variable to totalStakedRUDValue inorder to display unstake button
  const { userDetails, loading, contractStakedBalance } =
    useStakedUserDetails(account);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);
  const [lastRewardUpdated, setLastRewardUpdated] = useState(null);
  const [formattedData, setFormattedData] = useState(null);
  const [stakeId, setStakeId] = useState(null);
  const [lockDuration, setLockduration] = useState(null);
  const [maturityDate, setMaturityDate] = useState(null);
  const [maturityDateInSec, setMaturityDateInSec] = useState(null);
  const [stakedAmount, setStakedAmount] = useState(null);
  const [stakedTime, setStakedTime] = useState(null);
  const [currentBlockTimestamp, setCurrentBlockTimestamp] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [status, setStatus] = useState(null);
  const { userVestedDetails } = useRADEInWallet(account);
  const [stakedTimeInSec, setStakedTimeInSeconds] = useState(null);
  const [totalPoolStakeValue, setPoolStakeValue] = useState(null);
  const [lockDurationInSeconds, setLockDurationInSeconds] = useState(null);
  const [updateRewardGap, setUpdateRewardGap] = useState(null);
  // State variable to keep track of the currently expanded row index
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  useEffect(() => {
    if (
      userVestedDetails &&
      userVestedDetails[2] &&
      userVestedDetails[2][stakeId] &&
      userVestedDetails[2][stakeId][4]
    ) {
      setLastRewardUpdated(Number(userVestedDetails[2][stakeId][4]));
      const lastRewardTime = Number(userVestedDetails[2][stakeId][4]);
      if (lockDurationInSeconds === 3600) {
        if (Math.floor(new Date().getTime() / 1000) - lastRewardTime < 900) {
          setIsUpdateButtonDisabled(true);
        } else {
          setIsUpdateButtonDisabled(false);
        }
      } else {
        if (Math.floor(new Date().getTime() / 1000) - lastRewardTime < 86400) {
          setIsUpdateButtonDisabled(true);
        } else {
          setIsUpdateButtonDisabled(false);
        }
      }
    }
  }, [lastRewardUpdated, stakeId]);
  useEffect(() => {
    if (contractStakedBalance) {
      setPoolStakeValue(
        parseFloat(
          ethers.utils.formatEther(
            BigInt(contractStakedBalance._hex).toString()
          )
        )
      );
    }
  }, [contractStakedBalance]);

  const handleSubmitUnStakeButtonInUnlock = (index) => {
    setModalPageNumber(0);
    setModalIsOpen(true);
  };
  const handleSubmitUnStakeInLock = () => {
    setModalPageNumber(1);
    setModalIsOpen(true);
  };

  const handleToggleStakeDetails = (index) => {
    // Toggle the visibility of the stake details for the clicked row
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
    // setRowClicked(true); // Set rowClicked to true when a row is clicked
  };
  const toggleStakeDetails = (index) => {
    if (expandedRowIndex === index) {
      // If it's already expanded, collapse it
      setExpandedRowIndex(null);
    } else {
      // Otherwise, expand it
      setExpandedRowIndex(index);
    }
  };
  useEffect(async () => {
    if (userDetails) {
      const latestBlock = await provider.getBlock("latest");
      setFormattedData(formatUserStaking(userDetails));
      setCurrentBlockTimestamp(latestBlock.timestamp);
    } else {
      console.log("loading");
    }
  }, [userDetails]);

  const { isMobile } = useContext(AppContext);
  const CardTitle = () => (
    <div
      className={cx(styles.returnUserMainDiv, {
        [styles.returnUserMainDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.myInvestmentHeading, {
          [styles.myInvestmentHeadingMob]: isMobile,
        })}
      >
        {RETURN_USER_STAKING.returnUserCardTitle}
      </div>
    </div>
  );

  const CardBody = () => (
    <>
      <div
        className={cx(styles.returnUserStakeTable, {
          [styles.returnUserStakeTablemob]: isMobile,
        })}
      >
        <table className={styles.tableHeading}>
          <thead>
            {/* {!rowClicked && ( */}
            <tr
              className={cx(styles.stakeTableHeading, {
                [styles.stakeTableHeadingWeb]: !isMobile,
                [styles.stakeTableHeadingMob]: isMobile,
              })}
            >
              <th>{RETURN_USER_STAKING.acccout}</th>
              <th>{RETURN_USER_STAKING.stakedRUD}</th>
              <th>{RETURN_USER_STAKING.lockDuration}</th>
              {!isMobile && <th>{RETURN_USER_STAKING.accuredEarnings}</th>}
              {!isMobile && <th>{RETURN_USER_STAKING.maturityDate}</th>}
              <th>{RETURN_USER_STAKING.status}</th>
              {!isMobile && <th></th>}
            </tr>
            {/* )} */}
          </thead>

          <tbody>
            {formattedData.map((item, index) =>
              item.stakingDuration !== 0 ? (
                <React.Fragment key={item.index}>
                  <tr
                    className={cx(styles.stakeTableNest1, {
                      [styles.rowHidden]: expandedRowIndex === index,
                    })}
                    onClick={() =>
                      handleToggleStakeDetails(
                        index,
                        setAccountNumber(item.account),
                        setStakedAmount(item.stakedRUD),
                        setLockduration(item.lockDuration),
                        setMaturityDate(item.maturityDate),
                        setStakedTime(item.stakedTime),
                        setStatus(item.status),
                        setMaturityDateInSec(item.maturityDateInSeconds),
                        setStakeId(index),
                        setLockDurationInSeconds(item.lockDurationInSec),
                        setStakedTimeInSeconds(item.stakedTimeInSeconds)
                      )
                    }
                  >
                    <td>{item.account}</td>
                    <td>{item.stakedRUD}</td>
                    <td>{item.lockDuration}</td>
                    {!isMobile &&
                      (userVestedDetails &&
                      userVestedDetails[2] &&
                      userVestedDetails[2][index] &&
                      userVestedDetails[2][index][0] ? (
                        <td>
                          {userVestedDetails
                            ? parseFloat(
                                ethers.utils.formatEther(
                                  BigInt(
                                    userVestedDetails[2][index][0]._hex
                                  ).toString()
                                )
                              ).toFixed(MAX_DECIMAL_PLACE)
                            : "-"}
                        </td>
                      ) : (
                        <td>0</td>
                      ))}
                    {!isMobile && <td>{item.maturityDate} </td>}
                    <td
                      className={cx(styles.locked, {
                        [styles.unlocked]: item.status === "Unlocked",
                      })}
                    >
                      {item.status}
                    </td>
                    {!isMobile && item.stakedRUD !== 0 ? (
                      <td>
                        {item.maturityDateInSeconds >= currentBlockTimestamp ? (
                          <ButtonRade
                            outline
                            onClick={(event) => {
                              event.stopPropagation();
                              handleSubmitUnStakeInLock();
                            }}
                            customStyling={styles.stakeButtonInLock}
                          >
                            {RETURN_USER_STAKING.unStakeButton}
                          </ButtonRade>
                        ) : (
                          <ButtonRade
                            outline
                            onClick={(event) => {
                              event.stopPropagation();
                              handleSubmitUnStakeButtonInUnlock(
                                setStakeId(index),
                                setStakedAmount(item.stakedRUD)
                              );
                            }}
                            customStyling={styles.stakeButtonInUnlock}
                          >
                            {RETURN_USER_STAKING.unStakeButton}
                          </ButtonRade>
                        )}
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td className={styles.accordionTd} colSpan="7">
                        <StakeDetails
                          setStakedRUD={setStakedRUD}
                          updateRewardGap={updateRewardGap}
                          setUpdateRewardGap={setUpdateRewardGap}
                          lastRewardTime={lastRewardUpdated}
                          isUpdateButtonDisabled={isUpdateButtonDisabled}
                          lockDurationInSeconds={lockDurationInSeconds}
                          stakedTimeInSec={stakedTimeInSec}
                          totalPoolStakeValue={totalPoolStakeValue}
                          expandedRowIndex={expandedRowIndex}
                          toggleStakeDetails={toggleStakeDetails}
                          stakedAmount={stakedAmount}
                          lockDuration={lockDuration}
                          maturityDate={maturityDate}
                          stakedTime={stakedTime}
                          stakeId={index}
                          acrrRewards={acrrRewards}
                          setAccrRewards={setAccrRewards}
                          accountNumber={accountNumber}
                          status={status}
                          currentBlockTimestamp={currentBlockTimestamp}
                          maturityDateInSec={maturityDateInSec}
                          setStakeId={setStakeId}
                          setStakedAmount={setStakedAmount}
                          handleSubmitUnStakeInLock={handleSubmitUnStakeInLock}
                          handleSubmitUnStakeButtonInUnlock={
                            handleSubmitUnStakeButtonInUnlock
                          }
                          // setRowClicked={setRowClicked}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ) : null
            )}
          </tbody>
        </table>
      </div>
    </>
  );
  if (loading || !formattedData) {
    return <Spinner />;
  }
  return (
    <>
      <div>
        <div
          className={cx(styles.mainDiv, {
            [styles.mainDivWeb]: !isMobile,
            [styles.mainDivMob]: isMobile,
          })}
        >
          <div className={styles.returnUserStatisticsDiv}>
            <TableCard
              titleComponent={<CardTitle />}
              // calling as a function to avoid flickering issue

              bodyComponent={CardBody()}
            />
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
          {/*pass these values as props in upcoming Modals */}
          {(() => {
            switch (modalPageNumber) {
              case 0:
                return (
                  <ReturnUserPopUp
                    setModalIsOpen={setModalIsOpen}
                    setModalPageNumber={setModalPageNumber}
                    stakeId={stakeId}
                    stakedAmount={stakedAmount}
                    setIsError={setIsError}
                    setErrorMessage={setErrorMessage}
                  />
                );
              case 1:
                return <AlertForStakeInLock setModalIsOpen={setModalIsOpen} />;
              case 2:
                return (
                  <ReturnUserConfirm
                    isError={isError}
                    errorMessage={errorMessage}
                  />
                );
              case 3:
                return <ReturnUserSuccess setModalIsOpen={setModalIsOpen} />;
              default:
                return null;
            }
          })()}
        </Modal>
      </div>
    </>
  );
};
export default StakedUserDetails;
