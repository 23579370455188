const { ethers } = require("ethers");

function fromReadableAmount(amount, decimals) {
  return ethers.utils.parseUnits(amount.toString(), decimals);
}
function toReadableAmount(rawAmount, decimals) {
  return ethers.utils.formatUnits(rawAmount, decimals);
}

module.exports = { fromReadableAmount, toReadableAmount };
