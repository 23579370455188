import * as React from "react";
import styles from "../components/NavBar/Desktop/index.module.scss";
const HomeIcon = (props) => (
  <svg
    {...props}
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svgImage}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4.182A4.182 4.182 0 0 1 4.182 0h14.636A4.182 4.182 0 0 1 23 4.182v14.636A4.182 4.182 0 0 1 18.818 23H4.182A4.182 4.182 0 0 1 0 18.818V4.182ZM4.182 2.09h14.636a2.09 2.09 0 0 1 2.091 2.09v14.637a2.09 2.09 0 0 1-2.09 2.091H4.181a2.09 2.09 0 0 1-2.091-2.09V4.181A2.09 2.09 0 0 1 4.18 2.09Z"
      fill="#A5A2B8"
    />
    <path
      d="M17.773 12.027a.74.74 0 0 1-.21.518l-2.875 2.93a.712.712 0 0 1-1.008-.008.74.74 0 0 1-.008-1.027l2.366-2.413-2.367-2.413a.74.74 0 0 1 .01-1.027.712.712 0 0 1 1.007-.01l2.875 2.932a.74.74 0 0 1 .21.518Zm-7.398-3.45a.74.74 0 0 1 0 1.037l-2.367 2.413 2.367 2.413a.74.74 0 0 1-.008 1.027.712.712 0 0 1-1.008.009l-2.875-2.931a.74.74 0 0 1 0-1.036l2.875-2.931a.712.712 0 0 1 1.016 0Z"
      fill="#A5A2B8"
    />
  </svg>
);

export default HomeIcon;
