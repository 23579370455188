import React, { useState } from "react";
import cx from "classnames";
import { TOOL_TIP } from "../../../enums/homeEnum";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import styles from "./index.module.scss";
import ButtonRade from "../../../components/RadeButtons";
import RobotAvatarDefault from "../../../assets/AvatarIcons/Robot Avatars_10.svg";
import { SETTINGS_ENUM } from "../../../enums/settingsEnum";
import Modal from "react-modal";
import ProfileImagePopUp from "./ProfileImagePopUp/ProfileImagePop";
import ProfileUpdatePopUp from "../Profile/ProfileUpdatePopUp/ProfileUpdatePopUp";
import Expire from "./Expire";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
import QuestionMark from "../../../assets/QuestionMark.svg";
import { updateProfile } from "../../../api";
const Profile = () => {
  // state for hovering the avatar selection button in order to appear
  const [isHovering, setIsHovering] = useState(null);
  // Working of the hovering using handleMouseOver and handleMouseOut
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  // validation of email
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const [errorForEmail, setErrorForEmail] = useState(null);
  // State to display the status of updation in the profile
  const [avatar, setAvatar] = useState("null");
  // State for displaying error message if user isn't selected any avatar
  const [errorForUpdate, setErrorForUpdate] = useState(null);
  // state display the popup
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // state to display the image preview
  const [confirmUpdate, setConfirmUpdate] = useState(null);
  // state to display the popup for confirming the update in profile
  const [modalProfileUpdate, setModalProfileUpdate] = useState(false);
  // state for storing input name
  const [inputFieldName, setInputFieldName] = useState("");
  // state for storing input email
  const [inputFieldEmail, setInputFieldEmail] = useState("");
  // Working of update profile button
  const handlesubmitUpdateProfile = () => {
    if (!isValidEmail(inputFieldEmail)) {
      setErrorForEmail("Email is invalid");
    } else {
      setErrorForEmail(false);
      setModalProfileUpdate(true);
      setErrorForUpdate(false);
    }
  };

  const handleChangeInputFieldEmail = (event) => {
    setInputFieldEmail(event.target.value);
    setErrorForUpdate(false);
  };
  const handleChangeInputFieldName = (event) => {
    setInputFieldName(event.target.value);
    setErrorForUpdate(false);
  };
  const handleProfileConfirmation = () => {
    setModalIsOpen(true);
    setErrorForUpdate(false);
  };

  const { isMobile } = useContext(AppContext);
  const { setUserProfile } = useContext(UserContext);
  //confirm the settings updating and call patch request to backend
  const confirmProfileUpdate = async () => {
    setModalProfileUpdate(false);
    const data = {
      username: inputFieldName,
      email: inputFieldEmail,
    };
    const res = await updateProfile(data);
    if (res) {
      setConfirmUpdate(SETTINGS_ENUM.success);
      setUserProfile(data);
    } else {
      setConfirmUpdate(SETTINGS_ENUM.failed);
    }
  };
  const CardBody = () => (
    <div
      className={cx(styles.accountMainDiv, {
        [styles.accountMainDivMob]: isMobile,
      })}
    >
      {/* <div
        className={cx(styles.accountDiv, {
          [styles.accountDivWeb]: !isMobile,
          [styles.accountDivMob]: isMobile,
        })}
      >
        {SETTINGS_ENUM.profilePicture}
      </div> */}

      {/* If there is a selected avatar */}
      {avatar !== "null" ? (
        <div
          className={styles.profileImagePreview}
          onClick={handleProfileConfirmation}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <img
            alt="preview"
            src={avatar}
            className={styles.profileAvatarPreview}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleProfileConfirmation}
          />
        </div>
      ) : (
        <div
          className={cx(styles.avatarUploadButton, {
            [styles.avatarUploadButtonWeb]: !isMobile,
            [styles.avatarUploadButtonMob]: isMobile,
          })}
          onClick={handleProfileConfirmation}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <img
            className={cx(styles.profileImageDiv, {
              [styles.profileImageDivWeb]: !isMobile,
              [styles.profileImageDivMob]: isMobile,
            })}
            src={RobotAvatarDefault}
            alt="Avatar upload"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          />
        </div>
      )}

      {isHovering && (
        <div
          className={cx(styles.onHoverOverlay, {
            [styles.onHoverOverlayWeb]: !isMobile,
            [styles.onHoverOverlayMob]: isMobile,
          })}
        >
          <h2 className={styles.clickToChangeAvatar}>
            {TOOL_TIP.clickToChangeAvatar}
          </h2>
        </div>
      )}
      <br />

      {/* <div>
        <div
          className={cx(styles.profileNameEmailContainer, {
            [styles.profileNameEmailContainerWeb]: !isMobile,
            [styles.profileNameEmailContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.profileName, {
              [styles.profileNameWeb]: !isMobile,
              [styles.profileNameMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.profileNameContainer, {
                [styles.profileNameContainerWeb]: !isMobile,
                [styles.profileNameContainerMob]: isMobile,
              })}
            >
              Full Name
            </p>
            <input
              autoComplete="off"
              key="name"
              type="text"
              className={cx(styles.inputName, {
                [styles.inputNameWeb]: !isMobile,
                [styles.inputNameMob]: isMobile,
              })}
              value={inputFieldName}
              placeholder="Enter your full name"
              onChange={handleChangeInputFieldName}
            />
          </div>

          <div
            className={cx(styles.email, {
              [styles.emailWeb]: !isMobile,
              [styles.emailMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.emailContainer, {
                [styles.emailContainerWeb]: !isMobile,
                [styles.emailContainerMob]: isMobile,
              })}
            >
              Email
            </p>
            <input
              key="email"
              type="email"
              className={cx(styles.inputEmail, {
                [styles.inputEmailWeb]: !isMobile,
                [styles.inputEmailMob]: isMobile,
              })}
              placeholder="Enter your email"
              onChange={handleChangeInputFieldEmail}
              autoComplete="off"
              value={inputFieldEmail}
            />
          </div>
        </div>
        <div
          className={cx(styles.thisWillBeYourUniqueUserNameDiv, {
            [styles.thisWillBeYourUniqueUserNameDivWeb]: !isMobile,
            [styles.thisWillBeYourUniqueUserNameMob]: isMobile,
          })}
        >
          <p>{SETTINGS_ENUM.thisWillBeYourUniqueUserName}</p>
          <p>
            {SETTINGS_ENUM.userCanUseToRequest}
            <img
              src={QuestionMark}
              alt="tool tip"
              className={styles.toolTipInProfile}
            />
          </p>
        </div>
      </div> */}
      {/* <div
        className={cx(styles.updateProfileButtonDiv, {
          [styles.updateProfileButtonDivWeb]: !isMobile,
          [styles.updateProfileButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.updateProfileButton, {
            [styles.updateProfileButtonWeb]: !isMobile,
            [styles.updateProfileButtonMob]: isMobile,
          })}
          onClick={handlesubmitUpdateProfile}
        >
          {SETTINGS_ENUM.updateprofileButton}
        </ButtonRade>
      </div> */}

      {/* <div
        className={cx(styles.updateSuccessMessage, {
          [styles.updateSuccessMessageWeb]: !isMobile,
          [styles.updateSuccessMessageMob]: isMobile,
        })}
      >
        {confirmUpdate === SETTINGS_ENUM.success ? (
          <Expire delay="10000">{SETTINGS_ENUM.changesMadeSuccessfully}</Expire>
        ) : (
          confirmUpdate === SETTINGS_ENUM.failed && (
            <Expire delay="10000">{SETTINGS_ENUM.changesFailed}</Expire>
          )
        )}
      </div> */}
      {/* {errorForUpdate && (
        <ShakeError
          errorMessage={SETTINGS_ENUM.errorOnUpdate}
          customStyling={styles.alertSpan}
        />
      )}
      {errorForEmail && (
        <h2 className={styles.emailValidationError}>{errorForEmail}</h2>
      )} */}
    </div>
  );

  return (
    <>
      <div>
        {/* calling as a function to avoid flickering issue */}
        {CardBody()}
        <Modal
          isOpen={modalIsOpen}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          ariaHideApp={false}
        >
          <ProfileImagePopUp
            setModalIsOpen={setModalIsOpen}
            updateProfile={avatar}
            setUpdateProfile={setAvatar}
          />
        </Modal>
        <Modal
          isOpen={modalProfileUpdate}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          ariaHideApp={false}
        >
          <ProfileUpdatePopUp
            setModalProfileUpdate={setModalProfileUpdate}
            updateProfile={avatar}
            confirmFunc={confirmProfileUpdate}
            /*passing props to the Modal Component named MaxBorrowPopUp */
          />
        </Modal>
      </div>
    </>
  );
};

export default Profile;
