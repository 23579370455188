import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import cx from "classnames";
import Modal from "react-modal";
import { ethers } from "ethers";
import React, { useState } from "react";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import { STAKE_ENUM } from "../../../enums/stakeEnum";
import ButtonRade from "../../../components/RadeButtons";
import QuestionIcon from "../../../assets/Question Icon.svg";
import Card from "../../../components/Card";
import { Button } from "../../../components";
import DepositRUDConfirmModal from "../DepositRUDConfirmModal/DepositRUDConfirmModal";
import Stake from "../Stake";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
import StakeSuccessful from "../StakingSuccessfulModal/StakingSuccessfulModal";
import RudstakingContract from "../../../../src/artifacts/Contracts/stablecoin/RUDStaking.sol/RUDStaking.json";
import erc20PermitSignature from "../../../components/PermitSignature/ERC20PermitSignature";
import RaritiUsdContract from "../../../../src/artifacts/Contracts/stablecoin/RaritiUSD.sol/RaritiUSD.json";
import GetSignStaking from "../GetSignFromUser/GetSignFromUserModal";
import SignatureRequestSuccessfulModal from "../SignatureRequestSuccessful/SignatureRequestSuccessfulModal";
import SelectBox from "../../borrow/SelectBox/SelectBox";
import { TOOL_TIP } from "../../../enums/homeEnum";
import FailedSignatureRequest from "../../../components/FailedSignatureRequest/FailedSignatureRequest";
import styles from "../AddRUDInLiquidityPool/index.module.scss";
import useRudInWallet from "../../../Hooks/useRudBalance/useRudBalance";
const PERCENTAGE_CONVERSION = 100;
const LiquidityPool = ({
  setIsError,
  setErrorMessage,
  isError,
  errorMessage,
}) => {
  const { account } = useContext(UserContext);
  // Methods for tooltip
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.radePool}
    </Tooltip>
  );
  const { totalRadeInPool, rudBalance } = useRudInWallet(account);

  //state to set the amount of RUD that user wants to deposit
  const [RUDDeposit, setRUDDeposit] = useState("");
  //State to set an error message if the user hasn't entered any amount before Confirm button onClick.
  const [errorForConfirm, setErrorForConfirm] = useState(false);
  //state to switch to previous page when clicking on the Cancel button.
  const [returnStakeLandingPage, setReturnStakeLandingPage] = useState(false);
  const [modalPageNumber, setModalPageNumber] = useState(null);
  // Setting state for checking whether the staking term dropdown is selected or not
  const [selectedStakingDuration, setSelectedStakingDuration] = useState(null);
  // setting error message when staking duration is not selected
  const [stakingDurationError, setStakingDurationError] = useState(false);
  // setting errror message when staking amount is not entered.
  const [stakingAmountError, setStakingAmountError] = useState(false);
  const [etherscanTransaction, setEtherscanTransaction] = useState(null);
  const stakingDurationOptions = [
    { value: 3600, label: "1 Hour" },
    { value: STAKE_ENUM.threeMonthsStakingDuration, label: "3 Months" },
    { value: STAKE_ENUM.sixMonthsStakingDuration, label: "6 Months" },
    { value: STAKE_ENUM.nineMonthsStakingDuration, label: "9 Months" },
    { value: STAKE_ENUM.oneYearStakingDuration, label: "1 Year" },
  ];
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  //Staking Rud Contract
  const stakingContract = new ethers.Contract(
    RudstakingContract.address, // deployed contract address
    RudstakingContract.abi, //deployed contract abi
    signer
  );
  //RaritiUsd Contract
  const RaritiUsdDeployed = new ethers.Contract(
    RaritiUsdContract.address,
    RaritiUsdContract.abi,
    signer
  );
  //function to get the amount of RUD that user enter.
  const getUserEnteredRUDforDeposit = (e) => {
    const amount = e.target.value;
    setRUDDeposit(amount);
    setErrorForConfirm(false);
    setStakingAmountError(false);
    setStakingDurationError(false);
  };
  const handleSubmitConfirmButton = async () => {
    if (!RUDDeposit && !selectedStakingDuration) {
      setErrorForConfirm(true);
      setModalIsOpen(false);
    } else if (!RUDDeposit && selectedStakingDuration) {
      setStakingAmountError(true);
    } else if (RUDDeposit && !selectedStakingDuration) {
      setStakingDurationError(true);
    } else if (RUDDeposit && selectedStakingDuration) {
      setModalIsOpen(true);
      setModalPageNumber(0);
      try {
        setIsError(false);
        const stakingAmount = ethers.utils.parseUnits(
          RUDDeposit.toString(),
          "ether"
        );
        const stakingDuration = selectedStakingDuration.value;
        const { r, s, v, transactionDeadline, isRejected } =
          await erc20PermitSignature(
            account,
            stakingContract.address,
            stakingAmount,
            RaritiUsdDeployed,
            provider
          );
        if (isRejected) {
          setModalPageNumber(4);
        } else {
          setModalPageNumber(1);
          setTimeout(() => {
            setModalPageNumber(2);
          }, 3000);
          const stakingTx = await stakingContract.stakeToken(
            stakingAmount,
            stakingDuration,
            transactionDeadline,
            v,
            r,
            s
          );
          const receipt = await stakingTx.wait();
          if (receipt.status === 1) {
            setEtherscanTransaction(receipt.transactionHash);
            setModalIsOpen(true);
            setModalPageNumber(3);
          }
        }
      } catch (e) {
        console.log("e", e);
        if (e.code === 4001) {
          setIsError(true);
          setErrorMessage(STAKE_ENUM.transactionRejectedError);
        } else {
          setIsError(true);
          setErrorMessage(e.code);
        }
        setTimeout(() => setModalIsOpen(false), 4000);
      }
    }
  };
  const handleOnClickMaxButton = () => {
    setRUDDeposit(rudBalance);
  };
  //Function to return to Stake Landing Page on clicking the cancel button
  const handleSubmitCancelButton = () => {
    setErrorForConfirm(false);
    setReturnStakeLandingPage(true);
  };
  const handleChangeDurationSelection = () => {
    setStakingDurationError(false);
  };
  const dismissError = () => {
    setStakingDurationError(false);
    setStakingAmountError(false);
    setErrorForConfirm(false);
  };
  // state to set the Modal Window open.
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const Pool_Share = (RUDDeposit + totalRadeInPool) / PERCENTAGE_CONVERSION; // get equivalent percentage of RUD entered by user in Liquidity pool.

  const { isMobile } = useContext(AppContext);
  const CardTitle = () => (
    <>
      <div className={styles.stakeTitleContainer}>
        <div
          className={cx(styles.stakeHeadingText, {
            [styles.stakeHeadingTextMob]: isMobile,
          })}
        >
          {STAKE_ENUM.Stake_Card_Title}
        </div>
        <div
          className={cx(styles.questionIconDiv, {
            [styles.questionIconDivMob]: isMobile,
          })}
        >
          <OverlayTrigger placement="right" overlay={renderTooltip1}>
            <img
              src={QuestionIcon}
              alt="Question_Icon"
              className={cx(styles.questionIcon, {
                [styles.questionIconMob]: isMobile,
              })}
            />
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
  const CardBody = () => (
    <>
      <div className={styles.liquidityPoolDiv}>
        <div
          className={cx(styles.addRudInLP, {
            [styles.addRudInLPMob]: isMobile,
          })}
        >
          {STAKE_ENUM.RUD_Liquidity_Pool}
        </div>
      </div>
      <div
        className={cx(styles.stakeRUD, {
          [styles.stakeRUDMob]: isMobile,
        })}
      >
        <div className={styles.stakeRUDInLPDiv}>
          <div
            className={cx(styles.depositingRUD, {
              [styles.depositingRUDMob]: isMobile,
            })}
          >
            {STAKE_ENUM.deposit}
          </div>
          <div className={styles.maxButtonDiv}>
            <Button
              onClick={handleOnClickMaxButton}
              className={cx(styles.maxButton, {
                [styles.maxButtonMob]: isMobile,
              })}
            >
              Max
            </Button>
          </div>
        </div>
        <div
          className={cx(styles.payingEthValue, {
            [styles.payingEthValueMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.dollarSymbol, {
              [styles.dollarSymbolMob]: isMobile,
            })}
          >
            $
          </div>
          <input
            type="number"
            name="EthPay"
            value={RUDDeposit}
            onChange={getUserEnteredRUDforDeposit}
            onKeyDown={(e) => {
              if (e.key === "-") {
                e.preventDefault();
              }
            }}
            placeholder="0.0"
            autoFocus
            autocomplete="off"
            className={cx(styles.inputAmount, {
              [styles.inputAmountMob]: isMobile,
            })}
          ></input>
          <div
            className={cx(styles.payingCrypto, {
              [styles.payingCryptoMob]: isMobile,
            })}
          >
            RUD
          </div>
        </div>
      </div>
      <div
        className={cx(styles.poolShareDiv, {
          [styles.poolShareDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.poolShare, {
            [styles.poolShareMob]: isMobile,
          })}
        >
          {STAKE_ENUM.pool_share}
        </div>
        <div
          className={cx(styles.sharePercentage, {
            [styles.sharePercentageMob]: isMobile,
          })}
        >
          {RUDDeposit ? Number(Pool_Share).toFixed(4) : "0.0000"}%
        </div>
      </div>
      <div
        className={cx(styles.stakingDurationDropdownDiv, {
          [styles.stakingDurationDropdownDivMob]: isMobile,
        })}
      >
        <SelectBox
          placeHolder="Staking Duration"
          options={stakingDurationOptions}
          onChange={handleChangeDurationSelection}
          setSelectedValue={setSelectedStakingDuration}
          selectedValue={selectedStakingDuration}
          errorDismissOnclick={dismissError}
        />
      </div>
      {!isMobile && (
        <div className={styles.ButtonsDiv}>
          {!isMobile && (
            <div className={styles.alert}>
              {errorForConfirm && (
                <ShakeError
                  errorMessage={STAKE_ENUM.error_for_confirm}
                  customStyling={styles.alertBeforeConfirm}
                />
              )}
              {stakingAmountError && (
                <ShakeError
                  errorMessage={STAKE_ENUM.errorForStakingAmount}
                  customStyling={styles.alertBeforeConfirm}
                />
              )}
              {stakingDurationError && (
                <ShakeError
                  errorMessage={STAKE_ENUM.errorForStakingDuration}
                  customStyling={styles.alertBeforeConfirm}
                />
              )}
            </div>
          )}
          <div>
            <ButtonRade
              outline
              onClick={handleSubmitCancelButton}
              customStyling={styles.cancelButton}
            >
              {STAKE_ENUM.cancel_button}
            </ButtonRade>
            <ButtonRade
              outline
              onClick={handleSubmitConfirmButton}
              customStyling={styles.confirmButton}
            >
              {STAKE_ENUM.confirm_button}
            </ButtonRade>
          </div>
        </div>
      )}
      {isMobile && (
        <div className={styles.mobButtonsDiv}>
          <ButtonRade
            outline
            onClick={handleSubmitCancelButton}
            customStyling={styles.cancelButtonMob}
          >
            {STAKE_ENUM.cancel_button}
          </ButtonRade>
          <ButtonRade
            outline
            onClick={handleSubmitConfirmButton}
            customStyling={styles.confirmButtonMob}
          >
            {STAKE_ENUM.confirm_button}
          </ButtonRade>
        </div>
      )}
    </>
  );
  return (
    <>
      {returnStakeLandingPage ? (
        <Stake />
      ) : (
        // calling as a function to avoid flickering issue
        <Card titleComponent={<CardTitle />} children={CardBody()} />
      )}
      {isMobile && errorForConfirm && (
        <ShakeError
          errorMessage={STAKE_ENUM.error_for_confirm}
          customStyling={styles.alertBeforeConfirmMob}
        />
      )}

      {/*Use the react Modal component to opens up the deposit RUD Confirmation Modal*/}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return <GetSignStaking />;
            case 1:
              return <SignatureRequestSuccessfulModal />;
            case 2:
              return (
                <DepositRUDConfirmModal
                  isError={isError}
                  errorMessage={errorMessage}
                  value={RUDDeposit}
                  setModalIsOpen={setModalIsOpen}
                  //Pass these values as props in the upcoming modal
                />
              );
            case 3:
              return (
                <StakeSuccessful
                  setModalIsOpen={setModalIsOpen}
                  etherscanTransaction={etherscanTransaction}
                />
              );
            case 4:
              return <FailedSignatureRequest setModalIsOpen={setModalIsOpen} />;
            default:
              return null;
          }
        })()}
        {/*Opens up the appropriate modal based on the page number */}
      </Modal>
    </>
  );
};

export default LiquidityPool;
