import React from "react";
import { AppContext } from "../../../../context";
import styles from "./index.module.scss";
import { useContext, useState } from "react";
import { SETTINGS_ENUM } from "../../../../enums/settingsEnum";
import ButtonRade from "../../../../components/RadeButtons";
import cx from "classnames";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../firebase";
import {
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";

const Emailverification = ({
  setKYCPageNumber,
  KycStatus,
  setKycStatus,
  email,
}) => {
  const { isMobile } = useContext(AppContext);
  const [otp, setOTP] = useState(""); // State to store OTP input
  const [otpError, setOTPError] = useState(""); // State to store OTP validation error message
  //Function that handles cancel button
  const handleSubmitCancelButton = () => {
    setKYCPageNumber(0);
  };
  // Function to handle next button
  const handleSubmitNextButton = () => {
    // Validate OTP locally first
    // if (otp) {

    // after that we will complete the login process
    if (isSignInWithEmailLink(auth, window.location.href)) {
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // we can get the user from result.user but no need in this case
          console.log("result.user", result.user);
          setKYCPageNumber(2);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else {
      console.error("Invalid email link");
    }

    // } else {
    // setOTPError("Please enter a valid OTP.");
    // }
  };

  const CardBodyEmailVerification = () => (
    <>
      <div
        className={cx(styles.personalInformationMainDiv, {
          [styles.personalInformationMainDivWeb]: !isMobile,
          [styles.personalInformationMainDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.butttonContent, {
            [styles.butttonContentMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.contentText, {
              [styles.contentTextMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton1, {
                [styles.stepsButton1Mob]: isMobile,
              })}
            >
              1
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton2, {
                [styles.stepsButton2Mob]: isMobile,
              })}
            >
              2
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton3, {
                [styles.stepsButton3Mob]: isMobile,
              })}
            >
              3
            </div>
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton4, {
              [styles.stepsButton4Mob]: isMobile,
            })}
          >
            4
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton5, {
              [styles.stepsButton5Mob]: isMobile,
            })}
          >
            5
          </div>
        </div>

        <div
          className={cx(styles.step3Text, {
            [styles.step3TextWeb]: !isMobile,
            [styles.step3TextMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.step3}
        </div>
        <div
          className={cx(styles.personalInfo, {
            [styles.personalInfoWeb]: !isMobile,
            [styles.personalInfoMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.emailVerification}
        </div>
        <div className={styles.OTPContainer}>
          <div
            className={cx(styles.OTPDiv, {
              [styles.OTPDivWeb]: !isMobile,
              [styles.OTPDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.OTPText, {
                [styles.OTPTextWeb]: !isMobile,
                [styles.OTPTextMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.OTP}
            </div>
            <div>
              <input
                type="text"
                placeholder="Enter OTP received in your email"
                className={cx(styles.OTPInput, {
                  [styles.OTPInputWeb]: !isMobile,
                  [styles.OTPInputMob]: isMobile,
                })}
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
              />
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className={styles.resendOTPDiv}>
            <div className={styles.didnotGet}>{SETTINGS_ENUM.didnotGet}</div>
            <div className={styles.resendOTP}>{SETTINGS_ENUM.resendOTP}</div>
          </div>
        )}
        {otpError && <div className={styles.error}>{otpError}</div>}
        <div
          className={cx(styles.bottomButtonDiv, {
            [styles.bottomButtonDivWeb]: !isMobile,
            [styles.bottomButtonDivMob]: isMobile,
          })}
        >
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonWeb]: !isMobile,
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={handleSubmitCancelButton}
          >
            {SETTINGS_ENUM.backButton}
          </ButtonRade>
          <ButtonRade
            outline
            customStyling={cx(styles.nextButton, {
              [styles.nextButtonWeb]: !isMobile,
              [styles.nextButtonMob]: isMobile,
            })}
            onClick={handleSubmitNextButton}
          >
            {SETTINGS_ENUM.nextButton}
          </ButtonRade>
        </div>
        {isMobile && (
          <div className={styles.resendOTPDivMob}>
            <div className={styles.didnotGet}>{SETTINGS_ENUM.didnotGet}</div>
            <div className={styles.resendOTP}>{SETTINGS_ENUM.resendOTP}</div>
          </div>
        )}
      </div>
    </>
  );
  return <div>{CardBodyEmailVerification()}</div>;
};

export default Emailverification;
