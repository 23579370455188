import React, { useState } from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../TransactionSubmit/index.module.scss";
import uploadIcon from "../../../assets/Upload Icon.svg";
import ButtonRade from "../../../components/RadeButtons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { HOME_ENUM } from "../../../enums/homeEnum";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
import RaritiUsdContract from "../../../../src/artifacts/Contracts/stablecoin/RaritiUSD.sol/RaritiUSD.json";
import CrossIcon from "../../../assets/CrossIcon";
const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;
const SIX_DECIMAL_PLACES = 6;
const TransactionSubmit = ({
  setModalIsOpen,
  mintSuccessful,
  coinsMinted,
  etherscanTransaction,
}) => {
  let navigate = useNavigate();
  const { isMobile } = useContext(AppContext);
  //state to set an error messsage if the user deny to manually add token to the wallet asset.
  const [tokenAddRejectError, setTokenAddRejectError] = useState(false);
  //Function to make the modal close and return to Home Page
  const closeTransactionsuccessfulWindow = () => {
    setModalIsOpen(false);
    navigate("/"); //return back to homescreen
  };
  //function to handle the adding of suggested token to wallet asset.
  const handleRudTokenAdd = async () => {
    setTokenAddRejectError(false);
    await window.ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: RaritiUsdContract.address, // The address that the token is at.
            symbol: "RUD", // A ticker symbol or shorthand, up to 5 chars.
            decimals: "18",
          },
        },
      })
      .then(() => {
        setModalIsOpen(false);
        navigate("/");
      })
      .catch((e) => {
        if (e.code === 4001) {
          setTokenAddRejectError(true);
        }
      });
  };
  return (
    <div className={styles.transactionSubmitDiv}>
      <div
        className={cx(styles.crossIconDiv, {
          [styles.crossIconDivMob]: isMobile,
        })}
      >
        <Button onClick={closeTransactionsuccessfulWindow}>
          <CrossIcon />
        </Button>
      </div>
      <div
        className={cx(styles.uploadIconDiv, {
          [styles.uploadIconDivMob]: isMobile,
        })}
      >
        <img
          src={uploadIcon}
          alt="upload-icon"
          className={cx(styles.uploadIcon, {
            [styles.uploadIconMob]: isMobile,
          })}
        />
      </div>
      {mintSuccessful && (
        <div className={styles.mintSuccessfulDiv}>
          <div
            className={cx(styles.mintedRudAmount, {
              [styles.mintedRudAmountMob]: isMobile,
            })}
          >
            $ {Number(coinsMinted).toFixed(SIX_DECIMAL_PLACES)} RUD Coins minted
            successfully!
          </div>
        </div>
      )}
      <div className={styles.viewTransactionDetails}>
        <a
          href={`https://goerli.etherscan.io/tx/${etherscanTransaction}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.redirectToEtherScan}
        >
          {RUD_ENUM.transaction_details}
        </a>
      </div>
      <div
        className={cx(styles.addRudButtonDiv, {
          [styles.addRudButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.addRudButton, {
            [styles.addRudButtonMob]: isMobile,
          })}
          onClick={handleRudTokenAdd}
        >
          {RUD_ENUM.Add_Rud_button}
        </ButtonRade>
      </div>
      <div
        className={cx(styles.addTokenInWallet, {
          [styles.addTokenInWalletMob]: isMobile,
        })}
      >
        {RUD_ENUM.addTokenInWalletAssets}
      </div>
      {tokenAddRejectError && (
        <ShakeError
          errorMessage={HOME_ENUM.en.userDeniedTokenAdding}
          customStyling={cx(styles.alertDeniedTransaction, {
            [styles.alertDeniedTransactionMob]: isMobile,
          })}
        />
      )}
    </div>
  );
};
export default TransactionSubmit;
