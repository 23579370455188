import React, { useState, useEffect } from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "./EthSell.module.scss";
import ButtonRade from "../../../components/RadeButtons";
import Modal from "react-modal";
import SellSwapPopUp from "../SellSwapPopUp/SellSwapPopUp";
import SellConfirmPopUp from "../SellConfirmPopUp/SellConfirmPopUp";
import SellConfirmTransactionPopUp from "../SellConfirmTransactionPopUp/SellConfirmTransactionPopUp";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
import GetSignerFromUser from "../GetSignerFromUser/GetSignerFromUser";
import SignatureRequestSuccessfull from "../SignatureRequestSuccessful/SignatureRequestSuccessfull";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
import Skeleton from "react-loading-skeleton";
import "../../../components/ReactLoading/reactLoading.css";
import FailedSignatureRequest from "../../../components/FailedSignatureRequest/FailedSignatureRequest";
import useRudInWallet from "../../../Hooks/useRudBalance/useRudBalance";
import { ethers } from "ethers";
import StableCoinMinterContract from "../../../../src/artifacts/Contracts/stablecoin/RaritiStableCoinMinter.sol/RaritiStableCoinMinter.json";
import useUniSwapQuote from "../../../Hooks/useETHPriceUniswap/useUniSwapQuote";
const DECIMAL_PLACES = 6;
const PERCENTAGE_CONVERSION = 100;
const FLOORING_FACTOR = 100; // for fixing the issue of underflow numeric fault
let currAddress = null;
const EthSell = (
  {
    setUserModeSelection,
    setCanProceed,
    setErrorMessage,
    setIsError,
    setModalOpen,
    displayKYCAlert,
    setDisplayKYCAlert,
    setWaitingConfirmation,
    setConfirmTransaction,
  },
  interval = null
) => {
  const { account } = useContext(UserContext);
  const [tokenName, setTokenName] = useState(null);
  const [priceFeedAddress, setPriceFeedAddress] = useState(null);
  const WETH_UNISWAP_PRICE = useUniSwapQuote();
  //timer to check each 10sec on which metamask network is connected
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { priceFeedAddress, tokenName } = getNetworkDetails(chainID);
        setPriceFeedAddress(priceFeedAddress);
        setTokenName(tokenName);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };
    if (priceFeedAddress !== currAddress) {
      clearInterval(interval);
      currAddress = priceFeedAddress;
    }

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
      currAddress = null;
    };
  }, [priceFeedAddress, tokenName]);
  const { rudBalance } = useRudInWallet(account);
  //State to set the Modal window open or close.
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //State to set the paying RUD amount by the user.
  const [RUDpay, setRUDpay] = useState(null);
  //State to set error message before proceed.
  const [errorForProceed, setErrorForProceed] = useState(false);
  const [errorForNegativeInput, setErrorForNegativeInput] = useState(false);
  const [errorForInsufficientFund, setErrorForInsufficientFund] =
    useState(false);
  // state for saving the permitSignature parameters
  const [signatureParameters, setSignatureParameters] = useState(false);
  const [etherscanTransaction, setEtherscanTransaction] = useState(null);
  const [radeFees, setRadeFees] = useState("");
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  //Borrow Rud Contract
  const coinMinterContract = new ethers.Contract(
    StableCoinMinterContract.address, // deployed contract address
    StableCoinMinterContract.abi, //deployed contract abi
    signer
  );
  const getRadeFees = async () => {
    if (coinMinterContract) {
      const RadeFees = await coinMinterContract.rampFee();
      setRadeFees(RadeFees);
    } else {
      setRadeFees(undefined);
    }
  };
  useEffect(() => {
    getRadeFees();
  }, []);
  const rampFee = Number(radeFees) / PERCENTAGE_CONVERSION;
  //Equivalent value of RUD(provided a random digit, to be removed)
  var getEqvETH =
    RUDpay / WETH_UNISWAP_PRICE - (RUDpay / WETH_UNISWAP_PRICE) * rampFee;
  const maxRudInWallet = Number(rudBalance).toFixed(DECIMAL_PLACES);
  const handleChangeForGettingValue = (e) => {
    setErrorForInsufficientFund(false);
    setRUDpay(e.target.value);
    setErrorForNegativeInput(false);
    setErrorForProceed(false);
  };
  const handleSubmitBackButton = () => {
    setUserModeSelection(null);
    setCanProceed(false);
  };
  // Function to activate the proceed Button according to the state variable value
  const handleSubmitProceedButton = () => {
    setModalPageNumber(0);
    if (RUDpay < 0) {
      setModalIsOpen(false);
      setErrorForNegativeInput(true);
      setErrorForProceed(false);
      setErrorForInsufficientFund(false);
    } else if (Number(RUDpay) > Number(maxRudInWallet)) {
      setModalIsOpen(false);
      setErrorForNegativeInput(false);
      setErrorForInsufficientFund(true);
    }
    // if user haven'e entered any value in the input field
    else if (!RUDpay) {
      setErrorForProceed(true);
    } else {
      setErrorForNegativeInput(false);
      setErrorForProceed(false);
      setErrorForInsufficientFund(false);
      setModalIsOpen(true);
    }
  };
  const handleOnClickMaxButton = () => {
    setRUDpay(maxRudInWallet);
  };
  const [modalPageNumber, setModalPageNumber] = useState(null);
  const { isMobile } = useContext(AppContext);
  //State to open the Modals accoding to the cases in Switch statement, provided with Modal Page Number
  const CardBody = () => (
    <div
      className={cx(styles.contentSellDiv, {
        [styles.contentSellDivMob]: isMobile,
      })}
    >
      {WETH_UNISWAP_PRICE && tokenName ? (
        <div
          className={cx(styles.enterAnAmountOfRUDToSell, {
            [styles.enterAnAmountOfRUDToSellMob]: isMobile,
          })}
        >
          {RUD_ENUM.enterAnAmountOfRUDToSell}
        </div>
      ) : (
        <div
          className={cx(styles.enterAnAmountOfRUDToSellSkeleton, {
            [styles.enterAnAmountOfRUDToSellMob]: isMobile,
          })}
        >
          <Skeleton
            style={{
              display: "block",
              backgroundColor: "rgb(25, 39, 59)",
              width: "94%",
              height: "20px",
            }}
          />
        </div>
      )}
      {/* Skeleton loading if the value ETH is fetching */}

      {WETH_UNISWAP_PRICE && tokenName ? (
        <div
          className={cx(styles.rudpayDiv, {
            [styles.rudpayDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.rudpayh3Div, {
              [styles.rudpayh3DivMob]: isMobile,
            })}
          >
            <h3
              className={cx(styles.rudpayh3, {
                [styles.rudpayh3Mob]: isMobile,
              })}
            >
              You Sell
            </h3>
            {/* Custom styled Max Button */}
            <ButtonRade
              onClick={handleOnClickMaxButton}
              outline
              customStyling={cx(styles.maxButton, {
                [styles.maxButtonMob]: isMobile,
              })}
            >
              Max
            </ButtonRade>
          </div>

          <div
            className={cx(styles.inputFieldDivOne, {
              [styles.inputFieldDivOneMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.inputFieldDiv, {
                [styles.inputFieldDivMob]: isMobile,
              })}
            >
              $
              <input
                type="number"
                className={cx(styles.rudpayInput, {
                  [styles.rudpayInputMob]: isMobile,
                })}
                name="rudpay"
                value={RUDpay}
                onChange={handleChangeForGettingValue}
                onKeyDown={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
                placeholder="0.0"
                autoComplete="off"
                autoFocus
              ></input>
            </div>
            <div
              className={cx(styles.spanRUDGet, {
                [styles.spanRUDGetMob]: isMobile,
              })}
            >
              {RUD_ENUM.RUD_Heading}
            </div>
          </div>
        </div>
      ) : (
        <Skeleton
          style={{
            display: "block",
            backgroundColor: "rgb(25, 39, 59)",
            width: "91%",
            height: "86px",
            marginLeft: "32px",
          }}
        />
      )}

      {WETH_UNISWAP_PRICE && tokenName ? (
        <div
          className={cx(styles.rudgetDiv, {
            [styles.rudpayDivMob]: isMobile,
          })}
        >
          <h3
            className={cx(styles.rudgeth3, {
              [styles.rudgeth3Mob]: isMobile,
            })}
          >
            {RUD_ENUM.get}
          </h3>

          <div
            className={cx(styles.inputgetDivOneMain, {
              [styles.inputgetDivOneMainMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.inputgetDivOne, {
                [styles.inputgetDivMob]: isMobile,
              })}
            >
              <div
                className={cx(styles.rudpay, {
                  [styles.rudpayMob]: isMobile,
                })}
              />
              {Number(getEqvETH / FLOORING_FACTOR).toFixed(9)}
            </div>
            <div
              className={cx(styles.spanRUDGet, {
                [styles.spanRUDGetMob]: isMobile,
              })}
            >
              {tokenName}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={cx(styles.rudgetDivSkeleton, {
            [styles.rudpayDivMob]: isMobile,
          })}
        >
          <Skeleton
            style={{
              display: "block",
              backgroundColor: "rgb(25, 39, 59)",
              width: "91%",
              height: "86px",
              marginLeft: "32px",
            }}
          />
        </div>
      )}
      {/*error message before Proceed */}
      {errorForProceed && (
        <ShakeError
          errorMessage={RUD_ENUM.swapError_SellFlow}
          customStyling={styles.alertSpan}
        />
      )}
      {errorForNegativeInput && (
        <ShakeError
          errorMessage={RUD_ENUM.errorForNegative}
          customStyling={styles.alertSpan}
        />
      )}
      {errorForInsufficientFund && (
        <ShakeError
          errorMessage={RUD_ENUM.insufficientAmountAlert}
          customStyling={styles.alertSpan}
        />
      )}

      <div
        className={cx(styles.proceedButtonDiv, {
          [styles.proceedButtonDivMob]: isMobile,
        })}
      >
        {/* Custom styled back button */}
        <ButtonRade
          outline
          customStyling={cx(styles.backButton, {
            [styles.backButtonMob]: isMobile,
          })}
          onClick={handleSubmitBackButton}
        >
          {RUD_ENUM.backButton}
        </ButtonRade>
        {/* Custom styled button for proceed */}
        <ButtonRade
          outline
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          onClick={() => {
            handleSubmitProceedButton();
          }}
        >
          {RUD_ENUM.proceed_button}

          {/* <ProceedIcon /> */}
        </ButtonRade>
      </div>
    </div>
  );

  return (
    // <RudContext.Provider value={{ setModalPageNumber }}>
    <div>
      <div
        className={cx(styles.buttonTableDiv, {
          [styles.buttonTableDivMob]: isMobile,
        })}
      >
        {/* calling as a function to avoid flickering issue */}
        {CardBody()}
        <Modal
          isOpen={modalIsOpen}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
          {/*pass these values as props in upcoming Modals */}
          {(() => {
            switch (modalPageNumber) {
              case 0:
                return (
                  // Importing permission granting component
                  <GetSignerFromUser
                    setModalPageNumber={setModalPageNumber}
                    value={RUDpay}
                    setSignatureParameters={setSignatureParameters}
                    signatureParameters={signatureParameters}
                    setErrorMessage={setErrorMessage}
                    setIsError={setIsError}
                    // props that passes the state for opening the modal
                    setModalOpen={setModalOpen}
                  />
                );
              case 1:
                // Importing the component that displays "permission granted successfully"
                return (
                  <SignatureRequestSuccessfull
                    setModalPageNumber={setModalPageNumber}
                    value={RUDpay}
                    setErrorMessage={setErrorMessage}
                    setIsError={setIsError}
                  />
                );
              case 2:
                return (
                  <SellSwapPopUp
                    value={RUDpay}
                    setModalIsOpen={setModalIsOpen}
                    modalIsOpen={modalIsOpen}
                    setModalPageNumber={setModalPageNumber}
                    getEqvETH={getEqvETH}
                    displayKYCAlert={displayKYCAlert}
                    setDisplayKYCAlert={setDisplayKYCAlert}
                    setWaitingConfirmation={setWaitingConfirmation}
                    setConfirmTransaction={setConfirmTransaction}
                    signatureParameters={signatureParameters}
                    setErrorMessage={setErrorMessage}
                    setIsError={setIsError}
                    setEtherscanTransaction={setEtherscanTransaction}
                    // props that passes the state for opening the modal

                    setModalOpen={setModalOpen}
                  />
                );
              case 3:
                return (
                  <SellConfirmPopUp
                    setModalIsOpen={setModalIsOpen}
                    modalIsOpen={modalIsOpen}
                    setModalPageNumber={setModalPageNumber}
                    displayKYCAlert={displayKYCAlert}
                    setDisplayKYCAlert={setDisplayKYCAlert}
                    setWaitingConfirmation={setWaitingConfirmation}
                    setConfirmTransaction={setConfirmTransaction}
                  />
                );
              case 4:
                return (
                  <SellConfirmTransactionPopUp
                    setModalIsOpen={setModalIsOpen}
                    setModalPageNumber={setModalPageNumber}
                    displayKYCAlert={displayKYCAlert}
                    setDisplayKYCAlert={setDisplayKYCAlert}
                    setWaitingConfirmation={setWaitingConfirmation}
                    setConfirmTransaction={setConfirmTransaction}
                    etherscanTransaction={etherscanTransaction}
                  />
                );
              case 5:
                return (
                  <FailedSignatureRequest
                    setModalIsOpen={setModalIsOpen}
                    setModalPageNumber={setModalPageNumber}
                  />
                );
              default:
                return null;
            }
          })()}
        </Modal>
        {/*Opens up the appropriate modal based on the page number */}
      </div>
    </div>
    // </RudContext.Provider>
  );
};
export default EthSell;
