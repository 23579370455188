import styles from "./index.module.scss";
import { Button } from "../../../../components";
import React from "react";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../../../context";
import { BORROW_ENUM } from "../../../../enums/borrowEnum";
import { SETTINGS_ENUM } from "../../../../enums/settingsEnum";
const KYCConfirmPopUp = ({
  updateFirebaseUserProfile,
  setModalOpen,
  setKYCPageNumber,
  firstName,
  lastName,
  email,
  selectedCountryCode,
  phoneNumber,
  DOB,
  country,
  region,
  addressLineOne,
  addressLineTwo,
  city,
  zipCode,
  idBackSide,
  idFrontSide,
  pancardHolding,
  aadhaarHolding,
  userName,
  bankName,
  setBankName,
  bankAccountType,
  setBankAccountType,
  ABARoutingNumber,
  setABARoutingNumber,
  accountNumber,
  setAccountNumber,
}) => {
  const { isMobile } = useContext(AppContext);
  //Function to make the Modal close.
  function handleClickCompleteKYC() {
    updateFirebaseUserProfile(
      firstName,
      lastName,
      userName,
      email,
      DOB,
      selectedCountryCode,
      phoneNumber,
      country,
      region,
      addressLineOne,
      addressLineTwo,
      city,
      zipCode,
      idFrontSide,
      idBackSide,
      pancardHolding,
      aadhaarHolding,
      "Pending",
      bankName,
      bankAccountType,
      ABARoutingNumber,
      accountNumber
    );
    setModalOpen(false);
    setKYCPageNumber(4);
  }
  function closeModal() {
    setModalOpen(false);
  }
  return (
    <div>
      <div className={styles.popUpContentDiv}>
        <div
          className={cx(styles.popUpContent, {
            [styles.popUpContentMob]: isMobile,
          })}
        >
          {" "}
          {SETTINGS_ENUM.youWillNotBeAbleTo}
        </div>
        <div
          className={cx(styles.popUpButtonDiv, {
            [styles.popUpButtonDivMob]: isMobile,
          })}
        >
          <Button
            className={cx(styles.cancelButton, {
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={closeModal}
          >
            {BORROW_ENUM.cancelButton}
          </Button>

          <Button
            className={cx(styles.completeKYCButton, {
              [styles.completeKYCButtonMob]: isMobile,
            })}
            onClick={handleClickCompleteKYC}
          >
            {SETTINGS_ENUM.confirmButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default KYCConfirmPopUp;
