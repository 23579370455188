export const STAKE_ENUM = {
  Stake_Card_Title: "RADE Pool",
  Stake_Content:
    "You have no RUD in the RADE Liquidity Pool. Buy RUD from our inbuilt marketplace.",
  Stake_Reward_Earn: "Earn RADE rewards by staking RUD.",
  buy_RUD_Button: "Buy RUD",
  stakeButton: "Stake",
  RUD_Liquidity_Pool:
    "Enter the amount of RUD that you wish to stake in the Liquidity Pool. ",
  pool_share: "Pool Share",
  cancel_button: "Cancel",
  confirm_button: "Confirm",
  notification:
    "Become a Steward of the RADE pool by providing liquidity and earn revenue.",
  error_for_deposit: "You have no RUD in your account!",
  error_for_confirm:
    "Please specify the staking amount as well as the duration!",
  errorForStakingAmount: "Enter an amount to continue!",
  errorForStakingDuration: "Please select a staking duration to continue!",
  addingRUD: "Adding $",
  addingRUD_in_LP: "RUD to the Liquidity Pool",
  deposit: "Deposit",
  stakeSuccessful: "Staking Successful",
  closeButton: "Close",
  stakeFailed: "Staking Failed",
  tryAgain: "Try again",
  stakeContentReturnUser: " Buy RUD from our marketplace.",
  returnStakedUser:
    "Stake your $RUD to the liquidity pool and become a steward of the RADE's credit community.",
  threeMonthsStakingDuration: 7890000,
  sixMonthsStakingDuration: 15780000,
  nineMonthsStakingDuration: 23670000,
  oneYearStakingDuration: 31536000,
  transactionRejectedError: "You rejected the transaction!",
};
