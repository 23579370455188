import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import RaritiUsdContract from "../../artifacts/Contracts/stablecoin/RaritiUSD.sol/RaritiUSD.json";
import RudStakingContract from "../../artifacts/Contracts/stablecoin/RUDStaking.sol/RUDStaking.json";
/*global BigInt */
const provider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum)
  : null;
const RUDContract = new ethers.Contract(
  RaritiUsdContract.address, //RaritiUSD deployed contract address
  RaritiUsdContract.abi, //RaritiUSD deployed contract abi
  provider
);
let currAcc = null;
const useRudInWallet = (account, interval = null) => {
  //state to set the connected user's RUD Balance
  const [userRUDBalance, setUserRUDBalance] = useState(null);
  //state to set the total RUD supply
  const [RUDSupply, setRUDSupply] = useState(null);
  // state to store the balance of Rariti USD contract
  const [RUDInPool, setRUDInpool] = useState(null);
  //function to get the RUD balance using

  useEffect(() => {
    try {
      const getRudBalance = async () => {
        if (RUDContract && account) {
          const accountRUDBalance = await RUDContract.balanceOf(account);
          const totalRUDSupply = await RUDContract.totalSupply();
          const totalRUDInPool = await RUDContract.balanceOf(
            RudStakingContract.address
          );
          setRUDInpool(totalRUDInPool);
          setRUDSupply(totalRUDSupply);
          setUserRUDBalance(accountRUDBalance);
        } else {
          setUserRUDBalance(undefined);
          setRUDSupply(undefined);
          setRUDInpool(undefined);
        }
      };
      if (account !== currAcc) {
        clearInterval(interval);
        currAcc = account;
      }
      interval = setInterval(getRudBalance, 1000);
      return () => {
        clearInterval(interval);
        interval = null;
        currAcc = null;
      };
    } catch (error) {
      console.log("error", error);
    }
  }, [account]);
  if (userRUDBalance) {
    return {
      rudBalance: ethers.utils.formatEther(BigInt(userRUDBalance._hex)),
      rudTotalSupply: ethers.utils.formatEther(BigInt(RUDSupply._hex)),
      totalRadeInPool: ethers.utils.formatEther(BigInt(RUDInPool._hex)),
    };
  } else {
    return {
      rudBalance: null,
      rudTotalSupply: null,
      totalRadeInPool: null,
    };
  }
};
export default useRudInWallet;
