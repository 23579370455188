import cx from "classnames";
import Modal from "react-modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import React, { useState, useContext, useEffect, useCallback } from "react";
import { AppContext } from "../../../context";
import { RUD_ENUM } from "../../../enums/rudEnum";
import QuestionIcon from "../../../assets/Question Icon.svg";
import ButtonRade from "../../../components/RadeButtons";
import SwapPopup from "../SwapPopup/SwapPopup";
import TransactionSubmit from "../TransactionSubmit";
import ProceedBuyRudUsingETH from "../ProceedBuyRudUsingETH/ProceedBuyRudUsingETH";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
import SwapIcon from "../../../assets/SwapIcon/swapIcon";
import { TOOL_TIP } from "../../../enums/homeEnum";
import styles from "../SlippageTolerance/index.module.scss";
import { ethers } from "ethers";
import Slider from "../../../components/Slidebar/Slidebar";
import StableCoinMinterContract from "../../../../src/artifacts/Contracts/stablecoin/RaritiStableCoinMinter.sol/RaritiStableCoinMinter.json";
// Parent function to handle transaction by selecting slippage tolerance
const MAX_DECIMAL_PLACE = 2;
const SIX_DECIMAL_PLACES = 6;
const ETH_DECIMAL_PLACE = 18;
const PERCENTAGE_CONVERSION = 100;
const FLOORING_FACTOR = 100; // for fixing the issue of underflow numeric fault
const SlippageTolerance = ({
  rud,
  userSelectionMode,
  eth,
  setConfirmTransaction,
  setErrorMessage,
  setIsError,
  setWaitingConfirmation,
  setModalOpen,
}) => {
  const { isMobile } = useContext(AppContext);
  // State to set the Modal open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // State to set the functioning of back button to go to previous page
  const [showPreviousPage, setShowPreviousPage] = useState(false);
  //state to update the modals based on page numbers
  const [modalPageNumber, setModalPageNumber] = useState(null);
  const [mintSuccessful, setMintSuccessful] = useState(false);
  const [coinsMinted, setCoinsMinted] = useState(false);
  const [etherscanTransaction, setEtherscanTransaction] = useState(null);
  const [selectedSlippageTolerance, setSelectedSlippageTolerance] =
    useState(0.1);
  // Memoize the handleChangeToleranceSelection function
  const handleChangeToleranceSelection = useCallback((value) => {
    setError(false);
    setSelectedSlippageTolerance(value);
  }, []);

  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle1}>
      {TOOL_TIP.currentPriceInRUD}
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle2}>
      {TOOL_TIP.slippageTolerance}
    </Tooltip>
  );
  const renderTooltip3 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle4}>
      {TOOL_TIP.minimumReceived}
    </Tooltip>
  );
  const renderTooltip4 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle3}>
      {TOOL_TIP.RADEFees}
    </Tooltip>
  );
  const renderTooltip5 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle3}>
      {TOOL_TIP.maximumToPay}
    </Tooltip>
  );
  const [radeFees, setRadeFees] = useState(null);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  //Borrow Rud Contract
  const coinMinterContract = new ethers.Contract(
    StableCoinMinterContract.address, // deployed contract address
    StableCoinMinterContract.abi, //deployed contract abi
    signer
  );
  const getRadeFees = async () => {
    if (coinMinterContract) {
      const RadeFees = await coinMinterContract.rampFee();
      setRadeFees(RadeFees);
    } else {
      setRadeFees(undefined);
    }
  };
  useEffect(() => {
    getRadeFees();
  }, []);

  const currentPriceInRud = Number(rud * FLOORING_FACTOR).toFixed(
    MAX_DECIMAL_PLACE
  );
  const handleSubmitSwapButton = () => {
    if (!selectedSlippageTolerance) {
      setError(true);
    } else {
      setError(false);
      setModalPageNumber(0);
      setModalIsOpen(true);
    }
  };
  const handleSubmitCancelButton = () => {
    setShowPreviousPage(true);
  };

  //State to set the error message before proceed if user hasn't selected any options.
  const [error, setError] = useState(false);

  const rampFee = Number(radeFees) / PERCENTAGE_CONVERSION;
  const exactEth = Number(eth).toFixed(ETH_DECIMAL_PLACE);

  // Convert percentage to decimal form
  const slippageToleranceDecimal =
    selectedSlippageTolerance / PERCENTAGE_CONVERSION;

  const minimumReceivingRud =
    rud * FLOORING_FACTOR -
    rud * FLOORING_FACTOR * slippageToleranceDecimal -
    rud * FLOORING_FACTOR * rampFee;

  const maxETHtoSpent = eth + eth * rampFee + eth * slippageToleranceDecimal;

  const rudWithFees = Number(rud) + rampFee * Number(rud);

  const CardBody = () => (
    <div className={styles.slippageToleranceMainDiv}>
      <div
        className={cx(styles.selectSlippageTolerance, {
          [styles.selectSlippageToleranceMob]: isMobile,
        })}
      >
        {RUD_ENUM.selectSlippageTolerance}
      </div>
      {userSelectionMode === RUD_ENUM.rudEnterMode && (
        <div
          className={cx(styles.minimumReceivedInRUDDiv, {
            [styles.minimumReceivedInRUDDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.minimumReceivedKey, {
              [styles.minimumReceivedKeyMob]: isMobile,
            })}
          >
            {RUD_ENUM.amountReceivedInRUD}
          </div>
          <div className={styles.payingETHValue}>
            <div
              className={cx(styles.rudValue, {
                [styles.rudValueMob]: isMobile,
              })}
            >
              {rud}
            </div>
            <div
              className={cx(styles.rudContent, {
                [styles.rudContentMob]: isMobile,
              })}
            >
              {RUD_ENUM.RUD_Heading}
            </div>
          </div>
        </div>
      )}
      <div
        className={cx(styles.payingETHDiv, {
          [styles.payingETHDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.payETH, {
            [styles.payETHMob]: isMobile,
          })}
        >
          {userSelectionMode === RUD_ENUM.ethEnterMode
            ? RUD_ENUM.pay
            : RUD_ENUM.currentPriceInEth}
        </div>
        <div className={styles.payingETHValue}>
          <div
            className={cx(styles.ethValue, {
              [styles.ethValueMob]: isMobile,
            })}
          >
            {userSelectionMode === RUD_ENUM.rudEnterMode
              ? exactEth / FLOORING_FACTOR
              : exactEth}
          </div>
          <div
            className={cx(styles.ethContent, {
              [styles.ethContentMob]: isMobile,
            })}
          >
            {RUD_ENUM.ETH}
          </div>
        </div>
      </div>
      {userSelectionMode === RUD_ENUM.ethEnterMode && (
        <div
          className={cx(styles.userEnteredAmount, {
            [styles.userEnteredAmountMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.currentPriceInRUD, {
              [styles.currentPriceInRUDMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.questionIconDiv, {
                [styles.questionIconDivMob]: isMobile,
              })}
            >
              <OverlayTrigger placement="right" overlay={renderTooltip1}>
                <img
                  src={QuestionIcon}
                  alt="Question_Icon"
                  className={cx(styles.questionIcon, {
                    [styles.questionIconMob]: isMobile,
                  })}
                />
              </OverlayTrigger>
            </div>
            {RUD_ENUM.currentPriceInRUD}
          </div>
          <div className={styles.equivalentValue}>
            <div
              className={cx(styles.rudValue, {
                [styles.rudValueMob]: isMobile,
              })}
            >
              $ {currentPriceInRud}
            </div>
            <div
              className={cx(styles.rudContent, {
                [styles.rudContentMob]: isMobile,
              })}
            >
              {RUD_ENUM.RUD_Heading}
            </div>
          </div>
        </div>
      )}
      <div
        className={cx(styles.slippageToleranceDiv, {
          [styles.slippageToleranceDivMob]: isMobile,
        })}
      >
        <div className={styles.slippageToleranceIconAndKeyDiv}>
          <div
            className={cx(styles.tooltipIconDiv, {
              [styles.tooltipIconDivDivMob]: isMobile,
            })}
          >
            <OverlayTrigger placement="right" overlay={renderTooltip2}>
              <img
                src={QuestionIcon}
                alt="Question_Icon"
                className={cx(styles.questionIcon, {
                  [styles.questionIconMob]: isMobile,
                })}
              />
            </OverlayTrigger>
          </div>
          <div
            className={cx(styles.slippageTolerance, {
              [styles.slippageToleranceMob]: isMobile,
            })}
          >
            {RUD_ENUM.slippageTolerance}
          </div>
        </div>
        <Slider
          minValue={0.1}
          maxValue={5}
          onChange={handleChangeToleranceSelection}
        />
      </div>
      <div className={styles.slippageToleranceInfoDiv}>
        <div
          className={cx(styles.slippageToleranceInfoKeys, {
            [styles.slippageToleranceInfoKeysMob]: isMobile,
          })}
        >
          <div className={styles.radeFeesIconAndKeyDiv}>
            <div
              className={cx(styles.tooltipIconDiv, {
                [styles.tooltipIconDivDivMob]: isMobile,
              })}
            >
              <OverlayTrigger placement="right" overlay={renderTooltip4}>
                <img
                  src={QuestionIcon}
                  alt="Question_Icon"
                  className={cx(styles.questionIcon, {
                    [styles.questionIconMob]: isMobile,
                  })}
                />
              </OverlayTrigger>
            </div>
            <div
              className={cx(styles.radeFeesKey, {
                [styles.radeFeesKeyMob]: isMobile,
              })}
            >
              {RUD_ENUM.radeFees}
            </div>
          </div>

          <div
            className={cx(styles.minimunReceivedIconAndKeyDiv, {
              [styles.minimunReceivedIconAndKeyDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.tooltipIconDiv, {
                [styles.tooltipIconDivDivMob]: isMobile,
              })}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  userSelectionMode === RUD_ENUM.ethEnterMode
                    ? renderTooltip3
                    : renderTooltip5
                }
              >
                <img
                  src={QuestionIcon}
                  alt="Question_Icon"
                  className={cx(styles.questionIcon, {
                    [styles.questionIconMob]: isMobile,
                  })}
                />
              </OverlayTrigger>
            </div>

            <div
              className={cx(styles.minimumReceivedKey, {
                [styles.minimumReceivedKeyMob]: isMobile,
              })}
            >
              {userSelectionMode === RUD_ENUM.ethEnterMode
                ? RUD_ENUM.minimumReceived
                : `Max Amount to pay to receive $ ${rud} RUD`}
            </div>
          </div>
        </div>
        <div className={styles.slippageToleranceInfoVal}>
          <div
            className={cx(styles.radeFeesDiv, {
              [styles.radeFeesDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.radeFeesVal, {
                [styles.radeFeesValMob]: isMobile,
              })}
            >
              {Number(radeFees)}%
            </div>
          </div>

          <div className={styles.rudPriceDiv}>
            <div
              className={cx(styles.minimumReceivedVal, {
                [styles.minimumReceivedValMob]: isMobile,
              })}
            >
              {userSelectionMode === RUD_ENUM.ethEnterMode
                ? `$ ${Number(minimumReceivingRud).toFixed(
                    MAX_DECIMAL_PLACE
                  )} RUD`
                : `${Number(maxETHtoSpent / FLOORING_FACTOR).toFixed(
                    SIX_DECIMAL_PLACES
                  )} ETH ($ ${Number(rudWithFees).toFixed(
                    MAX_DECIMAL_PLACE
                  )} RUD)`}
            </div>
          </div>
        </div>
      </div>
      <div
        className={cx(styles.buttons, {
          [styles.buttonsMob]: isMobile,
        })}
      >
        {/*custom style cancel button */}
        <ButtonRade
          outline
          onClick={handleSubmitCancelButton}
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
        >
          {RUD_ENUM.cancel_swap}
        </ButtonRade>
        {/*custom styled swap button */}
        <ButtonRade
          outline
          customStyling={cx(styles.swapButton, {
            [styles.swapButtonMob]: isMobile,
          })}
          onClick={() => {
            handleSubmitSwapButton(); //Swap button Functioning
          }}
        >
          <div className={styles.swapButtonContentDiv}>
            <div className={styles.swapButtonContent}>
              {RUD_ENUM.swap_button}
            </div>
            <div
              className={cx(styles.swapImage, {
                [styles.swapImageMob]: isMobile,
              })}
            >
              <SwapIcon />
            </div>
          </div>
        </ButtonRade>
      </div>
      <div className={styles.tolranceError}>
        {error && (
          <ShakeError
            errorMessage={RUD_ENUM.slippageToleranceError}
            customStyling={cx(styles.alertTolerance, {
              [styles.alertToleranceMob]: isMobile,
            })}
          />
        )}
      </div>
    </div>
  );
  return (
    <>
      {/* calling as a function to avoid flickering issue */}
      {showPreviousPage ? (
        <ProceedBuyRudUsingETH
          setIsError={setIsError}
          setModalOpen={setModalOpen}
          setConfirmTransaction={setConfirmTransaction}
          setErrorMessage={setErrorMessage}
          setWaitingConfirmation={setWaitingConfirmation}
        />
      ) : (
        CardBody()
      )}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {/*pass these values as props in upcoming Modals */}
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return (
                <SwapPopup
                  setModalIsOpen={setModalIsOpen}
                  setModalPageNumber={setModalPageNumber}
                  ethPrice={eth}
                  rudPrice={rud}
                  rampFee={rampFee}
                  userSelectionMode={userSelectionMode}
                  setConfirmTransaction={setConfirmTransaction}
                  selectedSlippageTolerance={slippageToleranceDecimal}
                  exactEth={exactEth}
                  minimumReceivingRud={minimumReceivingRud}
                  setMintSuccessful={setMintSuccessful}
                  setCoinsMinted={setCoinsMinted}
                  setErrorMessage={setErrorMessage}
                  setIsError={setIsError}
                  setWaitingConfirmation={setWaitingConfirmation}
                  setModalOpen={setModalOpen}
                  setEtherscanTransaction={setEtherscanTransaction}
                />
              );
            case 1:
              return (
                <TransactionSubmit
                  setModalIsOpen={setModalIsOpen}
                  setModalPageNumber={setModalPageNumber}
                  mintSuccessful={mintSuccessful}
                  coinsMinted={coinsMinted}
                  rudPrice={rud}
                  etherscanTransaction={etherscanTransaction}
                />
              );
            default:
              return null;
          }
        })()}
        {/*Opens up the appropriate modal based on the page number */}
      </Modal>
    </>
  );
};
export default SlippageTolerance;
