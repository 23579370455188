export const STATISTICS_ITEMS = {
  joined: "Joined",
  joinedDate: "June 22, 2022",
  staked: "Staked",
  investmentAmount: "$ 0",
  borrrowed: "Borrowed",
  borrrowedAmount: "$ 0",
  eTHInWallet: "ETH in wallet",
  eTHInWalletAmount: "1.6743",
  RUD: "RUD in wallet",
  RUDAmount: "$ 0",
  RADETokenInWallet: "RADE Token",
  confidenceIndex: "Confidence Index",
};
