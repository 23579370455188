import React from "react";
import ButtonRade from "../../../components/RadeButtons";
import { SETTINGS_ENUM } from "../../../enums/settingsEnum";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { useContext, useEffect, useState } from "react";
import { db } from "../../../firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import KYCInProgess from "../../../assets/KYCInProcessIcon.svg";
import KYCVerifiedIcon from "../../../assets/kycVerified.svg";
import Spinner from "../../../components/Spinner/Spinner";
function KYCCompleted({
  setErrorForID,
  idBackSide,
  setIdBackSide,
  setErrorForPancard,
  idFrontSide,
  setIdFrontSide,
  setErrorForAadhar,
  reSubmitKyc,
  setResubmitKyc,
  isFileUploaded,
  setIsFileUploaded,
  idBackSideFileType,
  setIdBackSideFileType,
  idFrontSideFileType,
  setIdFrontSideFileType,
  selectedFileName,
  setSelectedFileName,
  pancardHolding,
  setPancardHolding,
  aadharHolding,
  setAadharHolding,
  setErrorForAadharHold,
  setErrorForPancardHold,
  userName,
  selectedCountryCode,
  bankName,
  setBankName,
  bankAccountType,
  setBankAccountType,
  ABARoutingNumber,
  setABARoutingNumber,
  accountNumber,
  setAccountNumber,
}) {
  const { isMobile, KycStatus, setKycStatus } = useContext(AppContext);
  const { account } = useContext(UserContext);
  const [kycDetails, setKYCDetails] = useState(null); //state to store kyc details
  useEffect(() => {
    // Function to fetch the KYC status from the database
    async function fetchKYCStatus() {
      // Set loading to true to indicate that the data is being fetched

      // Create a reference to the "userData" collection in the database
      const usersRef = collection(db, "userData");

      // Get the document reference for the user with the given profileEmail
      const userDocRef = doc(usersRef, account);

      // Fetch the user document snapshot from the database
      const userDocSnapshot = await getDoc(userDocRef);

      // Check if the user document exists in the database
      if (userDocSnapshot.exists()) {
        // Extract user data from the document snapshot
        const userData = userDocSnapshot.data();

        // Get the KYC status from the user data
        setKYCDetails(userData);
        setKycStatus(userData.kycStatus);

        // Check the KYC status and update the relevant states accordingly
      }
    }

    // Call the fetchKYCStatus function when KycStatus or confirmUpdateModalOpen changes
    // This ensures that the KYC status is fetched whenever there's an update to those dependencies
    // For example, when the user submits or updates the KYC status
    fetchKYCStatus();
  }, [KycStatus, setIsFileUploaded, setResubmitKyc, kycDetails]);

  return (
    <div
      className={cx(styles.KYCInProgressContainer, {
        [styles.KYCInProgressContainerWeb]: !isMobile,
        [styles.KYCInProgressContainerMob]: isMobile,
      })}
    >
      {KycStatus === "Pending" && (
        <>
          <div
            className={cx(styles.KYCApplicationSubmitted, {
              [styles.KYCApplicationSubmittedWeb]: !isMobile,
              [styles.KYCApplicationSubmittedMob]: isMobile,
            })}
          >
            {SETTINGS_ENUM.KYCApplicationSubmitted}
          </div>

          <div
            className={cx(styles.weWillLetYouKnow, {
              [styles.weWillLetYouKnowWeb]: !isMobile,
              [styles.weWillLetYouKnowMob]: isMobile,
            })}
          >
            {SETTINGS_ENUM.weWillLetYouKnow}
          </div>
          <div
            className={cx(styles.KYCButtonDiv, {
              [styles.KYCButtonDivWeb]: !isMobile,
              [styles.KYCButtonDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.KYCButton, {
                [styles.KYCButtonWeb]: !isMobile,
                [styles.KYCButtonMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.KYC}

              <img
                src={KYCInProgess}
                alt="KYC in progress"
                className={cx(styles.KYCInProgess, {
                  [styles.KYCInProgessWeb]: !isMobile,
                  [styles.KYCInProgessMob]: isMobile,
                })}
              />
            </div>
          </div>

          <div
            className={cx(styles.statusContainer, {
              [styles.statusContainerWeb]: !isMobile,
              [styles.statusContainerMob]: isMobile,
            })}
          >
            {" "}
            {kycDetails && (
              <div
                className={cx(styles.statusOfKYC, {
                  [styles.statusOfKYCWeb]: !isMobile,
                  [styles.statusOfKYCMob]: isMobile,
                })}
              >
                {SETTINGS_ENUM.statusOfKYC}
              </div>
            )}
            <div
              className={cx(styles.submittedAndWaiting, {
                [styles.submittedAndWaitingWeb]: !isMobile,
                [styles.submittedAndWaitingMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.submittedAndWaiting}
            </div>
          </div>
        </>
      )}
      {KycStatus === "Verified" && (
        <>
          <div
            className={cx(styles.KYCButtonDiv, {
              [styles.KYCButtonDivWeb]: !isMobile,
              [styles.KYCButtonDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.KYCButton, {
                [styles.KYCButtonWeb]: !isMobile,
                [styles.KYCButtonMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.KYC}

              <img
                src={KYCVerifiedIcon}
                alt="KYC in progress"
                className={cx(styles.KYCInProgess, {
                  [styles.KYCInProgessWeb]: !isMobile,
                  [styles.KYCInProgessMob]: isMobile,
                })}
              />
            </div>
          </div>

          <div
            className={cx(styles.statusContainer, {
              [styles.statusContainerWeb]: !isMobile,
              [styles.statusContainerMob]: isMobile,
            })}
          >
            {" "}
            {kycDetails && (
              <div
                className={cx(styles.statusOfKYC, {
                  [styles.statusOfKYCWeb]: !isMobile,
                  [styles.statusOfKYCMob]: isMobile,
                })}
              >
                {SETTINGS_ENUM.statusOfKYC}
              </div>
            )}
            <div
              className={cx(styles.yourAccountVerified, {
                [styles.yourAccountVerifiedWeb]: !isMobile,
                [styles.yourAccountVerifiedMob]: isMobile,
              })}
            >
              Your account is Verified.
            </div>
          </div>
        </>
      )}
      {kycDetails ? (
        <>
          {!isMobile && (
            <div className={styles.submittedKYCDetails}>
              <div className={styles.kycDetails}>
                {" "}
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>First Name:</div>
                  <div className={styles.kycValue}>{kycDetails.firstName}</div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Last Name:</div>
                  <div className={styles.kycValue}>{kycDetails.lastName}</div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Zip Code:</div>
                  <div className={styles.kycValue}>{kycDetails.pincode}</div>
                </div>
              </div>
              <div className={styles.kycDetails}>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Email:</div>
                  <div className={styles.kycValue}>{kycDetails.email}</div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>DOB:</div>
                  <div className={styles.kycValue}>{kycDetails.DOB}</div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Phone:</div>
                  <div className={styles.kycValue}>
                    {selectedCountryCode ? selectedCountryCode : "+1"}{" "}
                    {kycDetails.phone}
                  </div>
                </div>
              </div>

              <div className={styles.kycDetails}>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Address Line 1:</div>
                  <div className={styles.kycValue}>
                    {kycDetails.addressLine1}
                  </div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Address Line 2:</div>
                  <div className={styles.kycValue}>
                    {kycDetails.addressLine2}
                  </div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Country:</div>
                  <div className={styles.kycValue}>
                    {kycDetails.country.label}
                  </div>
                </div>
              </div>
              <div className={styles.kycDetails}>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>City:</div>
                  <div className={styles.kycValue}>{kycDetails.city}</div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Region:</div>
                  <div className={styles.kycValue}>
                    {kycDetails.region.label}
                  </div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Account Type:</div>
                  <div className={styles.kycValue}>
                    {kycDetails.bankAccountType}
                  </div>
                </div>
              </div>
              <div className={styles.kycDetails}>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Account Number:</div>
                  <div className={styles.kycValue}>
                    {kycDetails.accountNumber}
                  </div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Bank Name:</div>
                  <div className={styles.kycValue}>{kycDetails.bankName}</div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}> Routing Number:</div>
                  <div className={styles.kycValue}>
                    {kycDetails.ABARoutingNumber}
                  </div>
                </div>
              </div>
              <div className={styles.kycDetails}>
                {" "}
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>ID(Front Side):</div>
                  <div className={styles.kycValue}>
                    <img
                      src={kycDetails.idFrontSide}
                      alt="holding ID"
                      className={styles.ID}
                    />
                  </div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Holding ID (Front):</div>
                  <div className={styles.kycValue}>
                    <img
                      src={kycDetails.holdingPassport}
                      alt="holding ID"
                      className={styles.ID}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.kycDetailContainer}>
                <div>
                  <div className={styles.kycHeading}>Holding ID (Back):</div>
                  <div className={styles.kycValue}>
                    <img
                      src={kycDetails.holdingPassportBackside}
                      alt="holding ID"
                      className={styles.ID}
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.kycHeading}>Holding ID (Back):</div>
                  <div className={styles.kycValue}>
                    <img
                      src={kycDetails.holdingPassportBackside}
                      alt="holding ID"
                      className={styles.ID}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className={styles.submittedKYCDetails}>
              <div className={styles.kycDetails}>
                {" "}
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>First Name:</div>
                  <div className={styles.kycValue}>{kycDetails.firstName}</div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeadingMob}>Last Name:</div>
                  <div className={styles.kycValueMob}>
                    {kycDetails.lastName}
                  </div>
                </div>
              </div>
              <div className={styles.kycDetails}>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>User Name:</div>
                  <div className={styles.kycValue}>{kycDetails.userName}</div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeadingMob}>DOB:</div>
                  <div className={styles.kycValueMob}>{kycDetails.DOB}</div>
                </div>
              </div>
              <div className={styles.kycDetailsEmail}>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Email:</div>
                  <div className={styles.kycValue}>{kycDetails.email}</div>
                </div>
              </div>
              <div className={styles.kycDetails}>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Phone:</div>
                  <div className={styles.kycValue}>
                    {selectedCountryCode ? selectedCountryCode : "+1"}{" "}
                    {kycDetails.phone}
                  </div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeadingMob}>Country:</div>
                  <div className={styles.kycValueMob}>
                    {kycDetails.country.label}
                  </div>
                </div>
              </div>
              <div className={styles.kycDetails}>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Address Line 1:</div>
                  <div className={styles.kycValue}>
                    {kycDetails.addressLine1}
                  </div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeadingMob}>Address Line 2:</div>
                  <div className={styles.kycValueMob}>
                    {kycDetails.addressLine2}
                  </div>
                </div>
              </div>
              <div className={styles.kycDetails}>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>City:</div>
                  <div className={styles.kycValue}>{kycDetails.city}</div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeadingMob}>Region:</div>
                  <div className={styles.kycValueMob}>
                    {kycDetails.region.label}
                  </div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>Zip Code:</div>
                  <div className={styles.kycValue}>{kycDetails.pincode}</div>
                </div>
              </div>
              <div className={styles.kycDetails}>
                {" "}
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeading}>ID(Front Side):</div>
                  <div className={styles.kycValue}>
                    <img
                      src={kycDetails.idFrontSide}
                      alt="holding ID"
                      className={styles.ID}
                    />
                  </div>
                </div>
                <div className={styles.kycDetail}>
                  <div className={styles.kycHeadingMob}>
                    Holding ID (Front):
                  </div>
                  <div className={styles.kycValueMob}>
                    <img
                      src={kycDetails.holdingPassport}
                      alt="holding ID"
                      className={styles.ID}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.kycDetailContainer}>
                <div>
                  <div className={styles.kycHeading}>Holding ID (Back):</div>
                  <div className={styles.kycValue}>
                    <img
                      src={kycDetails.holdingPassportBackside}
                      alt="holding ID"
                      className={styles.ID}
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.kycHeadingMob}>Holding ID (Back):</div>
                  <div className={styles.kycValueMob}>
                    <img
                      src={kycDetails.holdingPassportBackside}
                      alt="holding ID"
                      className={styles.ID}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default KYCCompleted;
