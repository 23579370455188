export const APP_ENUM = {
  AUTH: "auth",
  ethPriceFeedAddressGoerli: "0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e", // Goerli address
  ethPriceFeedAddressMainnet: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419", // Mainnet address
  ethPriceFeedAddressMumbai: "0xd0D5e3DB44DE05E9F294BB0a3bEEaF030DE24Ada",
  wethAddressGeorli: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
  wethAddressMainnet: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  wethAddressMumbai: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  daiAddressGoerli: "0x11fE4B6AE13d2a6055C8D9cF65c55bac32B5d844",
  daiAddressMainnet: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  daiAddressMumbai: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  // ethPriceFeedAddress: "0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e", // Goerli address
  // // ethPriceFeedAddress: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419", // Mainnet address
  en: {
    text: "Connect Metamask",
    ethNetwork: "Ethereum Main Network",
    goerliTestNetwork: "Goerli Test Network",
    hardhatNetwork: "Hardhat",
    sepoliaNetwork: "Sepolia Test Network",
    mumbaiNetwork: "Mumbai",
    docButton: "Docs",
    launchApp: "Launch App",
    confirmAuthentication: "Confirm authentication to MetaMask",
    confirmMessage:
      "Confirm the request that just appeared. If you can’t see a request, open your MetaMask extension via your browser.",
    waitingConfirmMessage: "Waiting for confirmation...",
    remainder: "This won’t cost you any Ether",
    failedToConnect: " Failed to connect wallet",
    installMetamask:
      "You might need to install MetaMask or use a different browser",
    getMetamask: "Get metamask",
    cancelButton: "Cancel",
    retryButton: "Retry",
    mobileWaitingForConfirmMessage:
      "Confirm the request that just appeared in your MetaMask mobile App.",
    eth: "ETH",
    goerliEth: "GETH",
    mumbaiToken: "MATIC",
  },
};
