import "./index.css";
//import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { DAppProvider, Goerli, Mainnet } from "@usedapp/core";
import React from "react";
import ReactDOM from "react-dom";
import "typeface-jura";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// Change this to your own Infura project id: https://infura.io/register
/* 
Todo: Add Project and send config as prop
const INFURA_PROJECT_ID = "defba93b47f748f09fcead8282b9e58e";
const config = {
  readOnlyChainId: Mainnet.chainId,

  // readOnlyUrls: {
  //   [Mainnet.chainId]: "https://mainnet.infura.io/v3/" + INFURA_PROJECT_ID,
  // },
};


  readOnlyUrls: {
    [Mainnet.chainId]: "https://mainnet.infura.io/v3/" + INFURA_PROJECT_ID,
  },
}; */
//const config = {};

const config = {
  readOnlyChainId: Goerli.chainId,
  readOnlyUrls: {
    [Goerli.chainId]:
      process.env.REACT_APP_Goerli_Network_API_URL +
      process.env.REACT_APP_Goerli_Network_API_KEY,
  },
};

// You should replace this url with your own and put it into a .env file
// See all subgraphs: https://thegraph.com/explorer/
// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   uri: "https://api.thegraph.com/subgraphs/name/paulrberg/create-eth-app",
// });

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <DAppProvider config={config}>
        {/* <ApolloProvider client={client}> */}
        <App />
        {/* </ApolloProvider> */}
      </DAppProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
