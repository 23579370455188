import React, { useState, useEffect, useContext } from "react";
import attachmentLogo from "../../assets/attachmentLogo.svg";
import { firestore } from "../../firebase";
import { UserContext } from "../../context";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import styles from "./index.module.scss";
import ImagePreview from "./ImagePreview";
import PdfPreview from "./PdfPreview";

function FileUpload({
  setIsFileUploaded,
  id,
  setErrorForID,
  idBackSide,
  idFrontSide,
  setIdBackSide,
  setIdFrontSide,
  setErrorForPancard,
  setErrorForAadhar,
  reSubmitKyc,
  idBackSideFileType,
  setIdBackSideFileType,
  idFrontSideFileType,
  setIdFrontSideFileType,
  setIsLoadingAadhar,
  setIsLoadingPancard,
  setSelectedFileName,
  selectedFileName,
}) {
  const [errorMessage, setErrorMessage] = useState(null); //state to track error message
  const { account } = useContext(UserContext);
  const handleFileUpload = async (file) => {
    if (
      file &&
      file.size <= 5000000 &&
      /(jpeg|jpg|png|pdf)$/i.test(file.name)
    ) {
      const storage = getStorage(); // Get the storage object
      const storageRef = ref(storage); // Create a reference to the storage root
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isImage = /(jpeg|png|jpg)$/i.test(fileExtension);
      let filePath = ""; // Initialize the file path variable
      if (id === "idBackSide") {
        filePath = `kycDetails/${account}/frontSide/${file.name}`;
      } else if (id === "idFrontSide") {
        filePath = `kycDetails/${account}/backSide/${file.name}`;
      }

      if (filePath !== "") {
        const fileRef = ref(storageRef, filePath); // Create a reference to the file

        try {
          if (id === "idBackSide") {
            setIsLoadingPancard(true);
          } else if (id === "idFrontSide") {
            setIsLoadingAadhar(true);
          }
          // Upload the file to Firebase Storage
          await uploadBytes(fileRef, file);
          // Retrieve the download URL for the uploaded file
          const downloadURL = await getDownloadURL(fileRef);

          if (id === "idBackSide") {
            setIdBackSide(downloadURL); // Set the download URL
            setIsFileUploaded(true);
            setErrorForPancard(false); // Reset the error for idBackSide
            setIdBackSideFileType(isImage ? "image" : "pdf");
          } else if (id === "idFrontSide") {
            setIdFrontSide(downloadURL); // Set the download URL
            setIsFileUploaded(true);
            setErrorForAadhar(false); // Reset the error for idFrontSide
            setIdFrontSideFileType(isImage ? "image" : "pdf");
          }
          setErrorMessage(null);
        } catch (error) {
          console.error("Error uploading file to Firebase Storage:", error);
        } finally {
          setIsLoadingAadhar(false); // Stop loading
          setIsLoadingPancard(false);
        }
      }
    } else {
      if (id === "idBackSide") {
        setIdBackSide(null);
      } else if (id === "idFrontSide") {
        setIdFrontSide(null);
      }

      setErrorMessage(
        "Please select a valid file (JPEG, PNG, or PDF) that is under 5MB!"
      );
    }
  };

  useEffect(() => {
    async function fetchDetails() {
      const profileDataRef = collection(firestore, "userData"); // Reference to the "profileData" collection
      const profileDocRef = doc(profileDataRef, account); // Reference to the document with email ID as document ID
      const profileData = {
        idBackSide: idBackSide,
        idFrontSide: idFrontSide,
      };
      await updateDoc(profileDocRef, profileData);
    }

    fetchDetails();
  }, [idBackSide, idFrontSide, account]);
  const handleFileSelect = (event) => {
    setErrorForID(false);
    const file = event.target.files[0];
    setSelectedFileName(file.name); // Set the selected file name
    handleFileUpload(file);
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const isImage = /(jpeg|png|jpg)$/i.test(fileExtension);
    if (id === "idBackSide") {
      setIdBackSideFileType(isImage ? "image" : "pdf");
    } else if (id === "idFrontSide") {
      setIdFrontSideFileType(isImage ? "image" : "pdf");
    }
  };
  const handleImageClick = () => {
    if (reSubmitKyc !== "Pending" && reSubmitKyc !== "Verified") {
      // Trigger file selection when the image is clicked
      document.getElementById(`${id}-upload`).click();
    }
  };
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const usersRef = collection(firestore, "userData");
        const documentId = account; // Email of the user
        const userDocRef = doc(usersRef, documentId);
        const documentSnapshot = await getDoc(userDocRef);

        if (documentSnapshot.exists()) {
          // The document with the specified email address exists
          const userData = documentSnapshot.data();
          // Set the values from Firestore to the state
          setIdFrontSide(userData.idFrontSide || "");
          setIdBackSide(userData.idBackSide || "");
          const isPancardImage =
            userData.idBackSide.includes("image") ||
            /(png|jpeg|jpg)/i.test(userData.idBackSide);

          setIdBackSideFileType(isPancardImage ? "image" : "pdf");

          const isAadharImage =
            userData.idFrontSide.includes("image") ||
            /(png|jpeg|jpg)/i.test(userData.idFrontSide);

          setIdFrontSideFileType(isAadharImage ? "image" : "pdf");
        } else {
          // The document does not exist
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error retrieving profile information:", error);
      }
    };

    fetchProfileData();
  }, [account, setIdBackSide, setIdFrontSide]);
  return (
    <div>
      {(idBackSide && id === "idBackSide") ||
      (idFrontSide && id === "idFrontSide") ? (
        <div className={styles.previewDiv}>
          {id === "idBackSide" && idBackSideFileType === "image" ? (
            <ImagePreview
              src={idBackSide}
              alt={`attachment-${id}`}
              onClick={handleImageClick}
            />
          ) : id === "idFrontSide" && idFrontSideFileType === "image" ? (
            <ImagePreview
              src={idFrontSide}
              alt={`attachment-${id}`}
              onClick={handleImageClick}
            />
          ) : (
            <PdfPreview
              onClick={handleImageClick}
              selectedFileName={selectedFileName}
            />
          )}

          <label htmlFor={`${id}-upload`}>
            <div className={styles.imageUpload}></div>
          </label>
          <input
            type="file"
            id={`${id}-upload`}
            accept=".jpeg,.png,.pdf, .jpg"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </div>
      ) : (
        <div className={styles.previewDivWithoutFile}>
          <label htmlFor={`${id}-upload`}>
            <div className={styles.imageUpload}>
              <div className={styles.uploadIcon}>
                <img src={attachmentLogo} alt="attachment-logo" />
              </div>
              <div className={styles.dragAndDropText}>
                <div className={styles.browseLink}>Upload attachment</div>
              </div>
            </div>
          </label>
          <input
            type="file"
            id={`${id}-upload`}
            accept=".jpeg,.png,.pdf, .jpg"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </div>
      )}
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
}

export default FileUpload;
