import styles from "./index.module.scss";
import { Button } from "../../../components";
import React from "react";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { BORROW_ENUM } from "../../../enums/borrowEnum";
import borrowContract from "../../../../src/artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
import { ethers } from "ethers";
import { useState } from "react";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
// passing props
const MAX_DECIMAL_PLACE = 2;
const MaxBorrowPopUp = (props) => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  //Borrow Rud Contract
  const rudBorrowContract = new ethers.Contract(
    borrowContract.address, // deployed contract address
    borrowContract.abi, //deployed contract abi
    signer
  );

  const { isMobile } = useContext(AppContext);
  // Working of the cancel button
  function closeModal() {
    props.setIsOpen(false);
  }
  const confirmBorrowing = async () => {
    try {
      props.setModalPageNumber(1);
      const borrowingAmount = ethers.utils.parseUnits(
        props.borrowAmount.toString(),
        "ether"
      );
      //user entering eth amount to get a specified number of rud
      const transactionParams = {
        value: ethers.utils.parseUnits(props.value.toString(), "ether"),
        // Only required to send ether to the recipient from the initiating external account.
      };
      const loanDuration = props.selectedLoanTerm;
      const paymentDuration = props.selectedPaymentTerm;
      const borrowTx = await rudBorrowContract.borrowAmount(
        borrowingAmount,
        loanDuration,
        paymentDuration,
        transactionParams
      );
      const receipt = await borrowTx.wait();
      if (receipt.status === 1) {
        props.setEtherscanTransaction(receipt.transactionHash);
        props.setIsOpen(true);
        props.setModalPageNumber(2);
      }
    } catch (e) {
      console.log("error", e);
      props.setErrorReason(e.reason);
      if (e.code === 4001) {
        setIsError(true);
        setErrorMessage(BORROW_ENUM.transactionRejectedError);
      } else {
        setIsError(true);
        setErrorMessage(e.code);
      }
      setTimeout(() => props.setIsOpen(false), 4000);
    }
  };
  return (
    <div>
      <div className={styles.popUpContainer}>
        <div
          className={cx(styles.popUpDiv, {
            [styles.popUpDivMob]: isMobile,
          })}
        >
          {BORROW_ENUM.maxBorrowConfirm}
        </div>
        <div
          className={cx(styles.popUpContentDiv, {
            [styles.popUpContentDivMob]: isMobile,
          })}
        >
          <div>{BORROW_ENUM.collateral}</div>
          <div>{BORROW_ENUM.borrowAmount}</div>
          <div>{BORROW_ENUM.borrowingFee}</div>
          <div className={styles.popUpContentValue}>{props.value} ETH</div>
          <div className={styles.popUpContentValue}>
            {Number(props.borrowAmount).toFixed(MAX_DECIMAL_PLACE)} RUD
          </div>

          <div
            className={cx(styles.popUpContentValue, {
              [styles.popUpContentValueMob]: isMobile,
            })}
          >
            {Number(props.borrowFee).toFixed(MAX_DECIMAL_PLACE)} RUD
          </div>
        </div>

        <div
          className={cx(styles.popUpButtonDiv, {
            [styles.popUpButtonDivMob]: isMobile,
          })}
        >
          <Button
            className={cx(styles.cancelButton, {
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={closeModal}
          >
            {BORROW_ENUM.cancelButton}
          </Button>

          <Button
            className={cx(styles.confirmButton, {
              [styles.confirmButtonMob]: isMobile,
            })}
            onClick={confirmBorrowing}
          >
            {BORROW_ENUM.confirmButton}
          </Button>
        </div>
        <div className={styles.alertDiv}>
          {isError && (
            <ShakeError
              errorMessage={errorMessage}
              customStyling={cx(styles.alertDeniedTransaction, {
                [styles.alertDeniedTransactionMob]: isMobile,
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MaxBorrowPopUp;
