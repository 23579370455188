export const RUD_ENUM = {
  RUD_Heading: "RUD",
  ETH: "ETH",
  RUD_Content: "You have $0 RUD. Buy RUD from our marketplace.",
  Buy_button: "Buy",
  Sell_button: "Sell",
  currency_choose: "Select the currency you wish to use to purchase RUD.",
  Selling_RUD: "Select the currency you wish to recieve on selling your RUD.",
  fiat_option: "Fiat-USD",
  crypto_option: "Ethereum",
  proceed_button: "Proceed",
  getRUDAmount: "Enter the amount of RUD you want to purchase.",
  pay: "You Pay",
  get: "You Get",
  swap_button: "Swap",
  One_ETH_Value: "1 ETH =",
  cancel_swap: "Cancel",
  confirm_swap: "Confirm Swap",
  confirm_content: "Waiting for Confirmation",
  swapping_ETH: "Swapping 2 ETH for $3295.88 RUD ",
  transaction_confirm: "Confirm this transaction in your wallet",
  transaction_submit: "Transaction Successful",
  unstakingSuccessful: "Unstaking Successful",
  transaction_details: "View on explorer",
  Add_Rud_button: "Add RUD",
  KYCAlert: " KYC must be completed to withdraw or sell your RUD.",
  error_for_radioButtons: "Select an option to proceed!",
  proceedError_BuyFlow: "Enter an amount of RUD to proceed!",
  swapError_BuyFlow: "Enter an amount of ETH to proceed!",
  proceedError_SellFlow: "Enter an amount of RUD to proceed!",
  swapError_SellFlow: "Enter an amount of RUD to swap!",
  returnUserContent:
    "You have $2000 RUD. Buy or sell RUD from our marketplace.",
  selectSlippageTolerance:
    "Confirm your transaction by selecting slippage tolerance.",
  currentPriceInRUD: "Current price in RUD",
  slippageTolerance: "Slippage Tolerance",
  minimumReceived: "Minimum Received in RUD",
  priceImpact: "Price Impact",
  radeFees: "Rade Fees",
  backButton: "Back",
  confirmButton: "Confirm",
  swapConfirmContent: "Are you sure you want to confirm?",
  goBackButton: "Go Back",
  transactionFailed: "Transaction Failed",
  ethEnterMode: "EthEnterMode",
  rudEnterMode: "RudEnterMode",
  price: "Price:",
  rudPerEth: "RUD per ETH",
  nestIsClosed: " Nest is closed",
  nestDetails: "Your Collateral of 1.1 ETH has been send to your wallet",
  OKButton: "OK",
  slippageToleranceError: "Please select slippage tolerance to swap!",
  addTokenInWalletAssets:
    "Click Add to manually add your RUD tokens to your Wallet Assets",
  coinMintSuccessfulMessage: " RUD Coins minted successfully!",
  waitingConfirmation: "Transaction in progress...",
  staked: "Staked",
  borrowed: "Borrowed",
  currentPriceInEth: "Current price in ETH",
  maxEthPaid: "Maximum ETH paid",
  ConfirmSignatureRequest:
    "Please confirm the signature request prompted by your metamask wallet.This is entirely free of gas fees.",
  signatureTransactionSuccessfull: "Signature request complete",
  closeButton: "Close",
  insufficientAmountAlert: "Insufficient amount of RUD to sell!",
  errorForNegative: "Enter a valid amount of RUD to swap!",
  errorInvalidInput: "Enter a valid amount to proceed!",
  enterTheamountOfRUD: "Enter the amount of RUD you want to sell. ",
  yourProfileIsNotVerified: "You profile is not KYC verified.",
  youMustCompleteKYC: "You must complete KYC process to sell your RUD",
  completeKYCButton: "Complete KYC",
  cancelButton: "Cancel",
  enterAnAmountOfRUDToSell: "Enter the amount of RUD you want to sell.",
  youCancelledTheSignature: "You cancelled the signature!",
  closeAndInitiate: "Close and initiate the transfer again to proceed ",
  transactionRejectedError: "You rejected the transaction!",
  maxETHtoPay: "Max Amount to pay in ETH",
  ethEnterModeNote:
    "Note: Click proceed to calculate the final amount received in $RUD, after deducting fees.",
  amountReceivedInRUD: "Amount Received in RUD",
};
