import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext, UserContext } from "../../context";
import { BORROW_ENUM } from "../../enums/borrowEnum";
import { useContext } from "react";
import ButtonRade from "../../components/RadeButtons";
import Card from "../../components/Card";
import QuestionMark from "../../assets/QuestionMark.svg";
import { useState } from "react";
import CollateralizationMethod from "./CollateralizationMethod/CollateralizationMethod";
import GasTracker from "../../components/GasTracker";
import ReturnUserBorrowFlow from "./ReturnUserBorrow/ReturnUserBorrow";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { TOOL_TIP } from "../../enums/homeEnum";
import CheckMaxBorrowAmount from "./CheckMaxBorrowAmount/CheckMaxBorrowAmount";
import CalculateMonthlyPayment from "./CalculateMonthlyPayment/CalculateMonthlyPayment";
import { BorrowContext } from "./BorrowContext";
import useBorrowedUserDetails from "../../Hooks/useBorrowedUserDetails/useBorrowedUserDetails";

const Borrow = () => {
  const { account } = useContext(UserContext);
  const { borrowedRud } = useBorrowedUserDetails(account);
  const borrowedBalance = Number(borrowedRud);

  // Functions for tooltip
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.nests}
    </Tooltip>
  );
  const [showBorrowMain, setShowBorrowMain] = useState(true);
  // Setting state for page number for moving in between components in the Borrow Flow
  const [BorrowFlowPageNumber, setBorrowFlowPageNumber] = useState(0);
  const [selectedLoanTerm, setSelectedLoanTerm] = useState(null);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null);
  // Onclicking Next button move to the next page
  const onClickNestButton = () => {
    setBorrowFlowPageNumber(1);
  };
  const { isMobile } = useContext(AppContext);
  const CardTitle = () => (
    <>
      <div
        className={cx(styles.BorrowHeadingText, {
          [styles.BorrowHeadingTextMob]: isMobile,
        })}
      >
        {BORROW_ENUM.borrowCardTitle}
        <OverlayTrigger placement="right" overlay={renderTooltip1}>
          <img
            src={QuestionMark}
            className={cx(styles.questionmarkImageInHeader, {
              [styles.questionmarkImageMob]: isMobile,
            })}
            alt="Question_Icon"
          />
        </OverlayTrigger>
        {/* ToolTip */}
      </div>
    </>
  );
  const CardBody = () => (
    <>
      <div className={styles.BorrowContent}>
        <div
          className={cx(styles.contentText, {
            [styles.BorrowContentMob]: isMobile,
          })}
        >
          {BORROW_ENUM.borrowContent}
        </div>

        <div
          className={cx(styles.LearnMoreConatiner, {
            [styles.LearnMoreConatinerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.LearnMoreConatinerDiv, {
              [styles.LearnMoreConatinerDivMob]: isMobile,
            })}
          >
            {!isMobile && (
              <div className={styles.learnMoreAndLink}>
                <div>{BORROW_ENUM.learnMoreContent}</div>
                <a
                  className={styles.nest}
                  href=" https://rariti-labs.gitbook.io/rade/product/borrowing#what-is-a-nest"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {BORROW_ENUM.nest}{" "}
                </a>
              </div>
            )}
            <div
              className={cx(styles.borrowButtonDiv, {
                [styles.borrowButtonDivMob]: isMobile,
              })}
            >
              <ButtonRade
                outline
                customStyling={cx(styles.borrowButton, {
                  [styles.borrowButtonMob]: isMobile,
                })}
                onClick={onClickNestButton}
              >
                {BORROW_ENUM.borrowButton}
              </ButtonRade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <BorrowContext.Provider
      value={{
        selectedLoanTerm,
        setSelectedLoanTerm,
        selectedPaymentTerm,
        setSelectedPaymentTerm,
        setBorrowFlowPageNumber,
      }}
    >
      <div className={styles.borrowMainContainer}>
        <div className={styles.gasTrackerContainer}>
          {isMobile && <GasTracker />}
        </div>
        <div
          className={cx(styles.borrowMainDiv, {
            [styles.borrowMainDivMob]: isMobile,
          })}
        >
          {(() => {
            //Update to next pages in the stake flow based on the switch statement and the Page number defined.
            switch (BorrowFlowPageNumber) {
              case 0:
                if (borrowedBalance === 0 && showBorrowMain) {
                  // calling as a function to avoid flickering issue
                  return (
                    <Card
                      titleComponent={<CardTitle />}
                      children={CardBody()}
                    />
                  );
                }

                return (
                  <ReturnUserBorrowFlow
                    setBorrowFlowPageNumber={setBorrowFlowPageNumber}
                    BorrowFlowPageNumber={BorrowFlowPageNumber}
                    setShowBorrowMain={setShowBorrowMain}
                  />
                );

              case 1:
                return (
                  <CollateralizationMethod
                    setBorrowFlowPageNumber={setBorrowFlowPageNumber}
                  />
                  //Pass this value as props in the upcoming page.
                );

              case 2:
                return (
                  <CalculateMonthlyPayment
                    setBorrowFlowPageNumber={setBorrowFlowPageNumber}
                    setSelectedLoanTerm={setSelectedLoanTerm}
                    setSelectedPaymentTerm={setSelectedPaymentTerm}
                  />
                  //Pass this value as props in the upcoming page.
                );
              case 3:
                return (
                  <CheckMaxBorrowAmount
                    setBorrowFlowPageNumber={setBorrowFlowPageNumber}
                    selectedLoanTerm={selectedLoanTerm}
                    selectedPaymentTerm={selectedPaymentTerm}
                    setShowBorrowMain={setShowBorrowMain}
                  />
                  //Pass this value as props in the upcoming page.
                );
              default:
                return <></>;
            }
          })()}
        </div>
      </div>
    </BorrowContext.Provider>
  );
};

export default Borrow;
