export const formatUserLiquidation = (data, timestamp) => {
  const formattedList = [];
  const length = data[2].length;
  for (let i = 0; i < length; i++) {
    const obj = {};
    const CONVERSION_FACTOR = 10 ** 18;
    let paymentsMissed = 0;
    obj.user = "UNU";
    obj.borrowedRUD = parseInt(data[2][i][1]._hex, 16) / CONVERSION_FACTOR;
    obj.loanBalance = parseInt(data[2][i][2]._hex, 16) / CONVERSION_FACTOR;
    obj.collRatio = parseInt(data[2][i][10]._hex, 16);
    obj.collateralAmount = parseInt(data[2][i][0]._hex, 16);
    const paymentDuration = parseInt(data[2][i][5]._hex, 16);
    const loanStartDate = parseInt(data[2][i][3]._hex, 16);
    const loanEndDate = parseInt(data[2][i][11]._hex, 16);
    const numberOfPayments = parseInt(data[2][i][6]._hex, 16);
    const paymentsMade = parseInt(data[2][i][8]._hex, 16);
    const minimumRepayment =
      parseInt(data[2][i][7]._hex, 16) / CONVERSION_FACTOR;
    const currentTime = Math.floor(timestamp);
    const paymentsDueTillDate = Math.floor(
      (currentTime - loanStartDate) / paymentDuration
    );
    if (currentTime > loanEndDate) {
      paymentsMissed = numberOfPayments - paymentsMade;
    } else {
      paymentsMissed = paymentsDueTillDate - paymentsMade;
    }
    obj.defaultValue = paymentsMissed * minimumRepayment;
    obj.liquidateColl =
      (((obj.defaultValue * CONVERSION_FACTOR) / obj.borrowedRUD) *
        obj.collateralAmount) /
      CONVERSION_FACTOR;
    const proportionalAsset =
      (minimumRepayment * CONVERSION_FACTOR) / obj.borrowedRUD;
    obj.liquidationAmount =
      (proportionalAsset * obj.collateralAmount) / CONVERSION_FACTOR;
    formattedList.push(obj);
  }
  return formattedList;
};
