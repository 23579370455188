import axios from "axios";
import BASE_ENUM from "../enums/base";

const { DEV, DEV_BASE_API, PROD_BASE_API } = BASE_ENUM;
const { NODE_ENV: envState } = process.env;

// For common config
axios.defaults.headers.post["Content-Type"] = "application/json";

const mainAxios = axios.create({
  baseURL: !envState || envState === DEV ? DEV_BASE_API : PROD_BASE_API,
});

export { mainAxios };
