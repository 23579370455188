import React, { useContext } from "react";
import styles from "./index.module.scss";
import { AppContext } from "../../../context";
import cx from "classnames";
import { ethers } from "ethers";
import ButtonRade from "../../../components/RadeButtons";
import { LIQUIDATE_ENUM } from "../../../enums/liquidateEnum";
import RudBorrowContract from "../../../artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
const MAX_DECIMAL_PLACE = 2;
const ETHER_CONVERSION = 10 ** 18;
const ETH_CONV = 10 ** -18;
/* global BigInt */
const ConfirmLiquidateModal = ({
  setModalIsOpen,
  address,
  nestId,
  defaultValue,
  ethToliquidate,
  userName,
  setModalPageNumber,
  setGasCost,
  setLiquidatorBonus,
}) => {
  const { isMobile } = useContext(AppContext);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  //borrow Rud Contract
  const borrowContract = new ethers.Contract(
    RudBorrowContract.address,
    RudBorrowContract.abi,
    signer
  );
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const onClickConfirmButton = async () => {
    try {
      setModalPageNumber(1);
      const liquidateTx = await borrowContract.liquidateNest(address, nestId);
      const receipt = await liquidateTx.wait();
      console.log("receipt", receipt);
      console.log("transaction hash", receipt.transactionHash);
      const gasUsedInEther = ethers.utils.formatUnits(receipt.gasUsed, "ether");
      setGasCost(gasUsedInEther);
      console.log("Gas Used in Ether: ", gasUsedInEther);
      const liquidatorReward = BigInt(
        receipt.events[8].args.bonusAmount._hex
      ).toString();
      console.log("liquidatorReward", liquidatorReward);
      if (receipt.events[8].event === "LiquidatorBonusTransferSuccess") {
        setLiquidatorBonus(liquidatorReward * ETH_CONV);
      }
      if (receipt.status === 1) {
        setModalPageNumber(2);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  return (
    <div className={styles.modalContainer}>
      <div className={styles.liquidateHeaderContainer}>
        Liquidate the nest of {userName}
      </div>
      <div
        className={cx(styles.passingParameters, {
          [styles.passingParametersMob]: isMobile,
        })}
      >
        <div className={styles.rudPriceinfo}>
          <div
            className={cx(styles.currentRudPrice, {
              [styles.currentRudPriceMob]: isMobile,
            })}
          >
            {LIQUIDATE_ENUM.defaultValue}
          </div>
          <div
            className={cx(styles.currentRudPriceValue, {
              [styles.currentRudPriceValueMob]: isMobile,
            })}
          >
            {Number(defaultValue).toFixed(MAX_DECIMAL_PLACE)}
          </div>
        </div>
        <div className={styles.rudPriceValues}>
          <div
            className={cx(styles.minimumReceivingRUD, {
              [styles.minimumReceivingRUDMob]: isMobile,
            })}
          >
            Collateral to liquidate
          </div>
          <div
            className={cx(styles.minimumReceivedRudvalue, {
              [styles.minimumReceivedRudvalueMob]: isMobile,
            })}
          >
            {Number(ethToliquidate / ETHER_CONVERSION).toFixed(
              MAX_DECIMAL_PLACE
            )}
          </div>
        </div>
      </div>
      <div className={styles.note}>{LIQUIDATE_ENUM.liquidationModalNote}</div>
      <div
        className={cx(styles.popupButtonsDiv, {
          [styles.popupButtonsDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.cancelSwap, {
            [styles.cancelSwapMob]: isMobile,
          })}
          onClick={closeModal}
        >
          {/*Closes the currently opened Modal onClick */}
          {LIQUIDATE_ENUM.cancel}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={cx(styles.confirmSwap, {
            [styles.confirmSwapMob]: isMobile,
          })}
          onClick={onClickConfirmButton}
        >
          {/*opens the next Modal onClick */}
          {LIQUIDATE_ENUM.confirm}
        </ButtonRade>
      </div>
    </div>
  );
};

export default ConfirmLiquidateModal;
