import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../SwapPopup/index.module.scss";
import { ethers } from "ethers";
import stableCoinMinterContract from "../../../../src/artifacts/Contracts/stablecoin/RaritiStableCoinMinter.sol/RaritiStableCoinMinter.json";
import ButtonRade from "../../../components/RadeButtons";
const FLOORING_FACTOR = 100;
const MAX_DECIMAL_PLACE = 2;
const SIX_DECIMAL_PLACES = 6;
const ETH_CONV = 10 ** -18;
/*global BigInt*/
const SwapPopup = ({
  selectedSlippageTolerance,
  rudPrice,
  ethPrice,
  setIsError,
  setModalIsOpen,
  setModalOpen,
  setConfirmTransaction,
  setCoinsMinted,
  setMintSuccessful,
  setEtherscanTransaction,
  setWaitingConfirmation,
  setModalPageNumber,
  setErrorMessage,
  userSelectionMode,
  exactEth,
  minimumReceivingRud,
  rampFee,
}) => {
  const { isMobile } = useContext(AppContext);
  //state to set the error meassage if user denied transaction in metamask
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  //Coin minter conract
  const CoinMinterContract = new ethers.Contract(
    stableCoinMinterContract.address, // deployed contract address
    stableCoinMinterContract.abi, //deployed contract abi
    signer
  );
  const slippageTolerance = selectedSlippageTolerance;
  //The exact rud amount that user must get when he buy rud using eth
  const amountOut = ethers.utils.parseUnits(rudPrice.toString(), "ether");
  const amountOutMin =
    Number(rudPrice) -
    Number(rudPrice) * slippageTolerance -
    Number(rudPrice) * rampFee;

  const AMOUNTOUT = ethers.utils.parseUnits(amountOutMin.toString(), "ether");

  const amountInMax =
    Number(ethPrice) * rampFee +
    Number(ethPrice) * slippageTolerance +
    Number(ethPrice);

  const maxEthToSpent = ethers.utils.parseUnits(
    amountInMax.toString(),
    "ether"
  );

  //user entering eth amount to get a specified number of rud
  const transactionParams = {
    value: ethers.utils.parseUnits(ethPrice.toString(), "ether"),
    // Only required to send ether to the recipient from the initiating external account.
  };
  //required eth to swapExactOutputForEth
  const sendingETHTransaction = {
    value: ethers.utils.parseUnits(amountInMax.toString(), "ether"),
  };
  //User confirmation of the transaction
  const confirmSwapButton = async () => {
    setIsError(false);
    setModalIsOpen(false);
    setModalOpen(true);
    setConfirmTransaction(true);
    //if user is buying rud by entering a specific number of eth
    if (userSelectionMode === RUD_ENUM.ethEnterMode) {
      try {
        const txHash = await CoinMinterContract.swapExactInputETHToDAI(
          AMOUNTOUT,
          transactionParams
        );
        const receipt = await txHash.wait();
        const coinsMinted = BigInt(
          receipt.events[5].args.amount._hex
        ).toString();
        if (receipt.events[5].event === "MintSuccess") {
          setCoinsMinted(coinsMinted * ETH_CONV);
          setMintSuccessful(true);
        }
        console.log("receipt", receipt);
        if (receipt.status === 1) {
          setModalOpen(true);
          setWaitingConfirmation(true);
          setEtherscanTransaction(receipt.transactionHash);
          setTimeout(() => {
            setModalOpen(false);
            setModalIsOpen(true);
            setModalPageNumber(1);
          }, 4000);
        }
      } catch (e) {
        console.log("e", e);
        if (e.code === 4001) {
          setIsError(true);
          setErrorMessage(RUD_ENUM.transactionRejectedError);
        } else {
          setIsError(true);
          setErrorMessage(e.code);
        }
      }
      setTimeout(() => setConfirmTransaction(false), 4000);
    } else {
      //if user is trying to buy rud that he must receive
      try {
        const rudTx = await CoinMinterContract.swapExactOutputForETH(
          amountOut,
          maxEthToSpent,
          sendingETHTransaction
        );
        const receipt = await rudTx.wait();
        const coinsMinted = BigInt(
          receipt.events[6].args.amount._hex
        ).toString();
        if (receipt.events[6].event === "MintSuccess") {
          setCoinsMinted(coinsMinted * ETH_CONV);
          setMintSuccessful(true);
        }
        if (receipt.status === 1) {
          setModalOpen(true);
          setWaitingConfirmation(true);
          setEtherscanTransaction(receipt.transactionHash);
          setTimeout(() => {
            setModalOpen(false);
            setModalIsOpen(true);
            setModalPageNumber(1);
          }, 4000);
        }
      } catch (e) {
        if (e.code === 4001) {
          setIsError(true);
          setErrorMessage(RUD_ENUM.transactionRejectedError);
        } else {
          setIsError(true);
          setErrorMessage(e.code);
        }
      }
      setTimeout(() => setConfirmTransaction(false), 4000);
    }
  };

  //Function to make the modal close.
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <div
        className={cx(styles.popupMainDiv, {
          [styles.popupMainDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.swapConfirmContent, {
            [styles.swapConfirmContentMob]: isMobile,
          })}
        >
          {RUD_ENUM.swapConfirmContent}
        </div>
        {userSelectionMode === RUD_ENUM.ethEnterMode && (
          <div
            className={cx(styles.payEth, {
              [styles.payEthMob]: isMobile,
            })}
          >
            {RUD_ENUM.pay}
          </div>
        )}
        {userSelectionMode === RUD_ENUM.ethEnterMode && (
          <div
            className={cx(styles.ethPriceDiv, {
              [styles.ethPriceDivMob]: isMobile,
            })}
          >
            <div className={styles.sendingEth}>{exactEth}</div>
            <div className={styles.ethContent}>{RUD_ENUM.ETH}</div>
          </div>
        )}
        <div
          className={cx(styles.passingParameters, {
            [styles.passingParametersMob]: isMobile,
          })}
        >
          <div className={styles.rudPriceinfo}>
            <div
              className={cx(styles.currentRudPrice, {
                [styles.currentRudPriceMob]: isMobile,
              })}
            >
              {userSelectionMode === RUD_ENUM.ethEnterMode
                ? RUD_ENUM.currentPriceInRUD
                : RUD_ENUM.maxETHtoPay}
            </div>
            <div
              className={cx(styles.currentRudPriceValue, {
                [styles.currentRudPriceValueMob]: isMobile,
              })}
            >
              {userSelectionMode === RUD_ENUM.ethEnterMode
                ? `$ ${rudPrice * FLOORING_FACTOR} RUD`
                : (Number(maxEthToSpent._hex) * ETH_CONV).toFixed(
                    SIX_DECIMAL_PLACES
                  )}
            </div>
          </div>
          <div className={styles.rudPriceValues}>
            <div
              className={cx(styles.minimumReceivingRUD, {
                [styles.minimumReceivingRUDMob]: isMobile,
              })}
            >
              {userSelectionMode === RUD_ENUM.ethEnterMode
                ? RUD_ENUM.minimumReceived
                : RUD_ENUM.get}
            </div>
            <div
              className={cx(styles.minimumReceivedRudvalue, {
                [styles.minimumReceivedRudvalueMob]: isMobile,
              })}
            >
              {userSelectionMode === RUD_ENUM.ethEnterMode
                ? `$ ${Number(minimumReceivingRud).toFixed(
                    MAX_DECIMAL_PLACE
                  )} RUD`
                : `$ ${Number(rudPrice).toFixed(MAX_DECIMAL_PLACE)} RUD`}
            </div>
          </div>
        </div>
        <div
          className={cx(styles.popupButtonsDiv, {
            [styles.popupButtonsDivMob]: isMobile,
          })}
        >
          <ButtonRade
            outline
            customStyling={cx(styles.cancelSwap, {
              [styles.cancelSwapMob]: isMobile,
            })}
            onClick={closeModal}
          >
            {/*Closes the currently opened Modal onClick */}
            {RUD_ENUM.cancel_swap}
          </ButtonRade>
          <ButtonRade
            outline
            customStyling={cx(styles.confirmSwap, {
              [styles.confirmSwapMob]: isMobile,
            })}
            onClick={confirmSwapButton}
          >
            {/*opens the next Modal onClick */}
            {RUD_ENUM.confirmButton}
          </ButtonRade>
        </div>
      </div>
    </>
  );
};
export default SwapPopup;
