import styled from "styled-components";
export const Body = styled.div`
  align-items: center;
  color: white;
  justify-content: center;
  position: relative;

  height: auto;
`;
export const Button = styled.button`
  cursor: pointer;
  text-decoration: none;
`;

export const Container = styled.div`
  background-color: #081527;
`;

export const Header = styled.div`
  align-items: center;
  color: white;
  position: fixed;
  top: 0px;
  height: auto;
  width: 100%;
  z-index: 1;
`;
export const Image = styled.img`
  height: 40vmin;
  margin-bottom: 16px;
  pointer-events: none;
`;
export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 8px;
`;
export const BrandTitle = styled.h1`
  font-family: jura;
`;
export const LeftSideBar = styled.div`
  color: blue;
`;
export const Divider = styled.div`
  border-bottom: 1px solid #343339;
  height: 0;
  width: 100%;
  z-index: 1;
`;
export const DashboardHome = styled.p`
  font-family: jura;
  font-size: 18px;
  color: #a5a2b8;
  margin: auto;
`;
