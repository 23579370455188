import avatar1 from "../assets/AvatarIcons/Robot Avatars_1.svg";
import avatar2 from "../assets/AvatarIcons/Robot Avatars_2.svg";
import avatar3 from "../assets/AvatarIcons/Robot Avatars_3.svg";
import avatar4 from "../assets/AvatarIcons/Robot Avatars_4.svg";
import avatar5 from "../assets/AvatarIcons/Robot Avatars_5.svg";
import avatar6 from "../assets/AvatarIcons/Robot Avatars_6.svg";
import avatar7 from "../assets/AvatarIcons/Robot Avatars_7.svg";
import avatar8 from "../assets/AvatarIcons/Robot Avatars_8.svg";
import avatar9 from "../assets/AvatarIcons/Robot Avatars_9.svg";
import avatar10 from "../assets/AvatarIcons/Robot Avatars_10.svg";
import avatar11 from "../assets/AvatarIcons/Robot Avatars_11.svg";
import avatar12 from "../assets/AvatarIcons/Robot Avatars_12.svg";
import avatar13 from "../assets/AvatarIcons/Robot Avatars_13.svg";
import avatar14 from "../assets/AvatarIcons/Robot Avatars_14.svg";
import avatar15 from "../assets/AvatarIcons/Robot Avatars_15.svg";
import avatar16 from "../assets/AvatarIcons/Robot Avatars_16.svg";
import avatar17 from "../assets/AvatarIcons/Robot Avatars_17.svg";
import avatar18 from "../assets/AvatarIcons/Robot Avatars_18.svg";
import avatar19 from "../assets/AvatarIcons/Robot Avatars_19.svg";
import avatar20 from "../assets/AvatarIcons/Robot Avatars_20.svg";
export const AVATAR_MAIN = [
  {
    image: avatar1,
  },

  {
    image: avatar2,
  },
  {
    image: avatar3,
  },
  {
    image: avatar4,
  },
  {
    image: avatar5,
  },

  {
    image: avatar6,
  },
  {
    image: avatar7,
  },
  {
    image: avatar8,
  },
  {
    image: avatar9,
  },

  {
    image: avatar10,
  },
  {
    image: avatar11,
  },
  {
    image: avatar12,
  },
  {
    image: avatar13,
  },

  {
    image: avatar14,
  },
  {
    image: avatar15,
  },
  {
    image: avatar16,
  },
  {
    image: avatar17,
  },

  {
    image: avatar18,
  },
  {
    image: avatar19,
  },
  {
    image: avatar20,
  },
];
