// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAZBGdGxgjklODot-q1AWuctoMwL9_rLZQ",
  authDomain: "rade-a5338.firebaseapp.com",
  projectId: "rade-a5338",
  storageBucket: "rade-a5338.appspot.com",
  messagingSenderId: "702900980892",
  appId: "1:702900980892:web:b2ccaaac5ca66f3795b159",
  measurementId: "G-Y7DH7613YN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const storage = getStorage(app);
const auth = getAuth(app);
export { auth };
export { db };
export const firestore = getFirestore(app); // Export the firestore variable
