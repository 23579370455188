import React, { useContext } from "react";
import styles from "./index.module.scss";
import radeTitle from "../assets/radeTitle.svg";
import radeLogo from "../assets/logo_rade.svg";
import metamaskLogo from "../assets/Metamask Icon.svg";
import { UserContext } from "../context";
const WalletConnect = () => {
  const { setIsMetamaskConnected } = useContext(UserContext);
  const handleClickMetamaskButton = () => {
    setIsMetamaskConnected(true);
  };
  return (
    <div className={styles.walletConnectDisplay}>
      <div className={styles.walletConnectContainer}>
        <div className={styles.logoNameContainer}>
          <img className={styles.radeLogo} src={radeLogo} alt="rade_logo" />
          <img
            className={styles.radeHeading}
            src={radeTitle}
            alt="rade_title"
          />
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => handleClickMetamaskButton()}
          >
            <div className={styles.connectMetamaskButton}>
              <img
                className={styles.metamaskLogo}
                src={metamaskLogo}
                alt="metamask_logo"
              />
              <div className={styles.connectText}>Connect Metamask</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletConnect;
