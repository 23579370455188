import styles from "./index.module.scss";
import React from "react";
import ButtonRade from "../../../../components/RadeButtons";
import { RUD_ENUM } from "../../../../enums/rudEnum";
import uploadIcon from "../../../../assets/Upload Icon.svg";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../../../context";
import { ReturnUserBorrowContext } from "../ReturnUserContext";
const PaymentSuccessfulModal = ({
  setModalOpen,
  toggleBorrowDetails,
  nestId,
  nextPaymentAmount,
}) => {
  const { setReturnUserBorrowFlowPage } = useContext(ReturnUserBorrowContext);

  // Function that navigates to borrow landing page
  const handleClickOKButton = () => {
    setModalOpen(false);
    setReturnUserBorrowFlowPage(0);
    toggleBorrowDetails(nestId);
  };
  const { isMobile } = useContext(AppContext);
  return (
    <div className={styles.transactionSubmitDiv}>
      <div
        className={cx(styles.uploadIconDiv, {
          [styles.uploadIconDivMob]: isMobile,
        })}
      >
        <img
          src={uploadIcon}
          alt="upload-icon"
          className={cx(styles.uploadIcon, {
            [styles.uploadIconMob]: isMobile,
          })}
        />
      </div>
      <div
        className={cx(styles.transactionSubmit, {
          [styles.transactionSubmitMob]: isMobile,
        })}
      >
        {RUD_ENUM.transaction_submit}
      </div>
      <div
        className={cx(styles.viewTransactionDetails, {
          [styles.viewTransactionDetailsMob]: isMobile,
        })}
      >
        The partial payment of ${nextPaymentAmount} has been received.
      </div>
      <div
        className={cx(styles.OKButtonDiv, {
          [styles.OKButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          onClick={handleClickOKButton}
          customStyling={cx(styles.OKButton, {
            [styles.OKButtonMob]: isMobile,
          })}
        >
          {RUD_ENUM.OKButton}
        </ButtonRade>
      </div>
    </div>
  );
};

export default PaymentSuccessfulModal;
