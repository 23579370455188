import Skeleton from "react-loading-skeleton";
import React from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import styles from "../ReturnUserRUDBuy/index.module.scss";
import Card from "../../../components/Card";
import { HOME_ENUM } from "../../../enums/homeEnum";
import { RUD_ENUM } from "../../../enums/rudEnum";
import useStakedUserDetails from "../../../Hooks/useStakedUserDetails/useStakedUserDetails";
import useRudInWallet from "../../../Hooks/useRudBalance/useRudBalance";
import useBorrowedUserDetails from "../../../Hooks/useBorrowedUserDetails/useBorrowedUserDetails";

const MAX_DECIMAL_PLACE = 2;
const HomescreenReturnUserRudBuy = () => {
  const { account } = useContext(UserContext);
  const { isMobile } = useContext(AppContext);
  const { stakedRudBalance } = useStakedUserDetails(account);
  const { rudBalance } = useRudInWallet(account);
  const { borrowedRud } = useBorrowedUserDetails(account);
  const rudInWallet = Number(rudBalance);
  const userTotalStakedValue = Number(stakedRudBalance);
  const userTotalBorrowedValue = Number(borrowedRud);

  const CardTitle = () => (
    <>
      <div
        className={cx(styles.headingText, {
          [styles.headingTextMob]: isMobile,
        })}
      >
        {HOME_ENUM.en.portfolio}
      </div>
    </>
  );
  const CardBody = () => (
    <>
      <div
        className={cx(styles.returnUserRudBuyDiv, {
          [styles.returnUserRudBuyDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.rudBalanceDiv, {
            [styles.rudBalanceDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.rudBalancekey, {
              [styles.rudBalancekeyMob]: isMobile,
            })}
          >
            {HOME_ENUM.en.returnAfterRudBuy}
          </div>
          {rudBalance !== null ? (
            <div className={styles.rudBalanceContentDiv}>
              <div
                className={cx(styles.rudBalanceValue, {
                  [styles.rudBalanceValueMob]: isMobile,
                })}
              >
                $ {rudInWallet.toFixed(MAX_DECIMAL_PLACE)}
              </div>

              <div
                className={cx(styles.rudContent, {
                  [styles.rudContentMob]: isMobile,
                })}
              >
                {RUD_ENUM.RUD_Heading}
              </div>
            </div>
          ) : (
            <Skeleton
              style={{
                backgroundColor: "rgb(25, 39, 59)",
                width: "50%",
                height: "25px",
                marginRight: "150px",
              }}
            />
          )}
        </div>
        <div
          className={cx(styles.stakedValueDiv, {
            [styles.stakedValueDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.stakedKey, {
              [styles.stakedKeyMob]: isMobile,
            })}
          >
            {RUD_ENUM.staked}
          </div>
          {stakedRudBalance !== null ? (
            <div className={styles.rudBalanceContentDiv}>
              <div
                className={cx(styles.stakedValue, {
                  [styles.stakedValueMob]: isMobile,
                })}
              >
                ${userTotalStakedValue.toFixed(MAX_DECIMAL_PLACE)}
              </div>

              <div
                className={cx(styles.rudContent, {
                  [styles.rudContentMob]: isMobile,
                })}
              >
                {RUD_ENUM.RUD_Heading}
              </div>
            </div>
          ) : (
            <Skeleton
              style={{
                backgroundColor: "rgb(25, 39, 59)",
                width: "50%",
                height: "25px",
                marginRight: "150px",
              }}
            />
          )}
        </div>
        <div
          className={cx(styles.borrowedValueDiv, {
            [styles.borrowedValueDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.borrowedKey, {
              [styles.borrowedKeyMob]: isMobile,
            })}
          >
            {RUD_ENUM.borrowed}
          </div>
          {borrowedRud !== null ? (
            <div className={styles.rudBalanceContentDiv}>
              <div
                className={cx(styles.borrowedValue, {
                  [styles.borrowedValueMob]: isMobile,
                })}
              >
                ${userTotalBorrowedValue.toFixed(MAX_DECIMAL_PLACE)}
              </div>

              <div
                className={cx(styles.rudContent, {
                  [styles.rudContentMob]: isMobile,
                })}
              >
                {RUD_ENUM.RUD_Heading}
              </div>
            </div>
          ) : (
            <Skeleton
              style={{
                backgroundColor: "rgb(25, 39, 59)",
                width: "50%",
                height: "25px",
                marginRight: "150px",
              }}
            />
          )}
        </div>
      </div>
    </>
  );

  return (
    <>
      {/* calling as a function to avoid flickering issue */}
      <Card titleComponent={<CardTitle />} children={CardBody()} />
    </>
  );
};
export default HomescreenReturnUserRudBuy;
