const { ethers } = require("ethers");
const { Token } = require("@uniswap/sdk-core");
const { FeeAmount } = require("@uniswap/v3-sdk");
const { computePoolAddress } = require("@uniswap/v3-sdk");
const Quoter = require("@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json");
const IUniswapV3PoolABI = require("@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json");
const { getProvider } = require("./providers");
const { toReadableAmount, fromReadableAmount } = require("./conversion");

// Define an async function to get chain ID and tokens
async function getChainIDAndTokens() {
  try {
    const chainID = await window.ethereum.request({
      method: "net_version",
    });

    const { wethAddress, daiAddress } = await getNetworkDetails(chainID);

    const WETH_TOKEN = new Token(5, wethAddress, 18, "WETH", "Wrapped Ether");

    const USDC_TOKEN = new Token(5, daiAddress, 6, "USDC", "USD//C");

    return { chainID, WETH_TOKEN, USDC_TOKEN };
  } catch (error) {
    throw new Error(`Error fetching chain ID and tokens: ${error.message}`);
  }
}

const getNetworkDetails = (chainID) => {
  switch (chainID) {
    case "1":
      return {
        networkName: "Ethereum Main Network",
        wethAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        daiAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      };
    case "5":
      return {
        networkName: "Goerli Test Network",
        wethAddress: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
        daiAddress: "0x11fe4b6ae13d2a6055c8d9cf65c55bac32b5d844",
      };
    case "31337":
      return {
        networkName: "Hardhat",
        wethAddress: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
        daiAddress: "0x11fe4b6ae13d2a6055c8d9cf65c55bac32b5d844",
      };
    case "80001":
      return {
        networkName: "Mumbai",
        wethAddress: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
        daiAddress: "0x11fe4b6ae13d2a6055c8d9cf65c55bac32b5d844",
      };
    default:
      return {
        networkName: undefined,
        priceFeedAddress: undefined,
        tokenName: "ETH",
        wethAddress: undefined,
        daiAddress: undefined,
      };
  }
};

async function quote() {
  try {
    const { chainID, WETH_TOKEN, USDC_TOKEN } = await getChainIDAndTokens();
    const CurrentConfig = {
      tokens: {
        in: WETH_TOKEN,
        out: USDC_TOKEN,
        amountIn: 1,
        poolFee: FeeAmount.MEDIUM,
      },
    };

    const QUOTER_CONTRACT_ADDRESS =
      "0xb27308f9f90d607463bb33ea1bebb41c27ce5ab6";

    const quoterContract = new ethers.Contract(
      QUOTER_CONTRACT_ADDRESS,
      Quoter.abi,
      getProvider(chainID)
    );
    const poolConstants = await getPoolConstants(chainID);

    const quotedAmountOut =
      await quoterContract.callStatic.quoteExactInputSingle(
        poolConstants.token1,
        poolConstants.token0,
        poolConstants.fee,
        fromReadableAmount(
          CurrentConfig.tokens.amountIn,
          CurrentConfig.tokens.out.decimals
        ).toString(),
        0
      );

    return toReadableAmount(quotedAmountOut, CurrentConfig.tokens.out.decimals);
  } catch (error) {
    throw new Error(`Error fetching quote: ${error.message}`);
  }
}

async function getPoolConstants(chainID) {
  try {
    const { WETH_TOKEN, USDC_TOKEN } = await getChainIDAndTokens();

    const CurrentConfig = {
      tokens: {
        in: WETH_TOKEN,
        out: USDC_TOKEN,
        amountIn: 1,
        poolFee: FeeAmount.MEDIUM,
      },
    };
    const POOL_FACTORY_CONTRACT_ADDRESS =
      "0x1F98431c8aD98523631AE4a59f267346ea31F984";

    const currentPoolAddress = computePoolAddress({
      factoryAddress: POOL_FACTORY_CONTRACT_ADDRESS,
      tokenA: CurrentConfig.tokens.in,
      tokenB: CurrentConfig.tokens.out,
      fee: CurrentConfig.tokens.poolFee,
    });

    const poolContract = new ethers.Contract(
      currentPoolAddress,
      IUniswapV3PoolABI.abi,
      getProvider(chainID)
    );

    const [token0, token1, fee] = await Promise.all([
      poolContract.token0(),
      poolContract.token1(),
      poolContract.fee(),
    ]);

    return {
      token0,
      token1,
      fee,
    };
  } catch (error) {
    throw new Error(`Error fetching pool constants: ${error.message}`);
  }
}

export default quote;
