import React, { useState, useEffect } from "react";
import styles from "../Desktop/Profile.module.scss";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import Modal from "react-modal";
import Tooltip from "react-bootstrap/Tooltip";
import cx from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "../../ReactLoading/reactLoading.css";
import { db } from "../../../firebase";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import ProfileNameUpdatePopUp from "./ProfileNameUpdatePopUp/ProfileNameUpdatePopUp";

const ProfileName = () => {
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      Change username
    </Tooltip>
  );

  const { isMobile } = useContext(AppContext);
  const { account } = useContext(UserContext);
  const [lastUsernameChangeDate, setLastUsernameChangeDate] = useState(null);
  const [lastUsernameChangeDateError, setLastUsernameChangeDateError] =
    useState(null);

  const [userData, setUserData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // State for controlling the modal
  const [newUserName, setNewUserName] = useState(""); // State for storing the new username
  // Function to handle username update and Firebase data storage
  const handleUsernameUpdate = async () => {
    setLastUsernameChangeDateError(null);
    try {
      // Check if it has been at least 21 days since the last change
      const currentDate = new Date().getTime();

      // Check if the new username is unique
      if (!(await isUsernameUnique(newUserName))) {
        setLastUsernameChangeDateError(
          "Username is already in use. Please choose a different one."
        );
        return;
      }
      if (
        lastUsernameChangeDate &&
        currentDate - lastUsernameChangeDate < 21 * 86400
      ) {
        setLastUsernameChangeDateError(
          "You can only change your username every 21 days."
        );
        return;
      }
      // Store the new username in a separate collection "userData"
      const userDataRef = collection(db, "userData");
      const newUserNameDocRef = doc(userDataRef, account);

      // Set the new username and last change date in the document
      await updateDoc(newUserNameDocRef, {
        userName: newUserName,
        lastUsernameChangeDate: currentDate,
      });

      // Update the state with the new username and last change date
      setUserData(newUserName);
      setLastUsernameChangeDate(currentDate);

      // Close the modal
      setModalOpen(false);
    } catch (error) {
      console.error("Error updating username:", error);
    }
  };
  // Check if the new username is unique
  async function isUsernameUnique(newUsername) {
    const userDataRef = collection(db, "userData");
    const querySnapshot = await getDocs(userDataRef);

    let isUnique = true;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.userName === newUsername) {
        isUnique = false;
      }
    });

    return isUnique;
  }
  useEffect(() => {
    async function fetchUsernameChangeDate() {
      // Fetch the last username change date from the database
      const userDataRef = collection(db, "userData");
      const userDocRef = doc(userDataRef, account);

      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        setLastUsernameChangeDate(userData.lastUsernameChangeDate || null);
        setUserData(userData.userName);
      }
    }

    fetchUsernameChangeDate();
  }, []);
  return (
    <div>
      <OverlayTrigger placement="top" overlay={renderTooltip1}>
        <div
          className={cx(styles.profileName, {
            [styles.profileNameMob]: isMobile,
          })}
          onClick={() => setModalOpen(true)} // Open modal on click
          style={{ cursor: "pointer" }}
        >
          {userData ? userData : <>User_Name</>}
        </div>
      </OverlayTrigger>
      <Modal
        isOpen={modalOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        ariaHideApp={false}
      >
        <ProfileNameUpdatePopUp
          setNewUserName={setNewUserName}
          newUserName={newUserName}
          handleUsernameUpdate={handleUsernameUpdate}
          setModalOpen={setModalOpen}
          lastUsernameChangeDateError={lastUsernameChangeDateError}
          setLastUsernameChangeDateError={setLastUsernameChangeDateError}
        />
      </Modal>
    </div>
  );
};

export default ProfileName;
