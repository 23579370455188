import React from "react";
import { useContext } from "react";
import Modal from "react-modal";

import { Body, Container } from "..";
import { AppContext, UserContext } from "../../context";
import HeaderMain from "../HeaderMain";
import cx from "classnames";
import styles from "./Layout.module.scss";
import NavBarDesktop from "../NavBar/Desktop";
import RightSidePanel from "../RightSidePanel/RightSidePanel";

const Layout = ({ children }) => {
  const { isMobile, isModalOpen, modalChild } = useContext(AppContext);
  const { ens, account, connectedNetworkName, isMetamaskConnected } =
    useContext(UserContext);

  const isConnected = !!(ens || account);
  return (
    <Container>
      <HeaderMain />

      {!isMobile && isConnected && connectedNetworkName && (
        <>
          <RightSidePanel />
          <NavBarDesktop />
        </>
      )}
      <Body
        className={cx({
          [styles.bodyMobile]: isMobile,
          [styles.bodyDesktop]: !isMobile,
          [styles.beforeConnected]: !(ens || account),
        })}
      >
        {children}
      </Body>
      <Modal
        portalClassName={styles.modal}
        className={styles.modalContent}
        overlayClassName={styles.popupOverlay}
        isOpen={isModalOpen}
      >
        {modalChild}
      </Modal>
    </Container>
  );
};

export default Layout;
