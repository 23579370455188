import styles from "./PopUp.module.scss";
import React from "react";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../../context";
import KYCNotVerified from "../../../assets/KYCNotVerifiedIcon.svg";
import { RUD_ENUM } from "../../../enums/rudEnum";
import ButtonRade from "../../../components/RadeButtons/index";
import { useNavigate } from "react-router-dom";
const PopUp = ({ setIsOpen }) => {
  const { isMobile } = useContext(AppContext);
  //Function to make the Modal close.
  function closeModal() {
    setIsOpen(false);
  }

  let navigate = useNavigate();

  const handleClickCompleteKYC = () => {
    navigate("/settings");
  };
  return (
    <div>
      <div
        className={cx(styles.popUpContent, {
          [styles.popUpContentMob]: isMobile,
        })}
      >
        <div className={styles.KYCNotVerifiedContainer}>
          <div>
            <img src={KYCNotVerified} alt="KYC not verified" />
          </div>
          <div> {RUD_ENUM.yourProfileIsNotVerified}</div>
          <div>{RUD_ENUM.youMustCompleteKYC}</div>
        </div>
        <div
          className={cx(styles.popUpButtonDiv, {
            [styles.popUpButtonDivMob]: isMobile,
          })}
        >
          <ButtonRade
            className={cx(styles.cancelButton, {
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={closeModal}
          >
            {RUD_ENUM.cancelButton}
          </ButtonRade>

          <ButtonRade
            className={cx(styles.completeKYCButton, {
              [styles.completeKYCButtonMob]: isMobile,
            })}
            onClick={handleClickCompleteKYC}
          >
            {RUD_ENUM.completeKYCButton}
          </ButtonRade>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
