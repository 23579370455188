import cx from "classnames";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../../context";
import { RUD_ENUM } from "../../enums/rudEnum";
import FailedSignerTransactionIcon from "../../assets/signatureTransactionFailed.svg";
import ButtonRade from "../RadeButtons";
import styles from "./index.module.scss";
const FailedSignatureRequest = ({ setModalIsOpen, setModalOpen }) => {
  const { isMobile } = useContext(AppContext);
  function OnClickCloseButton() {
    setModalIsOpen(false);
    setModalOpen(false);
  }
  return (
    <div
      className={cx(styles.getSignerMainContainer, {
        [styles.getSignerMainContainerWeb]: !isMobile,
        [styles.getSignerMainContainerMob]: isMobile,
      })}
    >
      <div>
        <img
          src={FailedSignerTransactionIcon}
          alt="Fetching signature"
          className={cx(styles.FailedSignerTransactionIcon, {
            [styles.FailedSignerTransactionIconWeb]: !isMobile,
            [styles.FailedSignerTransactionIconMob]: isMobile,
          })}
        />
      </div>
      <div
        className={cx(styles.youCancelledTheSignature, {
          [styles.youCancelledTheSignatureWeb]: !isMobile,
          [styles.youCancelledTheSignatureMob]: isMobile,
        })}
      >
        {RUD_ENUM.youCancelledTheSignature}
      </div>
      <div
        className={cx(styles.closeAndInitiate, {
          [styles.closeAndInitiateWeb]: !isMobile,
          [styles.closeAndInitiateMob]: isMobile,
        })}
      >
        {RUD_ENUM.closeAndInitiate}
      </div>
      <ButtonRade
        customStyling={styles.closeButton}
        onClick={OnClickCloseButton}
      >
        {RUD_ENUM.closeButton}
      </ButtonRade>
    </div>
  );
};

export default FailedSignatureRequest;
