import React, { useEffect, useState } from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../context";
import styles from "../stake/index.module.scss";
import { useContext } from "react";
import { STAKE_ENUM } from "../../enums/stakeEnum";
import ButtonRade from "../../components/RadeButtons";
import QuestionIcon from "../../assets/Question Icon.svg";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card/index";
import GasTracker from "../../components/GasTracker/GasTracker";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { TOOL_TIP } from "../../enums/homeEnum";
import StakedUserDetails from "./userStakeDetails/UserStakeDetails";
import LiquidityPool from "./AddRUDInLiquidityPool/AddRUDInLiquidityPool";
import { StakeContext } from "./StakeContext";
import useRudInWallet from "../../Hooks/useRudBalance/useRudBalance";
import useStakedUserDetails from "../../Hooks/useStakedUserDetails/useStakedUserDetails";
const MAX_DECIMAL_PLACE = 2;

const Stake = () => {
  const { account } = useContext(UserContext);
  const { rudBalance } = useRudInWallet(account);
  const { stakedRudBalance } = useStakedUserDetails(account);
  const [stakedRUD, setStakedRUD] = useState(null);
  // Methods for tooltip
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.radePool}
    </Tooltip>
  );

  // state for saving the permitSignature parameters
  const [signatureParameters, setSignatureParameters] = useState(false);
  const userRUDBalance = Number(rudBalance);
  const stakedBalance = Number(stakedRudBalance);
  //state to switch to next page when clicking on the Deposit button.
  const [addRUDInLiquidityPool, setAddRUDInLiquidityPool] = useState(false);
  //State variable to set error message if there is any in backend
  const [isError, setIsError] = useState(false);
  //State variable to set apppropriate error message accordingly
  const [errorMessage, setErrorMessage] = useState(false);
  //Function to activate the Deposit Button onClick.
  const handleSubmitStakeButton = () => {
    setAddRUDInLiquidityPool(true);
  };
  useEffect(() => {
    setStakedRUD(stakedRudBalance);
  }, []);
  //Function to activate the GetRUD Button onClick.
  const handleSubmitGetRUDButton = () => {
    navigate("/get_rud");
  };
  let navigate = useNavigate();
  const { isMobile } = useContext(AppContext);
  const CardTitle = () => (
    <>
      <div className={styles.stakeTitleContainer}>
        <div
          className={cx(styles.stakeHeadingText, {
            [styles.stakeHeadingTextMob]: isMobile,
          })}
        >
          {STAKE_ENUM.Stake_Card_Title}
        </div>
        <div
          className={cx(styles.questionIconDiv, {
            [styles.questionIconDivMob]: isMobile,
          })}
        >
          <OverlayTrigger placement="right" overlay={renderTooltip1}>
            <img
              src={QuestionIcon}
              alt="Question_Icon"
              className={cx(styles.questionIcon, {
                [styles.questionIconMob]: isMobile,
              })}
            />
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
  const CardBody = () => (
    <>
      <div className={styles.stakeContent}>
        {stakedBalance === 0 && (
          <div
            className={cx(styles.stakeContentText, {
              [styles.stakeContentTextMob]: isMobile,
            })}
          >
            {userRUDBalance !== 0
              ? `You have $ ${userRUDBalance.toFixed(
                  MAX_DECIMAL_PLACE
                )} RUD in your wallet. Stake it to become liquidity provider.`
              : STAKE_ENUM.Stake_Content}
          </div>
        )}
        {stakedBalance !== 0 && (
          <div
            className={cx(styles.returnLiquidityProvider, {
              [styles.returnLiquidityProviderMob]: isMobile,
            })}
          >
            {STAKE_ENUM.returnStakedUser}
          </div>
        )}
        <div
          className={cx(styles.stakeButtonsDiv, {
            [styles.stakeButtonsDivMob]: isMobile,
            [styles.buttonsAfterStake]: stakedBalance !== 0,
          })}
        >
          {stakedBalance === 0 && (
            <div
              className={cx(styles.stakeEarnReward, {
                [styles.stakeEarnRewardMob]: isMobile,
              })}
            >
              {STAKE_ENUM.Stake_Reward_Earn}
            </div>
          )}
          <div className={styles.buttons}>
            {userRUDBalance !== 0 ? (
              <ButtonRade
                onClick={handleSubmitStakeButton}
                outline
                customStyling={cx(styles.stakeButton, {
                  [styles.stakeButtonMob]: isMobile,
                })}
              >
                {STAKE_ENUM.stakeButton}
              </ButtonRade>
            ) : (
              <ButtonRade
                onClick={handleSubmitGetRUDButton}
                outline
                customStyling={cx(styles.buyRUDButton, {
                  [styles.buyRUDButtonMob]: isMobile,
                  [styles.buyRudButtonWithoutBalance]: userRUDBalance === 0,
                })}
              >
                {STAKE_ENUM.buy_RUD_Button}
              </ButtonRade>
            )}
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      <div
        className={cx(styles.cardComponent, {
          [styles.returnUserComponent]:
            stakedBalance !== 0 || stakedBalance === 0,
        })}
      >
        {!addRUDInLiquidityPool && stakedBalance !== 0 && (
          <StakedUserDetails setStakedRUD={setStakedRUD} />
        )}
        <StakeContext.Provider value={{ setAddRUDInLiquidityPool }}>
          {addRUDInLiquidityPool ? (
            <LiquidityPool
              isError={isError}
              errorMessage={errorMessage}
              setIsError={setIsError}
              setErrorMessage={setErrorMessage}
              signatureParameters={signatureParameters}
              setSignatureParameters={setSignatureParameters}
            />
          ) : (
            // calling as a function to avoid flickering issue
            <Card titleComponent={<CardTitle />} children={CardBody()} />
          )}
        </StakeContext.Provider>
      </div>
      <div className={styles.gasTrackerContainer}>
        {isMobile && <GasTracker />}
      </div>
    </>
  );
};
export default Stake;
