import { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { BILLING_ADDRESS, CARD_LABELS } from "../enum";
import Spinner from "../../../../../components/Spinner/Spinner";
import { Button } from "../../../../../components";

import styles from "./index.module.scss";
import { useContext } from "react";
import { AppContext } from "../../../../../context";
import styled from "styled-components";
import { createPaymentIntent } from "../../../../../api";
import SuccessFailed from "./SuccessFailed";

const ElementContainer = styled.div`
  border: 1px solid #02aab0;
  border-radius: 4px;
  font-family: "Metrophobic";
  padding: 6px 17px 8px;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
`;

const CheckoutForm = ({ amount, isCancel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const { setIsModalOpen, setModalChild } = useContext(AppContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    // create payment Intent
    const { clientSecret, error } = await createPaymentIntent({
      amount,
      description: "buy rud",
      currency: "usd",
    });
    if (!clientSecret) {
      setMessage(error);
      setIsProcessing(false);
      return;
    }

    setIsModalOpen(true);
    setModalChild(<Spinner />);

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: cardHolderName,
            address: BILLING_ADDRESS,
          },
        },
      });

    let isSuccess = false;
    if (stripeError) {
      setMessage(stripeError.message);
    } else if (paymentIntent.status === "succeeded") {
      setMessage("Success");
      isSuccess = true;
    } else {
      setMessage(paymentIntent.status);
    }

    setModalChild(
      <SuccessFailed
        isSuccess={isSuccess}
        errorMsg={!isSuccess ? message : null}
        close={() => {
          // return to another page logic can be given here
          setIsModalOpen(false);
          setModalChild(null);
          //close the payment flow
          isCancel();
        }}
      />
    );

    setIsProcessing(false);
  };

  const inputStyle = {
    iconColor: "#c4f0ff",
    color: "#ffffff",
    fontFamily: "Metrophobic, roboto",
    weight: "400",
    fontSize: "14px",
    fontSmoothing: "antialiased",
    ":-webkit-autofill": {
      color: "#ffffff",
    },
    "::placeholder": {
      color: "#747474",
    },
  };
  const numberElOpt = {
    showIcon: true,
    style: {
      base: inputStyle,
    },
  };

  const otherElOpt = {
    style: {
      base: inputStyle,
    },
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className={styles.form}>
      <label className={styles.label}>{CARD_LABELS.title}</label>

      <ElementContainer>
        <label className={styles.labelCard}>{CARD_LABELS.cardholderName}</label>
        <input
          type="text"
          className={styles.input}
          onChange={(e) => setCardHolderName(e.target.value)}
          required
        />
      </ElementContainer>
      <ElementContainer>
        <label className={styles.labelCard}>{CARD_LABELS.cardNumer}</label>
        <CardNumberElement className={styles.card} options={numberElOpt} />
      </ElementContainer>
      <div className={styles.flex}>
        <ElementContainer>
          <label className={styles.labelCard}>{CARD_LABELS.securityCode}</label>
          <CardCvcElement className={styles.card} options={otherElOpt} />
        </ElementContainer>

        <ElementContainer>
          <label className={styles.labelCard}>
            {CARD_LABELS.expirationDate}
          </label>
          <CardExpiryElement className={styles.card} options={otherElOpt} />
        </ElementContainer>
      </div>
      <div className={styles.flex}>
        <Button className={styles.cancel} onClick={isCancel}>
          Cancel
        </Button>
        <Button className={styles.submit} disabled={isProcessing}>
          Pay Now
        </Button>
      </div>
    </form>
  );
};

export default CheckoutForm;
