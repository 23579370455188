import * as React from "react";
import styles from "./index.module.scss";
const DetailsUp = (props) => (
  <svg
    {...props}
    width={24}
    height={24}
    fill="rgb(0, 205, 172)"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.detailsIcon}
  >
    <g data-name="Layer 2">
      <path d="M19 13H5a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2z" data-name="minus" />
    </g>
  </svg>
);

export default DetailsUp;
