import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import styles from "./index.module.scss";
import { SETTINGS_ENUM } from "../../../enums/settingsEnum";
const Security = ({ shouldRenderSettings }) => {
  const { isMobile } = useContext(AppContext);

  const CardBody = () => (
    <div
      className={cx(styles.securityMainDiv, {
        [styles.securityMainDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.securityDiv, {
          [styles.securityDivMob]: isMobile,
        })}
      >
        {SETTINGS_ENUM.securityFeaturesComingSoon}
      </div>
    </div>
  );

  return (
    <>
      <CardBody />
    </>
  );
};

export default Security;
