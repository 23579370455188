import moment from "moment";

export const formatUserStaking = (data) => {
  const formattedList = [];
  const length = data[2].length;
  for (let i = 0; i < length; i++) {
    const obj = {};
    const ETHER_CONVERSION = 10 ** 18;
    obj.account = i + 1;
    const ONE_HOUR_LOCK = 3600;
    const THREE_MONTHS_LOCK = 7890000;
    const SIX_MONTHS_LOCK = 15780000;
    const NINE_MONTHS_LOCK = 23670000;
    const ONE_YEAR_LOCK = 31536000;
    const lockDuration = parseInt(data[2][i][2]._hex, 16);
    obj.lockDurationInSec = parseInt(data[2][i][2]._hex, 16);
    obj.stakedRUD = parseInt(data[2][i][0]._hex, 16) / ETHER_CONVERSION;
    if (lockDuration === ONE_HOUR_LOCK) {
      obj.lockDuration = "1 Hour";
    } else if (lockDuration === THREE_MONTHS_LOCK) {
      obj.lockDuration = "3 Months";
    } else if (lockDuration === SIX_MONTHS_LOCK) {
      obj.lockDuration = "6 Months";
    } else if (lockDuration === NINE_MONTHS_LOCK) {
      obj.lockDuration = "9 Months";
    } else if (lockDuration === ONE_YEAR_LOCK) {
      obj.lockDuration = "1 Year";
    }
    obj.maturityDate = moment
      .unix(parseInt(data[2][i][3]._hex, 16))
      .format("DD-MM-YY HH:mm:ss");
    obj.stakedTime = moment
      .unix(parseInt(data[2][i][1]._hex, 16))
      .format("DD-MM-YY HH:mm:ss");
    obj.stakedTimeInSeconds = parseInt(data[2][i][1]._hex, 16);
    const currentTime = moment();
    const maturityDate = moment(obj.maturityDate, "DD-MM-YY HH:mm:ss");
    obj.maturityDateInSeconds = parseInt(data[2][i][3]._hex, 16);
    if (currentTime.isBefore(maturityDate)) {
      obj.status = "Locked";
    } else {
      obj.status = "Unlocked";
    }
    formattedList.push(obj);
  }
  return formattedList;
};
