import * as React from "react";
import styles from "./index.module.scss";
const DetailsIcon = (props) => (
  <svg
    {...props}
    width={16}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className={styles.detailsIcon2}
  >
    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
  </svg>
);

export default DetailsIcon;
