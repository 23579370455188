import React, { useState } from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "./ProceedSell.module.scss";
import ButtonRade from "../../../components/RadeButtons";
import DetailsIcon from "../../../assets/DetailsIcon/DetailsUpIcon";
import PopUp from "../PopUp/PopUp";
import Modal from "react-modal";
import DetailsUp from "../../../assets/DetailsIcon/DetailsUp";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
import useRudInWallet from "../../../Hooks/useRudBalance/useRudBalance";
const DECIMAL_PLACES = 2;
const ProceedButton = ({ setUserModeSelection, setCanProceed }) => {
  const { account } = useContext(UserContext);
  const { rudBalance } = useRudInWallet(account);
  const maxRudInWallet = Number(rudBalance).toFixed(DECIMAL_PLACES);
  //State to set the Modal window open or close.
  const [modalIsOpen, setIsOpen] = useState(false);

  const accordionData = {
    detail: "Details",
  };
  //State to set the Accordion Div is active or not.
  const [isActiveaccordion, setIsActiveAcccordian] = useState(false);
  //State to set the paying RUD amount by the user.
  const [RUDpay, setRUDpay] = useState(null);
  //State to set error message before proceed.
  const [errorForProceed, setErrorForProceed] = useState(false);
  //Equivalent value of RUD (provided a random digit, to be removed)
  const RUDGet = RUDpay * 1.2;
  const handleChangeForGettingValue = (e) => {
    const amount = e.target.value;
    setRUDpay(amount);
    setErrorForProceed(false);
  };
  const onClickMaxButton = () => {
    setRUDpay(maxRudInWallet);
  };
  // Function to activate the Proceed Button according to the state variable value
  const handleSubmitProceedButton = () => {
    if (!RUDpay) {
      setIsOpen(false);
      setErrorForProceed(true);
    } else {
      setErrorForProceed(false);
      setIsOpen(true);
    }
  };
  const handleSubmitBackButton = () => {
    setUserModeSelection(null);
    setCanProceed(false);
  };
  const { isMobile } = useContext(AppContext);
  const CardBody = () => (
    <div className={styles.contentSellDiv}>
      <div
        className={cx(styles.enterTheamountOfRUD, {
          [styles.enterTheamountOfRUDMob]: isMobile,
        })}
      >
        {RUD_ENUM.enterTheamountOfRUD}
      </div>
      <div
        className={cx(styles.rudpayDiv, {
          [styles.rudpayDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.rudpayh3Div, {
            [styles.rudpayh3DivMob]: isMobile,
          })}
        >
          <h3
            className={cx(styles.rudpayh3, {
              [styles.rudpayh3Mob]: isMobile,
            })}
          >
            You Sell
          </h3>

          <ButtonRade
            outline
            onClick={onClickMaxButton}
            customStyling={cx(styles.maxButton, {
              [styles.maxButtonMob]: isMobile,
            })}
          >
            Max
          </ButtonRade>
        </div>

        <div
          className={cx(styles.inputFieldDivOne, {
            [styles.inputFieldDivOneMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.inputFieldDiv, {
              [styles.inputFieldDivMob]: isMobile,
            })}
          >
            $
            <input
              type="number"
              name="rudpay"
              value={RUDpay}
              onChange={handleChangeForGettingValue}
              onKeyDown={(e) => {
                if (e.key === "-") {
                  e.preventDefault();
                }
              }}
              placeholder="0.00"
              min="0.00"
              step="0.01"
              max="1.00"
              presicion={2}
              autoComplete="off"
              autoFocus
              className={cx(styles.rudpay, {
                [styles.rudpayMob]: isMobile,
              })}
            ></input>
          </div>
          <div
            className={cx(styles.spanRUDGet, {
              [styles.spanRUDGetMob]: isMobile,
            })}
          >
            RUD
          </div>
        </div>
      </div>

      <div
        className={cx(styles.rudgetDiv, {
          [styles.rudgetDivMob]: isMobile,
        })}
      >
        <h3
          className={cx(styles.rudpayh3, {
            [styles.rudpayh3Mob]: isMobile,
          })}
        >
          You Get
        </h3>

        <div
          className={cx(styles.inputgetDivOneMain, {
            [styles.inputgetDivOneMainMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.inputgetDivOne, {
              [styles.inputgetDivMob]: isMobile,
            })}
          >
            $
            <div
              className={cx(styles.rudpay, {
                [styles.rudpayMob]: isMobile,
              })}
            />
            {RUDGet.toFixed(2)}
          </div>
          <div
            className={cx(styles.spanRUDGet, {
              [styles.spanRUDGetMob]: isMobile,
            })}
          >
            USD
          </div>
        </div>
      </div>
      {/*Error Message */}
      {errorForProceed && (
        <ShakeError
          errorMessage={RUD_ENUM.proceedError_SellFlow}
          customStyling={cx(styles.alertSpan, {
            [styles.alertSpanMob]: isMobile,
          })}
        />
      )}
      {/*Accodion Div */}
      {!!RUDpay && (
        <div
          className={cx(styles.accordionItem, {
            [styles.accordionItemMob]: isMobile,
          })}
        >
          <div
            className={styles.accordionMain}
            onClick={() => setIsActiveAcccordian(!isActiveaccordion)}
          >
            <div className={styles.accordionTitleDiv}>
              <div
                className={cx(styles.accordionTitle, {
                  [styles.accordionTitleMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.accordionTitle, {
                    [styles.accordionTitleMob]: isMobile,
                  })}
                >
                  {RUDpay} is all you pay, fees included{" "}
                </div>
              </div>

              <div className={styles.detailsSpan}>
                {" "}
                <div
                  className={cx(styles.accordionTitle, {
                    [styles.accordionTitleMob]: isMobile,
                  })}
                  style={{ color: isActiveaccordion ? "#02aab0" : "" }}
                >
                  {accordionData.detail}
                  {isActiveaccordion ? <DetailsUp /> : <DetailsIcon />}
                </div>
              </div>
            </div>
          </div>
          {
            //Expand the accordion div if the state "isActiveaccordion" is active
          }
          {isActiveaccordion && (
            <div
              className={cx(styles.accordionContent, {
                [styles.accordionContentMob]: isMobile,
              })}
            >
              <div>
                <div
                  className={cx(styles.accordionContentLineOne, {
                    [styles.accordionContentLineOneMob]: isMobile,
                  })}
                >
                  <div>
                    {" "}
                    ${RUDGet.toFixed(2)} USD @ {RUDpay} RUD
                  </div>

                  <div>{RUDpay} </div>
                </div>

                <div
                  className={cx(styles.accordionDivOne, {
                    [styles.accordionDivOneMob]: isMobile,
                  })}
                >
                  <div> Ramp fee (depends on the payment method)</div>
                  <div className={styles.feeSection}>as low as $2.01</div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={cx(styles.proceedButtonDiv, {
          [styles.proceedButtonDivMob]: isMobile,
        })}
      >
        {/* Custom styled back button */}
        <ButtonRade
          outline
          customStyling={cx(styles.backButton, {
            [styles.backButtonMob]: isMobile,
          })}
          onClick={handleSubmitBackButton}
        >
          {RUD_ENUM.backButton}
        </ButtonRade>
        {/* Custom styled button for proceed */}
        <ButtonRade
          outline
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          onClick={() => {
            handleSubmitProceedButton();
          }}
        >
          {RUD_ENUM.proceed_button}
          {/* <ProceedIcon /> */}
        </ButtonRade>
      </div>
    </div>
  );

  return (
    <>
      {/* calling as a function to avoid flickering issue */}
      {CardBody()}
      <div>
        <Modal
          isOpen={modalIsOpen}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          ariaHideApp={false}
        >
          <PopUp setIsOpen={setIsOpen} />
          {/*pass this value as props to the Modal Component named PopUp */}
        </Modal>
      </div>
    </>
  );
};
export default ProceedButton;
