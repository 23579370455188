import cx from "classnames";
import { ethers } from "ethers";
import React from "react";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../context";
import { RUD_ENUM } from "../../../enums/rudEnum";
import RaritiUsdContract from "../../../../src/artifacts/Contracts/stablecoin/RaritiUSD.sol/RaritiUSD.json";
import ERC20PermitSignature from "../../../../src/components/PermitSignature/ERC20PermitSignature.js";
import stableCoinMinterContract from "../../../artifacts/Contracts/stablecoin/RaritiStableCoinMinter.sol/RaritiStableCoinMinter.json";
import { UserContext } from "../../../context";
import ConfirmSignerTransactionIcon from "../../../assets/ConfirmSignerTransactionIcon.svg";
import styles from "./index.module.scss";
const GetSignerFromUser = (props) => {
  const {
    setSignatureParameters,
    setModalPageNumber,
    setIsError,
    setErrorMessage,
  } = props;
  // Importing account from userContext
  const { account } = useContext(UserContext);
  //   Defining the provider of the contract
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   Defining the signer of the contract
  const signer = provider.getSigner();
  //   Fetching the stable coin minter address
  const stableCoinMinterAddress = stableCoinMinterContract.address;

  // const [isRejected, setIsRejected] = useState(false);
  let amountIn = ethers.utils.parseUnits(props.value.toString(), "ether");
  useEffect(() => {
    const fetchSignatureParam = async () => {
      try {
        //   Creating the contract instance
        const raritiUSDDeployed = new ethers.Contract(
          RaritiUsdContract.address,
          RaritiUsdContract.abi,
          signer
        );
        const { r, s, v, transactionDeadline, isRejected } =
          await ERC20PermitSignature(
            account,
            stableCoinMinterAddress,
            amountIn,
            raritiUSDDeployed,
            provider
          );
        setSignatureParameters([transactionDeadline, v, r, s]);
        console.log("isRejected", isRejected);
        if (isRejected) {
          setModalPageNumber(5);
        } else {
          setModalPageNumber(1);
        }
      } catch (e) {
        setIsError(true);
        setErrorMessage(e.message);
      }
    };
    fetchSignatureParam();
  }, []);

  const { isMobile } = useContext(AppContext);
  // If there is value in signature parameter variable
  return (
    <div
      className={cx(styles.getSignerMainContainer, {
        [styles.getSignerMainContainerWeb]: !isMobile,
        [styles.getSignerMainContainerMob]: isMobile,
      })}
    >
      <div>
        <img
          src={ConfirmSignerTransactionIcon}
          alt="Fetching signature"
          className={cx(styles.confirmSignerTransactionIcon, {
            [styles.confirmSignerTransactionIconWeb]: !isMobile,
            [styles.confirmSignerTransactionIconMob]: isMobile,
          })}
        />
      </div>
      <div
        className={cx(styles.confirmTheSignatureRequestText, {
          [styles.confirmTheSignatureRequestTextWeb]: !isMobile,
          [styles.confirmTheSignatureRequestTextMob]: isMobile,
        })}
      >
        {RUD_ENUM.ConfirmSignatureRequest}
      </div>
    </div>
  );
};
export default GetSignerFromUser;
