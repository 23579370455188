import * as React from "react";
const SettingsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#A5A2B8"
      d="M15.456 13.625H8.824C7.29 13.625 6 14.903 6 16.5s1.29 2.875 2.824 2.875h6.57c1.597 0 2.825-1.278 2.825-2.875s-1.228-2.875-2.763-2.875ZM6.982 16.5c0-1.022.86-1.917 1.904-1.917s1.903.83 1.903 1.917c0 1.086-.86 1.917-1.903 1.917-1.105 0-1.904-.895-1.904-1.917Zm8.474 1.917h-4.482c.43-.511.736-1.15.736-1.917 0-.767-.245-1.406-.737-1.917h4.483c1.044 0 1.903.83 1.903 1.917 0 1.086-.86 1.917-1.903 1.917ZM8.824 10.75h6.57c1.597 0 2.825-1.278 2.825-2.875S16.99 5 15.456 5H8.824C7.29 5 6 6.278 6 7.875s1.29 2.875 2.824 2.875Zm8.474-2.875c0 1.022-.86 1.917-1.904 1.917a1.897 1.897 0 0 1-1.903-1.917c0-1.086.86-1.917 1.903-1.917 1.106 0 1.904.895 1.904 1.917ZM8.824 5.958h4.483c-.43.511-.737 1.15-.737 1.917 0 .767.245 1.406.737 1.917H8.824a1.897 1.897 0 0 1-1.903-1.917c0-1.086.86-1.917 1.903-1.917Z"
    />
    <rect
      width={23}
      height={23}
      x={1}
      y={1}
      stroke="#A5A2B8"
      strokeWidth={2}
      rx={3}
    />
  </svg>
);
export default SettingsIcon;
