import Tooltip from "react-bootstrap/Tooltip";
import Skeleton from "react-loading-skeleton";
import React, { useState, useEffect } from "react";
import { HOME_ENUM, TOOL_TIP } from "../../enums/homeEnum";
import styles from "../../pages/home/home.module.scss";
import QuestionImage from "../../assets/Question Icon.svg";
import cx from "classnames";
import { AppContext, UserContext } from "../../context";
import { useContext } from "react";
import GasTracker from "../../components/GasTracker";
import { useNavigate } from "react-router-dom";
import HomescreenReturnUserRudBuy from "../RUD/ReturnUserRUDBuy/ReturnUserRudBuy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "../../components/ReactLoading/reactLoading.css";
import useRudInWallet from "../../Hooks/useRudBalance/useRudBalance";
import useBorrowedUserDetails from "../../Hooks/useBorrowedUserDetails/useBorrowedUserDetails";
import useRudBalanceInContract from "../../Hooks/useContractRUDBalance/useContractRUDBalance";
import useStakedUserDetails from "../../Hooks/useStakedUserDetails/useStakedUserDetails";
import useETHPrice from "../../Hooks/usePrice/usePrice";
import getNetworkDetails from "../../components/NetworkDetails/NetworkDetails";

const MAX_DECIMAL_PLACE = 2;
let currAddress = null;
/*global BigInt */
const Home = (interval = null) => {
  const { isMobile } = useContext(AppContext);
  const [priceFeedAddress, setPriceFeedAddress] = useState(null);
  //timer to check each 10sec on which metamask network is connected
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { priceFeedAddress } = getNetworkDetails(chainID);
        setPriceFeedAddress(priceFeedAddress);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };
    if (priceFeedAddress !== currAddress) {
      clearInterval(interval);
      currAddress = priceFeedAddress;
    }

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
      currAddress = null;
    };
  }, [priceFeedAddress]);
  const { ethPrice, loading } = useETHPrice(priceFeedAddress);
  // Fetch account from useContext
  const { account } = useContext(UserContext);

  // Fetch totalRadeInPool,rudTotalSupply and rudBalance from useRudInWallet hook
  const { totalRadeInPool, rudTotalSupply, rudBalance } =
    useRudInWallet(account);
  // Fetch totalRUDBorrowed, contractBorrowBalance and totalNumOfNests from useBorrowedUserDetails hook
  const { totalRUDBorrowed, contractBorrowBalance, totalNumOfNests } =
    useBorrowedUserDetails(account);
  // Fetch contractRudBalance from useRudBalanceInContract hook
  const { contractRudBalance } = useRudBalanceInContract(account);
  // Convert the totalRadeInPool value into two decimal value
  const radeInPool = Number(totalRadeInPool).toFixed(MAX_DECIMAL_PLACE);
  // Convert the rudTotalSupply value into two decimal value
  const RUDTotalSupply = Number(rudTotalSupply).toFixed(MAX_DECIMAL_PLACE);
  // Find the totalCollatRatio
  const totalCollatRatio =
    totalRUDBorrowed / (contractBorrowBalance * ethPrice);
  // Convert the contractRudBalance value into two decimal value
  const TVL = Number(contractRudBalance) + contractBorrowBalance * ethPrice;
  const rudInWallet = Number(rudBalance);
  let navigate = useNavigate();
  // Methods for tooltip
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle1}>
      {TOOL_TIP.totalValueLocked}
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle2}>
      {TOOL_TIP.nests}
    </Tooltip>
  );
  const renderTooltip3 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle3}>
      {TOOL_TIP.RUDSupply}
    </Tooltip>
  );
  const renderTooltip4 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle4}>
      {TOOL_TIP.RUDInPool}
    </Tooltip>
  );

  const renderTooltip5 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle5}>
      {TOOL_TIP.totalCollatRatio}
    </Tooltip>
  );
  return (
    <>
      <div
        className={cx(styles.homescreenDiv, {
          [styles.homescreenDivMob]: isMobile,
        })}
      >
        {rudInWallet !== 0 && (
          <>
            <div className={styles.portfolioDiv}>
              <HomescreenReturnUserRudBuy />
            </div>
          </>
        )}
        {!rudInWallet && (
          <p
            className={cx(styles.subHeading, {
              [styles.subheadMob]: isMobile,
            })}
          >
            <span
              className={cx(styles.title, {
                [styles.titleMob]: isMobile,
              })}
            >
              {HOME_ENUM.en.title}
            </span>
            <span>{HOME_ENUM.en.subheading}</span>
          </p>
        )}
        <div
          className={cx(styles.buttonDiv, {
            [styles.buttonDivMob]: isMobile,
          })}
        >
          {!rudInWallet && (
            <button
              onClick={() => navigate("/borrow")}
              className={cx(styles.borrow, {
                [styles.borrowMob]: isMobile,
              })}
            >
              <div
                className={cx(styles.borrowButtonContent, {
                  [styles.borrowButtonContentMob]: isMobile,
                })}
              >
                <span className={styles.firstContent}>Borrow at</span>
                <span>0% interest</span>
              </div>
            </button>
          )}
          {!rudInWallet && (
            <button
              onClick={() => navigate("/stake")}
              className={cx(styles.providor, {
                [styles.providorMob]: isMobile,
              })}
            >
              <div
                className={cx(styles.providerButtonContent, {
                  [styles.providerButtonContentMob]: isMobile,
                })}
              >
                <span className={styles.firstContent}>Be a Liquidity</span>
                <span>Provider and Earn</span>
              </div>
            </button>
          )}
          {!isMobile && rudInWallet === 0 && (
            <button
              onClick={() => navigate("/get_rud")}
              className={styles.getRUD}
            >
              <div
                className={cx(styles.getRudButtonContent, {
                  [styles.getRudButtonContentMob]: isMobile,
                })}
              >
                <span className={styles.firstContent}>Buy RUD</span>
                <span>to get started</span>
              </div>
            </button>
          )}
        </div>
        <div
          className={cx(styles.protocolDiv, {
            [styles.protocolMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.protocolFirstRow, {
              [styles.firstrowMob]: isMobile,
            })}
          >
            {HOME_ENUM.en.table_heading}
          </div>
          <div
            className={cx(styles.protocolSecondRow, {
              [styles.secondrowMob]: isMobile,
            })}
          >
            <div className={styles.totalLockedValue}>
              <div
                className={cx(styles.column1, {
                  [styles.column1Mob]: isMobile,
                })}
              >
                {HOME_ENUM.en.TLV_key}
                <OverlayTrigger placement="right" overlay={renderTooltip1}>
                  <img
                    className={styles.quesIcon}
                    src={QuestionImage}
                    alt="Question_icon"
                  />
                </OverlayTrigger>
              </div>

              {contractRudBalance === null || loading ? (
                <Skeleton
                  style={{
                    backgroundColor: "rgb(25, 39, 59)",
                    width: "83%",
                    marginRight: "118px",
                    paddingTop: "12px",
                    height: "12px",
                  }}
                />
              ) : (
                <div
                  className={cx(styles.column2, {
                    [styles.column2Mob]: isMobile,
                  })}
                >
                  ${TVL.toFixed(MAX_DECIMAL_PLACE)}
                </div>
              )}
            </div>
            <div className={styles.nests}>
              <div
                className={cx(styles.column1, {
                  [styles.column1Mob]: isMobile,
                })}
              >
                {HOME_ENUM.en.nests_key}
                <OverlayTrigger placement="right" overlay={renderTooltip2}>
                  <img
                    className={styles.quesIcon}
                    src={QuestionImage}
                    alt="Question_icon"
                  />
                </OverlayTrigger>
              </div>

              {totalNumOfNests !== null ? (
                <div
                  className={cx(styles.column2, {
                    [styles.column2Mob]: isMobile,
                  })}
                >
                  {BigInt(totalNumOfNests._hex).toString()}
                </div>
              ) : (
                <Skeleton
                  style={{
                    backgroundColor: "rgb(25, 39, 59)",
                    width: "83%",
                    marginRight: "118px",
                    paddingTop: "12px",
                    height: "12px",
                  }}
                />
              )}
            </div>
            <div className={styles.rudSupply}>
              <div
                className={cx(styles.column1, {
                  [styles.column1Mob]: isMobile,
                })}
              >
                ${HOME_ENUM.en.supply_key}
                <OverlayTrigger placement="right" overlay={renderTooltip3}>
                  <img
                    className={styles.quesIcon}
                    src={QuestionImage}
                    alt="Question_icon"
                  />
                </OverlayTrigger>
              </div>

              {rudTotalSupply !== null ? (
                <div
                  className={cx(styles.column2, {
                    [styles.column2Mob]: isMobile,
                  })}
                >
                  {RUDTotalSupply}
                </div>
              ) : (
                <Skeleton
                  style={{
                    backgroundColor: "rgb(25, 39, 59)",
                    width: "83%",
                    marginRight: "118px",
                    paddingTop: "12px",
                    height: "12px",
                  }}
                />
              )}
            </div>
            <div className={styles.rudRadePool}>
              <div
                className={cx(styles.column1, {
                  [styles.column1Mob]: isMobile,
                })}
              >
                ${HOME_ENUM.en.pool_key}
                <OverlayTrigger placement="right" overlay={renderTooltip4}>
                  <img
                    className={styles.quesIcon}
                    src={QuestionImage}
                    alt="Question_icon"
                  />
                </OverlayTrigger>
              </div>
              {totalRadeInPool !== null ? (
                <div
                  className={cx(styles.column2, {
                    [styles.column2Mob]: isMobile,
                  })}
                >
                  {radeInPool}
                </div>
              ) : (
                <Skeleton
                  style={{
                    backgroundColor: "rgb(25, 39, 59)",
                    width: "83%",
                    marginRight: "118px",
                    paddingTop: "12px",
                    height: "12px",
                  }}
                />
              )}
            </div>

            <div className={styles.collateralRatio}>
              <div
                className={cx(styles.column1, {
                  [styles.column1Mob]: isMobile,
                })}
              >
                {HOME_ENUM.en.collateral_ratio}
                <OverlayTrigger placement="right" overlay={renderTooltip5}>
                  <img
                    className={styles.quesIcon}
                    src={QuestionImage}
                    alt="Question_icon"
                  />
                </OverlayTrigger>
              </div>
              {contractBorrowBalance === null || loading ? (
                <div
                  className={cx(styles.column2, {
                    [styles.column2Mob]: isMobile,
                  })}
                >
                  <Skeleton
                    style={{
                      backgroundColor: "rgb(25, 39, 59)",
                      width: "104%",
                      marginRight: "94px",
                      paddingTop: "12px",
                      height: "12px",
                    }}
                  />
                </div>
              ) : (
                <div
                  className={cx(styles.column2, {
                    [styles.column2Mob]: isMobile,
                  })}
                >
                  {contractBorrowBalance === "0.0"
                    ? "-"
                    : totalCollatRatio.toFixed(MAX_DECIMAL_PLACE)}
                </div>
              )}
            </div>
          </div>
        </div>

        {isMobile && <GasTracker />}
      </div>
    </>
  );
};

export default Home;
