import React, { useState, useEffect } from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import ToggleIcon from "../../../assets/ToggleIcon.svg";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../ToggleUserEnteringOption/index.module.scss";
import styled from "styled-components";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
import useWalletEthBalance from "../../../Hooks/useWalletEthBalance/useWalletEthBalance";
const Button = styled.button``;
const MAX_DECIMAL_PLACE = 2;
const FLOORING_FACTOR = 100; // for fixing the issue of underflow numeric fault
// Parent function to handle the toggling between ETH and RUD
const ToggleUserEnteringOption = (
  {
    userSelectionMode,
    WETH_UNISWAP_PRICE,
    setUserSelectionMode,
    setEth,
    setRud,
    handleEthPay,
    handleRudGet,
    eth,
    rud,
  },
  interval = null
) => {
  const { isMobile } = useContext(AppContext);
  const { account } = useContext(UserContext);
  const { ethBalanceInWallet } = useWalletEthBalance(account);
  const currentEthPrice = Number(WETH_UNISWAP_PRICE * FLOORING_FACTOR).toFixed(
    MAX_DECIMAL_PLACE
  );
  const [tokenName, setTokenName] = useState(null);

  useEffect(() => {
    const connectedNetwork = async () => {
      const chainID = await window.ethereum.request({ method: "net_version" });
      const { tokenName } = getNetworkDetails(chainID);
      setTokenName(tokenName);
    };
    connectedNetwork();
    // interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [tokenName]);

  //Function to handle the toggling
  const handleOnClickToggleButton = () => {
    if (userSelectionMode === RUD_ENUM.ethEnterMode) {
      setUserSelectionMode(RUD_ENUM.rudEnterMode);
    } else {
      setUserSelectionMode(RUD_ENUM.ethEnterMode);
    }
  };
  const handleOnClickMaxButton = () => {
    setEth(ethBalanceInWallet);
    setRud(ethBalanceInWallet * WETH_UNISWAP_PRICE);
  };
  return (
    <div>
      <div
        className={cx(styles.payETH, {
          [styles.payETHMob]: isMobile,
        })}
      >
        <div className={styles.payCryptoDiv}>
          <div
            className={cx(styles.payingEth, {
              [styles.payingEthMob]: isMobile,
            })}
          >
            {userSelectionMode === RUD_ENUM.ethEnterMode
              ? RUD_ENUM.pay
              : RUD_ENUM.get}
          </div>
          {/* Custom styled Max Button */}
          {userSelectionMode === RUD_ENUM.ethEnterMode && (
            <div className={styles.maxButtonDiv}>
              <Button
                onClick={handleOnClickMaxButton}
                className={cx(styles.maxButton, {
                  [styles.maxButtonMob]: isMobile,
                })}
              >
                Max
              </Button>
            </div>
          )}
        </div>
        {/* Input Value entering Section */}
        <div className={styles.userEnteringField}>
          {userSelectionMode === RUD_ENUM.rudEnterMode && (
            <div
              className={cx(styles.dollarSymbol, {
                [styles.dollarSymbolMob]: isMobile,
              })}
            >
              $
            </div>
          )}
          <input
            type="number"
            placeholder={
              userSelectionMode === RUD_ENUM.ethEnterMode ? "0.0" : "0.0"
            }
            onChange={
              userSelectionMode === RUD_ENUM.ethEnterMode
                ? (e) => handleEthPay(e)
                : (e) => handleRudGet(e)
            }
            value={userSelectionMode === RUD_ENUM.ethEnterMode ? eth : rud}
            onKeyDown={(e) => {
              if (e.key === "-") {
                e.preventDefault();
              }
            }}
            autoFocus
            className={cx(styles.inputAmount, {
              [styles.inputAmountMob]: isMobile,
            })}
          ></input>
          <div
            className={cx(styles.payingCrypto, {
              [styles.payingCryptoMob]: isMobile,
            })}
          >
            {userSelectionMode === RUD_ENUM.ethEnterMode
              ? tokenName
              : RUD_ENUM.RUD_Heading}
          </div>
        </div>
      </div>
      {/* Toggle Button */}
      <div
        className={cx(styles.toggleButtonDiv, {
          [styles.toggleButtonDivMob]: isMobile,
        })}
      >
        <Button
          className={cx(styles.toggleButton, {
            [styles.toggleButtonMob]: isMobile,
          })}
          onClick={handleOnClickToggleButton}
        >
          <img
            src={ToggleIcon}
            alt="Toggle_Icon"
            className={styles.toggleIcon}
          />
        </Button>
      </div>
      {/* Display Section */}
      <div
        className={cx(styles.getRUD, {
          [styles.getRUDMob]: isMobile,
        })}
      >
        <div className={styles.recievingRUD}>
          {userSelectionMode === RUD_ENUM.ethEnterMode
            ? RUD_ENUM.get
            : RUD_ENUM.pay}
        </div>
        <div
          className={cx(styles.recievingRUDVal, {
            [styles.recievingRUDValMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.equivalentRud, {
              [styles.equivalentRudMob]: isMobile,
            })}
          >
            {userSelectionMode === RUD_ENUM.ethEnterMode
              ? `$ ${rud * FLOORING_FACTOR || "0.0"}`
              : eth / FLOORING_FACTOR || "0.0"}
          </div>
          <div
            className={cx(styles.cryptoRud, {
              [styles.cryptoRudMob]: isMobile,
            })}
          >
            {userSelectionMode === RUD_ENUM.ethEnterMode
              ? RUD_ENUM.RUD_Heading
              : tokenName}
          </div>
        </div>
      </div>
      {!!eth && !!rud && (
        <div className={styles.accordionDiv}>
          <div className={styles.ethPriceInfoDiv}>
            <div
              className={cx(styles.price, {
                [styles.priceMob]: isMobile,
              })}
            >
              {RUD_ENUM.price}
            </div>
            <div className={styles.rudPerEthPriceVal}>${currentEthPrice}</div>
            <div className={styles.rudPerEthPriceKey}>{RUD_ENUM.rudPerEth}</div>
          </div>
          <div className={styles.note}>
            {userSelectionMode === RUD_ENUM.ethEnterMode
              ? RUD_ENUM.ethEnterModeNote
              : `Click proceed to know the final cost of purchasing $ ${rud} RUD, including fees.`}
          </div>
        </div>
      )}
    </div>
  );
};
export default ToggleUserEnteringOption;
