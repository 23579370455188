import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import BorrowingContract from "../../../src/artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
/*global BigInt */
const provider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum)
  : null;
const RudBorrowContract = new ethers.Contract(
  BorrowingContract.address, //RudBorrowing deployed contract address
  BorrowingContract.abi, //RudBorrowing deployed contract abi
  provider
);
let currAcc = null;
const useBorrowedUserDetails = (account, interval = null) => {
  //state to set the connected user's borrowed details
  const [userBorrowedDetails, setUserBorrowedDetails] = useState(null);
  // state to set the total borrowed RUD
  const [borrowedRUDInTotal, setBorrowedRUDInTotal] = useState(null);
  // state to set the contract balance of borrow contract
  const [borrowContractBalance, setBorrowContractBalance] = useState(null);
  // state that store total nests
  const [nest, setNests] = useState(null);
  //function to get the RUD balance using
  useEffect(() => {
    try {
      const getBorrowedUserDetails = async () => {
        if (RudBorrowContract && account) {
          const borrowedUserDetails =
            await RudBorrowContract.getBorrowedUserDetails(account);
          const totalBorrowedRUD = await RudBorrowContract.getLoanBook();
          const totalNests = await RudBorrowContract.getTotalNumNests();
          const totalNestsCount = totalNests[0];
          provider.getBalance(BorrowingContract.address).then((balance) => {
            setBorrowContractBalance(ethers.utils.formatEther(balance));
          });
          setUserBorrowedDetails(borrowedUserDetails);
          setNests(totalNestsCount);
          setBorrowedRUDInTotal(totalBorrowedRUD);
        } else {
          setUserBorrowedDetails(undefined);
          setBorrowedRUDInTotal(undefined);
          setNests(null);
          setBorrowContractBalance(undefined);
        }
      };
      if (account !== currAcc) {
        clearInterval(interval);
        currAcc = account;
      }
      interval = setInterval(getBorrowedUserDetails, 1000);
      return () => {
        clearInterval(interval);
        interval = null;
        currAcc = null;
      };
    } catch (e) {
      console.log("error", e);
    }
  }, [account]);
  if (
    userBorrowedDetails &&
    borrowedRUDInTotal &&
    borrowContractBalance &&
    nest
  ) {
    return {
      userDetails: userBorrowedDetails,
      borrowedRud: ethers.utils.formatEther(
        userBorrowedDetails.totalLoanAmount
      ),
      totalRUDBorrowed: ethers.utils.formatEther(
        BigInt(borrowedRUDInTotal._hex)
      ),
      contractBorrowBalance: borrowContractBalance,
      totalNumOfNests: nest,
      loading: false,
    };
  } else {
    return {
      userDetails: null,
      borrowedRud: null,
      totalRUDBorrowed: null,
      contractBorrowBalance: null,
      totalNumOfNests: null,
      loading: true,
    };
  }
};
export default useBorrowedUserDetails;
