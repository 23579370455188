import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import { LIQUIDATE_ENUM } from "../../../enums/liquidateEnum";
import ButtonRade from "../../../components/RadeButtons";
import { ethers } from "ethers";
import { formatUserLiquidation } from "./borrowedUserPaymentDefaultDetails";
import useBorrowedUserDetails from "../../../Hooks/useBorrowedUserDetails/useBorrowedUserDetails";
import Loading from "../Loading";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { TOOL_TIP } from "../../../enums/homeEnum";
const MAX_DECIMAL_PLACE = 2;
const ETHER_CONVERSION = 10 ** 18;
const PaymentDefault = (interval = null) => {
  const { isMobile } = useContext(AppContext);
  const { account } = useContext(UserContext);
  const { userDetails, loading } = useBorrowedUserDetails(account);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const [formattedData, setFormattedData] = useState(null);
  const [tokenName, setTokenName] = useState(null);
  const renderTooltipLiquidateButton = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.liquidateMyNestTooltip}
    </Tooltip>
  );
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { tokenName } = getNetworkDetails(chainID);

        setTokenName(tokenName);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [tokenName]);

  useEffect(async () => {
    if (userDetails) {
      const latestBlock = await provider.getBlock("latest");

      setFormattedData(
        formatUserLiquidation(userDetails, latestBlock.timestamp)
      );
    } else {
      console.log("loading");
    }
  }, [userDetails]);

  const CardBody = () => (
    <>
      <div className={styles.returnUserBorrowTable}>
        {formattedData.some((item) => item.defaultValue !== 0) ? (
          <table className={styles.tableHeading}>
            <thead>
              <tr
                className={cx(styles.borrowTableHeading, {
                  [styles.borrowTableHeadingWeb]: !isMobile,
                  [styles.borrowTableHeadingMob]: isMobile,
                })}
              >
                <th>
                  <div>{LIQUIDATE_ENUM.borrowAndColl}</div>
                  <div className={styles.unit}>RUD/{tokenName}</div>
                </th>
                <th>
                  <div>{LIQUIDATE_ENUM.loanBalance}</div>
                  <div className={styles.unit}>RUD</div>
                </th>
                <th>{LIQUIDATE_ENUM.collateralRatio}</th>
                <th>
                  <div>{LIQUIDATE_ENUM.defaultValue}</div>
                  <div className={styles.unit}>RUD</div>
                </th>
                <th>
                  <div>{LIQUIDATE_ENUM.liquidateCollateral}</div>
                  <div className={styles.unit}>{tokenName}</div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {formattedData.map((item, index) =>
                item.defaultValue !== 0 ? (
                  <tr className={styles.borrowTableNest1}>
                    <td>
                      {Number(item.borrowedRUD).toFixed(MAX_DECIMAL_PLACE)}/
                      {Number(item.collateralAmount / ETHER_CONVERSION).toFixed(
                        MAX_DECIMAL_PLACE
                      )}
                    </td>
                    <td>
                      {Number(item.loanBalance).toFixed(MAX_DECIMAL_PLACE)}
                    </td>
                    <td className={styles.safeCollRatio}>{item.collRatio}%</td>
                    <td>
                      {Number(item.defaultValue).toFixed(MAX_DECIMAL_PLACE)}
                    </td>
                    <td>
                      {Number(item.liquidateColl / ETHER_CONVERSION).toFixed(
                        MAX_DECIMAL_PLACE
                      )}
                    </td>
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltipLiquidateButton}
                    >
                      <td>
                        <ButtonRade
                          outline
                          customStyling={styles.liquidateButton}
                        >
                          {LIQUIDATE_ENUM.liquidateButton}
                        </ButtonRade>
                      </td>
                    </OverlayTrigger>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        ) : (
          <div className={styles.noPaymentsDefaualtsContent}>
            You have no payments defaulted.
          </div>
        )}
      </div>
    </>
  );
  if (loading || !formattedData || !tokenName) {
    return <Loading />;
  }
  return (
    <div
      className={cx(styles.returnUsermainDiv, {
        [styles.returnUsermainDivWeb]: !isMobile,
        [styles.returnUsermainDivMob]: isMobile,
      })}
    >
      <CardBody />
    </div>
  );
};

export default PaymentDefault;
