import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { LIQUIDATE_ENUM } from "../../../enums/liquidateEnum";
import ButtonRade from "../../../components/RadeButtons";
import RudBorrowContract from "../../../artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
import { ethers } from "ethers";
import { formatBorrowerLiquidatingNests } from "./LiquidatingNestsFormat";
import Loading from "../Loading";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
import Modal from "react-modal";
import ConfirmLiquidateModal from "../ConfirmLiquidate/ConfirmLiquidateModal";
import WaitingConfirmation from "../../borrow/WaitingConfirmationModal/WaitingConfirmationModal";
import LiquidateSuccessful from "../LiquidateSuccessful/LiquidateSuccessful";
const MAX_DECIMAL_PLACE = 2;
const ETHER_CONVERSION = 10 ** 18;
const BorrowersPaymentDefault = (interval = null) => {
  const { isMobile } = useContext(AppContext);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [formattedData, setFormattedData] = useState(null);
  const [tokenName, setTokenName] = useState(null);
  const [address, setAddress] = useState("");
  const [nestId, setNestId] = useState("");
  const [userName, setUserName] = useState("");
  const [defaultvalue, setDefaultValue] = useState("");
  const [ethToliquidate, setEthToLiquidate] = useState("");
  // State to set the Modal open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalPageNumber, setModalPageNumber] = useState(null);
  const [gasCost, setGasCost] = useState(null);
  const [liquidatorBonus, setLiquidatorBonus] = useState(null);
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { tokenName } = getNetworkDetails(chainID);

        setTokenName(tokenName);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [tokenName]);
  //borrow Rud Contract
  const borrowContract = new ethers.Contract(
    RudBorrowContract.address,
    RudBorrowContract.abi,
    signer
  );
  useEffect(async () => {
    if (borrowContract) {
      const latestBlock = await provider.getBlock("latest");
      const liquidatingNests = await borrowContract.getLiquidatingNests();

      setFormattedData(
        formatBorrowerLiquidatingNests(liquidatingNests, latestBlock.timestamp)
      );
    } else {
      console.log("loading");
    }
  }, [formattedData]);

  const handleSubmitLiquidate = () => {
    setModalIsOpen(true);
    setModalPageNumber(0);
  };
  const CardBody = () => (
    <>
      <div className={styles.returnUserBorrowTable}>
        {formattedData.some((item) => item.defaultValue !== 0) ? (
          <table className={styles.tableHeading}>
            <thead>
              <tr
                className={cx(styles.borrowTableHeading, {
                  [styles.borrowTableHeadingWeb]: !isMobile,
                  [styles.borrowTableHeadingMob]: isMobile,
                })}
              >
                <th>{LIQUIDATE_ENUM.userName}</th>
                <th>
                  <div>{LIQUIDATE_ENUM.borrowAndColl}</div>
                  <div className={styles.unit}>RUD/{tokenName}</div>
                </th>
                <th>
                  <div>{LIQUIDATE_ENUM.loanBalance}</div>
                  <div className={styles.unit}>RUD</div>
                </th>
                <th>{LIQUIDATE_ENUM.collateralRatio}</th>
                <th>
                  <div>{LIQUIDATE_ENUM.defaultValue}</div>
                  <div className={styles.unit}>RUD</div>
                </th>
                <th>
                  <div>{LIQUIDATE_ENUM.liquidateCollateral}</div>
                  <div className={styles.unit}>{tokenName}</div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {formattedData.map((item) =>
                item.defaultValue !== 0 ? (
                  <tr className={styles.borrowTableNest1}>
                    <td>{item.user}</td>
                    <td>
                      {Number(item.borrowedRUD).toFixed(MAX_DECIMAL_PLACE)}/
                      {Number(item.collateralAmount / ETHER_CONVERSION).toFixed(
                        MAX_DECIMAL_PLACE
                      )}
                    </td>
                    <td>
                      {Number(item.loanBalance).toFixed(MAX_DECIMAL_PLACE)}
                    </td>
                    <td className={styles.safeCollRatio}>{item.collRatio}%</td>
                    <td>
                      {Number(item.defaultValue).toFixed(MAX_DECIMAL_PLACE)}
                    </td>
                    <td>
                      {Number(item.liquidateColl / ETHER_CONVERSION).toFixed(
                        MAX_DECIMAL_PLACE
                      )}
                    </td>
                    <td>
                      <ButtonRade
                        outline
                        onClick={() =>
                          handleSubmitLiquidate(
                            setAddress(item.account),
                            setNestId(item.nestId),
                            setUserName(item.user),
                            setDefaultValue(item.defaultValue),
                            setEthToLiquidate(item.liquidateColl)
                          )
                        }
                        customStyling={styles.liquidateButton}
                      >
                        {LIQUIDATE_ENUM.liquidateButton}
                      </ButtonRade>
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        ) : (
          <div className={styles.noPaymentsDefaualtsContent}>
            {LIQUIDATE_ENUM.noPaymentDefaults}
          </div>
        )}
      </div>
    </>
  );
  if (!formattedData || !tokenName) {
    return <Loading />;
  }
  return (
    <div
      className={cx(styles.returnUsermainDiv, {
        [styles.returnUsermainDivWeb]: !isMobile,
        [styles.returnUsermainDivMob]: isMobile,
      })}
    >
      <CardBody />
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return (
                <ConfirmLiquidateModal
                  setModalIsOpen={setModalIsOpen}
                  address={address}
                  nestId={nestId}
                  defaultValue={defaultvalue}
                  ethToliquidate={ethToliquidate}
                  userName={userName}
                  setModalPageNumber={setModalPageNumber}
                  setGasCost={setGasCost}
                  setLiquidatorBonus={setLiquidatorBonus}
                />
              );
            case 1:
              return <WaitingConfirmation />;
            case 2:
              return (
                <LiquidateSuccessful
                  setModalIsOpen={setModalIsOpen}
                  userName={userName}
                  gasCost={gasCost}
                  liquidatorBonus={liquidatorBonus}
                />
              );
            default:
              return null;
          }
        })()}
      </Modal>
    </div>
  );
};

export default BorrowersPaymentDefault;
