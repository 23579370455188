import moment from "moment";
export const formatUserBorrow = (data) => {
  const formattedList = [];
  const length = data[2].length;
  console.log("data", data);
  for (let i = 0; i < length; i++) {
    const obj = {};
    obj.nest = i + 1;
    const ETHER_CONVERSION = 10 ** 18;
    const FIFTEEN_MINUTES_TERM = 900;
    const weeklyPayment = 604800;
    const monthlyPayment = 2630000;
    const twoMonthsPayment = 5260000;
    const quarterlypayment = 10520000;
    const ONE_HOUR_LOAN_TERM = 3600;
    const ONE_MONTH_LOAN_TERM = 2630000;
    const TWO_MONTHS_LOAN_TERM = 5260000;
    const SIX_MONTHS_LOAN_TERM = 15780000;
    const ONE_YEAR_LOAN_TERM = 31536000;
    obj.borrowedRUD = parseInt(data[2][i][1]._hex, 16) / ETHER_CONVERSION;
    obj.loanBalance = parseInt(data[2][i][2]._hex, 16) / ETHER_CONVERSION;
    const duration = parseInt(data[2][i][5]._hex, 16);
    const loanDuration = parseInt(data[2][i][4]._hex, 16);
    if (loanDuration === ONE_HOUR_LOAN_TERM) {
      obj.loanDuration = "One Hour";
    } else if (loanDuration === ONE_MONTH_LOAN_TERM) {
      obj.loanDuration = "One month";
    } else if (loanDuration === TWO_MONTHS_LOAN_TERM) {
      obj.loanDuration = "Two months";
    } else if (loanDuration === SIX_MONTHS_LOAN_TERM) {
      obj.loanDuration = "Six months";
    } else if (loanDuration === ONE_YEAR_LOAN_TERM) {
      obj.loanDuration = "One Year";
    }

    if (duration === FIFTEEN_MINUTES_TERM) {
      obj.paymentSchedule = "15 Minutes";
    } else if (duration === weeklyPayment) {
      obj.paymentSchedule = "Weekly";
    } else if (duration === monthlyPayment) {
      obj.paymentSchedule = "Monthly";
    } else if (duration === twoMonthsPayment) {
      obj.paymentSchedule = "Every 2 months";
    } else if (duration === quarterlypayment) {
      obj.paymentSchedule = "Quarterly";
    }
    obj.coll_Ratio = parseInt(data[2][i][10]._hex, 16);
    obj.nextPayment = moment
      .unix(
        parseInt(data[2][i][3]._hex, 16) +
          (parseInt(data[2][i][8]._hex, 16) + 1) *
            parseInt(data[2][i][5]._hex, 16)
      )
      .format("MM-DD-YY");
    obj.collateralAmount = parseInt(data[2][i][0]._hex, 16);
    obj.nextPaymentAmount = parseInt(data[2][i][7]._hex, 16);
    obj.remainingTerms =
      parseInt(data[2][i][6]._hex, 16) - parseInt(data[2][i][8]._hex, 16);
    formattedList.push(obj);
  }
  return formattedList;
};
