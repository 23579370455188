export const BORROW_ENUM = {
  borrowCardTitle: "Nest",
  borrowContent:
    "You haven’t borrowed any RUD yet. Borrow $RUD by opening a Nest where you can pledge your Ethereum as collateral.",
  borrowButton: "Open Nest",
  learnMoreContent: "Learn more about",
  nest: "Nest",
  openNest: "Open Nest",
  step1: "Step 1",
  select: "Let’s select the collateralization method for eligibility check",
  cryptoCollateralizeButton: "Crypto Collateralize",
  memberVouchButton: "MemberVouch",
  cancelButton: "Cancel",
  nextButton: "Next",
  alertContent:
    "You can pledge your Ethereum as collateral to avail interest free borrowing.",
  step2: "Step 2",
  selectInStep2:
    "Select the time period of borrowing(Loan Term) and the payment term",
  alertContentInStep2:
    "RADE borrowing fee is based on termed payments. Choose your flexibility and avail relaxed term payments.",
  errorMessage: "Select and option to continue!",
  step3: "Step 3",
  selectInStep3:
    "Enter the amount of ETH you want to pledge and calculate maximum Borrow amount.",
  depositButton: "Collateral",
  maxBorrowAmount: "Max Borrow Amount",
  borrowingAmount: "Borrowing Amount",
  confirmButton: "Confirm",
  backButton: "Back",
  liquidationReserve: "Liquidation Reserve",
  borrowingFee: "Borrowing fee",
  collateralizationRatio: "Collateralization Ratio",
  USDValue: "200 USD",
  borrowingFeeValue: "100 RUD",
  borrowingFeePercentage: " (0.4%)",
  maxBorrowConfirm: "Please confirm to execute the transaction",
  collateral: "Collateral",
  borrowAmount: "Borrow amount",
  cryptoCollateralize: "Crypto Collateralize",
  paymentTerm: "Payment Term",
  loanTerm: "Loan Term",
  returnBorrowCardTitle: "My Nests",
  openNewNest: "Open New Nest",
  borrowedRUD: "Borrowed",
  borrowedRUDInNestDetails: "Borrowed RUD",
  loanBalance: "LoanBalance",
  paymentSchedule: "Schedule",
  nextPayment: "Next term payment",
  pay: "Pay",
  loanDetails: "Loan Details",
  paymentDetails: "Payment Details",
  nextPaymentAmount: "Next payment amount",
  repayingAmount: "Repaying Amount",
  nextPaymentDue: "Next payment due",
  remainingNumberOfTerms: "Remaining number of terms ",
  alertContainerReturnUserBorrow:
    "Borrow more RUD by opening a new RADE Nest where you can deposit your crypto as collateral for borrowing.",
  showNests: "Show Nests",
  selectPaymentTerm:
    " Select either pay term amount or full loan balance (to close loan) ",
  fullLaonBalance: "Full loan balance",
  KYCPaymentContent: "Are you sure you want to proceed to make the payment?",
  selectPaymentTermError:
    "Select either pay term amount or full loan balance to continue",
  collateralizationRatioHeading: "Coll.Ratio",
  due: "Due In",
  errorForMonthlyPayment: "  Select loan term to continue!",
  errorForConfirm: "Enter an amount to continue!",
  errorForMaxBorrow: "Enter an amount less than or equal to Max Borrow amount!",
  borrowSuccessful: "Borrowing Successful",
  kycCompletion: "KYC must be completed to withdraw or sell your RUD.",
  transactionRejectedError: "You rejected the transaction!",
  errorForPaymentTerm: "Select payment term to continue!",
  errorForLoanAndPaymentTerm: "Select loan term and payment term to continue!",
  nestPayment: "Payment for Nest",
  loanDuration: "Loan Duration",
};
