import React, { useState } from "react";
import cx from "classnames";
import { AppContext } from "../../../../context";
import styles from "./index.module.scss";
import { useContext } from "react";
import { SETTINGS_ENUM } from "../../../../enums/settingsEnum";
import ButtonRade from "../../../../components/RadeButtons";
import arrowIcon from "../../../../assets/nextIcon.svg";
import FileUpload from "../../../../components/FileUpload/FileUpload";
import KYCConfirmPopUp from "../KYCConfirmPopUp/KYCConfirmPopUp";
import KycUpload from "../../../../components/KYCUpload/KYCUpload";
import LoadingSpinner from "./LodingSpinner";
const UploadIDProof = ({
  updateFirebaseUserProfile,
  setKYCPageNumber,
  firstName,
  lastName,
  email,
  phoneNumber,
  DOB,
  country,
  region,
  addressLineOne,
  addressLineTwo,
  city,
  zipCode,
  setErrorForID,
  idBackSide,
  setIdBackSide,
  setErrorForPancard,
  idFrontSide,
  setIdFrontSide,
  setErrorForAadhar,
  reSubmitKyc,
  setResubmitKyc,
  isFileUploaded,
  setIsFileUploaded,
  idBackSideFileType,
  setIdBackSideFileType,
  idFrontSideFileType,
  setIdFrontSideFileType,
  selectedFileName,
  setSelectedFileName,
  pancardHolding,
  setPancardHolding,
  aadharHolding,
  setAadharHolding,
  setErrorForAadharHold,
  setErrorForPancardHold,
  KycStatus,
  setKycStatus,
  userName,
  aadhaarHolding,
  selectedCountryCode,
  bankName,
  setBankName,
  bankAccountType,
  setBankAccountType,
  ABARoutingNumber,
  setABARoutingNumber,
  accountNumber,
  setAccountNumber,
}) => {
  const [isLoadingPancard, setIsLoadingPancard] = useState(false); //loading states for ID uploading
  const [isLoadingAadhar, setIsLoadingAadhar] = useState(false); //loading states for ID uploading
  const [isLoadingPancardHold, setIsLoadingPancardHold] = useState(false); //loading states for ID uploading
  const [isLoadingAadharHold, setIsLoadingAadharHold] = useState(false); //loading states for ID uploading
  const [errorMessage, setErrorMessage] = useState(null);
  const { isMobile } = useContext(AppContext);
  const handleSubmitCancelButton = () => {
    setKYCPageNumber(0);
  };
  const handleSubmitNextButton = () => {
    if (!idBackSide || !idFrontSide || !pancardHolding || !aadhaarHolding) {
      setErrorMessage(true);
    } else {
      setKYCPageNumber(3);
      // setModalPageNumber(0);
    }
  };
  const CardBodyUploadIDProof = () => (
    <>
      <div
        className={cx(styles.personalInformationMainDiv, {
          [styles.personalInformationMainDivWeb]: !isMobile,
          [styles.personalInformationMainDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.butttonContent, {
            [styles.butttonContentMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.contentText, {
              [styles.contentTextMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton1, {
                [styles.stepsButton1Mob]: isMobile,
              })}
            >
              1
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton2, {
                [styles.stepsButton2Mob]: isMobile,
              })}
            >
              2
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton3, {
                [styles.stepsButton3Mob]: isMobile,
              })}
            >
              3
            </div>
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton4, {
              [styles.stepsButton4Mob]: isMobile,
            })}
          >
            4
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton5, {
              [styles.stepsButton5Mob]: isMobile,
            })}
          >
            5
          </div>
        </div>
        <div
          className={cx(styles.step1Text, {
            [styles.step1TextWeb]: !isMobile,
            [styles.step1TextMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.step4}
        </div>
        <div
          className={cx(styles.personalInfo, {
            [styles.personalInfoWeb]: !isMobile,
            [styles.personalInfoMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.indentificationDocument}
        </div>
        <div
          className={cx(styles.uploadYourPassport, {
            [styles.uploadYourPassportWeb]: !isMobile,
            [styles.uploadYourPassportMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.uploadYourPassport}
        </div>
        <div
          className={cx(styles.passportUploadContainer, {
            [styles.passportUploadContainerWeb]: !isMobile,
            [styles.passportUploadContainerMob]: isMobile,
          })}
        >
          <div className={styles.IDUploadMainContainer}>
            {" "}
            <p className={styles.passportFirstPage}>
              Government ID/ Passport *
            </p>
            <div>
              <div className={styles.loading}>
                <p className={styles.passportFirstPage}>Front Side</p>
                {isLoadingAadhar && (
                  <div className={styles.spinnerLoading}>
                    Loading
                    <div className={styles.spinner}>
                      <LoadingSpinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.passportFrontUpload}>
              <FileUpload
                id="idFrontSide"
                setErrorForID={setErrorForID}
                idBackSide={idBackSide}
                setIdBackSide={setIdBackSide}
                setErrorForPancard={setErrorForPancard}
                idFrontSide={idFrontSide}
                setIdFrontSide={setIdFrontSide}
                reSubmitKyc={reSubmitKyc}
                setResubmitKyc={setResubmitKyc}
                setIsFileUploaded={setIsFileUploaded}
                idBackSideFileType={idBackSideFileType}
                setIdBackSideFileType={setIdBackSideFileType}
                idFrontSideFileType={idFrontSideFileType}
                setIdFrontSideFileType={setIdFrontSideFileType}
                setIsLoadingPancard={setIsLoadingPancard}
                setIsLoadingAadhar={setIsLoadingAadhar}
                selectedFileName={selectedFileName}
                setSelectedFileName={setSelectedFileName}
              />
            </div>
          </div>

          <div className={styles.IDUploadMainContainer}>
            <p className={styles.passportFirstPage}>
              Government ID/ Passport *
            </p>
            <div>
              <div className={styles.loading}>
                <p className={styles.passportFirstPage}>Back Side</p>
                {isLoadingPancard && (
                  <div className={styles.spinnerLoading}>
                    Loading
                    <div className={styles.spinner}>
                      <LoadingSpinner />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.passportBackSideUpload}>
              <FileUpload
                id="idBackSide"
                setErrorForID={setErrorForID}
                idFrontSide={idFrontSide}
                setIdBackSide={setIdBackSide}
                setIdFrontSide={setIdFrontSide}
                setErrorForAadhar={setErrorForAadhar}
                idBackSide={idBackSide}
                reSubmitKyc={reSubmitKyc}
                setResubmitKyc={setResubmitKyc}
                setIsFileUploaded={setIsFileUploaded}
                idBackSideFileType={idBackSideFileType}
                setIdBackSideFileType={setIdBackSideFileType}
                idFrontSideFileType={idFrontSideFileType}
                setIdFrontSideFileType={setIdFrontSideFileType}
                setIsLoadingAadhar={setIsLoadingAadhar}
                setIsLoadingPancard={setIsLoadingPancard}
                setSelectedFileName={setSelectedFileName}
              />
            </div>
          </div>
        </div>
        {(idBackSide || idFrontSide) && (
          <p className={styles.clickThumbnail}>
            To change file click thumbnail
          </p>
        )}
        <p
          className={cx(styles.supportedFile, {
            [styles.supportedFileWeb]: !isMobile,
            [styles.supportedFileMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.supportedFile}
        </p>
        <div className={styles.selfieIDContainer}>
          {" "}
          <div className={styles.KYCIDUploadMainContainer}>
            {" "}
            <p className={styles.holdingPassportText}>
              Selfie with Government ID/ Passport *
            </p>
            <div>
              <div className={styles.loading}>
                <p className={styles.passportFirstPage}>Front Side</p>
                {isLoadingPancardHold && (
                  <div className={styles.spinnerLoading}>
                    Loading
                    <div className={styles.spinner}>
                      <LoadingSpinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.passportHoldingUpload}>
              <KycUpload
                id="holdPassport"
                pancardHolding={pancardHolding}
                setPancardHolding={setPancardHolding}
                aadharHolding={aadharHolding}
                setAadharHolding={setAadharHolding}
                setErrorForPancardHold={setErrorForPancardHold}
                setErrorForAadharHold={setErrorForAadharHold}
                reSubmitKyc={reSubmitKyc}
                setResubmitKyc={setResubmitKyc}
                setIsFileUploaded={setIsFileUploaded}
                setIsLoadingAadharHold={setIsLoadingAadharHold}
                setIsLoadingPancardHold={setIsLoadingPancardHold}
              />
            </div>
          </div>
          <div className={styles.KYCIDUploadMainContainer}>
            <p className={styles.holdingPassportText}>
              Selfie with Government ID/ Passport *
            </p>
            <div>
              <div className={styles.loading}>
                <p className={styles.passportFirstPage}>Back Side</p>
                {isLoadingAadharHold && (
                  <div className={styles.spinnerLoading}>
                    Loading
                    <div className={styles.spinner}>
                      <LoadingSpinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.passportHoldingUpload}>
              <KycUpload
                id="holdPassportBackside"
                pancardHolding={pancardHolding}
                setPancardHolding={setPancardHolding}
                aadharHolding={aadharHolding}
                setAadharHolding={setAadharHolding}
                setErrorForPancardHold={setErrorForPancardHold}
                reSubmitKyc={reSubmitKyc}
                setResubmitKyc={setResubmitKyc}
                setIsFileUploaded={setIsFileUploaded}
                setIsLoadingAadharHold={setIsLoadingAadharHold}
                setIsLoadingPancardHold={setIsLoadingPancardHold}
                setErrorForAadharHold={setErrorForAadharHold}
              />
            </div>
          </div>
        </div>
        {(pancardHolding || aadhaarHolding) && (
          <p className={styles.clickThumbnail}>
            To change file click thumbnail
          </p>
        )}
        <p
          className={cx(styles.supportedFile, {
            [styles.supportedFileWeb]: !isMobile,
            [styles.supportedFileMob]: isMobile,
          })}
        >
          File Types : JPEG, JPG and PNG <br />
          (PDF not supported)
        </p>
        {errorMessage && (
          <div className={styles.errorMessage}>Please upload the ID</div>
        )}
        <div
          className={cx(styles.bottomButtonDiv, {
            [styles.bottomButtonDivWeb]: !isMobile,
            [styles.bottomButtonDivMob]: isMobile,
          })}
        >
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonWeb]: !isMobile,
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={handleSubmitCancelButton}
          >
            {SETTINGS_ENUM.backButton}
          </ButtonRade>
          <ButtonRade
            outline
            customStyling={cx(styles.nextButton, {
              [styles.nextButtonWeb]: !isMobile,
              [styles.nextButtonMob]: isMobile,
            })}
            onClick={handleSubmitNextButton}
          >
            {SETTINGS_ENUM.nextButton}
          </ButtonRade>
        </div>
      </div>
    </>
  );
  return <>{CardBodyUploadIDProof()}</>;
};

export default UploadIDProof;
