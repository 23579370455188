import React, { useState } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext } from "react";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import { SETTINGS_ENUM } from "../../enums/settingsEnum";
import Security from "./Security/Security";
import KYC from "./KYC/KYC";
import { useNavigate } from "react-router-dom";

const Settings = ({
  shouldRenderSettings,
  titleButtonVal,
  setTitleButtonVal,
}) => {
  console.log("shouldRenderSettings", shouldRenderSettings);
  // const [titleButtonVal, setTitleButtonVal] = useState("KYC");
  const { isMobile } = useContext(AppContext);
  let navigate = useNavigate();

  // Update the variable according to the value of the button
  const handleTitleButtonClick = (e) => {
    console.log("e", e.target.value);
    navigate("/SETTINGS/" + e.target.value);
    // setTitleButtonVal(e.target.value);
    console.log("titleButtonVal", titleButtonVal);
  };
  const CardTitle = () => (
    <div
      className={cx(styles.settingsButtons, {
        [styles.settingsButtonsMob]: isMobile,
      })}
    >
      {/* Custom styled button for buy with value PROFILE */}

      {/* Custom styled button for buy with value SECURITY */}

      {/*  Custom styled button for buy with value KYC*/}
      <ButtonRade
        outline
        customStyling={cx(styles.KYCButton, {
          [styles.KYCButtonWeb]: !isMobile,
          [styles.KYCButtonMob]: isMobile,
        })}
        value="KYC"
        onClick={handleTitleButtonClick}
        style={{
          background: titleButtonVal === "KYC" ? "#02AAB0" : "transparent",
        }}
      >
        {SETTINGS_ENUM.KYCButton}
      </ButtonRade>
      {/* Hide for MVP */}
      <ButtonRade
        outline
        customStyling={cx(styles.securityButton, {
          [styles.securityButtonWeb]: !isMobile,
          [styles.securityButtonMob]: isMobile,
        })}
        value="SECURITY"
        onClick={handleTitleButtonClick}
        style={{
          background: titleButtonVal === "SECURITY" ? "#02AAB0" : "transparent",
        }}
      >
        {SETTINGS_ENUM.securityButton}
      </ButtonRade>
    </div>
  );

  return (
    <div
      className={cx(styles.buttonTableDiv, {
        [styles.buttonTableDivMob]: isMobile,
      })}
    >
      {/* Render only the relevant part of the card-body according to the state variable value */}
      <CardTitle />

      {/*To do: Hide for MVP*/}
      {titleButtonVal === "SECURITY" && (
        <>
          {" "}
          <Security shouldRenderSettings={shouldRenderSettings} />
        </>
      )}
      {titleButtonVal === "KYC" && (
        <>
          <KYC />
        </>
      )}
    </div>
  );
};

export default Settings;
