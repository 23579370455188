import * as React from "react";
import styles from "./index.module.scss";

const SwapIcon = (props) => (
  <svg
    {...props}
    width={23}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.swapIcon}
  >
    <path
      d="M10.2 19.6c0 1.1-.9 2-2 2-.9 0-1.7-.6-1.9-1.4 0 0-.1 0-.1-.1-1.2-.5-2.3-1.2-3.2-2.2-.9-.9-1.7-2-2.2-3.2C.3 13.6 0 12.3 0 11c0-1.3.3-2.6.8-3.8.5-1.3 1.2-2.4 2.1-3.3.9-.9 2-1.7 3.2-2.2C7.1 1.4 8 1.1 9 1c.5 0 1 .4 1 1 0 .5-.4 1-1 1-.7.1-1.4.3-2.1.6-.9.4-1.8 1-2.6 1.7S3 6.9 2.6 7.9c-.4 1-.6 2-.6 3s.2 2.1.6 3c.4 1 1 1.8 1.7 2.6.7.7 1.5 1.2 2.4 1.7.4-.4.9-.6 1.5-.6 1.1 0 2 .9 2 2ZM22.098 10.6c0 1.3-.2 2.6-.8 3.8-.5 1.2-1.2 2.3-2.2 3.2-.9.9-2 1.7-3.2 2.2-.9.4-1.8.6-2.8.7-.5.1-1-.4-1-.9s.5-1 1-1.1c.7-.1 1.4-.3 2.1-.5 1-.4 1.9-1 2.6-1.7.7-.7 1.3-1.6 1.7-2.6.4-1 .6-2 .6-3.1 0-1-.2-2.1-.6-3-.4-1-1-1.9-1.7-2.6-.7-.7-1.5-1.2-2.4-1.7-.4.4-.9.7-1.5.7-1.1 0-2-.9-2-2s.9-2 2-2c.9 0 1.6.6 1.9 1.4h.1c1.2.5 2.3 1.2 3.2 2.2.9.9 1.7 2 2.2 3.2.6 1.2.8 2.5.8 3.8Z"
      fill="#fff"
    />
  </svg>
);

export default SwapIcon;
