import React from "react";
import { useState } from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "./RUDSell.module.scss";
import ButtonRade from "../../../components/RadeButtons";
import ProceedSell from "../ProceedSell/ProceedSell";
import RadioButton from "../../../components/RadioButtons/RadioButton";
import EthSell from "../EthSell/EthSell";
import GetRUD from "../Rud";
import { RudContext } from "../RUDContext";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { TOOL_TIP } from "../../../enums/homeEnum";
import { useNavigate } from "react-router-dom";
const RUDSell = ({
  displayKYCAlert,
  setDisplayKYCAlert,
  setWaitingConfirmation,
  setConfirmTransaction,
  setErrorMessage,
  setIsError,
  setModalOpen,
  setShowNextpage,
  setShouldRenderRUD,
}) => {
  //State to set the Currency option choosed by the user.
  const [userModeSelection, setUserModeSelection] = useState(null);
  //State to proceed if user has selected any of the currency options
  const [canProceed, setCanProceed] = useState(false);
  // const { setShowNextpage } = useContext(RudContext);
  let navigate = useNavigate();

  const [returnRUDLandingPage, setReturnRUDLandingPage] = useState(false);
  const { isMobile } = useContext(AppContext);
  //State to set the error message before proceed if user hasn't selected any options.
  const [error, setError] = useState(false);
  // Currency Options
  const currencyList = [{ value: "ETH", label: "Ethereum" }];
  const renderTooltip2 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyleForMemberVouch}>
      {TOOL_TIP.comingSoon}
    </Tooltip>
  );
  const handleSubmitProceedButton = (e) => {
    if (!userModeSelection) {
      setError(true);
    } else {
      setError(false);
      setCanProceed(true);
    }
  };
  const handleSubmitCancelButton = () => {
    setReturnRUDLandingPage(true);
    setShowNextpage(false);
    setShouldRenderRUD(false);

    navigate("/get_rud");
  };
  //Get the value choosen by the user (either Fiat or ETH)
  const handleChangeGettingRadioInput = (e) => {
    setError(false);
    setUserModeSelection(e.target.value);
  };

  const CardBody = () => (
    <div className={styles.buttonContentDiv}>
      <div
        className={cx(styles.currencyChoose, {
          [styles.currencyChooseMob]: isMobile,
        })}
      >
        {RUD_ENUM.Selling_RUD}
      </div>
      <div
        className={cx(styles.currencyOptions, {
          [styles.currencyOptionsMob]: isMobile,
        })}
      >
        <RadioButton
          name="currency"
          value={userModeSelection}
          options={currencyList}
          onChangeFunc={handleChangeGettingRadioInput}
          error={error}
        />
        <OverlayTrigger placement="top" overlay={renderTooltip2}>
          <div
            className={cx(styles.dummyMemberVouchRadioButton, {
              [styles.dummyMemberVouchRadioButtonMob]: isMobile,
            })}
          >
            <span className={styles.circle}></span>

            <div
              className={cx(styles.memberVouchButton, {
                [styles.memberVouchButtonMob]: isMobile,
              })}
            >
              Fiat-USD
            </div>
          </div>
        </OverlayTrigger>
      </div>
      <div
        className={cx(styles.proceedButtonDiv, {
          [styles.proceedButtonDivMob]: isMobile,
        })}
      >
        {/* Custom styled cancel button */}
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleSubmitCancelButton}
        >
          {RUD_ENUM.cancelButton}
        </ButtonRade>
        {/* Custom styled button for proceed */}
        <ButtonRade
          outline
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          onClick={() => {
            handleSubmitProceedButton();
          }}
        >
          {RUD_ENUM.proceed_button}
          {/* <ProceedIcon /> */}
        </ButtonRade>
      </div>
    </div>
  );

  //Update to next pages in the sell flow based on the currency that user selects
  switch (userModeSelection) {
    case "Fiat":
      if (canProceed) {
        return (
          <ProceedSell
            setUserModeSelection={setUserModeSelection}
            setCanProceed={setCanProceed}
          />
        );
      } else {
        {
          /* calling as a function to avoid flickering issue */
        }
        return CardBody();
      }
    case "ETH":
      if (canProceed) {
        return (
          <EthSell
            displayKYCAlert={displayKYCAlert}
            setDisplayKYCAlert={setDisplayKYCAlert}
            setWaitingConfirmation={setWaitingConfirmation}
            setConfirmTransaction={setConfirmTransaction}
            setErrorMessage={setErrorMessage}
            setIsError={setIsError}
            // props that passes the state for opening the modal
            setModalOpen={setModalOpen}
            setUserModeSelection={setUserModeSelection}
            setCanProceed={setCanProceed}
          />
        );
      } else {
        // calling as a function to avoid flickering issue
        return CardBody();
      }
    default:
      return returnRUDLandingPage ? <GetRUD /> : CardBody();

    // calling as a function to avoid flickering issue
  }
};
export default RUDSell;
