import React, { useEffect, useState } from "react";
import { useEthers, useLookupAddress } from "@usedapp/core";
import Web3 from "web3";
import { Routes, Route, Outlet } from "react-router-dom";
import { Button } from "./components";
import { AppContext, UserContext } from "./context";
import Layout from "./components/Layout";
import { APP_ENUM } from "./enums/appEnum";
import styled from "styled-components";
import { db } from "./firebase";
import { serverTimestamp } from "firebase/firestore";
import { collection, doc, getDoc, setDoc, FieldPath } from "firebase/firestore";
import { useMediaQuery } from "react-responsive";
import {
  handleAuthenticate,
  handleLoginIn,
  handleSignUp,
  setAuthToken,
} from "./api";
import { ROUTES_MAIN } from "./pages/routes";
import ConnectMetamaskFlow from "../src/components/ConnectMetamaskFlow/ConnectMetamaskFlow";
import Background from "./components/BackgroundComponent/Background";
import WalletConnect from "./WalletConnect/WalletConnect";
import RudBuy from "./pages/RUD/RudBuy/RudBuy";
import RUDSell from "./pages/RUD/RUDSell/RUDSell";
import RudDummySell from "./pages/RUD/RudDummySell";
import RudDummyBuy from "./pages/RUD/RudDummyBuy";
import RudDummy from "./pages/RUD/RudDummy";
import Settings from "./pages/settings";
import Liquidate from "./pages/liquidate/liquidate";
import GetRUD from "./pages/RUD/Rud";
import Borrow from "./pages/borrow";
import Stake from "./pages/stake";
import Home from "./pages/home";
import MainRoutes from "./pages/mainroutes";
const ConnectMetaMask = styled(Button)`
  position: fixed;
  background: transparent;
  border-radius: 20px;
  right: 24px;
  top: 43px;
  padding: 7px 24px 7px 24px;
  border: 1px solid #02aab0;
  font-family: "Jura";
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #f9fafa;
  z-index: 2;
  font-size: 18px;
  color: #f9fafa;
  @media only screen and (max-width: 992px) {
    font-size: 14px;
    padding: 7px 12px 7px 12px;
    right: 12px;
    top: 33px;
  }
`;

let web3;
function App() {
  // Read more about useDapp on https://usedapp.io/
  //address for fetching current eth price from pricefeed.
  const ens = useLookupAddress();
  const { account, error } = useEthers();
  const [userData, setUserData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const [KycStatus, setKycStatus] = useState(false);

  /* 
MODAL
To use a modal in your component, call is setIsModalOpen(true), then call setModalChild to render the component, that you wanted to 
be shown
*/
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalChild, setModalChild] = useState(null);
  //state to store the acrrRewards
  const [acrrRewards, setAccrRewards] = useState(null);
  const [isMetamaskConnected, setIsMetamaskConnected] = useState(null);
  const [connectedNetworkName, setConnectedNetworkName] = useState(null);
  const isConnected = !!(ens || account);

  const handleSignMessage = async ({ publicAddress, nonce }) => {
    try {
      const signature = await web3.eth.personal.sign(
        `I am signing my one-time nonce: ${nonce}`,
        publicAddress,
        ""
      );
      return { publicAddress, signature };
    } catch (err) {
      throw new Error(
        "You need to sign the message to be able to log in.",
        err
      );
    }
  };
  const validateLogin = async (isData = false) => {
    const auth = JSON.parse(localStorage.getItem(APP_ENUM.AUTH));
    if (auth && auth.accessToken) {
      const isValid = await setAuthToken(
        auth.accessToken,
        account || ens,
        isData
      );
      if (isValid && isValid.data.login) {
        setIsLoggedIn(true);
        if (isData) {
          setUserData(isValid.data.userData);
        }
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    if (account) {
      const updateFirebaseUserProfile = async () => {
        try {
          // Create a document reference in the 'userData' collection with the lowercase user's MetaMask account address
          const userDataCollection = collection(db, "userData");
          const userDataDocRef = doc(userDataCollection, account);

          // Check if the document already exists
          const docSnapshot = await getDoc(userDataDocRef);

          if (!docSnapshot.exists()) {
            // Document doesn't exist, so you can set the data
            await setDoc(userDataDocRef, {
              firstName: "",
              lastName: "",
              userName: "",
              email: "",
              DOB: "",
              selectedCountryCode: "",
              phone: "",
              country: "",
              region: "",
              addressLine1: "",
              addressLine2: "",
              city: "",
              pincode: "",
              idFrontSide: "",
              idBackSide: "",
              holdingPassport: "",
              holdingPassportBackside: "",
              kycStatus: "Not Initialized",
              bankName: "",
              bankAccountType: "",
              ABARoutingNumber: "",
              accountNumber: "",
              userJoinedOn: serverTimestamp(),
            });
            console.log("userData Document created and initialized!");
          } else {
            console.log("userData Document already exists, no update needed.");
          }
        } catch (error) {
          // Handle any errors
          console.error("Error updating userData document: ", error);
        }
      };
      updateFirebaseUserProfile();
    }
  }, account);
  useEffect(() => {
    const handleLoginWithUser = async (publicAddress) => {
      const isAlreadyLoggedIn = await validateLogin(true);
      if (isAlreadyLoggedIn) {
        return true;
      }
      if (!web3) {
        try {
          // Request account access if needed
          await window.ethereum.enable();
          // We don't know window.web3 version, so we use our own instance of Web3
          // with the injected provider given by MetaMask
          web3 = new Web3(window.ethereum);
        } catch (error) {
          window.alert("You need to allow MetaMask.");
          return;
        }
        try {
          let response = await handleLoginIn(publicAddress);
          if (response.data.userData === null) {
            // no user with the address proceeding to signup
            response = await handleSignUp(publicAddress);
          }
          const signData = await handleSignMessage({
            publicAddress,
            nonce: response.data.userData.nonce,
          });
          const auth = await handleAuthenticate(
            publicAddress,
            signData.signature
          );
          localStorage.setItem(APP_ENUM.AUTH, JSON.stringify(auth.data));
          await validateLogin();
          setUserData(response.data.userData);
        } catch (e) {
          console.log("e", e);
        }
      }
    };

    if ((account || ens) && !userData) {
      //call user data api
      //comment the line below until the dev api is hosted
      handleLoginWithUser(account || ens);
    }
  }, [account, ens, userData]);

  const setUserProfile = (data) => {
    setUserData({ ...userData, username: data.username, email: data.email });
  };

  useEffect(() => {
    if (!isModalOpen) {
      setModalChild(null);
    }
  }, [isModalOpen]);

  return (
    <AppContext.Provider
      value={{
        isMobile,
        isModalOpen,
        setIsModalOpen,
        modalChild,
        setModalChild,
        KycStatus,
        setKycStatus,
      }}
    >
      <UserContext.Provider
        value={{
          ens,
          account,
          error,
          isMetamaskConnected,
          setIsMetamaskConnected,
          isConnected,
          connectedNetworkName,
          setConnectedNetworkName,
          acrrRewards,
          setAccrRewards,
        }}
      >
        <Layout>
          {/* If user clicks on connect metamask button */}

          {!account && !ens ? (
            <>
              {!isMetamaskConnected && (
                <>
                  {/* <ConnectMetaMask onClick={() => handleClickMetamaskButton()}>
                    {APP_ENUM.en.text}
                  </ConnectMetaMask> */}
                  <WalletConnect />
                </>
              )}
            </>
          ) : isLoggedIn || true ? (
            !connectedNetworkName ? (
              <>
                <Background />
              </>
            ) : (
              <MainRoutes />
            )
          ) : (
            /* 
            todo: Better ui to be done here
            */
            <>Loading..</>
          )}
        </Layout>
        {!isConnected && isMetamaskConnected && (
          <ConnectMetamaskFlow isMetamaskConnected={setIsMetamaskConnected} />
        )}
      </UserContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
