import styles from "./index.module.scss";
import styled from "styled-components";
import { ROUTES_MAIN } from "../../../pages/routes";
import FooterMain from "../../FooterMain";
import { NavLink, Outlet } from "react-router-dom";
import cx from "classnames";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/index";
import DocButton from "./DocButton";
const NavDiv = styled.div`
  display: flex;
  justify-content: center;
`;
const NavBarDesktop = () => {
  const { isMobile } = useContext(AppContext);
  const nonActive = styles.nav;
  const activeNav = styles.activeNav;
  const [activeMainRoute, setActiveMainRoute] = useState(null);

  const toggleSubRoutes = (route) => {
    setActiveMainRoute(activeMainRoute === route ? null : route);
  };
  return (
    <NavDiv
      className={cx(styles.navBar, {
        [styles.navBarM]: isMobile,
      })}
    >
      <div className={styles.routes}>
        {ROUTES_MAIN.map((item) => (
          <NavLink
            end="/"
            to={item.route}
            key={item.route}
            style={{ textDecoration: "none" }}
          >
            {({ isActive }) => (
              <span className={isActive ? activeNav : nonActive}>
                <div
                  className={isActive ? styles.activeNavDiv : styles.navDiv}
                  onClick={() => toggleSubRoutes(item.route)}
                >
                  {item.image}
                  <div className={styles.nextIconContainer}>
                    {" "}
                    <span className={styles.spanInNav}>{item.label}</span>
                    <span
                      className={cx(styles.nextIcon, {
                        [styles.rotated]: activeMainRoute === item.route,
                      })}
                    >
                      {item.nextIcon}
                    </span>
                  </div>
                </div>

                {/* Display subroutes under the corresponding main route */}
                {activeMainRoute === item.route && item.subRoutes && (
                  <div
                    className={cx(
                      styles.subRoutesContainer,
                      {
                        [styles.subRoutesContainerOpen]:
                          activeMainRoute === item.route,
                      },
                      {
                        [styles.subRoutesContainerClosed]:
                          activeMainRoute !== item.route,
                      }
                    )}
                  >
                    <div className={styles.lineContainer}></div>
                    {item.subRoutes.map((subItem) => (
                      <NavLink
                        key={`${item.route}/${subItem.route}`}
                        to={`${item.route}/${subItem.route}`}
                        style={{ textDecoration: "none" }}
                      >
                        {({ isActive: subIsActive }) => (
                          <span
                            className={
                              subIsActive ? styles.activeNav : styles.nonActive
                            }
                          >
                            <div className={styles.subNavDiv}>
                              {subItem.image}
                              <div
                                className={styles.straightLineContainer}
                              ></div>
                              <span
                                className={
                                  subIsActive
                                    ? styles.activeSpanInNav
                                    : styles.spanInNav
                                }
                              >
                                {subItem.label}
                              </span>
                            </div>
                          </span>
                        )}
                      </NavLink>
                    ))}
                  </div>
                )}
              </span>
            )}
          </NavLink>
        ))}
      </div>
      <DocButton />
      <FooterMain />
    </NavDiv>
  );
};

export default NavBarDesktop;
