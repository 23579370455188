import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import React from "react";
import styles from "../Desktop/KycSec.module.scss";
import kycButton from "../../../assets/KYC pending icon.svg";
import kycPendingIcon from "../../../assets/KYCInProcessIcon.svg";
import kycVerifiedIcon from "../../../assets/kycVerified.svg";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { Button } from "../..";
import { TOOL_TIP } from "../../../enums/homeEnum";
const KycSec = () => {
  // Function for tooltip
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.kycNotCompleted}
    </Tooltip>
  );
  // variable for navigating to the settings section if users clicks on the KYC button
  let navigate = useNavigate();
  const handleOnclickKYC = () => {
    navigate("/settings");
  };
  const { isMobile, KycStatus } = useContext(AppContext);
  console.log("KycStatus", KycStatus);
  return (
    <>
      <div
        className={cx(styles.kycButtonDiv, {
          [styles.kycButtonDivMob]: isMobile,
        })}
      >
        <Button className={styles.kycButton} onClick={handleOnclickKYC}>
          KYC
          {(KycStatus === "Rejected" ||
            !KycStatus ||
            KycStatus === "Not initialized") && (
            <OverlayTrigger placement="left" overlay={renderTooltip1}>
              <img
                className={styles.kycPending}
                src={kycButton}
                alt="Kyc_Image"
              />
            </OverlayTrigger>
          )}
          {KycStatus === "Pending" && (
            <img
              className={styles.kycPending}
              src={kycPendingIcon}
              alt="Kyc_Image"
            />
          )}
          {KycStatus === "Verified" && (
            <img
              className={styles.kycPending}
              src={kycVerifiedIcon}
              alt="Kyc_Image"
            />
          )}
        </Button>
      </div>
    </>
  );
};

export default KycSec;
