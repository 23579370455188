import styles from "./index.module.scss";
import React from "react";
import ButtonRade from "../../../../components/RadeButtons";
import { HOME_ENUM } from "../../../../enums/homeEnum";
import { useEthers } from "@usedapp/core";
import { APP_ENUM } from "../../../../enums/appEnum";
import { useContext } from "react";
import { UserContext } from "../../../../context";
const LogoutMetamask = (props) => {
  const { deactivate } = useEthers();
  const { setIsMetamaskConnected } = useContext(UserContext);
  //Function to close the Modal.
  function handleOnClickCancel() {
    props.setModalIsOpen(false);
  }
  const hanldeOnClickContinue = () => {
    deactivate();
    localStorage.removeItem(APP_ENUM.AUTH);
    setIsMetamaskConnected(false);
  };
  return (
    <div>
      <div className={styles.popUpContent}>{HOME_ENUM.en.logoutMessage}</div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={styles.cancelButton}
          onClick={handleOnClickCancel}
        >
          {HOME_ENUM.en.cancel}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={styles.continueButton}
          onClick={hanldeOnClickContinue}
        >
          Logout
        </ButtonRade>
      </div>
    </div>
  );
};

export default LogoutMetamask;
