import React, { useState } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import { useContext } from "react";
import styles from "./index.module.scss";
import { Button } from "../../components";
import { LIQUIDATE_ENUM } from "../../enums/liquidateEnum";
import Card from "../../components/Card";
import PaymentDefault from "./PaymentDefault/PaymentDefault";
import UserCollateralRatio from "./UserCollateralRatio/UserCollateralRatio";
import BorrowersPaymentDefault from "./LiquidatingNests/BorrowersPaymentDefaults";
import CollateralBasedLiquidatingNests from "./CollateralBasedNests/CollateralBasedNests";

const Liquidate = () => {
  const [myNestButtonVal, setMyNestButtonVal] = useState("PAYMENTS_MISSED");
  const [otherNestButtonVal, setOtherNestButtonVal] =
    useState("PAYMENTS_MISSED");
  const { isMobile } = useContext(AppContext);

  // Update the variable according to the value of the button
  const handleMyNestButtonClick = (e) => {
    setMyNestButtonVal(e.target.value);
  };

  // Update the variable according to the value of the button
  const handleOtherNestButtonClick = (e) => {
    setOtherNestButtonVal(e.target.value);
  };

  const MyNestCardTitle = () => (
    <div className={styles.liquidateCardTitleDiv}>
      <div className={styles.liquidateCardTitle}>{LIQUIDATE_ENUM.myNests}</div>
      <div
        className={cx(styles.buttonsDiv, {
          [styles.buttonsDivMob]: isMobile,
        })}
      >
        <Button
          value="PAYMENTS_MISSED"
          className={cx(styles.paymentMissedButton, {
            [styles.paymentMissedButtonWeb]: !isMobile,
            [styles.paymentMissedButtonMob]: isMobile,
          })}
          style={{
            backgroundColor:
              myNestButtonVal === "PAYMENTS_MISSED" ? "#1D293A" : "transparent",
          }}
          onClick={handleMyNestButtonClick}
        >
          {LIQUIDATE_ENUM.paymentMissedButton}
        </Button>
        <Button
          value="ASSET_DEVALUATION"
          className={cx(styles.assetDevaluationButton, {
            [styles.assetDevaluationButtonWeb]: !isMobile,
            [styles.assetDevaluationButtonMob]: isMobile,
          })}
          style={{
            backgroundColor:
              myNestButtonVal === "ASSET_DEVALUATION"
                ? "#1D293A"
                : "transparent",
          }}
          onClick={handleMyNestButtonClick}
        >
          {LIQUIDATE_ENUM.assetDevaluationButton}
        </Button>
      </div>
    </div>
  );

  const OtherNestCardTitle = () => (
    <div className={styles.liquidateCardTitleDiv}>
      <div className={styles.liquidateCardTitle}>
        {LIQUIDATE_ENUM.otherNests}
      </div>
      <div
        className={cx(styles.buttonsDiv, {
          [styles.buttonsDivMob]: isMobile,
        })}
      >
        <Button
          value="PAYMENTS_MISSED"
          className={cx(styles.paymentMissedButton, {
            [styles.paymentMissedButtonWeb]: !isMobile,
            [styles.paymentMissedButtonMob]: isMobile,
          })}
          style={{
            backgroundColor:
              otherNestButtonVal === "PAYMENTS_MISSED"
                ? "#1D293A"
                : "transparent",
          }}
          onClick={handleOtherNestButtonClick}
        >
          {LIQUIDATE_ENUM.paymentMissedButton}
        </Button>
        <Button
          value="ASSET_DEVALUATION"
          className={cx(styles.assetDevaluationButton, {
            [styles.assetDevaluationButtonWeb]: !isMobile,
            [styles.assetDevaluationButtonMob]: isMobile,
          })}
          style={{
            backgroundColor:
              otherNestButtonVal === "ASSET_DEVALUATION"
                ? "#1D293A"
                : "transparent",
          }}
          onClick={handleOtherNestButtonClick}
        >
          {LIQUIDATE_ENUM.assetDevaluationButton}
        </Button>
      </div>
    </div>
  );

  return (
    <div
      className={cx(styles.buttonTableDiv, {
        [styles.buttonTableDivMob]: isMobile,
      })}
    >
      <div className={styles.myNestTableDiv}>
        <Card titleComponent={<MyNestCardTitle />}>
          {/* Render only the relevant part of the card-body according to the state variable value */}
          {myNestButtonVal === "PAYMENTS_MISSED" && <PaymentDefault />}
          {myNestButtonVal === "ASSET_DEVALUATION" && <UserCollateralRatio />}
        </Card>
      </div>
      <div className={styles.myNestTableDiv}>
        <Card titleComponent={<OtherNestCardTitle />}>
          {/* Render only the relevant part of the card-body according to the state variable value */}
          {otherNestButtonVal === "PAYMENTS_MISSED" && (
            <BorrowersPaymentDefault />
          )}
          {otherNestButtonVal === "ASSET_DEVALUATION" && (
            <CollateralBasedLiquidatingNests />
          )}
        </Card>
      </div>
      {/* To be implemented later*/}
      {/* {titleButtonVal === "PAYMENTS_MISSED" && (
        <div
          className={cx(styles.notificationDiv, {
            [styles.notificationDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.alertImageAndMsg, {
              [styles.alertImageAndMsgMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.bellIcon, {
                [styles.bellIconMob]: isMobile,
              })}
            >
              <AnimatedIcon />
            </div>
            <div
              className={cx(styles.alertMsg, {
                [styles.alertMsgMob]: isMobile,
              })}
            >
              {LIQUIDATE_ENUM.paymentDefaultNotification}
            </div>
          </div>
        </div>
      )} */}
      {/* To be implemented later*/}
      {/* {titleButtonVal === "ASSET_DEVALUATION" && (
        <div
          className={cx(styles.notificationDiv, {
            [styles.notificationDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.alertImageAndMsg, {
              [styles.alertImageAndMsgMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.bellIcon, {
                [styles.bellIconMob]: isMobile,
              })}
            >
              <AnimatedIcon />
            </div>
            <div
              className={cx(styles.alertMsg, {
                [styles.alertMsgMob]: isMobile,
              })}
            >
              {LIQUIDATE_ENUM.assetDevaluationNotification}
              <div className={styles.healthPrediction}>
                <div className={styles.riskyCircle}> </div>
                <div className={styles.lowHealth}>
                  {LIQUIDATE_ENUM.lowHealth}
                </div>
                <div className={styles.mediumHealthyCircle}></div>
                <div className={styles.mediumHealth}>
                  {LIQUIDATE_ENUM.mediumHealth}
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Liquidate;
