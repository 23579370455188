import * as React from "react";
import styles from "../components/NavBar/Desktop/index.module.scss";
const LendIcon = (props) => (
  <svg
    {...props}
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svgImage}
  >
    <path
      d="M21.867 0H1.521c-.3 0-.587.182-.799.506-.212.323-.331.762-.331 1.22v5.75h1.507V2.3H21.49v18.401H1.898v-5.175H.39v5.75c0 .458.12.896.331 1.22.212.323.5.505.799.506h20.346c.3-.001.587-.183.8-.506.211-.324.33-.762.33-1.22V1.726c0-.458-.119-.897-.33-1.22-.213-.324-.5-.505-.8-.506Z"
      fill="#A5A2B8"
    />
    <path
      d="M14.714 12.707a1 1 0 0 0 0-1.414L8.35 4.929a1 1 0 0 0-1.415 1.414L12.592 12l-5.657 5.657A1 1 0 1 0 8.35 19.07l6.364-6.364ZM0 13h14.007v-2H0v2Z"
      fill="#A5A2B8"
    />
  </svg>
);

export default LendIcon;
