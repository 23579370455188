import React, { useState, useEffect } from "react";
import cx from "classnames";
import Skeleton from "react-loading-skeleton";
import { AppContext, UserContext } from "../../../context";
import styles from "./index.module.scss";
import { useContext } from "react";
import { BORROW_ENUM } from "../../../enums/borrowEnum";
import ButtonRade from "../../../components/RadeButtons";
import QuestionIcon from "../../../assets/Question Icon.svg";
import Card from "../../../components/Card";
import BellIcon from "../../../assets/BellIcon.svg";
import Modal from "react-modal";
import MaxBorrowPopUp from "../MaxBorrowPopUp/MaxBorrowPopUp";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { TOOL_TIP } from "../../../enums/homeEnum";
import borrowContract from "../../../../src/artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
import { ethers } from "ethers";
import useETHPrice from "../../../Hooks/usePrice/usePrice";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
import BorrowSuccessful from "../BorrowSuccessfulModal/BorrowSuccessfulModal";
import WaitingConfirmation from "../WaitingConfirmationModal/WaitingConfirmationModal";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
import useWalletEthBalance from "../../../Hooks/useWalletEthBalance/useWalletEthBalance";

let currAddress = null;
const MAX_DECIMAL_PLACE = 2;
/* global BigInt */
const CheckMaxBorrowAmount = (
  {
    setBorrowFlowPageNumber,
    selectedLoanTerm,
    selectedPaymentTerm,
    setShowBorrowMain,
  },
  interval = null
) => {
  const { account } = useContext(UserContext);
  const { ethBalanceInWallet } = useWalletEthBalance(account);
  // Function for tooltip
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle1}>
      {TOOL_TIP.nests}
    </Tooltip>
  );
  const renderTooltip3 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle2}>
      {TOOL_TIP.maxBorrowAmount}
    </Tooltip>
  );
  const renderTooltip4 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle3}>
      {TOOL_TIP.borrowingFee}
    </Tooltip>
  );
  const renderTooltip5 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle4}>
      {TOOL_TIP.collatRatio}
    </Tooltip>
  );
  //state to set the amount of RUD that user wants to deposit
  const [RUDDeposit, setRUDDeposit] = useState("");
  //State to set an error message if the user hasn't entered any amount before Confirm button onClick.
  const [errorForConfirm, setErrorForConfirm] = useState(false);
  const [maxBorrowAmount, setMaxBorrowAmount] = useState("");
  const [borrowingAmount, setBorrowingAmount] = useState("");
  const [errorForMaxBorrowAmount, setErrorForMaxBorrowAmount] = useState(false);
  const [modalPageNumber, setModalPageNumber] = useState(null);
  const [etherscanTransaction, setEtherscanTransaction] = useState(null);
  const [priceFeedAddress, setPriceFeedAddress] = useState(null);
  const [errorReason, setErrorReason] = useState("");
  //timer to check each 10sec on which metamask network is connected
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { priceFeedAddress } = getNetworkDetails(chainID);
        setPriceFeedAddress(priceFeedAddress);
      } catch (error) {
        console.error("Error while fetching network details:", error);
      }
    };
    if (priceFeedAddress !== currAddress) {
      clearInterval(interval);
      currAddress = priceFeedAddress;
    }

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
      currAddress = null;
    };
  }, [priceFeedAddress]);

  const { ethPrice, loading } = useETHPrice(priceFeedAddress);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  //Borrow Rud Contract
  const rudBorrowContract = new ethers.Contract(
    borrowContract.address, // deployed contract address
    borrowContract.abi, //deployed contract abi
    signer
  );
  const [collateralRatio, setCollateralRatio] = useState(null);
  const getCollateralRatio = async () => {
    if (rudBorrowContract) {
      const collateralizationRatio = await rudBorrowContract.collateralRatio();
      setCollateralRatio(collateralizationRatio);
    } else {
      setCollateralRatio(undefined);
    }
  };
  useEffect(() => {
    getCollateralRatio();
  }, []);
  const [borrowFee, setBorrowFee] = useState(null);

  // State definition for opening modal
  const onClickBackButton = () => {
    setBorrowFlowPageNumber(2);
  };
  const [modalIsOpen, setIsOpen] = useState(false);

  //function to get the amount of collateral that user enter.
  const getEquivalentPercentage = (e) => {
    const amount = e.target.value;
    setRUDDeposit(amount);
    if (amount === "" || amount === 0) {
      setBorrowingAmount(0);
      setMaxBorrowAmount(0);
      setBorrowFee(0);
    } else if (Number(collateralRatio) === 0) {
      setBorrowingAmount(Math.floor((amount * ethPrice * 100) / 150));
      setMaxBorrowAmount(Math.floor((amount * ethPrice * 100) / 150));
    } else {
      setBorrowingAmount(
        Math.floor((amount * ethPrice * 100) / Number(collateralRatio))
      );
      setMaxBorrowAmount(
        Math.floor((amount * ethPrice * 100) / Number(collateralRatio))
      );
    }
    setErrorForConfirm(false);
  };
  const onChangeUserEnteringLoanAmount = (e) => {
    const amount = e.target.value;
    setBorrowingAmount(amount);
    if (Number(amount) !== 0) {
      setCollateralRatio(((RUDDeposit * ethPrice) / amount) * 100);
    } else {
      setCollateralRatio(0);
    }
    setErrorForMaxBorrowAmount(false);
  };
  const isRUDDepositEmpty = RUDDeposit === "" || RUDDeposit === 0;

  const handleSubmitConfirmButton = () => {
    if (!RUDDeposit) {
      setErrorForConfirm(true);
    } else if (borrowingAmount > maxBorrowAmount) {
      setErrorForMaxBorrowAmount(true);
    } else {
      setErrorForConfirm(false);
      setErrorForMaxBorrowAmount(false);
      setIsOpen(true);
      setModalPageNumber(0);
    }
  };
  const handleOnClickMaxButton = () => {
    setRUDDeposit(ethBalanceInWallet);
    if (ethBalanceInWallet === "" || ethBalanceInWallet === 0) {
      setBorrowingAmount(0);
      setMaxBorrowAmount(0);
    } else if (Number(collateralRatio) === 0) {
      setBorrowingAmount(
        Math.floor((ethBalanceInWallet * ethPrice * 100) / 150)
      );
      setMaxBorrowAmount(
        Math.floor((ethBalanceInWallet * ethPrice * 100) / 150)
      );
    } else {
      setBorrowingAmount(
        Math.floor(
          (ethBalanceInWallet * ethPrice * 100) / Number(collateralRatio)
        )
      );
      setMaxBorrowAmount(
        Math.floor(
          (ethBalanceInWallet * ethPrice * 100) / Number(collateralRatio)
        )
      );
    }
    // setRud(ethBalanceInWallet * ethPrice);
  };
  const { isMobile } = useContext(AppContext);
  useEffect(() => {
    const borrowingFee =
      0.05 * borrowingAmount -
      0.0005 * ((borrowingAmount * selectedLoanTerm) / selectedPaymentTerm);
    setBorrowFee(Math.ceil(borrowingFee));
  }, [borrowingAmount]);
  const CardTitle = () => (
    <>
      <div className={styles.borrowTitleContainer}>
        <div
          className={cx(styles.borrowHeadingText, {
            [styles.borrowHeadingTextMob]: isMobile,
          })}
        >
          {BORROW_ENUM.nest}
          <OverlayTrigger
            placement="right"
            overlay={renderTooltip1}
            className={styles.toolTipStyle}
          >
            <img
              className={styles.quesIcon}
              src={QuestionIcon}
              alt="Question_icon"
            />
          </OverlayTrigger>
        </div>
      </div>
    </>
  );
  const CardBody = () => (
    <>
      <div className={styles.liquidityPoolDiv}>
        <div className={styles.ButttonContent}>
          <div
            className={cx(styles.contentText, {
              [styles.contentTextMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton1, {
                [styles.stepsButton1Mob]: isMobile,
              })}
            >
              1
            </div>
          </div>
          <div className={styles.line}></div>

          <div
            className={cx(styles.buyRUDButtonDiv, {
              [styles.buyRUDButtonDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton2, {
                [styles.stepsButton2Mob]: isMobile,
              })}
            >
              2
            </div>
          </div>
          <div className={styles.line}></div>

          <div
            className={cx(styles.buyRUDButtonDiv, {
              [styles.buyRUDButtonDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton3, {
                [styles.stepsButton3Mob]: isMobile,
              })}
            >
              3
            </div>
          </div>
        </div>
        <div
          className={cx(styles.step3, {
            [styles.step3Mob]: isMobile,
          })}
        >
          {BORROW_ENUM.step3}
        </div>
        <div
          className={cx(styles.selectContainer, {
            [styles.selectContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.selectInStep3, {
              [styles.selectInStep3Mob]: isMobile,
            })}
          >
            {BORROW_ENUM.selectInStep3}
          </div>
        </div>
      </div>
      <div
        className={cx(styles.depositRUD, {
          [styles.depositRUDMob]: isMobile,
        })}
      >
        <div className={styles.depositRUDInLPDiv}>
          <div
            className={cx(styles.depositingRUD, {
              [styles.depositingRUDMob]: isMobile,
            })}
          >
            {BORROW_ENUM.depositButton}
          </div>
          <div className={styles.maxButtonDiv}>
            <ButtonRade
              outline
              customStyling={cx(styles.maxButton, {
                [styles.maxButtonMob]: isMobile,
              })}
              onClick={handleOnClickMaxButton}
            >
              Max
            </ButtonRade>
          </div>
        </div>
        {loading ? (
          <div className={styles.skeletonContainer}>
            <Skeleton
              style={{
                backgroundColor: "rgb(25, 39, 59)",
                width: "95%",
                height: "25px",
              }}
            />
          </div>
        ) : (
          <div
            className={cx(styles.payingEthValue, {
              [styles.payingEthValueMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.dollarSymbol, {
                [styles.dollarSymbolMob]: isMobile,
              })}
            ></div>
            <input
              type="number"
              placeholder="0.0"
              onChange={getEquivalentPercentage}
              onKeyDown={(e) => {
                if (e.key === "-") {
                  e.preventDefault();
                }
              }}
              value={RUDDeposit}
              autoFocus
              autoComplete="off"
              className={cx(styles.inputAmount, {
                [styles.inputAmountMob]: isMobile,
              })}
            ></input>
            <div
              className={cx(styles.payingCrypto, {
                [styles.payingCryptoMob]: isMobile,
              })}
            >
              ETH
            </div>
          </div>
        )}
      </div>
      <div className={styles.poolGetDiv}>
        <div className={styles.poolShare}>{BORROW_ENUM.pool_share}</div>

        <div
          className={cx(styles.GetBorrowUSDContainer, {
            [styles.GetBorrowUSDContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.GetBorrowAmount, {
              [styles.GetBorrowAmountMob]: isMobile,
            })}
          >
            {BORROW_ENUM.borrowAmount}
          </div>
          {loading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton
                style={{
                  backgroundColor: "rgb(25, 39, 59)",
                  width: "95%",
                  height: "25px",
                }}
              />
            </div>
          ) : (
            <div
              className={cx(styles.GetBorrowAmountValue, {
                [styles.GetBorrowAmountValueMob]: isMobile,
              })}
            >
              <input
                type="number"
                placeholder="0.0"
                onChange={onChangeUserEnteringLoanAmount}
                onKeyDown={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
                value={borrowingAmount}
                autoComplete="off"
                disabled={isRUDDepositEmpty}
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                })}
              ></input>
              <span>RUD</span>
            </div>
          )}
        </div>
      </div>
      {/* Hidden part in the UI */}
      <div
        className={cx(styles.hiddenDiv, {
          [styles.hiddenDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.maxBorrowAmountDiv, {
            [styles.maxBorrowAmountDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.maxBorrowAmount, {
              [styles.maxBorrowAmountMob]: isMobile,
            })}
          >
            {BORROW_ENUM.maxBorrowAmount}
            <OverlayTrigger placement="right" overlay={renderTooltip3}>
              <img
                src={QuestionIcon}
                alt="Question_Icon"
                className={cx(styles.questionIconHiddenDiv, {
                  [styles.questionIconHiddenDivMob]: isMobile,
                })}
              />
            </OverlayTrigger>
            {/* Tooltip */}
          </div>
          {loading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton
                style={{
                  backgroundColor: "rgb(25, 39, 59)",
                  width: "80%",
                  height: "25px",
                }}
              />
            </div>
          ) : (
            <div
              className={cx(styles.maxBorrowAmountValue, {
                [styles.maxBorrowAmountValueMob]: isMobile,
              })}
            >
              {Number(maxBorrowAmount).toFixed(MAX_DECIMAL_PLACE)} RUD
            </div>
          )}
        </div>
        <div
          className={cx(styles.borrowingFeeDiv, {
            [styles.borrowingFeeDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.borrowingFee, {
              [styles.borrowingFeeMob]: isMobile,
            })}
          >
            {BORROW_ENUM.borrowingFee} *
            <OverlayTrigger placement="right" overlay={renderTooltip4}>
              <img
                src={QuestionIcon}
                alt="Question_Icon"
                className={cx(styles.questionIconHiddenDiv, {
                  [styles.questionIconHiddenDivMob]: isMobile,
                })}
              />
            </OverlayTrigger>
          </div>
          {loading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton
                style={{
                  backgroundColor: "rgb(25, 39, 59)",
                  width: "80%",
                  height: "25px",
                }}
              />
            </div>
          ) : (
            <div
              className={cx(styles.borrowFeeValue, {
                [styles.borrowFeeValueMob]: isMobile,
              })}
            >
              {Number(borrowFee).toFixed(MAX_DECIMAL_PLACE)} RUD
            </div>
          )}
        </div>
        <div
          className={cx(styles.collateralizationRatioDiv, {
            [styles.collateralizationRatioDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.collateralizationMainConatiner, {
              [styles.collateralizationMainConatinerMob]: isMobile,
            })}
          >
            {BORROW_ENUM.collateralizationRatio}
            <OverlayTrigger placement="right" overlay={renderTooltip5}>
              <img
                src={QuestionIcon}
                alt="Question_Icon"
                className={cx(styles.questionIconHiddenDiv, {
                  [styles.questionIconHiddenDivMob]: isMobile,
                })}
              />
            </OverlayTrigger>
            {/* Tooltip */}
          </div>
          {loading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton
                style={{
                  backgroundColor: "rgb(25, 39, 59)",
                  width: "80%",
                  height: "25px",
                }}
              />
            </div>
          ) : (
            <div
              className={cx(styles.poolSharePercentageValue, {
                [styles.poolSharePercentageValueMob]: isMobile,
                [styles.underCollateral]: collateralRatio < 150,
              })}
            >
              {collateralRatio === null ? (
                <Skeleton
                  style={{
                    backgroundColor: "rgb(25, 39, 59)",
                    width: "74%",
                    height: "25px",
                  }}
                />
              ) : (
                Number(collateralRatio).toFixed(MAX_DECIMAL_PLACE)
              )}
              {collateralRatio !== null && <>%</>}
            </div>
          )}
        </div>
      </div>
      {/* Displaying error message if the user tries to moe to next step without providing input */}
      {errorForConfirm && (
        <ShakeError
          errorMessage={BORROW_ENUM.errorForConfirm}
          customStyling={cx(styles.alertBeforeConfirm, {
            [styles.alertBeforeconfirmMob]: isMobile,
          })}
        />
      )}
      {errorForMaxBorrowAmount && (
        <ShakeError
          errorMessage={BORROW_ENUM.errorForMaxBorrow}
          customStyling={cx(styles.alertBeforeConfirm, {
            [styles.alertBeforeConfirmMob]: isMobile,
          })}
        />
      )}
      <div
        className={cx(styles.ButtonsDiv, {
          [styles.ButtonsDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={onClickBackButton}
        >
          {BORROW_ENUM.backButton}
        </ButtonRade>
        <ButtonRade
          outline
          onClick={handleSubmitConfirmButton}
          customStyling={cx(styles.confirmButton, {
            [styles.confirmButtonMob]: isMobile,
          })}
        >
          {BORROW_ENUM.confirmButton}
        </ButtonRade>
      </div>
    </>
  );

  return (
    <>
      {/* calling as a function to avoid flickering issue */}
      <Card titleComponent={<CardTitle />} children={CardBody()} />

      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        ariaHideApp={false}
      >
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return (
                <MaxBorrowPopUp
                  value={RUDDeposit}
                  borrowAmount={borrowingAmount}
                  setIsOpen={setIsOpen}
                  selectedLoanTerm={selectedLoanTerm}
                  selectedPaymentTerm={selectedPaymentTerm}
                  setModalPageNumber={setModalPageNumber}
                  setEtherscanTransaction={setEtherscanTransaction}
                  borrowFee={borrowFee}
                  setErrorReason={setErrorReason}
                  /*passing props to the Modal Component named MaxBorrowPopUp */
                />
              );
            case 1:
              return <WaitingConfirmation errorReason={errorReason} />;
            case 2:
              return (
                <BorrowSuccessful
                  setIsOpen={setIsOpen}
                  setBorrowFlowPageNumber={setBorrowFlowPageNumber}
                  etherscanTransaction={etherscanTransaction}
                  setShowBorrowMain={setShowBorrowMain}
                />
              );
            default:
              return null;
          }
        })()}
      </Modal>
    </>
  );
};

export default CheckMaxBorrowAmount;
