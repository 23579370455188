import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "./SellConfirmPopUp.module.scss";
import ButtonRade from "../../../components/RadeButtons";
const SellConfirmPopUp = ({ setModalPageNumber }) => {
  const { isMobile } = useContext(AppContext);
  const TransactionSuccessfulButton = () => {
    setModalPageNumber(2); //Opens up the next modal
  };
  return (
    <>
      <div
        className={cx(styles.swapConfirmDiv, {
          [styles.swapConfirmDivMob]: isMobile,
        })}
      >
        <ul className={styles.loaderList}>
          <li>
            <div
              className={cx(styles.loaderCircle, {
                [styles.loaderCircleMob]: isMobile,
              })}
            >
              <span></span>
            </div>
          </li>
        </ul>
        <div
          className={cx(styles.confirmContent, {
            [styles.confirmContentMob]: isMobile,
          })}
        >
          {RUD_ENUM.confirm_content}
        </div>
        <div
          className={cx(styles.swappingEth, {
            [styles.swappingEthMob]: isMobile,
          })}
        >
          {RUD_ENUM.swapping_ETH}
        </div>
        <div
          className={cx(styles.transactionConfirm, {
            [styles.transactionConfirmMob]: isMobile,
          })}
        >
          {RUD_ENUM.transaction_confirm}
        </div>
        <div className={styles.viewConfirmation}>
          <ButtonRade outline onClick={TransactionSuccessfulButton}>
            View Confirmation
          </ButtonRade>
        </div>
      </div>
    </>
  );
};

export default SellConfirmPopUp;
