import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../StakingSuccessfulModal/index.module.scss";
import uploadIcon from "../../../assets/Upload Icon.svg";
import ButtonRade from "../../../components/RadeButtons";
import { STAKE_ENUM } from "../../../enums/stakeEnum";
import { StakeContext } from "../StakeContext";
const StakeSuccessful = ({ setModalIsOpen, etherscanTransaction }) => {
  const { isMobile } = useContext(AppContext);
  const { setAddRUDInLiquidityPool } = useContext(StakeContext);
  //Function to make the modal close and return to Home Page
  const closeStakingSuccessfulWindow = () => {
    setModalIsOpen(false);
    setAddRUDInLiquidityPool(false);
  };

  return (
    <div className={styles.stakeSuccessfulDiv}>
      <div
        className={cx(styles.uploadIconDiv, {
          [styles.uploadIconDivMob]: isMobile,
        })}
      >
        <img
          src={uploadIcon}
          alt="upload-icon"
          className={cx(styles.uploadIcon, {
            [styles.uploadIconMob]: isMobile,
          })}
        />
      </div>
      <div
        className={cx(styles.stakeSuccessful, {
          [styles.stakeSuccessfulMob]: isMobile,
        })}
      >
        {STAKE_ENUM.stakeSuccessful}
      </div>
      <div className={styles.viewTransactionDetails}>
        <a
          href={`https://goerli.etherscan.io/tx/${etherscanTransaction}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.redirectToEtherScan}
        >
          {RUD_ENUM.transaction_details}
        </a>
      </div>
      <div
        className={cx(styles.closeButtonDiv, {
          [styles.closeButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.closeButton, {
            [styles.closeButtonMob]: isMobile,
          })}
          onClick={closeStakingSuccessfulWindow}
        >
          {STAKE_ENUM.closeButton}
        </ButtonRade>
      </div>
    </div>
  );
};
export default StakeSuccessful;
