import React from "react";
import styles from "./FooterMain.module.scss";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../context";
const FooterMain = () => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={cx(styles.footer, {
        [styles.footerMob]: isMobile,
      })}
    >
      <span className={styles.copyright}>
        &copy; Copyright 2023 Rariti, Inc
      </span>
    </div>
  );
};

export default FooterMain;
