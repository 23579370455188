import styles from "./index.module.scss";
import React, { useState } from "react";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { RETURN_USER_STAKING } from "../../../enums/returnUserStaking";
import ButtonRade from "../../../components/RadeButtons";
import { ethers } from "ethers";
import RudstakingContract from "../../../../src/artifacts/Contracts/stablecoin/RUDStaking.sol/RUDStaking.json";
import { Button } from "../../../components";
import { STAKE_ENUM } from "../../../enums/stakeEnum";
const ReturnUserPopUp = ({
  stakedAmount,
  setModalIsOpen,
  setModalPageNumber,
  stakeId,
  setIsError,
  setErrorMessage,
}) => {
  const { isMobile } = useContext(AppContext);
  //state to set the amount of RUD that user wants to unstake
  const [unstakingAmount, setUnstakingAmount] = useState("");
  const handleOnClickMaxButton = () => {
    setUnstakingAmount(stakedAmount);
  };
  const getUserEnteredRUDforUnstake = (e) => {
    const amount = e.target.value;
    setUnstakingAmount(amount);
  };
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  //Staking Rud Contract
  const stakingContract = new ethers.Contract(
    RudstakingContract.address, // deployed contract address
    RudstakingContract.abi, //deployed contract abi
    signer
  );
  //Function to close the Modal.
  function closeModal() {
    setModalIsOpen(false);
  }
  // Function that confirms the transaction
  const onClickUnStake = async () => {
    // todo API to be integrated here
    setModalPageNumber(2);
    try {
      const unstakeAmount = ethers.utils.parseUnits(
        unstakingAmount.toString(),
        "ether"
      );
      const unstakeTx = await stakingContract.unstakeToken(
        unstakeAmount,
        stakeId
      );
      const receipt = await unstakeTx.wait();
      if (receipt.status === 1) {
        setModalIsOpen(true);
        setModalPageNumber(3);
      }
    } catch (e) {
      console.log("e", e);
      setIsError(true);
      setErrorMessage(e.code);
      setTimeout(() => setModalIsOpen(false), 4000);
    }
  };

  return (
    <div>
      <div
        className={cx(styles.popUpContent, {
          [styles.popUpContentMob]: isMobile,
        })}
      >
        {RETURN_USER_STAKING.confirmMessage}
      </div>
      <div
        className={cx(styles.stakeRUD, {
          [styles.stakeRUDMob]: isMobile,
        })}
      >
        <div className={styles.stakeRUDInLPDiv}>
          <div
            className={cx(styles.depositingRUD, {
              [styles.depositingRUDMob]: isMobile,
            })}
          >
            {STAKE_ENUM.deposit}
          </div>
          <div className={styles.maxButtonDiv}>
            <Button
              onClick={handleOnClickMaxButton}
              className={cx(styles.maxButton, {
                [styles.maxButtonMob]: isMobile,
              })}
            >
              Max
            </Button>
          </div>
        </div>
        <div
          className={cx(styles.payingEthValue, {
            [styles.payingEthValueMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.dollarSymbol, {
              [styles.dollarSymbolMob]: isMobile,
            })}
          >
            $
          </div>
          <input
            type="number"
            name="EthPay"
            value={unstakingAmount}
            onChange={getUserEnteredRUDforUnstake}
            onKeyDown={(e) => {
              if (e.key === "-") {
                e.preventDefault();
              }
            }}
            placeholder="0.0"
            autoFocus
            autoComplete="off"
            className={cx(styles.inputAmount, {
              [styles.inputAmountMob]: isMobile,
            })}
          ></input>
          <div
            className={cx(styles.payingCrypto, {
              [styles.payingCryptoMob]: isMobile,
            })}
          >
            RUD
          </div>
        </div>
      </div>
      <div
        className={cx(styles.popUpButtonDiv, {
          [styles.popUpButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={closeModal}
        >
          {RETURN_USER_STAKING.cancel}
        </ButtonRade>

        <ButtonRade
          outline
          customStyling={cx(styles.unstakeButton, {
            [styles.unstakeButtonMob]: isMobile,
          })}
          onClick={onClickUnStake}
        >
          {RETURN_USER_STAKING.unStake}
        </ButtonRade>
      </div>
    </div>
  );
};

export default ReturnUserPopUp;
