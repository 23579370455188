import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "./SellConfirmTransactionPopUp.module.scss";
import RadeButton from "../../../components/RadeButtons";
import uploadIcon from "../../../assets/UploadIcon.svg";
import { useNavigate } from "react-router-dom";

const SellConfirmTransactionPopUp = ({
  setModalIsOpen,
  etherscanTransaction,
}) => {
  // Variable to navigate to the home screen
  let navigate = useNavigate();

  const { isMobile } = useContext(AppContext);
  // Working of the close button
  const onClickCloseButton = () => {
    setModalIsOpen(false);
    navigate("/");
  };
  return (
    <div className={styles.transactionSubmitDiv}>
      <div
        className={cx(styles.uploadIconDiv, {
          [styles.uploadIconDivMob]: isMobile,
        })}
      >
        <img
          src={uploadIcon}
          alt="upload-icon"
          className={cx(styles.uploadIcon, {
            [styles.uploadIconMob]: isMobile,
          })}
        />
      </div>
      <div
        className={cx(styles.transactionSubmit, {
          [styles.transactionSubmitMob]: isMobile,
        })}
      >
        {RUD_ENUM.transaction_submit}
      </div>
      <div className={styles.viewTransactionDetails}>
        <a
          href={` https://goerli.etherscan.io/tx/${etherscanTransaction}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.redirectToEtherScan}
        >
          {RUD_ENUM.transaction_details}
        </a>
      </div>
      <div
        className={cx(styles.closeButtonDiv, {
          [styles.closeButtonDivMob]: isMobile,
        })}
      >
        <RadeButton
          customStyling={cx(styles.closeButton, {
            [styles.closeButtonMob]: isMobile,
          })}
          onClick={onClickCloseButton}
        >
          {RUD_ENUM.closeButton}
        </RadeButton>
      </div>
    </div>
  );
};

export default SellConfirmTransactionPopUp;
