import styles from "./index.module.scss";
import { Button } from "../../../../components";
import React from "react";
import cx from "classnames";
import { useContext } from "react";
import { AppContext, UserContext } from "../../../../context";
import { BORROW_ENUM } from "../../../../enums/borrowEnum";
import borrowContract from "../../../../../src/artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
import { ethers } from "ethers";
import erc20PermitSignature from "../../../../components/PermitSignature/ERC20PermitSignature";
import RaritiUsdContract from "../../../../../src/artifacts/Contracts/stablecoin/RaritiUSD.sol/RaritiUSD.json";

/* global BigInt */
const PayConfirmPopUp = ({
  loanBalance,
  radioButtonInput,
  setModalOpen,
  setModalPageNumber,
  nestId,
  setSignatureParameters,
}) => {
  const { isMobile } = useContext(AppContext);

  const { account } = useContext(UserContext);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  //Borrow Rud Contract
  const rudBorrowContract = new ethers.Contract(
    borrowContract.address, // deployed contract address
    borrowContract.abi, //deployed contract abi
    signer
  );
  //RaritiUsd Contract
  const RaritiUsdDeployed = new ethers.Contract(
    RaritiUsdContract.address,
    RaritiUsdContract.abi,
    signer
  );

  //Function to make the Modal close.
  async function handleOnclickPayButton() {
    setModalPageNumber(1);
    try {
      const repayingAmount = ethers.utils.parseUnits(
        radioButtonInput.toString(),
        "ether"
      );

      const loanBalanceInEther = ethers.utils.parseUnits(
        loanBalance.toString(),
        "ether"
      );

      const { r, s, v, transactionDeadline, isRejected } =
        await erc20PermitSignature(
          account,
          rudBorrowContract.address,
          repayingAmount,
          RaritiUsdDeployed,
          provider
        );
      setSignatureParameters([transactionDeadline, v, r, s]);

      if (isRejected) {
        setModalPageNumber(5);
      } else {
        setModalPageNumber(2);
        setTimeout(() => {
          setModalPageNumber(6); // waiting for confirmation
        }, 3000);
        const repayTx = await rudBorrowContract.repayment(
          repayingAmount,
          nestId,
          transactionDeadline,
          v,
          r,
          s
        );
        const receipt = await repayTx.wait();
        if (receipt.status === 1) {
          if (repayingAmount.lt(loanBalanceInEther)) {
            setModalPageNumber(4);
          } else if (repayingAmount.eq(loanBalanceInEther)) {
            setModalPageNumber(3);
          }
        }
      }
    } catch (e) {
      console.log("e", e);
      setTimeout(() => setModalOpen(false), 4000);
    }
  }
  function closeModal() {
    setModalOpen(false);
  }
  return (
    <div>
      <div className={styles.popUpContentDiv}>
        <div
          className={cx(styles.popUpContent, {
            [styles.popUpContentMob]: isMobile,
          })}
        >
          {" "}
          {BORROW_ENUM.KYCPaymentContent}
        </div>
        <div
          className={cx(styles.popUpButtonDiv, {
            [styles.popUpButtonDivMob]: isMobile,
          })}
        >
          <Button
            className={cx(styles.cancelButton, {
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={closeModal}
          >
            {BORROW_ENUM.cancelButton}
          </Button>

          <Button
            className={cx(styles.completeKYCButton, {
              [styles.completeKYCButtonMob]: isMobile,
            })}
            onClick={handleOnclickPayButton}
          >
            {BORROW_ENUM.pay}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PayConfirmPopUp;
