import React, { useState } from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";

import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../RudBuy/index.module.scss";
import styled from "styled-components";
import Card from "../../../components/Card";
import RudBuy from "../RudBuy/RudBuy";
import RUDSell from "../RUDSell/RUDSell";
import SwapConfirmModal from "../SwapConfirmModal/SwapConfirmModal";
import Modal from "react-modal";
import useRudInWallet from "../../../Hooks/useRudBalance/useRudBalance";
import { useNavigate } from "react-router-dom";

const Button = styled.button``;
// Parent function to handle both BUY and SELL scenes in the RUD swap workflow
const RudLandingPage = ({
  titleButtonVal,
  setTitleButtonVal,
  setShowNextpage,
  setShouldRenderRUD,
}) => {
  const { account } = useContext(UserContext);
  let navigate = useNavigate();

  const { rudBalance } = useRudInWallet(account);
  const rudInWallet = Number(rudBalance);
  const [canProceedofSell, setCanProceedofSell] = useState(false);
  const { isMobile } = useContext(AppContext);
  //State variable to go to a component showing that user accepted to confirm his transaction
  const [confirmTransaction, setConfirmTransaction] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  //State variable to set error message if there is any in backend
  const [isError, setIsError] = useState(false);
  //State variable to set apppropriate error message accordingly
  const [errorMessage, setErrorMessage] = useState(false);
  // State for hiding the KYC alert message
  const [displayKYCAlert, setDisplayKYCAlert] = useState(false);
  // Update the variable according to the value of the button (buy/sell)
  const handleTitleButtonClick = (e) => {
    // setTitleButtonVal(e.target.value);
    navigate("/get_rud/" + e.target.value);
  };

  const CardTitle = () => (
    <div
      className={cx(styles.buySellButtons, {
        [styles.buySellButtonsMob]: isMobile,
      })}
    >
      {/* Custom styled button for buy with value BUY */}
      <Button
        className={cx(styles.buyRudButton, {
          [styles.buyRudButtonMob]: isMobile,
        })}
        value="BUY"
        style={{
          backgroundColor: titleButtonVal === "BUY" ? "#02AAB0" : "transparent",
        }}
        onClick={handleTitleButtonClick}
      >
        {RUD_ENUM.Buy_button}
      </Button>

      {/* Custom styled button for buy with value SELL */}
      {rudInWallet !== 0 && (
        <Button
          className={cx(styles.sellRudButton, {
            [styles.sellRudButtonMob]: isMobile,
          })}
          value="SELL"
          onClick={handleTitleButtonClick}
          style={{
            background: titleButtonVal === "SELL" ? "#02AAB0" : "transparent",
          }}
        >
          {RUD_ENUM.Sell_button}
        </Button>
      )}
    </div>
  );

  return (
    <div
      className={cx(styles.buttonTableDiv, {
        [styles.buttonTableDivMob]: isMobile,
      })}
    >
      {/* Render only the relevant part of the card-body according to the state variable value */}
      <Card titleComponent={<CardTitle />}>
        {titleButtonVal === "BUY" && (
          <RudBuy
            setConfirmTransaction={setConfirmTransaction}
            setErrorMessage={setErrorMessage}
            setIsError={setIsError}
            setWaitingConfirmation={setWaitingConfirmation}
            setModalOpen={setModalOpen}
            setShowNextpage={setShowNextpage}
            setShouldRenderRUD={setShouldRenderRUD}
          />
        )}
        {titleButtonVal === "SELL" && (
          <RUDSell
            setCanProceedofSell={setCanProceedofSell}
            setConfirmTransaction={setConfirmTransaction}
            displayKYCAlert={displayKYCAlert}
            setDisplayKYCAlert={setDisplayKYCAlert}
            setWaitingConfirmation={setWaitingConfirmation}
            setErrorMessage={setErrorMessage}
            setIsError={setIsError}
            setShowNextpage={setShowNextpage}
            // props that passes the state for opening the modal
            setModalOpen={setModalOpen}
            setShouldRenderRUD={setShouldRenderRUD}
          />
        )}
      </Card>
      {/* To be implemented later */}
      {/* {titleButtonVal === "SELL" && canProceedofSell && !displayKYCAlert && (
        <div className={styles.kycAlert}>
          <div className={styles.KycAlertDiv}>
            <img
              className={styles.BellIconImage}
              src={BellIcon}
              alt="bell_icon"
            ></img>
            <div className={styles.BellIconSpan}>{RUD_ENUM.KYCAlert}</div>
          </div>
        </div>
      )} */}
      {confirmTransaction && (
        <Modal
          isOpen={modalOpen}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
          <SwapConfirmModal
            isError={isError}
            errorMessage={errorMessage}
            waitingConfirmation={waitingConfirmation}
          />
        </Modal>
      )}
    </div>
  );
};
export default RudLandingPage;
