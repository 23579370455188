import { useContext } from "react";
import { AppContext } from "../../context";
import NavBarDesktop from "./Desktop";
import NavBarMobile from "./Mobile";

const NavBar = () => {
  const { isMobile } = useContext(AppContext);
  return isMobile ? <NavBarMobile/> : <NavBarDesktop/>;
};

export default NavBar;
