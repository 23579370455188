import React from "react";
import styles from "../../components/RightSidePanel/RightSidepanel.module.scss";
import GasTracker from "../GasTracker";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../context";
import Statistics from "./Statistics";
import ProfileImage from "../NavBar/Desktop/ProfileImage";
import ProfileName from "../NavBar/Desktop/ProfileName";
import KycSec from "../NavBar/Desktop/KycSec";
import HeaderWallerButton from "../HeaderWalletButton/HeaderWalletButton";
const RightSidePanel = () => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={cx(styles.mainDiv, {
        [styles.mainDivMob]: isMobile,
      })}
    >
      <div className={styles.userStatsDiv}>
        <div className={styles.profileImage}>
          <ProfileImage />
        </div>
        <div className={styles.userNameDiv}>
          <div className={styles.userKyc}>
            <ProfileName />
            <KycSec />
          </div>
          <HeaderWallerButton />
        </div>
      </div>
      <div className={styles.statistics}>
        <Statistics />
      </div>
      <GasTracker />
    </div>
  );
};

export default RightSidePanel;
