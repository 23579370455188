import React, { useState } from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../context";
import styles from "./rud.module.scss";
import { useContext } from "react";
import { RUD_ENUM } from "../../enums/rudEnum";
import GasTracker from "../../components/GasTracker";
import ButtonRade from "../../components/RadeButtons";
import Card from "../../components/Card";
import RudLandingPage from "./RUDLandingPage/RUDLandingPage";
import { RudContext } from "./RUDContext";
import useRudInWallet from "../../Hooks/useRudBalance/useRudBalance";
import { useNavigate } from "react-router-dom";

const MAX_DECIMAL_PLACE = 2;
const GetRUD = ({
  showNextpage,
  setShowNextpage,
  titleButtonVal,
  setTitleButtonVal,
}) => {
  const { isMobile } = useContext(AppContext);
  const { account } = useContext(UserContext);
  const { rudBalance } = useRudInWallet(account);
  let navigate = useNavigate();

  const rudInWallet = Number(rudBalance);
  const buyOnClick = () => {
    setShowNextpage(true);
    navigate("/get_rud/buy");

    // setTitleButtonVal("BUY");
  };
  const sellOnClick = () => {
    setShowNextpage(true);
    navigate("/get_rud/sell");
    // setTitleButtonVal("SELL");
  };
  const CardTitle = () => (
    <>
      <div
        className={cx(styles.headingText, {
          [styles.headingTextMob]: isMobile,
        })}
      >
        {RUD_ENUM.RUD_Heading}
      </div>
    </>
  );
  const CardBody = () => (
    <>
      <div
        className={cx(styles.rudContent, {
          [styles.rudContentMob]: isMobile,
          [styles.contentAfterRUDBuy]: rudInWallet !== 0,
          [styles.contentAfterRUDBuyMob]: rudInWallet !== 0 && isMobile,
        })}
      >
        {rudInWallet === 0 && (
          <div
            className={cx(styles.contentText, {
              [styles.contentTextMob]: isMobile,
            })}
          >
            {RUD_ENUM.RUD_Content}
          </div>
        )}
        {rudInWallet !== 0 && (
          <div
            className={cx(styles.rudReturnUserContent, {
              [styles.returnUserContentMob]: isMobile,
            })}
          >
            You have $ {rudInWallet.toFixed(MAX_DECIMAL_PLACE)} RUD. Buy or sell
            RUD from our marketplace.
          </div>
        )}
        <div
          className={cx(styles.buySellButtons, {
            [styles.buySellButtonsMob]: isMobile,
            [styles.buttonsAfterRudBuy]: rudInWallet !== 0,
            [styles.buttonsAfterRudBuyMob]: rudInWallet !== 0 && isMobile,
          })}
        >
          <ButtonRade
            onClick={buyOnClick} //activate the function in which switching to next page is defined
            outline
            customStyling={cx(styles.buyButton, {
              [styles.buyButtonMob]: isMobile,
              [styles.buyButtonAfterRudBuy]: rudInWallet !== 0,
              [styles.buyButtonAfterRudBuyMob]: rudInWallet !== 0 && isMobile,
            })}
          >
            {RUD_ENUM.Buy_button}
          </ButtonRade>

          {/* Custom styled button for buy with value SELL */}
          {rudInWallet === 0 ? null : (
            <ButtonRade
              outline
              onClick={sellOnClick}
              customStyling={cx(styles.sellRudButton, {
                [styles.sellRudButtonMob]: isMobile,
              })}
              value="SELL"
            >
              {RUD_ENUM.Sell_button}
            </ButtonRade>
          )}
        </div>
      </div>
    </>
  );
  return (
    <div
      className={cx(styles.landingPageMainDiv, {
        [styles.landingPageMainDivMob]: isMobile,
      })}
    >
      {/* switch to the Landing page component if the state is active, else display the Card component */}
      <RudContext.Provider value={{ setShowNextpage }}>
        {showNextpage ? (
          <RudLandingPage
            titleButtonVal={titleButtonVal}
            setTitleButtonVal={setTitleButtonVal}
          />
        ) : (
          // calling as a function to avoid flickering issue
          <Card titleComponent={<CardTitle />} children={CardBody()} />
        )}
      </RudContext.Provider>
      <div
        className={cx(styles.landingpageGasTracker, {
          [styles.landingpageGasTrackerMob]: isMobile,
        })}
      >
        <div className={styles.gasTrackerContainer}>
          {" "}
          {isMobile && <GasTracker />}
        </div>
      </div>
    </div>
  );
};

export default GetRUD;
