import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import attachmentLogo from "../../assets/attachmentLogo.svg";
import { firestore } from "../../firebase";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import styles from "./index.module.scss";

function KycUpload({
  id,
  pancardHolding,
  setPancardHolding,
  aadharHolding,
  setAadharHolding,
  setErrorForPancardHold,
  setErrorForAadharHold,
  reSubmitKyc,
  setIsFileUploaded,
  setIsLoadingAadharHold,
  setIsLoadingPancardHold,
}) {
  const { account } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFileUpload = async (selectedFile) => {
    if (
      selectedFile &&
      selectedFile.size <= 5000000 &&
      /(jpeg|png|pdf|jpg)$/i.test(selectedFile.name)
    ) {
      if (id === "holdPassport") {
        setIsLoadingPancardHold(true);
      } else if (id === "holdPassportBackside") {
        setIsLoadingAadharHold(true);
      }
      const storage = getStorage(); // Get the storage object
      const storageRef = ref(storage); // Create a reference to the storage root

      let filePath = ""; // Initialize the file path variable

      if (id === "holdPassport") {
        filePath = `kycDetails/${account}/holdPassport/${selectedFile.name}`;
      } else if (id === "holdPassportBackside") {
        filePath = `kycDetails/${account}/holdPassportBackSide/${selectedFile.name}`;
      }
      if (filePath !== "") {
        const fileRef = ref(storageRef, filePath); // Create a reference to the file
        try {
          // Upload the file to Firebase Storage
          await uploadBytes(fileRef, selectedFile);
          // Retrieve the download URL for the uploaded file
          const downloadURL = await getDownloadURL(fileRef);

          if (id === "holdPassport") {
            setPancardHolding(downloadURL); // Set the download URL
            setIsFileUploaded(true);
            setErrorForPancardHold(false); // Reset the error for pancard
          } else if (id === "holdPassportBackside") {
            setAadharHolding(downloadURL); // Set the download URL
            setIsFileUploaded(true);
            setErrorForAadharHold(false); // Reset the error for aadhar card
          }

          setErrorMessage(null);
        } catch (error) {
          console.error("Error uploading file to Firebase Storage:", error);
        } finally {
          setIsLoadingAadharHold(false); // Stop loading
          setIsLoadingPancardHold(false);
        }
      }
    } else {
      if (id === "holdPassport") {
        setPancardHolding(null);
      } else if (id === "holdPassportBackside") {
        setAadharHolding(null);
      }

      setErrorMessage(
        "Please select a valid file (JPEG, PNG, or PDF) that is under 5MB!"
      );
    }
  };

  useEffect(() => {
    async function fetchDetails() {
      const profileDataRef = collection(firestore, "userData"); // Reference to the "profileData" collection
      const profileDocRef = doc(profileDataRef, account); // Reference to the document with email ID as document ID
      const profileData = {
        holdingPassport: pancardHolding,
        holdingPassportBackside: aadharHolding,
      };
      await updateDoc(profileDocRef, profileData);
    }
    fetchDetails();
  }, [pancardHolding, account, aadharHolding]);

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];

    handleFileUpload(selectedFile);
  };

  const handleImageClick = () => {
    if (reSubmitKyc !== "Pending" && reSubmitKyc !== "Verified") {
      // Trigger file selection when the image is clicked
      document.getElementById(`${id}-upload`).click();
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const usersRef = collection(firestore, "userData");
        const documentId = account; // Email of the user
        const userDocRef = doc(usersRef, documentId);
        const documentSnapshot = await getDoc(userDocRef);

        if (documentSnapshot.exists()) {
          // The document with the specified email address exists
          const userData = documentSnapshot.data();
          // Set the values from Firestore to the state
          setPancardHolding(userData.holdingPassport || "");
          setAadharHolding(userData.AadharCardHold || "");
        } else {
          // The document does not exist
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error retrieving profile information:", error);
      }
    };

    fetchProfileData();
  }, [account]);
  return (
    <div>
      {(pancardHolding && id === "holdPassport") ||
      (aadharHolding && id === "holdPassportBackside") ? (
        <div className={styles.previewDiv}>
          <img
            src={id === "holdPassport" ? pancardHolding : aadharHolding}
            alt={`attachment-${id}`}
            className={styles.previewImage}
            onClick={handleImageClick}
          />

          <label htmlFor={`${id}-upload`}>
            <div className={styles.imageUpload}></div>
          </label>
          <input
            type="file"
            id={`${id}-upload`}
            accept=".jpeg,.png,.pdf, .jpg"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </div>
      ) : (
        <div className={styles.previewDivWithoutFile}>
          <label htmlFor={`${id}-upload`}>
            <div className={styles.imageUpload}>
              <div className={styles.uploadIcon}>
                <img src={attachmentLogo} alt="attachment-logo" />
              </div>
              <div className={styles.dragAndDropText}>
                <div className={styles.browseLink}>Upload attachment</div>
              </div>
            </div>
          </label>
          <input
            type="file"
            id={`${id}-upload`}
            accept=".jpeg,.png,.pdf, .jpg"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </div>
      )}
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
}

export default KycUpload;
