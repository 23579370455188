import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import cx from "classnames";
import { useContext, useState, useEffect } from "react";
import { AppContext, UserContext } from "../../../context";
import ButtonRade from "../../../components/RadeButtons";
import { RETURN_USER_STAKING } from "../../../enums/returnUserStaking";
import { ethers } from "ethers";
import Progressbar from "../../../components/ProgressBar/ProgressBar";
import { TOOL_TIP } from "../../../enums/homeEnum";
import useRADEInWallet from "../../../Hooks/useRADEVestDetails/useRADEVestDetails";
import vestingContract from "../../../../src/artifacts/Contracts/stablecoin/VestingToken.sol/RADE.json";
import styles from "./index.module.scss";
import UpArrowIcon from "../../../assets/UpArrow";
import QuestionMark from "../../../assets/QuestionMark.svg";
/*global BigInt*/
const MAX_DECIMAL_PLACE = 2;
const PERCENTAGE_CONVERSION = 100;
const StakeDetails = ({
  updateRewardGap,
  setUpdateRewardGap,
  stakeId,
  accountNumber,
  stakedAmount,
  lockDuration,
  maturityDate,
  stakedTime,
  stakedRUD,
  lockDurationInSeconds,
  stakedTimeInSec,
  vestingSchedule,
  acrrRewards,
  setAccrRewards,
  expandedRowIndex,
  toggleStakeDetails,
  setRowClicked,
  status,
  currentBlockTimestamp,
  maturityDateInSec,
  setStakeId,
  setStakedAmount,
  handleSubmitUnStakeInLock,
  handleSubmitUnStakeButtonInUnlock,
  totalPoolStakeValue,
  isUpdateButtonDisabled,
}) => {
  const progressiveBarMainContainer =
    stakedAmount > 0
      ? styles.progressiveBarMainContainer
      : styles.progressiveBarMainContainerStakeComplete;

  const { account } = useContext(UserContext);
  const { userVestedDetails, loading } = useRADEInWallet(account);
  const [isMintButtonDisabled, setIsMintButtonDisabled] = useState(true);
  const [isStakeLocked, setIsStakeLocked] = useState(false);
  const [timeDifference, setTimeDifference] = useState(null);
  const [isRewardUpdatedCompleted, setIsRewardUpdatedCompleted] =
    useState(null);
  const DYNAMIC_REWARD_VALUE = 8;
  const TOTAL_ACC_REWARD =
    (DYNAMIC_REWARD_VALUE * stakedAmount) / totalPoolStakeValue;
  function convertSecondsToTime(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;

    let result = "";

    if (days > 0 && days !== 1) {
      result += `${days} days `;
    }
    if (days === 1) {
      result += `${days} day `;
    }
    if (hours > 0) {
      result += `${hours} hours `;
    }

    if (minutes > 0) {
      result += `${minutes} minutes `;
    }

    if (remainingSeconds > 0) {
      result += `${remainingSeconds} seconds`;
    }

    return result.trim();
  }
  const renderTooltipMintButton = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.onHoverMintButton}
    </Tooltip>
  );
  const renderTooltipUpdateButton = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.onHoverUpdateButton}
    </Tooltip>
  );
  const renderToolForecastedDetails = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.forecastedDetails}
    </Tooltip>
  );
  const renderToolAccruedDetails = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.accruedDetails}
    </Tooltip>
  );

  const { isMobile } = useContext(AppContext);
  function epochTimeToFormattedDateTime(epochTime) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(epochTime * 1000); // Convert seconds to milliseconds
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = String(date.getFullYear());

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }
  useEffect(() => {
    if (
      userVestedDetails &&
      userVestedDetails[2] &&
      userVestedDetails[2][stakeId] &&
      userVestedDetails[2][stakeId][1]
    ) {
      const vestingEndTime =
        parseInt(userVestedDetails[2][stakeId][1]._hex, 16) +
        parseInt(userVestedDetails[2][stakeId][2]._hex, 16);
      /* If reward is updated completely then hide update rewards button */
      if (
        (BigInt(userVestedDetails[2][stakeId][0]._hex).toString() /
          BigInt(userVestedDetails[2][stakeId][8]._hex).toString()) *
          PERCENTAGE_CONVERSION.toFixed(MAX_DECIMAL_PLACE) ===
        100
      ) {
        setIsRewardUpdatedCompleted(true);
      }
      if (vestingEndTime < Math.floor(new Date().getTime() / 1000)) {
        setIsMintButtonDisabled(false);
      } else {
        setIsMintButtonDisabled(true);
      }
    }
  }, [userVestedDetails]);
  const handleClickUpdateRewardToken = async () => {
    try {
      if (lockDuration === 3600) {
        await vestingContractInstance.claimRewards();
      } else {
        await vestingContractInstance.claimRewardsOneHour();
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleClickMintToken = async () => {
    try {
      await vestingContractInstance.scheduleMint();
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    // Parse the stakedTime string into a Date object
    // Get the current time
    const currentDate = new Date();
    const epochTime = currentDate.getTime() / 1000;

    if (epochTime < maturityDateInSec) {
      if (lockDurationInSeconds === 3600) {
        setTimeDifference(((epochTime - stakedTimeInSec) / 900).toFixed(0));
        setUpdateRewardGap(900);
      } else {
        setUpdateRewardGap(86400);
        setTimeDifference(((epochTime - stakedTimeInSec) / 86400).toFixed(0));
      }
    } else if (lockDurationInSeconds !== 3600) {
      setUpdateRewardGap(86400);
      setTimeDifference((maturityDateInSec - stakedTimeInSec) / 86400);
      setIsStakeLocked(true);
    } else {
      setTimeDifference((maturityDateInSec - stakedTimeInSec) / 900);
      setUpdateRewardGap(900);
    }
  }, []);

  useEffect(() => {
    if (
      !loading &&
      userVestedDetails &&
      userVestedDetails[2] &&
      userVestedDetails[2][stakeId] &&
      userVestedDetails[2][stakeId][1]
    ) {
      setAccrRewards(
        parseFloat(
          ethers.utils.formatEther(
            BigInt(userVestedDetails[2][stakeId][0]._hex).toString()
          )
        ).toFixed(MAX_DECIMAL_PLACE)
      );
    }
  }, [userVestedDetails]);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // Defining the signer of the contract
  const signer = provider.getSigner();
  const vestingContractInstance = new ethers.Contract(
    vestingContract.address, // deployed contract address
    vestingContract.abi, //deployed contract abi
    signer
  );
  const handleOnClickArrow = () => {
    // Toggle the details for this row
    toggleStakeDetails(stakeId);
  };

  const AccordionBody = () => {
    return (
      <div className={styles.stakeDetailsContainer}>
        <div className={styles.headingContainer} onClick={handleOnClickArrow}>
          <div
            className={cx(styles.details, {
              [styles.detailsMob]: isMobile,
            })}
          >
            Account {accountNumber} Details
          </div>
          <div>
            <UpArrowIcon />
          </div>
        </div>

        {stakedAmount !== 0 && (
          <div className={styles.detailsDiv}>
            {!isMobile && (
              <div className={styles.stakedRUDInfo}>
                <div className={styles.detailsHeading}>
                  {RETURN_USER_STAKING.stakedRUD}
                </div>
                <div className={styles.stakeDetailsValue}>{stakedAmount}</div>
              </div>
            )}

            {!isMobile && (
              <div className={styles.vestingSchedule}>
                <div className={styles.detailsHeading}>
                  {RETURN_USER_STAKING.lockDuration}
                </div>
                <div className={styles.stakeDetailsValue}>{lockDuration}</div>
              </div>
            )}

            {!isMobile && (
              <>
                <div className={styles.maturityDate}>
                  <div className={styles.detailsHeading}>
                    {RETURN_USER_STAKING.maturityDate}
                  </div>
                  <div className={styles.stakeDetailsValue}>{maturityDate}</div>
                </div>
                <div className={styles.maturityDateInMobile}>
                  <div className={styles.stakedDate}>
                    <div className={styles.detailsHeading}>
                      {RETURN_USER_STAKING.stakedDate}
                    </div>
                    <div className={styles.stakeDetailsValue}>{stakedTime}</div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {stakedAmount === 0 && (
          <div className={styles.detailsDiv}>You account is unstaked</div>
        )}
        {isMobile && (
          <div className={styles.stakedRUDInfoInMobile}>
            <div>
              <div className={styles.detailsHeadingInMobile}>
                {RETURN_USER_STAKING.stakedRUD}
              </div>
              <div className={styles.stakeDetailsValueInMobile}>
                {stakedAmount}
              </div>
            </div>
            <div>
              <div className={styles.vestingScheduleInMobile}>
                <div className={styles.detailsHeadingInMobile}>
                  {RETURN_USER_STAKING.lockDuration}
                </div>
                <div className={styles.stakeDetailsValueInMobile}>
                  {lockDuration}
                </div>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className={styles.stakedRUDInfoInMobile}>
            <div>
              <div className={styles.detailsHeadingInMobile}>
                {RETURN_USER_STAKING.stakedRUD}
              </div>
              <div className={styles.stakeDetailsValueInMobile}>
                {stakedAmount}
              </div>
            </div>
            <div>
              <div className={styles.vestingScheduleInMobile}>
                <div className={styles.detailsHeadingInMobile}>
                  {RETURN_USER_STAKING.lockDuration}
                </div>
                <div className={styles.stakeDetailsValueInMobile}>
                  {lockDuration}
                </div>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className={styles.maturityDateVestingScheduleInMobile}>
            <div className={styles.maturityDateInMobile}>
              <div className={styles.detailsHeadingInMob}>
                {RETURN_USER_STAKING.maturityDate}
              </div>
              <div className={styles.stakeDetailsValueInMob}>
                {maturityDate}
              </div>
            </div>

            <div className={styles.vestingScheduleInMobile}>
              <div className={styles.detailsHeadingInMob}>
                {RETURN_USER_STAKING.stakedDate}
              </div>
              <div className={styles.stakeDetailsValueInMob}>{stakedTime}</div>
            </div>
          </div>
        )}

        <div
          className={cx(styles.maturityDateVestingSchedule, {
            [styles.maturityDateVestingScheduleInMobile]: isMobile,
          })}
        >
          <div className={styles.statusContainer}>
            <div
              className={cx(styles.detailsHeading, {
                [styles.detailsHeadingInMob]: isMobile,
              })}
            >
              {RETURN_USER_STAKING.status}
            </div>
            <div
              className={cx(styles.stakeDetailsValue, {
                [styles.stakeDetailsValueInMobile]: isMobile,
                [styles.unlocked]: status === "Unlocked",
                [styles.locked]: status === "Locked",
              })}
            >
              {status}
            </div>
          </div>
        </div>

        <div
          className={cx(styles.stakingRewardsContainer, {
            [styles.stakingRewardsContainerMob]: isMobile,
          })}
        >
          <div className={styles.rewardContainer}>
            <div
              className={cx(styles.stakingRewards, {
                [styles.stakingRewardsMob]: isMobile,
              })}
            >
              {RETURN_USER_STAKING.stakingRewards}
            </div>
          </div>

          <div className={styles.accruedRewardsContainer}>
            <div>
              <div
                className={cx(styles.accruedRewards, {
                  [styles.accruedRewardsMob]: isMobile,
                })}
              >
                {RETURN_USER_STAKING.accruedRewards}{" "}
                <OverlayTrigger
                  placement="top"
                  overlay={renderToolAccruedDetails}
                >
                  <img
                    src={QuestionMark}
                    alt="tooltip"
                    className={styles.tooltip}
                  />
                </OverlayTrigger>
              </div>
              {!loading &&
              userVestedDetails &&
              userVestedDetails[2] &&
              userVestedDetails[2][stakeId] &&
              userVestedDetails[2][stakeId][1] ? (
                <div
                  className={cx(styles.accruedRewardsValue, {
                    [styles.accruedRewardsValueMob]: isMobile,
                  })}
                >
                  {parseFloat(
                    ethers.utils.formatEther(
                      BigInt(userVestedDetails[2][stakeId][0]._hex).toString()
                    )
                  )}{" "}
                  RADE
                  <p className={styles.lastUpdated}>
                    Last Updated :{" "}
                    {epochTimeToFormattedDateTime(
                      Number(userVestedDetails[2][stakeId][4])
                    )}
                  </p>
                </div>
              ) : (
                <div
                  className={cx(styles.accruedRewardsValue, {
                    [styles.accruedRewardsValueMob]: isMobile,
                  })}
                >
                  0 RADE
                </div>
              )}
            </div>
            {stakedAmount !== 0 && (
              <div>
                <div
                  className={cx(styles.accruedRewardsForecasted, {
                    [styles.accruedRewardsForecastedMob]: isMobile,
                  })}
                >
                  Forecasted Rewards{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={renderToolForecastedDetails}
                  >
                    <img
                      src={QuestionMark}
                      alt="tooltip"
                      className={styles.tooltip}
                    />
                  </OverlayTrigger>
                </div>{" "}
                {!loading ? (
                  <div
                    className={cx(styles.accruedRewardsValue, {
                      [styles.accruedRewardsValueMob]: isMobile,
                    })}
                  >
                    {TOTAL_ACC_REWARD * timeDifference} RADE
                  </div>
                ) : (
                  <div
                    className={cx(styles.accruedRewardsValue, {
                      [styles.accruedRewardsValueMob]: isMobile,
                    })}
                  >
                    0 RADE
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={progressiveBarMainContainer}>
            <div
              className={cx(styles.progressiveBarTooltipContainer, {
                [styles.progressiveBarTooltipContainerMob]: isMobile,
              })}
            >
              <div
                className={cx(styles.progressiveBarContainer, {
                  [styles.progressiveBarContainerMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.progressiveBarHeading, {
                    [styles.progressiveBarHeadingMob]: isMobile,
                  })}
                >
                  {!loading &&
                  userVestedDetails &&
                  userVestedDetails[2] &&
                  userVestedDetails[2][stakeId] &&
                  userVestedDetails[2][stakeId][1] &&
                  BigInt(userVestedDetails[2][stakeId][8]._hex).toString() >
                    0 ? (
                    <div
                      className={cx(styles.rewardVested, {
                        [styles.rewardVestedMob]: isMobile,
                      })}
                    >
                      {(
                        (BigInt(
                          userVestedDetails[2][stakeId][0]._hex
                        ).toString() /
                          BigInt(
                            userVestedDetails[2][stakeId][8]._hex
                          ).toString()) *
                        PERCENTAGE_CONVERSION
                      ).toFixed(MAX_DECIMAL_PLACE)}{" "}
                      %
                    </div>
                  ) : (
                    <div
                      className={cx(styles.rewardVested, {
                        [styles.rewardVestedMob]: isMobile,
                      })}
                    >
                      0 %
                    </div>
                  )}

                  {!loading &&
                  userVestedDetails &&
                  userVestedDetails[2] &&
                  userVestedDetails[2][stakeId] &&
                  userVestedDetails[2][stakeId][1] ? (
                    <div
                      className={cx(styles.progressiveBarDetailsValue, {
                        [styles.progressiveBarDetailsValueMob]: isMobile,
                      })}
                    >
                      Approx.
                      {parseFloat(
                        ethers.utils.formatEther(
                          BigInt(
                            userVestedDetails[2][stakeId][8]._hex
                          ).toString()
                        )
                      ).toFixed(MAX_DECIMAL_PLACE)}
                      <span className={styles.radeTokenText}>RADE Tokens</span>
                    </div>
                  ) : (
                    <div
                      className={cx(styles.progressiveBarDetailsValue, {
                        [styles.progressiveBarDetailsValueMob]: isMobile,
                      })}
                    >
                      Approx. 0 RADE Tokens
                    </div>
                  )}
                </div>
                <div>
                  <Progressbar
                    bgcolor="#02AAB0"
                    progress={
                      loading
                        ? 0
                        : (BigInt(
                            userVestedDetails[2][stakeId][0]._hex
                          ).toString() /
                            BigInt(
                              userVestedDetails[2][stakeId][8]._hex
                            ).toString()) *
                          PERCENTAGE_CONVERSION.toFixed(MAX_DECIMAL_PLACE)
                    }
                    height={15.79}
                    width={50}
                  />
                </div>
              </div>
            </div>
            {stakedAmount !== 0 && (
              <div
                className={cx(styles.progressiveBarContainer, {
                  [styles.progressiveBarContainerMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.progressiveBarHeading, {
                    [styles.progressiveBarHeadingMob]: isMobile,
                  })}
                >
                  {!loading ? (
                    <div
                      className={cx(styles.rewardVested, {
                        [styles.rewardVestedMob]: isMobile,
                      })}
                    >
                      {!isStakeLocked && updateRewardGap
                        ? (
                            ((TOTAL_ACC_REWARD * timeDifference) /
                              (TOTAL_ACC_REWARD *
                                (lockDurationInSeconds / updateRewardGap))) *
                            100
                          ).toFixed(MAX_DECIMAL_PLACE)
                        : 100}
                      %
                    </div>
                  ) : (
                    <div
                      className={cx(styles.rewardVested, {
                        [styles.rewardVestedMob]: isMobile,
                      })}
                    >
                      0 %
                    </div>
                  )}

                  {!loading ? (
                    <div
                      className={cx(styles.progressiveBarDetailsValue, {
                        [styles.progressiveBarDetailsValueMob]: isMobile,
                      })}
                    >
                      Approx.{" "}
                      {lockDurationInSeconds !== 3600
                        ? (
                            TOTAL_ACC_REWARD *
                            (lockDurationInSeconds / 86400)
                          ).toFixed(MAX_DECIMAL_PLACE)
                        : (
                            TOTAL_ACC_REWARD *
                            (lockDurationInSeconds / 900)
                          ).toFixed(MAX_DECIMAL_PLACE)}
                      {" RADE Tokens"}
                    </div>
                  ) : (
                    <div
                      className={cx(styles.progressiveBarDetailsValue, {
                        [styles.progressiveBarDetailsValueMob]: isMobile,
                      })}
                    >
                      Approx. 0 RADE Tokens
                    </div>
                  )}
                </div>
                {lockDurationInSeconds !== 3600 && (
                  <div>
                    {!isStakeLocked ? (
                      <Progressbar
                        bgcolor="#f9b959"
                        progress={
                          loading
                            ? 0
                            : ((TOTAL_ACC_REWARD * timeDifference) /
                                (TOTAL_ACC_REWARD *
                                  (lockDurationInSeconds / 86400))) *
                              100
                        }
                        height={15.79}
                        width={50}
                      />
                    ) : (
                      <Progressbar
                        bgcolor="#f9b959"
                        progress={100}
                        height={15.79}
                        width={50}
                      />
                    )}
                  </div>
                )}
                {lockDurationInSeconds === 3600 && (
                  <div>
                    <Progressbar
                      bgcolor="#f9b959"
                      progress={
                        loading
                          ? 0
                          : ((TOTAL_ACC_REWARD * timeDifference) /
                              (TOTAL_ACC_REWARD *
                                (lockDurationInSeconds / 900))) *
                            100
                      }
                      height={15.79}
                      width={50}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <div
            className={cx(styles.stakedDateRemainingDaysContainer, {
              [styles.stakedDateRemainingDaysContainerMob]: isMobile,
            })}
          >
            <div className={styles.noOfDaysRemaining}>
              <div
                className={cx(styles.detailsHeading, {
                  [styles.detailsHeadingInMob]: isMobile,
                })}
              >
                {RETURN_USER_STAKING.noOfDaysRemaining}
              </div>
              {!loading &&
              userVestedDetails &&
              userVestedDetails[2] &&
              userVestedDetails[2][stakeId] &&
              userVestedDetails[2][stakeId][1] ? (
                <div
                  className={cx(styles.stakeDetailsValue, {
                    [styles.stakeDetailsValueMob]: isMobile,
                  })}
                >
                  {((timeInSeconds) => {
                    const time = convertSecondsToTime(timeInSeconds);
                    return timeInSeconds < 0 ? "0 day" : time;
                  })(
                    parseInt(userVestedDetails[2][stakeId][1]._hex, 16) +
                      parseInt(userVestedDetails[2][stakeId][2]._hex, 16) -
                      Math.floor(new Date().getTime() / 1000)
                  )}
                </div>
              ) : (
                <div
                  className={cx(styles.stakeDetailsValue, {
                    [styles.stakeDetailsValueMob]: isMobile,
                  })}
                >
                  0 days
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.mintButtonContainer}>
          {!isRewardUpdatedCompleted && (
            <OverlayTrigger placement="top" overlay={renderTooltipUpdateButton}>
              <div>
                <ButtonRade
                  className={cx(styles.mintButton, {
                    [styles.mintButtonMob]: isMobile,
                    [styles.disabled]: isUpdateButtonDisabled,
                  })}
                  disabled={isUpdateButtonDisabled}
                  onClick={handleClickUpdateRewardToken}
                >
                  {RETURN_USER_STAKING.updateRewardButton}
                </ButtonRade>
              </div>
            </OverlayTrigger>
          )}
          <OverlayTrigger placement="top" overlay={renderTooltipMintButton}>
            <div>
              <ButtonRade
                className={cx(styles.mintButton, {
                  [styles.mintButtonMob]: isMobile,
                  [styles.disabled]: isMintButtonDisabled,
                })}
                disabled={isMintButtonDisabled}
                onClick={handleClickMintToken}
              >
                {RETURN_USER_STAKING.claimRewardButton}
              </ButtonRade>
            </div>
          </OverlayTrigger>
          {stakedAmount !== 0 && (
            <div className={styles.buttonContainerMob}>
              {maturityDateInSec >= currentBlockTimestamp ? (
                <ButtonRade
                  outline
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSubmitUnStakeInLock();
                  }}
                  customStyling={cx(styles.stakeButtonInLockWeb, {
                    [styles.stakeButtonInLock]: isMobile,
                  })}
                >
                  {RETURN_USER_STAKING.unStakeButton}
                </ButtonRade>
              ) : (
                <ButtonRade
                  outline
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSubmitUnStakeButtonInUnlock(
                      setStakeId(stakeId),
                      setStakedAmount(stakedAmount)
                    );
                  }}
                  customStyling={cx(styles.stakeButtonInUnlockWeb, {
                    [styles.stakeButtonInUnlock]: isMobile,
                  })}
                >
                  {RETURN_USER_STAKING.unStakeButton}
                </ButtonRade>
              )}
            </div>
          )}
        </div>
        <div>
          {/* {timeLeft > 0 && ( */}
          {userVestedDetails &&
            userVestedDetails[2] &&
            userVestedDetails[2][stakeId] &&
            userVestedDetails[2][stakeId][1] &&
            lockDurationInSeconds !== 3600 && (
              <div className={styles.note}>
                Note: You can only call the Update Reward Function on{" "}
                {epochTimeToFormattedDateTime(
                  Number(userVestedDetails[2][stakeId][4]) + 86400
                )}
                {}
              </div>
            )}
          {userVestedDetails &&
            userVestedDetails[2] &&
            userVestedDetails[2][stakeId] &&
            userVestedDetails[2][stakeId][1] &&
            lockDurationInSeconds === 3600 && (
              <div className={styles.note}>
                Note: You can only call the Update Reward Function on{" "}
                {epochTimeToFormattedDateTime(
                  Number(userVestedDetails[2][stakeId][4]) + 900
                )}
                {}
              </div>
            )}
          {/* )} */}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.stakeDetailsContainer}>
      {/* calling as a function to avoid flickering issue */}
      {AccordionBody()}
    </div>
  );
};

export default StakeDetails;
