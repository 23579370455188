import React from "react";
import { Button } from "react-bootstrap";
import styles from "./index.module.scss";

const SuccessFailed = ({ isSuccess, errorMsg, close }) => {
  return (
    <div>
      {isSuccess ? (
        <div>
          <label className={styles.label}>Success</label>
        </div>
      ) : (
        <div>
          <label className={styles.label}>Failed</label>
          <label className={styles.label}>{errorMsg}</label>
        </div>
      )}
      <Button onClick={close}>Close</Button>
    </div>
  );
};

export default SuccessFailed;
