import Tooltip from "react-bootstrap/Tooltip";
import Skeleton from "react-loading-skeleton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import cx from "classnames";
import React, { useContext } from "react";
import ether from "../../assets/Group 3144.svg";
import { STATISTICS_ITEMS } from "../../enums/statistics";
import { AppContext, UserContext } from "../../context";
import { TOOL_TIP } from "../../enums/homeEnum";
import styles from "../../components/RightSidePanel/Statistics.module.scss";
import "../../components/ReactLoading/reactLoading.css";
import useRudInWallet from "../../Hooks/useRudBalance/useRudBalance";
import useWalletEthBalance from "../../Hooks/useWalletEthBalance/useWalletEthBalance";
import useRADEInWallet from "../../Hooks/useRADEVestDetails/useRADEVestDetails";
import useStakedUserDetails from "../../Hooks/useStakedUserDetails/useStakedUserDetails";
import useBorrowedUserDetails from "../../Hooks/useBorrowedUserDetails/useBorrowedUserDetails";
import stakeIcon from "../../../src/assets/StakedIcon.svg";
import borrowIcon from "../../../src/assets/BorrowIcon.svg";
import ETHInWalletIcon from "../../../src/assets/ETHInWalletIcon.svg";
import RUDIcon from "../../../src/assets/RUDRightSidePanelIcon.svg";
import confidenceIndexIcon from "../../../src/assets/ConfidenceIndexIcon.svg";
import RadeIcon from "../../../src/assets/RADEIcon.svg";
const MAX_DECIMAL_PLACE = 2;
const renderTooltipConfidenceIndex = (props) => (
  <Tooltip {...props} className={styles.toolTipStyle}>
    {STATISTICS_ITEMS.confidenceIndex}
  </Tooltip>
);
const renderTooltipStaked = (props) => (
  <Tooltip {...props} className={styles.toolTipStyle}>
    {STATISTICS_ITEMS.staked}
  </Tooltip>
);
const renderTooltipBorrowed = (props) => (
  <Tooltip {...props} className={styles.toolTipStyle}>
    {STATISTICS_ITEMS.borrrowed}
  </Tooltip>
);
const renderTooltipETHInWallet = (props) => (
  <Tooltip {...props} className={styles.toolTipStyle}>
    {STATISTICS_ITEMS.eTHInWallet}{" "}
  </Tooltip>
);
const renderTooltipRUDInWallet = (props) => (
  <Tooltip {...props} className={styles.toolTipStyle}>
    {STATISTICS_ITEMS.RUD}
  </Tooltip>
);
const renderTooltipRadeInWallet = (props) => (
  <Tooltip {...props} className={styles.toolTipStyle}>
    {STATISTICS_ITEMS.RADETokenInWallet}
  </Tooltip>
);
const Statistics = () => {
  const { account } = useContext(UserContext);
  const { rudBalance } = useRudInWallet(account);
  const { ethBalanceInWallet } = useWalletEthBalance(account);
  const { radeBalance } = useRADEInWallet(account);
  const { stakedRudBalance } = useStakedUserDetails(account);
  const { borrowedRud } = useBorrowedUserDetails(account);
  const { isMobile } = useContext(AppContext);
  const rudInWallet = Number(rudBalance).toFixed(MAX_DECIMAL_PLACE);
  const ethInWallet = Number(ethBalanceInWallet).toFixed(MAX_DECIMAL_PLACE);
  const radeInWallet = Number(radeBalance).toFixed(MAX_DECIMAL_PLACE);
  const stakedBalance = Number(stakedRudBalance).toFixed(MAX_DECIMAL_PLACE);
  const borrowedRUD = Number(borrowedRud).toFixed(MAX_DECIMAL_PLACE);
  return (
    <div
      className={cx(styles.statisticsDiv, {
        [styles.statisticsMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.rowStatis, {
          [styles.rowStatisMob]: isMobile,
        })}
      >
        <OverlayTrigger placement="top" overlay={renderTooltipConfidenceIndex}>
          <p className={styles.col1PStatis}>
            <img
              src={confidenceIndexIcon}
              alt="Confidence index"
              className={styles.confidenceIndexIcon}
            />
          </p>
        </OverlayTrigger>

        <p className={styles.hiphen}>-</p>
      </div>
      <div
        className={cx(styles.rowStatis, {
          [styles.rowStatisMob]: isMobile,
        })}
      >
        <OverlayTrigger placement="top" overlay={renderTooltipRUDInWallet}>
          <p className={styles.col1PStatis}>
            {" "}
            <img src={RUDIcon} alt="RUD" className={styles.RUDIcon} />
          </p>
        </OverlayTrigger>
        {rudBalance !== null ? (
          <p className={styles.col2PStatis}>${rudInWallet} </p>
        ) : (
          <Skeleton
            style={{
              backgroundColor: "rgb(25, 39, 59)",
              width: "92%",
              height: "20px",
              marginRight: "44px",
            }}
          />
        )}
      </div>
      <div
        className={cx(styles.rowStatis, {
          [styles.rowStatisMob]: isMobile,
        })}
      >
        <OverlayTrigger placement="top" overlay={renderTooltipRadeInWallet}>
          <p className={styles.col1PStatis}>
            {" "}
            <img src={RadeIcon} alt="RADE" className={styles.radeIcon} />
          </p>
        </OverlayTrigger>

        {radeInWallet !== null ? (
          <p className={styles.col2PStatis}>${radeInWallet} </p>
        ) : (
          <Skeleton
            style={{
              backgroundColor: "rgb(25, 39, 59)",
              width: "92%",
              height: "20px",
              marginRight: "44px",
            }}
          />
        )}
      </div>

      <div
        className={cx(styles.rowStatis, {
          [styles.rowStatisMob]: isMobile,
        })}
      >
        <OverlayTrigger placement="top" overlay={renderTooltipBorrowed}>
          <p className={styles.col1PStatis}>
            <img
              src={borrowIcon}
              alt="Borrowed"
              className={styles.borrowIcon}
            />
          </p>
        </OverlayTrigger>
        {borrowedRud !== null ? (
          <p className={styles.col2PStatis}>{borrowedRUD}</p>
        ) : (
          <Skeleton
            style={{
              backgroundColor: "rgb(25, 39, 59)",
              width: "92%",
              height: "20px",
              marginRight: "44px",
            }}
          />
        )}
      </div>
      <div
        className={cx(styles.rowStatis, {
          [styles.rowStatisMob]: isMobile,
        })}
      >
        <OverlayTrigger placement="top" overlay={renderTooltipStaked}>
          <p className={styles.col1PStatis}>
            {" "}
            <img src={stakeIcon} alt="Staked" className={styles.stakeIcon} />
          </p>
        </OverlayTrigger>

        {stakedRudBalance !== null ? (
          <p className={styles.col2PStatis}>{stakedBalance}</p>
        ) : (
          <Skeleton
            style={{
              backgroundColor: "rgb(25, 39, 59)",
              width: "92%",
              height: "20px",
              marginRight: "44px",
            }}
          />
        )}
      </div>
      <div
        className={cx(styles.rowStatis, {
          [styles.rowStatisMob]: isMobile,
        })}
      >
        <OverlayTrigger placement="top" overlay={renderTooltipETHInWallet}>
          <p className={styles.col1PStatis}>
            <img
              src={ETHInWalletIcon}
              alt="ETH"
              className={styles.ETHInWalletIcon}
            />
          </p>
        </OverlayTrigger>
        {ethBalanceInWallet !== null ? (
          <p className={styles.col2PStatis}>
            {" "}
            <img
              className={styles.etherImage}
              src={ether}
              alt="Ethereum_Icon"
            />
            {ethInWallet}
          </p>
        ) : (
          <Skeleton
            style={{
              backgroundColor: "rgb(25, 39, 59)",
              width: "92%",
              height: "20px",
              marginRight: "44px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Statistics;
