import React, { useState, useEffect } from "react";
import styles from "../GasTracker/GasTracker.module.scss";
import { GAS_TRACKER } from "../../enums/gastracker";
import gasTracker from "../../assets/Gas track icon.svg";
import { useContext } from "react";
import useGasPrice from "../../Hooks/useGasPrice/useGasPrice";
import { AppContext } from "../../context";
import useETHPrice from "../../Hooks/usePrice/usePrice";
import cx from "classnames";
import ArrowIcon from "../../assets/Arrow Icon.svg";
import Skeleton from "react-loading-skeleton";
import getNetworkDetails from "../NetworkDetails/NetworkDetails";

const MAX_DECIMAL_PLACE = 2;
let currAddress = null;
const GasTracker = (interval = null) => {
  const [priceFeedAddress, setPriceFeedAddress] = useState(null);
  //timer to check each 10sec on which metamask network is connected

  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });

        const { priceFeedAddress } = getNetworkDetails(chainID);
        setPriceFeedAddress(priceFeedAddress);
      } catch (error) {
        console.error("Error while fetching network details:", error);
      }
    };
    if (priceFeedAddress !== currAddress) {
      clearInterval(interval);
      currAddress = priceFeedAddress;
    }

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
      currAddress = null;
    };
  }, [priceFeedAddress]);

  const { isMobile } = useContext(AppContext);
  const { slowGasRate, stdGasRate, fastGasRate } = useGasPrice();
  const { ethPrice, loading } = useETHPrice(priceFeedAddress);
  const currentEthPrice = Number(ethPrice).toFixed(MAX_DECIMAL_PLACE);
  return (
    <div
      className={cx(styles.GastableDiv, {
        [styles.GastableDivMob]: isMobile,
      })}
    >
      <div className={styles.gasTableFirstRow}>
        <div className={styles.gasTableThDiv}>
          {GAS_TRACKER.heading}
          <img
            className={cx(styles.gasTrackerImage, {
              [styles.gasTrackerImageMob]: isMobile,
            })}
            src={gasTracker}
            alt="Gas_Tracker"
          />
        </div>
      </div>
      <div className={styles.gasTableSecondRow}>
        <div
          className={cx(styles.liveEth, {
            [styles.liveEthMob]: isMobile,
          })}
        >
          <div>{GAS_TRACKER.token_value}</div>
          {!loading ? (
            <div>{currentEthPrice}</div>
          ) : (
            <Skeleton
              width={95}
              height={17}
              style={{
                display: "block",
                backgroundColor: "rgb(25, 39, 59)",
              }}
            />
          )}
        </div>

        <div
          className={cx(styles.liveEthVar, {
            [styles.liveEthVarMob]: isMobile,
          })}
        >
          <div className={styles.ETHToken}>{GAS_TRACKER.Eth}</div>
          {loading ? (
            <div className={styles.ETHChangeVal}>
              <Skeleton
                width={52}
                height={17}
                style={{
                  display: "block",
                  backgroundColor: "rgb(25, 39, 59)",
                }}
              />
            </div>
          ) : (
            <div className={styles.ETHChangeVal}>
              {GAS_TRACKER.change_value}
              <img
                className={cx(styles.arrowIcon, {
                  [styles.arrowMob]: isMobile,
                })}
                src={ArrowIcon}
                alt="arrow_icon"
              />
            </div>
          )}
        </div>
        <div
          className={cx(styles.gasTableColumn11, {
            [styles.gasTrackerKeys]: isMobile,
          })}
        >
          <div>{GAS_TRACKER.Slow}</div>
          <div>{GAS_TRACKER.Std}</div>
          <div>{GAS_TRACKER.Fast}</div>
        </div>
        <div
          className={cx(styles.gasTableColumn21, {
            [styles.gasTrackerValues]: isMobile,
          })}
        >
          {/* slowGasPrice !== null */}
          {slowGasRate !== null ? (
            <div>{slowGasRate} gwei</div>
          ) : (
            <Skeleton
              width={52}
              height={17}
              style={{
                display: "block",
                backgroundColor: "rgb(25, 39, 59)",
              }}
            />
          )}
          {stdGasRate !== null ? (
            <div className={styles.gasTableRow21}>{stdGasRate} gwei</div>
          ) : (
            <Skeleton
              width={52}
              height={17}
              style={{
                display: "block",
                backgroundColor: "rgb(25, 39, 59)",
              }}
            />
          )}

          {fastGasRate !== null ? (
            <div className={styles.gasTableRow22}>{fastGasRate} gwei</div>
          ) : (
            <Skeleton
              width={52}
              height={17}
              style={{
                display: "block",
                backgroundColor: "rgb(25, 39, 59)",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GasTracker;
