import React from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../context";
const TableCard = ({ titleComponent, bodyComponent }) => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={cx(styles.cardMainDiv, {
        [styles.cardMainDivMob]: isMobile,
      })}
    >
      <div className={styles.titleContainer}>{titleComponent}</div>
      <div className={styles.bodyContainer}>{bodyComponent}</div>
    </div>
  );
};

export default TableCard;
