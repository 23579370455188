export const RECEIVER_INFO = {
  billing_details: {
    name: "Rariti",
    address: {
      line1: "651 N Broad St",
      line2: "Suite 206, Middletown 19709",
      postal_code: "19709",
      state: "Delaware",
      country: "US",
    },
  },
};

export const CARD_LABELS = {
  title: "Select mode of payment",
  cardholderName: "Cardholder Name",
  cardNumer: "Card Number",
  expirationDate: "Expiration Date",
  securityCode: "Security Code",
  billingAddress: "Billing Address",
  line1: "Line 1",
  line2: "line 2",
  selectCountry: "Select Country",
  postalCode: "Postal Code",
};

export const BILLING_ADDRESS = {
  country: "US",
  line1: "651 N Broad St, Suite 206, Middletown 19709",
  line2: "",
  city: "Middletown",
  state: "Delaware",
  postal_code: "Suite 206",
};
