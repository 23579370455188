import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { BORROW_ENUM } from "../../../enums/borrowEnum";
import { useContext } from "react";
import ButtonRade from "../../../components/RadeButtons";
import { useState } from "react";
import NestDetails from "../../../components/NestDetails/NestDetails";
import BellIcon from "../../../assets/BellIcon.svg";
import { formatUserBorrow } from "../returnUserBorrowedDetails";
import useBorrowedUserDetails from "../../../Hooks/useBorrowedUserDetails/useBorrowedUserDetails";
import Spinner from "../../../components/Spinner/Spinner";
import MyTimer from "../../../components/CountDownTimer/CountDownTimer";
import ReturnUserPaySection from "./ReturnBorrowUserPaySection/Pay";
import TableCard from "../../../components/TableCard";
import { ReturnUserBorrowContext } from "./ReturnUserContext";
const MAX_DECIMAL_PLACE = 2;
const ReturnUserBorrowFlow = ({
  setBorrowFlowPageNumber,
  setShowBorrowMain,
}) => {
  const { account } = useContext(UserContext);
  const { userDetails, loading } = useBorrowedUserDetails(account);
  const [nestCount, setNestCount] = useState(null);
  const [nestId, setNestId] = useState(null);
  const [borrowedAmount, setBorrowedAmount] = useState(null);
  const [loanBalance, setLoanBalance] = useState(null);
  const [nextPayment, setNextpayment] = useState(null);
  const [collateralizationRatio, setCollateralizationRatio] = useState(null);
  const [formattedData, setFormattedData] = useState(null);
  const [collateralAmount, setCollateralAmount] = useState(null);
  const [nextPaymentAmount, setNextPaymentAmount] = useState(null);
  const [paymentSchedule, setPaymentSchedule] = useState(null);
  const [remainingTerms, setRemainingTerms] = useState(null);
  const [loanDuration, setLoanDuration] = useState(null);
  const [returnUserBorrowFlowPage, setReturnUserBorrowFlowPage] =
    useState(null);
  // State variable to keep track of the currently expanded row index
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const handleToggleBorrowDetails = (index) => {
    // Toggle the visibility of the stake details for the clicked row
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const toggleBorrowDetails = (index) => {
    if (expandedRowIndex === index) {
      // If it's already expanded, collapse it
      setExpandedRowIndex(null);
    } else {
      // Otherwise, expand it
      setExpandedRowIndex(index);
    }
  };

  useEffect(() => {
    if (userDetails) {
      setFormattedData(formatUserBorrow(userDetails));
    } else {
      console.log("loading");
    }
  }, [userDetails]);

  useEffect(() => {
    return () => {
      console.log("component unmounted");
    };
  }, []);

  const handleClickOpenNest = () => {
    setBorrowFlowPageNumber(1);
  };
  const { isMobile } = useContext(AppContext);
  const CardTitle = () => (
    <>
      <div className={styles.titleDiv}>
        <div
          className={cx(styles.BorrowHeadingText, {
            [styles.BorrowHeadingTextWeb]: !isMobile,
            [styles.BorrowHeadingTextMob]: isMobile,
          })}
        >
          {BORROW_ENUM.returnBorrowCardTitle}
        </div>

        <ButtonRade
          outline
          customStyling={cx(styles.openNewNestButton, {
            [styles.openNewNestButtonWeb]: !isMobile,
            [styles.openNewNestButtonMob]: isMobile,
          })}
          onClick={handleClickOpenNest}
        >
          {BORROW_ENUM.openNewNest}
        </ButtonRade>
      </div>
    </>
  );
  const CardBody = () => (
    <>
      <div className={styles.returnUserBorrowTable}>
        <table className={styles.tableHeading}>
          <thead>
            <tr
              className={cx(styles.borrowTableHeading, {
                [styles.borrowTableHeadingWeb]: !isMobile,
                [styles.borrowTableHeadingMob]: isMobile,
              })}
            >
              <th>{BORROW_ENUM.nest}</th>
              <th>{BORROW_ENUM.borrowedRUD}</th>
              {!isMobile && (
                <>
                  <th>{BORROW_ENUM.loanBalance}</th>
                  <th>{BORROW_ENUM.loanDuration}</th>
                </>
              )}
              <th>{BORROW_ENUM.collateralizationRatioHeading}</th>
              {!isMobile && <th>{BORROW_ENUM.due}</th>}
            </tr>
          </thead>
          <tbody>
            {formattedData.map((item, index) =>
              item.loanBalance !== 0 ? (
                <React.Fragment key={item.index}>
                  <tr
                    className={cx(styles.borrowTableNest1, {
                      [styles.rowHidden]: expandedRowIndex === index,
                    })}
                    onClick={() =>
                      handleToggleBorrowDetails(
                        index,
                        setNestCount(item.nest),
                        setBorrowedAmount(item.borrowedRUD),
                        setLoanBalance(item.loanBalance),
                        setNextpayment(item.nextPayment),
                        setCollateralizationRatio(item.coll_Ratio),
                        setCollateralAmount(item.collateralAmount),
                        setNextPaymentAmount(item.nextPaymentAmount),
                        setPaymentSchedule(item.paymentSchedule),
                        setRemainingTerms(item.remainingTerms),
                        setNestId(index),
                        setLoanDuration(item.loanDuration)
                      )
                    }
                  >
                    <td>{item.nest}</td>
                    <td>
                      {Number(item.borrowedRUD).toFixed(MAX_DECIMAL_PLACE)}
                    </td>
                    {!isMobile && (
                      <td>
                        {Number(item.loanBalance).toFixed(MAX_DECIMAL_PLACE)}
                      </td>
                    )}
                    {!isMobile && <td>{item.loanDuration}</td>}
                    <td>{item.coll_Ratio}%</td>
                    {!isMobile && (
                      <td className={styles.dueInCounter}>
                        <MyTimer expiryTimestamp={new Date(item.nextPayment)} />
                      </td>
                    )}
                  </tr>
                  {expandedRowIndex === index && (
                    <tr>
                      <td className={styles.accordionTd} colSpan="6">
                        <NestDetails
                          expandedRowIndex={expandedRowIndex}
                          toggleBorrowDetails={toggleBorrowDetails}
                          setReturnUserBorrowFlowPage={
                            setReturnUserBorrowFlowPage
                          }
                          nestCount={nestCount}
                          borrowedAmount={borrowedAmount}
                          loanBalance={loanBalance}
                          nextPayment={nextPayment}
                          collateralizationRatio={collateralizationRatio}
                          collateralAmount={collateralAmount}
                          nextPaymentAmount={nextPaymentAmount}
                          paymentSchedule={paymentSchedule}
                          remainingTerms={remainingTerms}
                          nestId={index}
                          loanDuration={loanDuration}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ) : null
            )}
          </tbody>
        </table>
      </div>
    </>
  );
  if (loading || !formattedData) {
    return <Spinner />;
  }
  return (
    <>
      <ReturnUserBorrowContext.Provider value={{ setReturnUserBorrowFlowPage }}>
        <div
          className={cx(styles.returnUsermainDiv, {
            [styles.returnUsermainDivWeb]: !isMobile,
            [styles.returnUsermainDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.mainDiv, {
              [styles.mainDivWeb]: !isMobile,
              [styles.mainDivMob]: isMobile,
            })}
          >
            {/* calling as a function to avoid flickering issue */}
            {!returnUserBorrowFlowPage && (
              <TableCard
                titleComponent={<CardTitle />}
                bodyComponent={CardBody()}
              />
            )}
          </div>
          {(() => {
            //Update to next pages in the stake flow based on the switch statement and the Page number defined.
            switch (returnUserBorrowFlowPage) {
              case 1:
                return (
                  <ReturnUserPaySection
                    setReturnUserBorrowFlowPage={setReturnUserBorrowFlowPage}
                    nestCount={nestCount}
                    borrowedAmount={borrowedAmount}
                    loanBalance={loanBalance}
                    nextPayment={nextPayment}
                    collateralizationRatio={collateralizationRatio}
                    collateralAmount={collateralAmount}
                    nextPaymentAmount={nextPaymentAmount}
                    paymentSchedule={paymentSchedule}
                    remainingTerms={remainingTerms}
                    nestId={nestId}
                    toggleBorrowDetails={toggleBorrowDetails}
                    setShowBorrowMain={setShowBorrowMain}
                  />
                  //Pass this value as props in the upcoming page.
                );
              default:
                return null;
            }
          })()}
        </div>
      </ReturnUserBorrowContext.Provider>
    </>
  );
};

export default ReturnUserBorrowFlow;
