import { APP_ENUM } from "../../enums/appEnum";

const getNetworkDetails = (chainID) => {
  switch (chainID) {
    case "1":
      return {
        networkName: APP_ENUM.en.ethNetwork,
        priceFeedAddress: APP_ENUM.ethPriceFeedAddressMainnet,
        tokenName: APP_ENUM.en.eth,
        wethAddress: APP_ENUM.wethAddressMainnet,
        daiAddress: APP_ENUM.daiAddressMainnet,
      };
    case "5":
      return {
        networkName: APP_ENUM.en.goerliTestNetwork,
        priceFeedAddress: APP_ENUM.ethPriceFeedAddressGoerli,
        tokenName: APP_ENUM.en.goerliEth,
        wethAddress: APP_ENUM.wethAddressGeorli,
        daiAddress: APP_ENUM.daiAddressGoerli,
      };
    case "31337":
      return {
        networkName: APP_ENUM.en.hardhatNetwork,
        priceFeedAddress: APP_ENUM.ethPriceFeedAddressGoerli, // gorli fork
        tokenName: APP_ENUM.en.eth,
        wethAddress: APP_ENUM.wethAddressGeorli,
        daiAddress: APP_ENUM.daiAddressGoerli,
      };
    case "80001":
      return {
        networkName: APP_ENUM.en.mumbaiNetwork,
        priceFeedAddress: APP_ENUM.ethPriceFeedAddressMumbai,
        tokenName: APP_ENUM.en.mumbaiToken,
        wethAddress: APP_ENUM.wethAddressMumbai,
        daiAddress: APP_ENUM.daiAddressMumbai,
      };
    default:
      return {
        networkName: null,
        priceFeedAddress: null,
        tokenName: null,
        wethAddress: null,
        daiAddress: null,
      };
  }
};
export default getNetworkDetails;
