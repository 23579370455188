import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import RUDContract from "../../artifacts/Contracts/stablecoin/VestingToken.sol/RADE.json";
/*global BigInt */
const provider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum)
  : null;
const RUDContractInstance = new ethers.Contract(
  RUDContract.address, //RaritiUSD deployed contract address
  RUDContract.abi, //RaritiUSD deployed contract abi
  provider
);

let currAcc = null;
const useRADEInWallet = (account, interval = null) => {
  //state to set the connected user's RUD Balance
  const [userRUDBalance, setUserRUDBalance] = useState(null);
  // state to set the vestedUserDetail
  const [vestedUserInfo, setVestedUserInfo] = useState(null);
  //function to get the RUD balance using

  useEffect(() => {
    try {
      const getRudBalance = async () => {
        if (RUDContractInstance && account) {
          const accountRUDBalance = await RUDContractInstance.balanceOf(
            account
          );
          const vestedUserDetail = await RUDContractInstance.getUserDetails(
            account
          );
          setVestedUserInfo(vestedUserDetail);
          setUserRUDBalance(accountRUDBalance);
        } else {
          setUserRUDBalance(undefined);
          setVestedUserInfo(undefined);
        }
      };
      if (account !== currAcc) {
        clearInterval(interval);
        currAcc = account;
      }
      interval = setInterval(getRudBalance, 1000);
      return () => {
        clearInterval(interval);
        interval = null;
        currAcc = null;
      };
    } catch (e) {
      console.log("error", e);
    }
  }, [account]);
  if (userRUDBalance && vestedUserInfo) {
    return {
      radeBalance: ethers.utils.formatEther(BigInt(userRUDBalance._hex)),
      userVestedAmount: ethers.utils.formatEther(
        BigInt(vestedUserInfo[1]._hex)
      ),
      userVestedDetails: vestedUserInfo,
      loading: false,
    };
  } else {
    return {
      radeBalance: null,
      userDetails: null,
      loading: true,
    };
  }
};
export default useRADEInWallet;
