/* eslint-disable react-hooks/exhaustive-deps */

import { mainAxios } from "./axiosUtil";

export const setAuthToken = async (token, address, isData = false) => {
  if (token) {
    mainAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    mainAxios.defaults.headers.common["Address"] = address;
    try {
      const response = await mainAxios.post("/api/auth/verify", { isData });
      return response;
    } catch (error) {
      return false;
    }
  }
  delete mainAxios.defaults.headers.common["Authorization"];
  delete mainAxios.defaults.headers.common["Address"];
  return false;
};

export const handleLoginIn = async (address) => {
  try {
    mainAxios.defaults.headers.common["Address"] = address;
    const response = await mainAxios.get(`api/user/nonce`);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const handleSignUp = async (address) => {
  try {
    const response = await mainAxios.post(`api/user/${address}/create`);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateUserData = async (userData) => {
  const body = {};
  body.userData = userData;
  try {
    const response = await mainAxios.patch(`api/user`, body);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const handleAuthenticate = async (publicAddress, signature) => {
  try {
    const response = await mainAxios.post(
      "api/auth",
      { publicAddress, signature },

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const createPaymentIntent = async (data) => {
  let response = { data: null };
  try {
    response = await mainAxios.post("/create-payment-intent", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    console.log(err, "response", response);
    return response.data;
  }
};

export const updateNotification = async (address, data) => {
  try {
    const response = await mainAxios.patch(
      `api/notification/${address}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return { error: error.message };
  }
};

export const updateProfile = async (data) => {
  try {
    const response = await mainAxios.patch("api/user/update", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return { error: error.message };
  }
};
