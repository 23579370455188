export const GAS_TRACKER = {
  heading: "Gas Tracker",
  Slow: "Slow",
  Std: "Std",
  Fast: "Fast",
  gwei1: "32 gwei",
  gwei2: "35 gwei",
  gwei3: "38 gwei",
  token_value: "Ethereum",
  Eth: "ETH",
  change_value: "2.64%",
  price_value: "$1,6789.79",
};
