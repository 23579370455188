import React, { useState, useEffect } from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "./SellSwapPopUp.module.scss";
import InfoIcon from "../../../assets/Info Square.svg";
import { Button } from "../../../components";
import { ethers } from "ethers";
import stableCoinMinterContract from "../../../../src/artifacts/Contracts/stablecoin/RaritiStableCoinMinter.sol/RaritiStableCoinMinter.json";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
import Skeleton from "react-loading-skeleton";
import useUniSwapQuote from "../../../Hooks/useETHPriceUniswap/useUniSwapQuote";
let currAddress = null;
const FLOORING_FACTOR = 100; // for fixing the issue of underflow numeric fault
/*global BigInt*/
const SellSwapPopUp = (
  {
    setDisplayKYCAlert,
    setModalIsOpen,
    setModalOpen,
    setConfirmTransaction,
    setWaitingConfirmation,
    value,
    signatureParameters,
    setEtherscanTransaction,
    setModalPageNumber,
    setIsError,
    setErrorMessage,
    getEqvETH,
  },
  interval = null
) => {
  const [priceFeedAddress, setPriceFeedAddress] = useState(null);
  //timer to check each 10sec on which metamask network is connected
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { priceFeedAddress } = getNetworkDetails(chainID);
        setPriceFeedAddress(priceFeedAddress);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };
    if (priceFeedAddress !== currAddress) {
      clearInterval(interval);
      currAddress = priceFeedAddress;
    }

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
      currAddress = null;
    };
  }, [priceFeedAddress]);

  // Importing the account address from the userContext
  const WETH_UNISWAP_PRICE = useUniSwapQuote();

  // state for storing the signatureParms
  // Defining the provider of the contract
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // Defining the signer of the contract
  const signer = provider.getSigner();
  const { isMobile } = useContext(AppContext);
  const confirmSwapButton = async () => {
    // hiding the KYC alert
    setDisplayKYCAlert(true);
    // Displaying the confirm transaction component
    setModalIsOpen(false);
    setModalOpen(true);
    setConfirmTransaction(true);
    setWaitingConfirmation(false);
    let amountIn = ethers.utils.parseUnits(value.toString(), "ether");

    try {
      const txHash = await CoinMinterContract.swapExactStableCoin(
        BigInt(amountIn),
        ...signatureParameters
      );
      const receipt = await txHash.wait();

      // If transaction is successfull
      if (receipt.status === 1) {
        setEtherscanTransaction(receipt.transactionHash);
        setModalOpen(true);
        setConfirmTransaction(true);
        setWaitingConfirmation(true);
        setTimeout(() => {
          setModalOpen(false);
          setModalIsOpen(true);
          setModalPageNumber(4);
        }, 1000);
      }
    } catch (e) {
      if (e.code === 4001) {
        setIsError(true);
        setErrorMessage(RUD_ENUM.transactionRejectedError);
      } else {
        setIsError(true);
        setErrorMessage(e.code);
      }
      setTimeout(() => setConfirmTransaction(false), 4000);
    }
  };

  //Function to make the modal close
  const closeModal = () => {
    setModalIsOpen(false);
  };

  //Coin minter conract
  const CoinMinterContract = new ethers.Contract(
    stableCoinMinterContract.address, // deployed contract address
    stableCoinMinterContract.abi, //deployed contract abi
    signer
  );
  return (
    <>
      <div
        className={cx(styles.popupMainDiv, {
          [styles.popupMainDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.payEthPopup, {
            [styles.payEthPopupMob]: isMobile,
          })}
        >
          <div className={styles.popupPayingEth}>You Sell</div>
          {!WETH_UNISWAP_PRICE ? (
            <div className={styles.skeletonContainer}>
              <Skeleton
                style={{
                  backgroundColor: "rgb(25, 39, 59)",
                  width: "94%",
                  height: "25px",
                }}
              />
            </div>
          ) : (
            <div className={styles.popupPayingCryptoDiv}>
              <div className={styles.dollarSymbol}>$</div>
              <div className={styles.userEnteredAmount}>{value}</div>
              <div
                className={cx(styles.popupPayingVal, {
                  [styles.popupPayingValMob]: isMobile,
                })}
              >
                {RUD_ENUM.RUD_Heading}
              </div>
            </div>
          )}
        </div>
        <div
          className={cx(styles.getRUDPopup, {
            [styles.getRUDPopupMob]: isMobile,
          })}
        >
          <div className={styles.popupReceivingRUD}>{RUD_ENUM.get}</div>
          {!WETH_UNISWAP_PRICE ? (
            <div className={styles.skeletonContainer}>
              <Skeleton
                style={{
                  backgroundColor: "rgb(25, 39, 59)",
                  width: "94%",
                  height: "25px",
                }}
              />
            </div>
          ) : (
            <div
              className={cx(styles.popupRUDVal, {
                [styles.popupRUDValMob]: isMobile,
              })}
            >
              <div className={styles.firstFlex}>
                <div className={styles.correspondingRUDVal}>
                  {getEqvETH / FLOORING_FACTOR}
                </div>
              </div>
              <div
                className={cx(styles.popupRUDCrypto, {
                  [styles.popupRUDCryptoMob]: isMobile,
                })}
              >
                {RUD_ENUM.ETH}
              </div>
            </div>
          )}
        </div>
        {!WETH_UNISWAP_PRICE ? (
          <div className={styles.skeletonContainer}>
            <Skeleton
              style={{
                backgroundColor: "rgb(25, 39, 59)",
                width: "50%",
                height: "25px",
                marginLeft: "72px",
              }}
            />
          </div>
        ) : (
          <div
            className={cx(styles.ethValueInfoDiv, {
              [styles.ethValueInfoDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.oneEthVal, {
                [styles.oneEthValMob]: isMobile,
              })}
            >
              {RUD_ENUM.One_ETH_Value}
            </div>
            <div
              className={cx(styles.equivalentRUDval, {
                [styles.equivalentRUDvalMob]: isMobile,
              })}
            >
              ${WETH_UNISWAP_PRICE * FLOORING_FACTOR}
            </div>
            <div
              className={cx(styles.rudContent, {
                [styles.rudContentMob]: isMobile,
              })}
            >
              {RUD_ENUM.RUD_Heading}
            </div>
          </div>
        )}
        <div
          className={cx(styles.popupButtonsDiv, {
            [styles.popupButtonsDivMob]: isMobile,
          })}
        >
          <Button
            className={cx(styles.cancelSwap, {
              [styles.cancelSwapMob]: isMobile,
            })}
            onClick={closeModal}
          >
            {RUD_ENUM.cancel_swap}
          </Button>
          <Button
            className={cx(styles.confirmSwap, {
              [styles.confirmSwapMob]: isMobile,
            })}
            onClick={confirmSwapButton}
          >
            {RUD_ENUM.confirm_swap}
          </Button>
        </div>
      </div>
    </>
  );
};
export default SellSwapPopUp;
