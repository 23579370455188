import styles from "../../src/components/NavBar/Desktop/index.module.scss";
import React from "react";
const BorrowIcon = (props) => (
  <svg
    {...props}
    width={23}
    height={23}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svgImage}
  >
    <path
      d="M22.85 0H2.15c-.305 0-.597.182-.813.506C1.122.829 1 1.268 1 1.725v5.75h1.533V2.3h19.934v18.4H2.533v-5.175H1v5.75c0 .457.122.896.337 1.22.216.323.508.505.813.505h20.7c.305 0 .597-.182.813-.506.215-.323.337-.762.337-1.219V1.725c0-.457-.122-.896-.337-1.22-.216-.323-.508-.504-.813-.505Z"
      fill="#A5A2B8"
    />
    <path
      d="M1.168 10.793a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L3.29 11.5l5.657-5.657A1 1 0 0 0 7.532 4.43l-6.364 6.364Zm13.645-.293H1.874v2h12.938v-2Z"
      fill="#A5A2B8"
    />
  </svg>
);

export default BorrowIcon;
