import React, { useState } from "react";
import cx from "classnames";
import Modal from "react-modal";
import { AppContext, UserContext } from "../../../../context";
import styles from "./index.module.scss";
import { useContext, useEffect } from "react";
import { SETTINGS_ENUM } from "../../../../enums/settingsEnum";
import ButtonRade from "../../../../components/RadeButtons";
import RadioButton from "../../../../components/RadioButtons/RadioButton";
import "./index.css";
import KYCConfirmPopUp from "../KYCConfirmPopUp/KYCConfirmPopUp";

const UserBankAccountDetails = ({
  updateFirebaseUserProfile,
  setKYCPageNumber,
  KYCPageNumber,
  firstName,
  lastName,
  email,
  phoneNumber,
  DOB,
  country,
  region,
  addressLineOne,
  addressLineTwo,
  city,
  zipCode,
  setErrorForID,
  idBackSide,
  setIdBackSide,
  setErrorForPancard,
  idFrontSide,
  setIdFrontSide,
  setErrorForAadhar,
  reSubmitKyc,
  setResubmitKyc,
  isFileUploaded,
  setIsFileUploaded,
  idBackSideFileType,
  setIdBackSideFileType,
  idFrontSideFileType,
  setIdFrontSideFileType,
  selectedFileName,
  setSelectedFileName,
  pancardHolding,
  setPancardHolding,
  aadharHolding,
  setAadharHolding,
  setErrorForAadharHold,
  setErrorForPancardHold,
  KycStatus,
  setKycStatus,
  userName,
  aadhaarHolding,
  selectedCountryCode,
  bankName,
  setBankName,
  bankAccountType,
  setBankAccountType,
  ABARoutingNumber,
  setABARoutingNumber,
  accountNumber,
  setAccountNumber,
}) => {
  const { account } = useContext(UserContext);

  const { isMobile } = useContext(AppContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPageNumber, setModalPageNumber] = useState("");
  const [inputErrors, setInputErrors] = useState({
    bankAccountType: "",
    accountNumber: "",
    ABARoutingNumber: "",
    bankName: "",
  }); //state to display error message
  const BankAccountType = [
    { value: "Checking", label: "Checking Account" },
    { value: "Savings", label: "Savings Account" },
  ];

  const [error, setError] = useState(false);

  const validateInputs = () => {
    let isValid = true;
    const errors = {};

    if (!bankAccountType) {
      errors.bankAccountType = "Please choose a bank account type";
      isValid = false;
    } else if (!accountNumber) {
      errors.accountNumber = "Please enter an account number";
      isValid = false;
    } else if (!ABARoutingNumber) {
      errors.ABARoutingNumber = "Please enter an ABA routing number";
      isValid = false;
    } else if (!bankName) {
      errors.bankName = "Please enter the bank name";
      isValid = false;
    }

    setInputErrors(errors);

    return isValid;
  };
  //Fetch user details from firebase

  const handleChangeBankName = (event) => {
    setBankName(event.target.value);
  };
  const handleChangeAccountNumber = (event) => {
    setAccountNumber(event.target.value);
  };
  const handleChangeABARoutingNumber = (event) => {
    setABARoutingNumber(event.target.value);
  };

  const handleSubmitCancelButton = () => {
    setKYCPageNumber(2);
  };
  const handleSubmitNextButton = () => {
    if (!validateInputs()) {
      return;
    }

    setModalOpen(true);
    setModalPageNumber(0);
  };
  const handleChangeGettingRadioInput = (e) => {
    setBankAccountType(e.target.value);
    setError(false);
  };
  const CardBody = () => (
    <>
      <div
        className={cx(styles.personalInformationMainDiv, {
          [styles.personalInformationMainDivWeb]: !isMobile,
          [styles.personalInformationMainDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.butttonContent, {
            [styles.butttonContentMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.contentText, {
              [styles.contentTextMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton1, {
                [styles.stepsButton1Mob]: isMobile,
              })}
            >
              1
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton2, {
                [styles.stepsButton2Mob]: isMobile,
              })}
            >
              2
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton3, {
                [styles.stepsButton3Mob]: isMobile,
              })}
            >
              3
            </div>
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton4, {
              [styles.stepsButton4Mob]: isMobile,
            })}
          >
            4
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton5, {
              [styles.stepsButton5Mob]: isMobile,
            })}
          >
            5
          </div>
        </div>
        <div
          className={cx(styles.step1Text, {
            [styles.step1TextWeb]: !isMobile,
            [styles.step1TextMob]: isMobile,
          })}
        >
          Step 5
        </div>
        <div
          className={cx(styles.personalInfo, {
            [styles.personalInfoWeb]: !isMobile,
            [styles.personalInfoMob]: isMobile,
          })}
        >
          Account Details
        </div>
        <div className={styles.radioButton}>
          <p className={styles.chooseYourBankACcountType}>
            Choose your bank account type
          </p>
          <RadioButton
            name="currency"
            value={bankAccountType}
            options={BankAccountType}
            onChangeFunc={handleChangeGettingRadioInput}
            error={error}
            padding="12px 0px 12px 10px"
            gap="51px"
            width="334px"
          />
        </div>
        <div
          className={cx(styles.inputNameDiv, {
            [styles.inputNameDivWeb]: !isMobile,
            [styles.inputNameDivMob]: isMobile,
          })}
        >
          {isMobile && (
            <div
              className={cx(styles.firstName, {
                [styles.firstNameWeb]: !isMobile,
                [styles.firstNameMob]: isMobile,
              })}
            >
              Account Number
            </div>
          )}
          <div className={styles.inputFirstNameDiv}>
            {!isMobile && (
              <div
                className={cx(styles.firstName, {
                  [styles.firstNameWeb]: !isMobile,
                  [styles.firstNameMob]: isMobile,
                })}
              >
                Account Number *
              </div>
            )}
            <div>
              <input
                key="name"
                type="text"
                autoFocus
                placeholder="Enter your account number"
                value={accountNumber}
                autoComplete="off"
                onChange={handleChangeAccountNumber}
                className={cx(styles.firstNameInput, {
                  [styles.firstNameInputWeb]: !isMobile,
                  [styles.firstNameInputMob]: isMobile,
                })}
              />
            </div>
          </div>
          {isMobile && (
            <div
              className={cx(styles.lastName, {
                [styles.lastNameWeb]: !isMobile,
                [styles.lastNameMob]: isMobile,
              })}
            >
              ABA Routing Number
            </div>
          )}
          <div className={styles.inputLastNameDiv}>
            {!isMobile && (
              <div
                className={cx(styles.lastName, {
                  [styles.lastNameWeb]: !isMobile,
                  [styles.lastNameMob]: isMobile,
                })}
              >
                ABA Routing Number *
              </div>
            )}
            <div>
              <input
                type="text"
                autoComplete="off"
                placeholder="Enter your ABA rrouting number"
                value={ABARoutingNumber}
                onChange={handleChangeABARoutingNumber}
                className={cx(styles.lastNameInput, {
                  [styles.lastNameInputWeb]: !isMobile,
                  [styles.lastNameInputMob]: isMobile,
                })}
              />
            </div>
          </div>
        </div>
        {isMobile && (
          <div
            className={cx(styles.DOB, {
              [styles.DOBWeb]: !isMobile,
              [styles.DOBMob]: isMobile,
            })}
          >
            Name of the Bank *
          </div>
        )}
        <div className={styles.inputBoxContainerEmail}>
          <div className={styles.emailDiv}>
            {!isMobile && (
              <div
                className={cx(styles.email, {
                  [styles.emailWeb]: !isMobile,
                  [styles.emailMob]: isMobile,
                })}
              >
                Name of the Bank *
              </div>
            )}
            <div>
              <input
                type="text"
                autoComplete="off"
                placeholder="Enter the name of the bank"
                value={bankName}
                onChange={handleChangeBankName}
                className={cx(styles.emailInput, {
                  [styles.emailInputWeb]: !isMobile,
                  [styles.emailInputMob]: isMobile,
                })}
              />
            </div>
          </div>
        </div>
        {inputErrors.bankAccountType && (
          <div className={styles.error}>{inputErrors.bankAccountType}</div>
        )}
        {inputErrors.accountNumber && (
          <div className={styles.error}>{inputErrors.accountNumber}</div>
        )}
        {inputErrors.ABARoutingNumber && (
          <div className={styles.error}>{inputErrors.ABARoutingNumber}</div>
        )}
        {inputErrors.bankName && (
          <div className={styles.error}>{inputErrors.bankName}</div>
        )}

        <div
          className={cx(styles.bottomButtonDiv, {
            [styles.bottomButtonDivWeb]: !isMobile,
            [styles.bottomButtonDivMob]: isMobile,
          })}
        >
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonWeb]: !isMobile,
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={handleSubmitCancelButton}
          >
            {SETTINGS_ENUM.backButton}
          </ButtonRade>
          <ButtonRade
            outline
            customStyling={cx(styles.nextButton, {
              [styles.nextButtonWeb]: !isMobile,
              [styles.nextButtonMob]: isMobile,
            })}
            onClick={handleSubmitNextButton}
          >
            {SETTINGS_ENUM.nextButton}
          </ButtonRade>
        </div>
      </div>
    </>
  );
  return (
    <>
      {CardBody()}
      <Modal
        isOpen={modalOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        ariaHideApp={false}
      >
        {/*pass these values as props in upcoming Modals */}
        {(() => {
          switch (modalPageNumber) {
            case 0:
              return (
                <KYCConfirmPopUp
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  modalPageNumber={modalPageNumber}
                  setModalPageNumber={setModalPageNumber}
                  setKYCPageNumber={setKYCPageNumber}
                  updateFirebaseUserProfile={updateFirebaseUserProfile}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phoneNumber={phoneNumber}
                  DOB={DOB}
                  country={country}
                  region={region}
                  addressLineOne={addressLineOne}
                  addressLineTwo={addressLineTwo}
                  city={city}
                  zipCode={zipCode}
                  idBackSide={idBackSide}
                  idFrontSide={idFrontSide}
                  pancardHolding={pancardHolding}
                  userName={userName}
                  aadhaarHolding={aadhaarHolding}
                  selectedCountryCode={selectedCountryCode}
                  bankName={bankName}
                  setBankName={setBankName}
                  bankAccountType={bankAccountType}
                  setBankAccountType={setBankAccountType}
                  ABARoutingNumber={ABARoutingNumber}
                  setABARoutingNumber={setABARoutingNumber}
                  accountNumber={accountNumber}
                  setAccountNumber={setAccountNumber}
                />
              );

            default:
              return null;
          }
        })()}
        {/*Opens up the appropriate modal based on the page number */}
      </Modal>
    </>
  );
};

export default UserBankAccountDetails;
