import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

const url = `https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${process.env.REACT_APP_gasPriceTest}`;
let interval;

function useGasPrice() {
  const [slowGasRate, setSlowGasRate] = useState(null);
  const [stdGasRate, setStdGasRate] = useState(null);
  const [fastGasRate, setFastGasRate] = useState(null);

  useEffect(() => {
    try {
      const fetchGasPrice = async () => {
        const gasPrice = await axios.get(url);
        setSlowGasRate(gasPrice.data.result.SafeGasPrice);
        setStdGasRate(gasPrice.data.result.ProposeGasPrice);
        setFastGasRate(gasPrice.data.result.FastGasPrice);
      };
      interval = setInterval(fetchGasPrice, 1000);
      return () => {
        clearInterval(interval);
      };
    } catch (e) {
      console.log("error", e);
    }
  }, []);
  if (slowGasRate && stdGasRate && fastGasRate) {
    return {
      slowGasRate: slowGasRate,
      stdGasRate: stdGasRate,
      fastGasRate: fastGasRate,
    };
  } else {
    return {
      slowGasRate: null,
      stdGasRate: null,
      fastGasRate: null,
    };
  }
}
export default useGasPrice;
