import cx from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import React, { useState } from "react";
import { BORROW_ENUM } from "../../../enums/borrowEnum";
import ButtonRade from "../../../components/RadeButtons";
import { AppContext } from "../../../context";
import Card from "../../../components/Card";
import { useContext } from "react";
import QuestionMark from "../../../assets/QuestionMarkWithButton.svg";
import BellIcon from "../../../assets/BellIcon.svg";
import NextIcon from "../../../assets/NextIcon";
import SelectBox from "../SelectBox/SelectBox";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
import { TOOL_TIP } from "../../../enums/homeEnum";
import styles from "./CalculateMonthlyPayment.module.scss";
// Passing props
const CalculateMonthlyPayment = (props) => {
  // Functions for tooltip
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.nests}
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.paymentTerms}
    </Tooltip>
  );
  // Working of the Back button
  const onClickBackButton = () => {
    props.setBorrowFlowPageNumber(1);
  };

  // On Selecting the Payment Term dropdown
  const handleChangePaymentTermSelection = () => {
    setErrorForLaonAndPaymentTerm(false);
    setPaymentTermError(false);
  };
  // On Selecting the Laon Term dropdown
  const handleChangeLoanTermButtonSelection = () => {
    setErrorForLaonAndPaymentTerm(false);
    setLoanTermError(false);
  };
  const dismissError = () => {
    setErrorForLaonAndPaymentTerm(false);
    setLoanTermError(false);
    setPaymentTermError(false);
  };
  // Setting setting for Loan Term dropdown
  const [loanTermError, setLoanTermError] = useState(null);
  // Setting setting for Payment Term dropdown
  const [paymentTermerror, setPaymentTermError] = useState(null);
  // Setting setting for bot dropdown
  const [errorForLoanAndPaymentTerm, setErrorForLaonAndPaymentTerm] =
    useState(null);
  // Setting state for checking whether the Loan Term dropdown is selected or not
  const [selectedLoanTerm, setSelectedLoanTerm] = useState(null);
  // Setting state for checking whether the Payment Term dropdown is selected or not
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null);
  // /Working of next button
  const onClickNextButton = () => {
    if (!selectedLoanTerm && !selectedPaymentTerm) {
      setErrorForLaonAndPaymentTerm(true);
    } else if (!selectedLoanTerm && selectedPaymentTerm) {
      setLoanTermError(true);
    } else if (!selectedPaymentTerm && selectedLoanTerm) {
      setPaymentTermError(true);
    } else if (selectedLoanTerm && selectedPaymentTerm) {
      props.setBorrowFlowPageNumber(3);
      props.setSelectedPaymentTerm(selectedPaymentTerm.value);
      props.setSelectedLoanTerm(selectedLoanTerm.value);
    }
  };
  const { isMobile } = useContext(AppContext);
  const CardTitle = () => (
    <>
      <div
        className={cx(styles.collateralizationheading, {
          [styles.collateralizationheadingMob]: isMobile,
        })}
      >
        {BORROW_ENUM.nest}
        <OverlayTrigger placement="right" overlay={renderTooltip1}>
          <img
            src={QuestionMark}
            alt="ToolTip"
            className={cx(styles.questionmarkInHeader, {
              [styles.questionmarkInHeaderMob]: isMobile,
            })}
            // Calling the tooltip
          ></img>
        </OverlayTrigger>
        {/* Tool tip */}
      </div>
    </>
  );
  const LoanTermOptions = [
    { value: 3600, label: "1 Hour" },
    { value: 2630000, label: "1 Month" },
    { value: 5260000, label: "2 Months" },
    { value: 15780000, label: "6 Months" },
    { value: 31536000, label: "12 Months" },
  ];
  const PaymentTermOptions = [
    { value: 900, label: "15 Minutes" },
    { value: 604800, label: "Weekly" },
    { value: 2630000, label: "Monthly" },
    {
      value: 5260000,
      label: "Every 2 months",
    },
    { value: 10520000, label: "Quarterly" },
  ];

  // function to filter Payment Term options based on the selected Loan Term
  const getFilteredPaymentTermOptions = () => {
    if (selectedLoanTerm) {
      if (selectedLoanTerm.value === 3600) {
        return PaymentTermOptions.filter((option) => option.value === 900);
      } else if (selectedLoanTerm.value === 2630000) {
        return PaymentTermOptions.filter(
          (option) => option.value === 604800 || option.value === 2630000
        );
      } else if (
        selectedLoanTerm.value === 5260000 ||
        selectedLoanTerm.value === 15780000
      ) {
        return PaymentTermOptions.filter(
          (option) =>
            option.value === 604800 ||
            option.value === 2630000 ||
            option.value === 5260000
        );
      } else if (selectedLoanTerm.value === 31536000) {
        return PaymentTermOptions;
      } else {
        return PaymentTermOptions; // Show all options by default
      }
    }
  };
  const CardBody = () => (
    <>
      <div>
        <div
          className={cx(styles.ButttonContent, {
            [styles.ButttonContentMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.contentText, {
              [styles.contentTextMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton1, {
                [styles.stepsButton1Mob]: isMobile,
              })}
            >
              1
            </div>
          </div>

          <div
            className={cx(styles.line, {
              [styles.lineMob]: isMobile,
            })}
          ></div>

          <div
            className={cx(styles.buyRUDButtonDiv, {
              [styles.buyRUDButtonDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton2, {
                [styles.stepsButton2Mob]: isMobile,
              })}
            >
              2
            </div>
          </div>

          <div
            className={cx(styles.line, {
              [styles.lineMob]: isMobile,
            })}
          ></div>

          <div
            className={cx(styles.buyRUDButtonDiv, {
              [styles.buyRUDButtonDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton3, {
                [styles.stepsButton3Mob]: isMobile,
              })}
            >
              3
            </div>
          </div>
        </div>
        <div
          className={cx(styles.step1, {
            [styles.step1Mob]: isMobile,
          })}
        >
          {BORROW_ENUM.step2}
        </div>
        <div
          className={cx(styles.step1SelectBorrow, {
            [styles.step1SelectBorrowMob]: isMobile,
          })}
        >
          {BORROW_ENUM.selectInStep2}

          <OverlayTrigger placement="right" overlay={renderTooltip2}>
            <img
              src={QuestionMark}
              alt="ToolTip"
              className={cx(styles.questionmark, {
                [styles.questionmarkMob]: isMobile,
              })}
              // Calling the tooltip
            ></img>
          </OverlayTrigger>
          {/* Tool tip */}
        </div>
        <div
          className={cx(styles.selectDiv, {
            [styles.selectDivMob]: isMobile,
          })}
        >
          <SelectBox
            placeHolder="Loan Term"
            options={LoanTermOptions}
            setSelectedValue={setSelectedLoanTerm}
            selectedValue={selectedLoanTerm}
            onChange={handleChangeLoanTermButtonSelection}
            errorDismissOnclick={dismissError}
          />
          <SelectBox
            placeHolder="Payment Term"
            options={getFilteredPaymentTermOptions()} // Use the filtered options
            setSelectedValue={setSelectedPaymentTerm}
            selectedValue={selectedPaymentTerm}
            onChange={handleChangePaymentTermSelection}
            errorDismissOnclick={dismissError}
          />
        </div>
        {/* Printing error message when options aren't choosen */}
        {errorForLoanAndPaymentTerm && (
          <ShakeError
            errorMessage={BORROW_ENUM.errorForLoanAndPaymentTerm}
            customStyling={cx(styles.alertSpan, {
              [styles.alertSpanMob]: isMobile,
            })}
          />
        )}
        {/* Printing error message when user haven't choose PaymentTerm option */}
        {paymentTermerror && (
          <ShakeError
            errorMessage={BORROW_ENUM.errorForPaymentTerm}
            customStyling={cx(styles.alertSpanLoanTerm, {
              [styles.alertSpanLoanTermMob]: isMobile,
            })}
          />
        )}
        {/* Printing error message when user haven't choose LoanTerm option */}
        {loanTermError && (
          <ShakeError
            errorMessage={BORROW_ENUM.errorForMonthlyPayment}
            customStyling={cx(styles.alertSpanPaymentTerm, {
              [styles.alertSpanPaymentTermMob]: isMobile,
            })}
          />
        )}
        <div
          className={cx(styles.bottomSectionButton, {
            [styles.bottomSectionButtonMob]: isMobile,
          })}
        >
          <ButtonRade
            outline
            customStyling={cx(styles.nextButton, {
              [styles.nextButtonMob]: isMobile,
            })}
            onClick={(e) => {
              onClickNextButton();
            }}
          >
            {BORROW_ENUM.nextButton} <NextIcon />
          </ButtonRade>
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={onClickBackButton}
          >
            {BORROW_ENUM.backButton}
          </ButtonRade>
        </div>
      </div>
    </>
  );
  return (
    <>
      {/* calling as a function to avoid flickering issue */}
      <Card titleComponent={<CardTitle />} children={CardBody()} />
    </>
  );
};

export default CalculateMonthlyPayment;
