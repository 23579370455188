import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";
import { AppContext, UserContext } from "../../../../context";
import { useContext, useState } from "react";
import { SETTINGS_ENUM } from "../../../../enums/settingsEnum";
import ButtonRade from "../../../../components/RadeButtons";
import SelectBox from "../../../borrow/SelectBox/SelectBox";
const PersonalInformationStep2 = ({
  setKYCPageNumber,

  addressLineOne,
  addressLineTwo,
  city,
  zipCode,
  setCountry,
  setRegion,
  setAddressLineOne,
  setAddressLineTwo,
  setCity,
  setZipCode,

  selectedCountry,
  setSelectedCountry,
  selectedRegion,
  setSelectedRegion,
}) => {
  const { isMobile } = useContext(AppContext);
  const [inputErrors, setInputErrors] = useState({
    country: "",
    region: "",
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    zipCode: "",
  }); //state that stores the errors message

  // On Selecting the Laon Term dropdown
  const dismissError = () => {};
  //Function that handles cancel button
  const handleSubmitCancelButton = () => {
    setKYCPageNumber(-1);
  };
  //select box options for country
  const countryOptions = [
    // North America
    { value: "USA", label: "United States" },
    { value: "Canada", label: "Canada" },

    // Add more North American countries as needed
  ];
  //select box options for region
  const countryRegionMapping = {
    "United States": [
      { value: "New York", label: "New York" },
      { value: "California", label: "California" },
      // Add regions for the United States
    ],
    Canada: [
      { value: "Ontario", label: "Ontario" },
      { value: "Quebec", label: "Quebec" },
    ],
    // Add mappings for other countries as needed
  };
  const handleRegionChange = (newRegion) => {
    setSelectedRegion(newRegion);
    setRegion(newRegion);
  };

  //Function that validates the input field
  const validateInputs = () => {
    let isValid = true;
    const errors = {};

    if (!selectedCountry) {
      errors.country = "Please select your country of Residence";
      isValid = false;
    } else if (!selectedRegion) {
      errors.region = "Please select your region/state";
      isValid = false;
    } else if (!addressLineOne) {
      errors.addressLineOne = "Please enter your address line 1";
      isValid = false;
    } else if (!addressLineTwo) {
      errors.addressLineTwo = "Please enter your address line 2";
      isValid = false;
    } else if (!city) {
      errors.city = "Please enter your city";
      isValid = false;
    } else if (!zipCode) {
      errors.zipCode = "Please enter your pincode/zipcode";
      isValid = false;
    }

    setInputErrors(errors);

    return isValid;
  };
  //Function that handles next button
  const handleSubmitNextButton = () => {
    if (!validateInputs()) {
      return;
    } else {
      setKYCPageNumber(1);
    }
  };

  const handleChangeCountryButtonSelection = (newCountry) => {
    setSelectedCountry(newCountry);
    setCountry(newCountry);
    // Clear the selected region when the country changes
    setSelectedRegion(null);
  };

  //Function that handle address line 1 input field
  const handleAddressLineOneChange = (event) => {
    setAddressLineOne(event.target.value);
  };
  //Function that handle address line 2 input field
  const handleAddressLineTwoChange = (event) => {
    setAddressLineTwo(event.target.value);
  };
  //Function that handle city input field
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };
  //Function that handle zip code input field
  const handleZipCodeChange = (event) => {
    const enteredZipCode = event.target.value; // Get the entered phone number

    // Remove any non-numeric characters from the entered phone number
    const numericZipCode = enteredZipCode.replace(/\D/g, "");

    // Ensure the phone number does not exceed 10 digits
    const truncatedZipCode = numericZipCode.slice(0, 6);

    // Update the phoneNumber state with the formatted phone number
    setZipCode(truncatedZipCode);
  };
  const CardBodyPersonalInformationStep2 = () => (
    <>
      <div
        className={cx(styles.personalInformationMainDiv, {
          [styles.personalInformationMainDivWeb]: !isMobile,
          [styles.personalInformationMainDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.butttonContent, {
            [styles.butttonContentMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.contentText, {
              [styles.contentTextMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton1, {
                [styles.stepsButton1Mob]: isMobile,
              })}
            >
              1
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton2, {
                [styles.stepsButton2Mob]: isMobile,
              })}
            >
              2
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton3, {
                [styles.stepsButton3Mob]: isMobile,
              })}
            >
              3
            </div>
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton4, {
              [styles.stepsButton4Mob]: isMobile,
            })}
          >
            4
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton5, {
              [styles.stepsButton5Mob]: isMobile,
            })}
          >
            5
          </div>
        </div>

        <div
          className={cx(styles.step1Text, {
            [styles.step1TextWeb]: !isMobile,
            [styles.step1TextMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.step2}
        </div>
        <div
          className={cx(styles.personalInfo, {
            [styles.personalInfoWeb]: !isMobile,
            [styles.personalInfoMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.personalInfo}
        </div>

        <div
          className={cx(styles.countryContainer, {
            [styles.countryContainerWeb]: !isMobile,
            [styles.countryContainerMob]: isMobile,
          })}
        >
          <SelectBox
            placeHolder="Select Country"
            options={countryOptions}
            setSelectedValue={setSelectedCountry}
            selectedValue={selectedCountry}
            onChange={handleChangeCountryButtonSelection}
            errorDismissOnclick={dismissError}
          />
          <SelectBox
            placeHolder="Select Region"
            options={countryRegionMapping[selectedCountry?.label] || []}
            setSelectedValue={setSelectedRegion}
            selectedValue={selectedRegion}
            onChange={handleRegionChange}
            errorDismissOnclick={dismissError}
          />
        </div>

        <div
          className={cx(styles.addressContainer, {
            [styles.addressContainerWeb]: !isMobile,
            [styles.addressContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.addressLineOneDiv, {
              [styles.addressLineOneDivWeb]: !isMobile,
              [styles.addressLineOneDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.addressLineOnetext, {
                [styles.addressLineOnetextWeb]: !isMobile,
                [styles.addressLineOnetextMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.addressLineOne}
            </div>
            <div>
              <input
                type="text"
                value={addressLineOne}
                autoComplete={false}
                onChange={handleAddressLineOneChange}
                placeholder="Enter your country of Residence"
                className={cx(styles.addressLineOneInput, {
                  [styles.addressLineOneInputWeb]: !isMobile,
                  [styles.addressLineOneInputMob]: isMobile,
                })}
              />
            </div>
          </div>
          <div
            className={cx(styles.addressLineTwoDiv, {
              [styles.addressLineTwoDivWeb]: !isMobile,
              [styles.addressLineTwoDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.addressLineTwotext, {
                [styles.addressLineTwotextWeb]: !isMobile,
                [styles.addressLineTwotextMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.addressLineTwo}
            </div>
            <div>
              <input
                type="text"
                autoComplete={false}
                value={addressLineTwo}
                onChange={handleAddressLineTwoChange}
                className={cx(styles.addressLineTwoInput, {
                  [styles.addressLineTwoInputWeb]: !isMobile,
                  [styles.addressLineTwoInputMob]: isMobile,
                })}
              />
            </div>
          </div>
        </div>
        <div
          className={cx(styles.cityPinCodeContainer, {
            [styles.cityPinCodeContainerWeb]: !isMobile,
            [styles.cityPinCodeContainerMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.cityDiv, {
              [styles.cityDivWeb]: !isMobile,
              [styles.cityDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.cityText, {
                [styles.cityTextWeb]: !isMobile,
                [styles.cityTextMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.city}
            </div>
            <div>
              <input
                type="text"
                value={city}
                autoComplete={false}
                onChange={handleCityChange}
                className={cx(styles.cityInput, {
                  [styles.cityInputWeb]: !isMobile,
                  [styles.cityInputMob]: isMobile,
                })}
              />
            </div>
          </div>
          <div
            className={cx(styles.zipCodeDiv, {
              [styles.zipCodeDivWeb]: !isMobile,
              [styles.zipCodeDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.zipCodeText, {
                [styles.zipCodeTextWeb]: !isMobile,
                [styles.zipCodeTextMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.zipCode}
            </div>
            <div>
              <input
                type="number"
                value={zipCode}
                autoComplete={false}
                onChange={handleZipCodeChange}
                className={cx(styles.zipCodeInput, {
                  [styles.zipCodeInputWeb]: !isMobile,
                  [styles.zipCodeInputMob]: isMobile,
                })}
              />
            </div>
          </div>
        </div>
        {inputErrors.country && (
          <div className={styles.error}>{inputErrors.country}</div>
        )}
        {inputErrors.region && (
          <div className={styles.error}>{inputErrors.region}</div>
        )}
        {inputErrors.addressLineOne && (
          <div className={styles.error}>{inputErrors.addressLineOne}</div>
        )}
        {inputErrors.addressLineTwo && (
          <div className={styles.error}>{inputErrors.addressLineTwo}</div>
        )}
        {inputErrors.city && (
          <div className={styles.error}>{inputErrors.city}</div>
        )}
        {inputErrors.zipCode && (
          <div className={styles.error}>{inputErrors.zipCode}</div>
        )}
        <div
          className={cx(styles.bottomButtonDiv, {
            [styles.bottomButtonDivWeb]: !isMobile,
            [styles.bottomButtonDivMob]: isMobile,
          })}
        >
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonWeb]: !isMobile,
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={handleSubmitCancelButton}
          >
            {SETTINGS_ENUM.backButton}
          </ButtonRade>
          <ButtonRade
            outline
            customStyling={cx(styles.nextButton, {
              [styles.nextButtonWeb]: !isMobile,
              [styles.nextButtonMob]: isMobile,
            })}
            onClick={handleSubmitNextButton}
          >
            {SETTINGS_ENUM.nextButton}
          </ButtonRade>
        </div>
      </div>
    </>
  );
  return <div>{CardBodyPersonalInformationStep2()}</div>;
};

export default PersonalInformationStep2;
