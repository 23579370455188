const { utils } = require("ethers");
const ERC20PermitSignature = async (
  owner,
  spender,
  amount,
  contract,
  provider
) => {
  try {
    const transactionDeadline = Date.now() + 20 * 60;
    const nonce = await contract.nonces(owner);
    const contractName = await contract.name();
    const EIP712Domain = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];
    const domain = {
      name: contractName,
      version: "1",
      chainId: provider.network.chainId,
      verifyingContract: contract.address,
    };
    const Permit = [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ];
    const message = {
      owner,
      spender,
      value: amount.toString(),
      nonce: nonce.toHexString(),
      deadline: transactionDeadline,
    };
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit,
      },
      domain,
      primaryType: "Permit",
      message,
    });

    const signature = await provider.send("eth_signTypedData_v4", [
      owner,
      data,
    ]);
    const signData = utils.splitSignature(signature);
    const { r, s, v } = signData;
    return {
      r,
      s,
      v,
      transactionDeadline,
      isRejected: false,
    };
  } catch (e) {
    console.log("error", e);
    return {
      r: undefined,
      s: undefined,
      v: undefined,
      transactionDeadline: undefined,
      isRejected: true,
    };
  }
};
export default ERC20PermitSignature;
