export const RETURN_USER_STAKING = {
  investmentSummary: "Investment Summary",
  returnUserCardTitle: "My Investment",
  unStakeButton: "Unstake",
  totalStakedRUD: "Your total Staked RUD",
  currentAPY: "Current APY",
  accruedEarnings: "Accrued rewards",
  stakedDate: "Staked Date",
  nextYieldReturnDate: "Next Yield return date",
  totalStakedRUDValue: "$ 2500",
  currentAPYValue: "~2.7%",
  accruedEarningsValue: "$470 RUD",
  stakedDateValue: "11-12-2022",
  nextYieldReturnDateValue: "03-03-2023",
  confirmMessage: "How much you want to unstake from your investment?",
  cancel: "Cancel",
  unStake: "Unstake",
  acccout: "Account",
  stakedRUD: "Staked RUD",
  vestingSchedule: "Vesting Schedule ",
  accuredEarnings: "Accr. Rewards",
  maturityDate: "Maturity Date",
  createNewStakingAccountText:
    "Create new staking accounts to put more liquidity into the the RADE Pool.",
  newStakingAccount: "New Staking Account",
  yourStakingIsStillInProgress: "Your Stake is still under vesting schedule.",
  youHaveToWaitUntilTheVestingPeriod:
    "You have to wait until the vesting period is over to unstake your investment.",
  status: "Status",
  details: "Details",
  noOfDaysRemaining: "Remaining time (Vesting)",
  lockDuration: "Lock Duration",
  stakingRewards: "Staking Rewards",
  accruedRewards: "Actual Accrued Rewards",
  mintCoin: "Mint coin",
  acccouts: "Accounts",
  claimRewardButton: "Claim Actual Rewards",
  updateRewardButton: "Update Actual Rewards",
};
