import React, { useState, useEffect } from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../ProceedBuyRudUsingETH/index.module.scss";
import ButtonRade from "../../../components/RadeButtons";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
import SlippageTolerance from "../SlippageTolerance/SlippageTolerance";
import ToggleUserEnteringOption from "../ToggleUserEnteringOption/ToggleUserEnteringOption";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
import Skeleton from "react-loading-skeleton";
import "../../../components/ReactLoading/reactLoading.css";
import { RudContext } from "../RUDContext";
import useUniSwapQuote from "../../../Hooks/useETHPriceUniswap/useUniSwapQuote";
let currAddress = null;
// Parent function to handle the Buy RUD using ETH
const ProceedBuyRudUsingETH = (
  {
    setConfirmTransaction,
    setIsError,
    setErrorMessage,
    setWaitingConfirmation,
    setModalOpen,
  },
  interval = null
) => {
  const { isMobile } = useContext(AppContext);
  const { setUserModeSelection, setCanProceed } = useContext(RudContext);
  //State to set the paying ETH amount by the user.
  const [eth, setEth] = useState("");
  //State to set the required RUD amount by the user
  const [rud, setRud] = useState("");
  const [priceFeedAddress, setPriceFeedAddress] = useState(null);
  //timer to check each 10sec on which metamask network is connected
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { priceFeedAddress } = getNetworkDetails(chainID);
        setPriceFeedAddress(priceFeedAddress);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };
    if (priceFeedAddress !== currAddress) {
      clearInterval(interval);
      currAddress = priceFeedAddress;
    }

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
      currAddress = null;
    };
  }, [priceFeedAddress]);
  const WETH_UNISWAP_PRICE = useUniSwapQuote();
  //state to set the user selection based on they enter Pay Eth or Get Rud
  const [userSelectionMode, setUserSelectionMode] = useState(
    RUD_ENUM.ethEnterMode
  );
  //State to set error message before proceed.
  const [errorForProceed, setErrorForProceed] = useState(false);
  const [errorForInvalidInput, setErrorForInvalidInput] = useState(false);
  //state to set the upcoming component
  const [showSlippageTolerance, setShowSlippageTolerance] = useState(false);
  //function to set the user entering ETH amount and it's equivalent value in RUD
  const handleEthPay = (e) => {
    const amount = e.target.value;
    setEth(amount);
    setRud(amount * Number(WETH_UNISWAP_PRICE));
    setErrorForProceed(false);
  };
  //function to set the required RUD amount by the user and it's equivalent value in ETH
  const handleRudGet = (e) => {
    const amount = e.target.value;
    setRud(amount);
    setEth(amount * (1 / Number(WETH_UNISWAP_PRICE)));
    setErrorForProceed(false);
  };

  // Function to activate the proceed Button according to the state variable value
  const handleSubmitProceedButton = async () => {
    if (!eth || !rud) {
      setErrorForProceed(true);
      setShowSlippageTolerance(false);
    } else if (eth < 0 || rud < 0) {
      setErrorForInvalidInput(true);
      setShowSlippageTolerance(false);
    } else {
      setErrorForProceed(false);
      setErrorForInvalidInput(false);
      setShowSlippageTolerance(true);
    }
  };
  const handleSubmitBackButton = () => {
    setUserModeSelection(null);
    setCanProceed(false);
  };
  const CardBody = () => (
    <div className={styles.rudBuyMainDiv}>
      {WETH_UNISWAP_PRICE ? (
        <div
          className={cx(styles.getRUDAmountEnter, {
            [styles.getRUDAmountEnterMob]: isMobile,
          })}
        >
          {RUD_ENUM.getRUDAmount}
        </div>
      ) : (
        <div
          className={cx(styles.getRUDAmountEnter, {
            [styles.getRUDAmountEnterMob]: isMobile,
          })}
        >
          {/* Skeleton loading if the value ETH is fetching */}
          <Skeleton
            style={{
              display: "block",
              backgroundColor: "rgb(25, 39, 59)",
              width: "91%",
              height: "20px",
            }}
          />
        </div>
      )}
      {WETH_UNISWAP_PRICE ? (
        <ToggleUserEnteringOption
          eth={eth}
          handleEthPay={handleEthPay}
          rud={rud}
          handleRudGet={handleRudGet}
          userSelectionMode={userSelectionMode}
          setUserSelectionMode={setUserSelectionMode}
          WETH_UNISWAP_PRICE={WETH_UNISWAP_PRICE}
          setEth={setEth}
          setRud={setRud}
        />
      ) : (
        <>
          {/* Skeleton loading if the value ETH is fetching */}
          <Skeleton
            style={{
              display: "block",
              backgroundColor: "rgb(25, 39, 59)",
              width: "91%",
              height: "52px",
              marginLeft: "32px",
            }}
          />
          <Skeleton
            style={{
              display: "block",
              backgroundColor: "rgb(25, 39, 59)",
              width: "10%",
              paddingTop: "22px",
              height: "33px",
              marginLeft: "42%",
            }}
          />
          <Skeleton
            style={{
              display: "block",
              backgroundColor: "rgb(25, 39, 59)",
              width: "91%",
              paddingTop: "22px",
              height: "52px",
              marginLeft: "32px",
            }}
          />
        </>
      )}
      {/*error message before Swap */}
      {errorForProceed && (
        <ShakeError
          errorMessage={
            userSelectionMode === RUD_ENUM.ethEnterMode
              ? RUD_ENUM.swapError_BuyFlow
              : RUD_ENUM.proceedError_SellFlow
          }
          customStyling={cx(styles.alertBeforeSwap, {
            [styles.alertBeforeSwapMob]: isMobile,
          })}
        />
      )}
      {errorForInvalidInput && (
        <ShakeError
          errorMessage={RUD_ENUM.errorInvalidInput}
          customStyling={cx(styles.alertBeforeSwap, {
            [styles.alertBeforeSwapMob]: isMobile,
          })}
        />
      )}
      <div
        className={cx(styles.proceedButtonDiv, {
          [styles.proceedButtonDivMob]: isMobile,
        })}
      >
        {/* Custom styled back button */}
        <ButtonRade
          outline
          customStyling={cx(styles.backButton, {
            [styles.backButtonMob]: isMobile,
          })}
          onClick={handleSubmitBackButton}
        >
          {RUD_ENUM.backButton}
        </ButtonRade>
        {/* Custom styled button for Swap */}
        <ButtonRade
          outline
          customStyling={cx(styles.proceedButton, {
            [styles.proceedButtonMob]: isMobile,
          })}
          onClick={() => {
            handleSubmitProceedButton();
          }}
        >
          {RUD_ENUM.proceed_button}

          {/* <ProceedIcon /> */}
        </ButtonRade>
      </div>
    </div>
  );

  return (
    <div>
      {showSlippageTolerance ? (
        <SlippageTolerance
          rud={rud}
          eth={eth}
          userSelectionMode={userSelectionMode}
          setConfirmTransaction={setConfirmTransaction}
          setErrorMessage={setErrorMessage}
          setIsError={setIsError}
          setWaitingConfirmation={setWaitingConfirmation}
          setModalOpen={setModalOpen}
        />
      ) : (
        // calling as a function to avoid flickering issue
        CardBody()
      )}
    </div>
  );
};
export default ProceedBuyRudUsingETH;
