import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import { AppContext, UserContext } from "../../../context";
import { useContext } from "react";
import { LIQUIDATE_ENUM } from "../../../enums/liquidateEnum";
import ButtonRade from "../../../components/RadeButtons";
import RudBorrowContract from "../../../../src/artifacts/Contracts/stablecoin/Borrow.sol/Loan.json";
import { ethers } from "ethers";
import useBorrowedUserDetails from "../../../Hooks/useBorrowedUserDetails/useBorrowedUserDetails";
import { formatUserLiquidation } from "../PaymentDefault/borrowedUserPaymentDefaultDetails";
import Loading from "../Loading";
import getNetworkDetails from "../../../components/NetworkDetails/NetworkDetails";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { TOOL_TIP } from "../../../enums/homeEnum";
const MAX_DECIMAL_PLACE = 2;
const ETHER_CONVERSION = 10 ** 18;
const UserCollateralRatio = (interval = null) => {
  const { isMobile } = useContext(AppContext);
  const { account } = useContext(UserContext);
  const { userDetails, loading } = useBorrowedUserDetails(account);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [formattedData, setFormattedData] = useState(null);
  const [liquidationRatio, setliquidationRatio] = useState(null);
  const [collateralRatio, setCollateralRatio] = useState(null);
  const [tokenName, setTokenName] = useState(null);
  const renderTooltipLiquidateButton = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.liquidateMyNestTooltip}
    </Tooltip>
  );
  useEffect(() => {
    const connectedNetwork = async () => {
      try {
        const chainID = await window.ethereum.request({
          method: "net_version",
        });
        const { tokenName } = getNetworkDetails(chainID);

        setTokenName(tokenName);
      } catch (error) {
        // Handle the error here, e.g., log it or show a user-friendly message
        console.error("Error while fetching network details:", error);
      }
    };

    interval = setInterval(connectedNetwork, 10000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [tokenName]);
  //Staking Rud Contract
  const borrowContract = new ethers.Contract(
    RudBorrowContract.address,
    RudBorrowContract.abi,
    signer
  );
  useEffect(() => {
    if (userDetails) {
      setFormattedData(formatUserLiquidation(userDetails));
    } else {
      console.log("loading");
    }
  }, [userDetails]);

  const getCollateralRatio = async () => {
    if (borrowContract) {
      const collateralizationRatio = await borrowContract.collateralRatio();
      setCollateralRatio(collateralizationRatio);
    } else {
      setCollateralRatio(undefined);
    }
  };
  useEffect(() => {
    getCollateralRatio();
  }, []);

  const getLiquidationRatio = async () => {
    if (borrowContract) {
      const liqRatio = await borrowContract.liquidationRatio();
      setliquidationRatio(liqRatio);
    } else {
      setliquidationRatio(undefined);
    }
  };
  useEffect(() => {
    getLiquidationRatio();
  }, []);

  const CardBody = () => (
    <>
      <div className={styles.liquidationTable}>
        {formattedData.some(
          (item) =>
            item.collRatio < Number(collateralRatio).toString() &&
            item.collRatio > 0
        ) ? (
          <table className={styles.tableHeading}>
            <thead>
              <tr
                className={cx(styles.borrowTableHeading, {
                  [styles.borrowTableHeadingWeb]: !isMobile,
                  [styles.borrowTableHeadingMob]: isMobile,
                })}
              >
                <th>
                  <div>{LIQUIDATE_ENUM.borrowAndColl}</div>
                  <div className={styles.unit}>RUD/{tokenName}</div>
                </th>
                <th>{LIQUIDATE_ENUM.collateralRatio}</th>
                <th>{LIQUIDATE_ENUM.revitalizeFactor}</th>
                <th>
                  <div>{LIQUIDATE_ENUM.liquidationAmount}</div>
                  <div className={styles.unit}>{tokenName}</div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {formattedData.map((item, index) => (
                <tr className={styles.borrowTableNest1}>
                  <td>
                    {Number(item.borrowedRUD).toFixed(MAX_DECIMAL_PLACE)}/
                    {Number(item.collateralAmount / ETHER_CONVERSION).toFixed(
                      MAX_DECIMAL_PLACE
                    )}
                  </td>
                  <td
                    className={cx(styles.collateralRatio, {
                      [styles.mediumHealth]:
                        item.collRatio > Number(liquidationRatio).toString(),
                      [styles.riskyCollateral]:
                        item.collRatio < Number(liquidationRatio).toString(),
                    })}
                  >
                    {item.collRatio}%
                  </td>
                  <td>{Number(collateralRatio).toString() - item.collRatio}</td>
                  <td>
                    {Number(item.liquidationAmount / ETHER_CONVERSION).toFixed(
                      MAX_DECIMAL_PLACE
                    )}
                  </td>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltipLiquidateButton}
                  >
                    <td>
                      <ButtonRade
                        outline
                        customStyling={cx(styles.liquidateButton, {
                          [styles.riskyCollateralButton]:
                            item.collRatio <
                            Number(liquidationRatio).toString(),
                        })}
                      >
                        {LIQUIDATE_ENUM.liquidateButton}
                      </ButtonRade>
                    </td>
                  </OverlayTrigger>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.liquidationRatioContent}>
            You have no risky nests at the moment.
          </div>
        )}
      </div>
    </>
  );
  if (loading || !formattedData || !tokenName) {
    return <Loading />;
  }
  return (
    <div
      className={cx(styles.returnUsermainDiv, {
        [styles.returnUsermainDivWeb]: !isMobile,
        [styles.returnUsermainDivMob]: isMobile,
      })}
    >
      {/* calling as a function to avoid flickering issue */}
      {CardBody()}
    </div>
  );
};

export default UserCollateralRatio;
