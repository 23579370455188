import styles from "./index.module.scss";
import cx from "classnames";
import { useContext, useState } from "react";
import { AppContext } from "../../../../context";
import { BORROW_ENUM } from "../../../../enums/borrowEnum";
import ButtonRade from "../../../../components/RadeButtons";
import Modal from "react-modal";
import PayConfirmPopUp from "../PaymentConfirmPopUp/PaymentConfirmPopUp";
import ShakeError from "../../../../components/ShakingErrorMessages/ShakingErrorMessages";
import FullTermPayment from "../FullTermPaymentConfirmPopUp/FullTermPaymentConfirmPopUp";
import GetSignStaking from "../../../stake/GetSignFromUser/GetSignFromUserModal";
import SignatureRequestSuccessfulModal from "../../../stake/SignatureRequestSuccessful/SignatureRequestSuccessfulModal";
import PaymentSuccessfulModal from "../PaymentConfirmModal/PaymentConfirmModal";
import Radio from "../../../../components/RadioButtons/RadioButton";
import Card from "../../../../components/Card";
import FailedSignatureRequest from "../../../../components/FailedSignatureRequest/FailedSignatureRequest";
import WaitingConfirmation from "../../WaitingConfirmationModal/WaitingConfirmationModal";
const ETHER_CONVERSION = 10 ** 18;
const MAX_DECIMAL_PLACE = 2;
const ReturnUserPaySection = ({
  setReturnUserBorrowFlowPage,
  nestCount,
  borrowedAmount,
  loanBalance,
  collateralAmount,
  nextPaymentAmount,
  paymentSchedule,
  nestId,
  toggleBorrowDetails,
  setShowBorrowMain,
}) => {
  const { isMobile } = useContext(AppContext);
  // state for setting the page nu,ber for modal
  const [modalPageNumber, setModalPageNumber] = useState(null);
  const [radioButtonInput, setRadioButtonInput] = useState(false);
  const [modelIsOpen, setModalOpen] = useState(false);
  const [errorforProceed, setErrorforProceed] = useState(null);
  // state for saving the permitSignature parameters
  const [signatureParameters, setSignatureParameters] = useState(false);

  // Payment Options
  const nextPaymentValue = Number(nextPaymentAmount / ETHER_CONVERSION).toFixed(
    MAX_DECIMAL_PLACE
  );

  const paymentOptionOne = [
    { value: nextPaymentValue, label: nextPaymentValue },
  ];
  const paymentOptionTwo = [
    {
      value: Number(loanBalance).toFixed(MAX_DECIMAL_PLACE),
      label: Number(loanBalance).toFixed(MAX_DECIMAL_PLACE),
    },
  ];
  const collateralETH = collateralAmount / ETHER_CONVERSION;
  const handleOnClickRadioButton = (e) => {
    setRadioButtonInput(e.target.value);
    setErrorforProceed(false);
  };
  const handleOnClickCloseButton = () => {
    setReturnUserBorrowFlowPage(0);
  };
  const onClickConfirmButton = () => {
    if (!radioButtonInput) {
      setErrorforProceed(true);
    } else {
      setModalOpen(true);
      setModalPageNumber(0);
    }
  };
  const getUserEnteredRepayingAmount = (e) => {
    const amount = e.target.value;
    setRadioButtonInput(amount);
    setErrorforProceed(false);
  };
  const LoanBalance = () => {
    return (
      <>
        <div className={styles.nestDetails}>
          <div className={styles.nestDetailsHeading}>
            {BORROW_ENUM.loanBalance}
          </div>
          <tr className={styles.nestDetailsValue}>
            {Number(loanBalance).toFixed(MAX_DECIMAL_PLACE)}
          </tr>
        </div>
      </>
    );
  };
  const PaymentSchedule = () => {
    return (
      <>
        <div className={styles.nestDetails}>
          <div className={styles.nestDetailsHeading}>
            {BORROW_ENUM.paymentSchedule}
          </div>
          <tr className={styles.nestDetailsValue}>{paymentSchedule}</tr>
        </div>
      </>
    );
  };
  const CardTitle = () => (
    <div
      className={cx(styles.titleDiv, {
        [styles.titleDivWeb]: !isMobile,
        [styles.titleDivMob]: isMobile,
      })}
    >
      {BORROW_ENUM.nestPayment}
      <span className={styles.nestCount}>{nestCount} </span>
    </div>
  );

  const CardBody = () => (
    <div
      className={cx(styles.bodyContainer, {
        [styles.bodyContainerWeb]: !isMobile,
        [styles.bodyContainerMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.loanDetails, {
          [styles.loanDetailsWeb]: !isMobile,
          [styles.loanDetailsMob]: isMobile,
        })}
      >
        {BORROW_ENUM.loanDetails}
      </div>

      <div
        className={cx(styles.nestTableHeading, {
          [styles.nestTableHeadingWeb]: !isMobile,
          [styles.nestTableHeadingMob]: isMobile,
        })}
      >
        <div className={styles.nestDetails}>
          <div className={styles.nestDetailsHeading}>
            {BORROW_ENUM.borrowedRUD}
          </div>
          <tr className={styles.nestDetailsValue}>{borrowedAmount}</tr>
        </div>
        <div className={styles.nestDetails}>
          <div className={styles.nestDetailsHeading}>
            {BORROW_ENUM.collateral}
          </div>
          <tr className={styles.nestDetailsValue}>{collateralETH}ETH</tr>
        </div>
      </div>

      <div className={styles.loanBalancePaymentSchedule}>
        <LoanBalance />
        <PaymentSchedule />
      </div>
      <div
        className={cx(styles.selectPaymentTermDiv, {
          [styles.selectPaymentTermDivWeb]: !isMobile,
          [styles.selectPaymentTermDivMob]: isMobile,
        })}
      >
        {BORROW_ENUM.selectPaymentTerm}
        <div
          className={cx(styles.radioButtonDiv, {
            [styles.radioButtonDivMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.radioButtonNextPayment, {
              [styles.radioButtonNextPaymentMob]: isMobile,
            })}
          >
            <Radio
              name="paymentOptionOne"
              value={radioButtonInput}
              options={paymentOptionOne}
              onChangeFunc={handleOnClickRadioButton}
            />
          </div>
          <div
            className={cx(styles.radioButtonFullPayment, {
              [styles.radioButtonFullPaymentMob]: isMobile,
            })}
          >
            <Radio
              name="paymentOptionTwo"
              value={radioButtonInput}
              options={paymentOptionTwo}
              onChangeFunc={handleOnClickRadioButton}
            />
          </div>
        </div>
      </div>

      {!isMobile && (
        <div className={styles.paymentOptions}>
          <div>{BORROW_ENUM.nextPayment}</div>
          <div>{BORROW_ENUM.fullLaonBalance}</div>
        </div>
      )}
      <div
        className={cx(styles.repayAmountDiv, {
          [styles.repayAmountDivMob]: isMobile,
        })}
      >
        <div className={styles.repayAmount}>
          <div
            className={cx(styles.repayingAmount, {
              [styles.repayingAmountMob]: isMobile,
            })}
          >
            {BORROW_ENUM.repayingAmount}
          </div>
        </div>
        <div
          className={cx(styles.repayingValue, {
            [styles.repayingValueMob]: isMobile,
          })}
        >
          <input
            type="number"
            name="EthPay"
            value={radioButtonInput}
            onChange={getUserEnteredRepayingAmount}
            onKeyDown={(e) => {
              if (e.key === "-") {
                e.preventDefault();
              }
            }}
            placeholder="0.0"
            autoFocus
            autoComplete="off"
            className={cx(styles.inputAmount, {
              [styles.inputAmountMob]: isMobile,
            })}
          ></input>
          <div
            className={cx(styles.payingCrypto, {
              [styles.payingCryptoMob]: isMobile,
            })}
          >
            RUD
          </div>
        </div>
      </div>
      {errorforProceed && (
        <ShakeError
          errorMessage={BORROW_ENUM.selectPaymentTermError}
          customStyling={styles.errorMessage}
        />
      )}
      <div
        className={cx(styles.bottomButtonContainer, {
          [styles.bottomButtonContainerWeb]: !isMobile,
          [styles.bottomButtonContainerMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonWeb]: !isMobile,
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleOnClickCloseButton}
        >
          {BORROW_ENUM.cancelButton}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={cx(styles.confirmButton, {
            [styles.confirmButtonWeb]: !isMobile,
            [styles.confirmButtonMob]: isMobile,
          })}
          onClick={onClickConfirmButton}
        >
          {BORROW_ENUM.confirmButton}
        </ButtonRade>
      </div>
    </div>
  );

  return (
    <>
      <div
        className={cx(styles.nestDetailsMainDiv, {
          [styles.nestDetailsMainDivWeb]: !isMobile,
          [styles.nestDetailsMainDivMob]: isMobile,
        })}
      >
        {/* calling as a function to avoid flickering issue */}
        <Card titleComponent={<CardTitle />} children={CardBody()} />
        <Modal
          isOpen={modelIsOpen}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          ariaHideApp={false}
        >
          {/*pass these values as props in upcoming Modals */}
          {(() => {
            switch (modalPageNumber) {
              case 0:
                return (
                  <PayConfirmPopUp
                    loanBalance={loanBalance}
                    nextPaymentAmount={nextPaymentValue}
                    radioButtonInput={radioButtonInput}
                    setModalOpen={setModalOpen}
                    modalPageNumber={modalPageNumber}
                    setModalPageNumber={setModalPageNumber}
                    nestId={nestId}
                    signatureParameters={signatureParameters}
                    setSignatureParameters={setSignatureParameters}
                  />
                );
              case 1:
                return <GetSignStaking />;
              case 2:
                return <SignatureRequestSuccessfulModal />;

              case 3:
                return (
                  <FullTermPayment
                    setModalOpen={setModalOpen}
                    collateralAmount={collateralETH}
                    setShowBorrowMain={setShowBorrowMain}
                  />
                );
              case 4:
                return (
                  <PaymentSuccessfulModal
                    setModalOpen={setModalOpen}
                    toggleBorrowDetails={toggleBorrowDetails}
                    nestId={nestId}
                    nextPaymentAmount={nextPaymentValue}
                  />
                );
              case 5:
                return (
                  <FailedSignatureRequest
                    setModalOpen={setModalOpen}
                    setModalPageNumber={setModalPageNumber}
                  />
                );
              case 6:
                return <WaitingConfirmation />;
              default:
                return null;
            }
          })()}
          {/*Opens up the appropriate modal based on the page number */}
        </Modal>
      </div>
    </>
  );
};

export default ReturnUserPaySection;
