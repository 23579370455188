import React, { useContext, useState } from "react";
import cx from "classnames";
import styles from "./index.module.scss";
import { AppContext } from "../../../../context";
import { AVATAR_MAIN } from "../../../../enums/avatar";
import Skeleton from "react-loading-skeleton";
import "../../../../components/ReactLoading/reactLoading.css";
const AvatarList = (props) => {
  const { isMobile } = useContext(AppContext);

  const [selectedAvatar, setSelectedAvatar] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const handleClickAvatar = (e, item) => {
    props.setNewUserInfo(e.target.src);
    setSelectedAvatar(item);
    props.setErrorForAttach(false);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  return (
    <>
      <div
        className={cx(styles.avatarList, {
          [styles.avatarListWeb]: !isMobile,
          [styles.avatarListMob]: isMobile,
        })}
      >
        <div className={styles.avatarListMain}>
          {AVATAR_MAIN.map((item, index) => (
            <div className={styles.avatarList} key={`menu-${index}`}>
              {imageLoading && (
                <Skeleton
                  width={83}
                  height={83}
                  circle={true}
                  style={{
                    display: "block",
                    backgroundColor: "rgb(25, 39, 59)",
                  }}
                />
              )}

              <img
                src={item.image}
                onClick={(e) => {
                  handleClickAvatar(e, item);
                }}
                alt="Avatar"
                style={{
                  border: selectedAvatar === item ? "2px solid #00cdac" : "",
                }}
                className={styles.avatarIcon}
                onLoad={handleImageLoad}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AvatarList;
