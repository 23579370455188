import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss";

const Slider = ({ minValue, maxValue, onChange }) => {
  const [value, setValue] = useState(minValue);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const sliderRef = useRef(null);

  // Function to calculate the position of the thumb based on the current value
  const updateThumb = () => {
    const percent = ((value - minValue) / (maxValue - minValue)) * 100;
    return { left: `calc(${percent}% - 10px)` }; // Adjusting for the thumb size (20px / 2 = 10px)
  };

  // Handle the dragging of the thumb
  const handleDrag = (e) => {
    const rect = sliderRef.current.getBoundingClientRect();
    const percent = ((e.clientX - rect.left) / rect.width) * 100;

    // Ensure the value stays within the range
    const newValue = Math.max(
      minValue,
      Math.min(maxValue, minValue + (percent / 100) * (maxValue - minValue))
    );

    setValue(newValue);
    setTooltipPosition(newValue);

    // Pass the updated value to the parent component
    onChange(newValue);
  };

  // Handle mouse down on the thumb
  const handleMouseDown = (e) => {
    // Prevent default behavior to avoid issues with drag and drop
    e.preventDefault();
    // Attach mousemove and mouseup listeners on the document
    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", handleMouseUp);
  };

  // Handle mouse up after dragging
  const handleMouseUp = () => {
    // Detach mousemove and mouseup listeners on the document
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // Handle mouse leave to hide the tooltip
  const handleMouseLeave = () => {
    setTooltipPosition(null);
  };

  // Cleanup event listeners on component unmount
  useEffect(() => {
    return () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <div className={styles.slidebarContainer}>
      <div
        className={styles.slidebar}
        ref={sliderRef}
        onMouseMove={handleDrag}
        onMouseLeave={handleMouseLeave}
      >
        {tooltipPosition !== null && (
          <div
            className={styles.tooltip}
            style={{ left: `calc(${tooltipPosition}% - 10px)` }}
          >
            {tooltipPosition.toFixed(1)}%
          </div>
        )}
        {/* Display the thumb for dragging */}
        <div
          className={styles.slidebarThumb}
          style={updateThumb()}
          onMouseDown={handleMouseDown} // Handle dragging on mousedown event
        ></div>
      </div>
      <div className={styles.sliderValues}>
        {/* Display minimum value at the start */}
        <div className={styles.startValue}>{minValue.toFixed(1)}%</div>
        {/* Display maximum value at the end */}
        <div className={styles.endValue}>{maxValue.toFixed(1)}%</div>
      </div>
    </div>
  );
};

Slider.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Slider;
