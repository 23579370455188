import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../SwapConfirmModal/index.module.scss";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
const SwapConfirmModal = (props) => {
  const { isMobile } = useContext(AppContext);
  //Opens up the next modal TransactionSuccessful
  return (
    <>
      <div
        className={cx(styles.notificationDiv, {
          [styles.notificationDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.alertImageAndMsg, {
            [styles.alertImageAndMsgMob]: isMobile,
          })}
        >
          <div className={styles.confirmLoader}>
            <ul className={styles.loaderList}>
              <li>
                <div
                  className={cx(styles.loaderCircle, {
                    [styles.loaderCircleMob]: isMobile,
                  })}
                >
                  <span></span>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.transactionConfirmContentsDiv}>
            {!props.waitingConfirmation && (
              <div
                className={cx(styles.confirmContent, {
                  [styles.confirmContentMob]: isMobile,
                })}
              >
                {RUD_ENUM.confirm_content}
              </div>
            )}
            {props.waitingConfirmation && (
              <div className={styles.waitingConfirmation}>
                {RUD_ENUM.waitingConfirmation}
              </div>
            )}
            {!props.waitingConfirmation && (
              <div
                className={cx(styles.transactionConfirm, {
                  [styles.transactionConfirmMob]: isMobile,
                })}
              >
                {RUD_ENUM.transaction_confirm}
              </div>
            )}
            {props.isError && (
              <ShakeError
                errorMessage={props.errorMessage}
                customStyling={cx(styles.alertDeniedTransaction, {
                  [styles.alertDeniedTransactionMob]: isMobile,
                })}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default SwapConfirmModal;
