import { useState, useEffect } from "react";
import quote from "./quote";

const useUniSwapQuote = () => {
  const [outputAmount, setOutputAmount] = useState(null);

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const result = await quote();
        setOutputAmount(result);
      } catch (error) {
        console.error("Error fetching quote:", error);
      }
    };
    fetchQuote();
  }, []);
  // multiply by 100 due to underflow error
  return outputAmount / 100;
};

export default useUniSwapQuote;
