import { ethers } from "ethers";
import { useEffect } from "react";
import { useState } from "react";
import priceFeedABI from "../../../src/PriceFeed/PriceFeed.json";

let interval;
function useETHPrice(tokenAddress) {
  const [roundETHPrice, setRoundETHPrice] = useState(null);
  const [decimals, setDecimals] = useState(null);
  // We create an instance of the contract which we can interact with

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        const priceFeedContract = new ethers.Contract(
          tokenAddress,
          priceFeedABI.aggregatorV3InterfaceABI,
          provider
        );
        const fetchEthPrice = async () => {
          if (priceFeedContract && tokenAddress) {
            // We get the data from the last round of the contract
            setRoundETHPrice(await priceFeedContract.latestRoundData());
            // Determine how many decimals the price feed has
            setDecimals(await priceFeedContract.decimals());
          } else {
            setRoundETHPrice(null);
          }
        };
        interval = setInterval(fetchEthPrice, 1000);
        return () => {
          clearInterval(interval);
        };
      } catch (e) {
        console.log("error", e);
      }
    }
  }, [tokenAddress]);

  if (roundETHPrice) {
    return {
      ethPrice: Number(roundETHPrice.answer / 10 ** decimals),
      loading: false,
    };
  } else {
    return { loading: true };
  }
}
export default useETHPrice;
