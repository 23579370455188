import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../BorrowSuccessfulModal/index.module.scss";
import uploadIcon from "../../../assets/Upload Icon.svg";
import ButtonRade from "../../../components/RadeButtons";
import { BORROW_ENUM } from "../../../enums/borrowEnum";
import { STAKE_ENUM } from "../../../enums/stakeEnum";
const BorrowSuccessful = (props) => {
  const { isMobile } = useContext(AppContext);
  //Function to make the modal close and return to Home Page
  const closeBorrowSuccessfulWindow = () => {
    props.setIsOpen(false);
    props.setShowBorrowMain(false);
    props.setBorrowFlowPageNumber(0);
  };

  return (
    <div className={styles.borrowSuccessfulDiv}>
      <div
        className={cx(styles.uploadIconDiv, {
          [styles.uploadIconDivMob]: isMobile,
        })}
      >
        <img
          src={uploadIcon}
          alt="upload-icon"
          className={cx(styles.uploadIcon, {
            [styles.uploadIconMob]: isMobile,
          })}
        />
      </div>
      <div
        className={cx(styles.borrowSuccessful, {
          [styles.borrowSuccessfulMob]: isMobile,
        })}
      >
        {BORROW_ENUM.borrowSuccessful}
      </div>
      <div className={styles.viewTransactionDetails}>
        <a
          href={` https://goerli.etherscan.io/tx/${props.etherscanTransaction}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.redirectToEtherScan}
        >
          {RUD_ENUM.transaction_details}
        </a>
      </div>
      <div
        className={cx(styles.closeButtonDiv, {
          [styles.closeButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.closeButton, {
            [styles.closeButtonMob]: isMobile,
          })}
          onClick={closeBorrowSuccessfulWindow}
        >
          {STAKE_ENUM.closeButton}
        </ButtonRade>
      </div>
    </div>
  );
};
export default BorrowSuccessful;
