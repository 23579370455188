import cx from "classnames";
import React from "react";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import ConfirmSignerTransactionIcon from "../../../assets/ConfirmSignerTransactionIcon.svg";
import styles from "./index.module.scss";
const GetSignStaking = () => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={cx(styles.getSignerMainContainer, {
        [styles.getSignerMainContainerWeb]: !isMobile,
        [styles.getSignerMainContainerMob]: isMobile,
      })}
    >
      <div>
        <img
          src={ConfirmSignerTransactionIcon}
          alt="Fetching signature"
          className={cx(styles.confirmSignerTransactionIcon, {
            [styles.confirmSignerTransactionIconWeb]: !isMobile,
            [styles.confirmSignerTransactionIconMob]: isMobile,
          })}
        />
      </div>
      <div
        className={cx(styles.confirmTheSignatureRequestText, {
          [styles.confirmTheSignatureRequestTextWeb]: !isMobile,
          [styles.confirmTheSignatureRequestTextMob]: isMobile,
        })}
      >
        {RUD_ENUM.ConfirmSignatureRequest}
      </div>
    </div>
  );
};
export default GetSignStaking;
