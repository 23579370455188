import React from "react";
import cx from "classnames";
import styles from "../../components/RadeButtons/index.module.scss";
import { Button } from "..";
const ButtonRade = ({
  outline,
  children,
  customStyling,
  disabled,
  ...otherProps
}) => {
  return (
    <Button
      className={cx(
        { [styles.outline]: outline, [styles.filled]: !outline },
        customStyling
      )}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </Button>
  );
};
export default ButtonRade;
