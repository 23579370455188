export const SETTINGS_ENUM = {
  accountButton: "Profile",
  securityButton: "Security",
  notificationButton: "Notification",
  KYCButton: "KYC",
  profilePicture: "Your Avatar",
  uploadPhoto: " Upload your photo",
  updateprofileButton: "Update Profile",
  attachedFileAppear: "Attached file will appear here",
  cancelButton: "Cancel",
  attachButton: "Attach File",
  securityFeaturesComingSoon: "Security features coming soon..",
  uploadFileToAttach: "Upload file to attach",
  preview: "Preview",
  dragDropText: "Drag and drop your files anywhere or",
  confirmMessage: "Are you sure you want to submit changes?",
  submit: "Submit",
  changesMadeSuccessfully: "Profile updated successfully!",
  changesFailed:
    "Something wrong with profile changes. Please check your connection!",
  appNotification: "App Notifications",
  emailNotification: "Email Notifications",
  clickToAddPhoto: "Click to add photo",
  errorForImageUpload: "Please select an avatar to continue!",
  profileIsNotverified: "Your profile is not verified.",
  verifyYourProfile:
    " Verify your profile by completing KYC-AML Verfification Process.",
  KYCAMLButton: "Start KYC-AML",
  step1: "Step 1",
  personalInfo: "Personal Information",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phone: " Phone[optional)",
  DOB: "Date of Birth",
  chooseYourAvatar: "Choose Your Avatar",
  confirmButton: "Confirm",
  errorOnUpdate: "Please update the details!",
  saveButton: "Save",
  nextButton: "Next",
  step2: "Step 2",
  country: "Country",
  region: "Region",
  addressLineOne: "Address Line 1",
  addressLineTwo: "Address Line 2",
  city: "City",
  zipCode: "Pincode (Zip)",
  step3: "Step 3",
  emailVerification: "Email Verification",
  OTP: "OTP",
  didnotGet: "Did not receive email?",
  resendOTP: "Resend OTP",
  step4: "Step 4",
  indentificationDocument: "Identification Document",
  uploadYourPassport: "Upload your Government ID/ Passport.",
  passportFirstPage: "Passport (First Page)",
  passportLastPage: "Passport (Last Page)",
  supportedFile: "File Type : PDF, JPEG, JPG and PNG",
  youWillNotBeAbleTo:
    "You will not be able to change your details until the verification process ends. Are you sure you want to submit?",
  KYCApplicationSubmitted: "KYC Applicaiton submitted successfully.",
  weWillLetYouKnow:
    "We will let you know once your account is fully verified. This usually takes upto 2 business days.  ",
  KYC: "KYC",
  statusOfKYC: "Status :",
  submittedAndWaiting: "Submitted and Awaiting for Approval",
  thisWillBeYourUniqueUserName: "This will be your unique username which other",
  userCanUseToRequest: "users can use to request for MemberVouch feature.",
  success: "success",
  failed: "failed",
  backButton: "Back",
};
