import styles from "./index.module.scss";
import React from "react";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { RUD_ENUM } from "../../../enums/rudEnum";
import SignatureTransactionSuccessfull from "../../../assets/SignatureTransactionSuccessfull.svg";
const SignatireRequestSuccessful = ({ setModalPageNumber }) => {
  const { isMobile } = useContext(AppContext);
  // Timeout for displaying the transaction successfull pop up
  setTimeout(() => {
    setModalPageNumber(2);
  }, 3000);
  return (
    <div
      className={cx(styles.getSignerMainContainer, {
        [styles.getSignerMainContainerWeb]: !isMobile,
        [styles.getSignerMainContainerMob]: isMobile,
      })}
    >
      <div>
        <img
          src={SignatureTransactionSuccessfull}
          alt="Signature transaction successfull"
          className={cx(styles.signatureTransactionSuccessfullIcon, {
            [styles.signatureTransactionSuccessfullIconWeb]: !isMobile,
            [styles.signatureTransactionSuccessfullIconMob]: isMobile,
          })}
        />
      </div>

      <div
        className={cx(styles.signatureTransactionSuccessfull, {
          [styles.signatureTransactionSuccessfullWeb]: !isMobile,
          [styles.signatureTransactionSuccessfullMob]: isMobile,
        })}
      >
        {RUD_ENUM.signatureTransactionSuccessfull}
      </div>
    </div>
  );
};

export default SignatireRequestSuccessful;
