import React from "react";
import styles from "./index.module.scss";
import { useContext } from "react";
import { AppContext } from "../../../context";
import cx from "classnames";
import ButtonRade from "../../../components/RadeButtons";
import { LIQUIDATE_ENUM } from "../../../enums/liquidateEnum";
import uploadIcon from "../../../assets/Upload Icon.svg";
const LiquidateSuccessful = ({
  setModalIsOpen,
  userName,
  gasCost,
  liquidatorBonus,
}) => {
  const { isMobile } = useContext(AppContext);
  //Function to close the modal
  const closeSuccessfullWindow = () => {
    setModalIsOpen(false);
  };
  return (
    <div
      className={cx(styles.transactionSubmitDiv, {
        [styles.transactionSubmitDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.uploadIconDiv, {
          [styles.uploadIconDivMob]: isMobile,
        })}
      >
        <img
          src={uploadIcon}
          alt="upload-icon"
          className={cx(styles.uploadIcon, {
            [styles.uploadIconMob]: isMobile,
          })}
        />
      </div>
      <div className={styles.congrats}>Congrats!</div>
      <div
        className={cx(styles.transactionSubmit, {
          [styles.transactionSubmitMob]: isMobile,
        })}
      >
        You have successfully liquidated the nest of {userName}.
      </div>
      <div className={styles.gasCostContainer}>Gas Used: {gasCost} ETH</div>
      <div className={styles.bonusContainer}>
        Your reward: {liquidatorBonus} ETH
      </div>
      <div
        className={cx(styles.okayButtonDiv, {
          [styles.okayButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.closeButton, {
            [styles.closeButtonMob]: isMobile,
          })}
          onClick={closeSuccessfullWindow}
        >
          {LIQUIDATE_ENUM.close}
        </ButtonRade>
      </div>
    </div>
  );
};

export default LiquidateSuccessful;
