import styles from "./index.module.scss";
import React from "react";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { RETURN_USER_STAKING } from "../../../enums/returnUserStaking";
import ButtonRade from "../../../components/RadeButtons";
const AlertForStakeInLock = ({ setModalIsOpen }) => {
  const { isMobile } = useContext(AppContext);
  //Function to close the Modal.
  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <div>
      <div
        className={cx(styles.popUpContent, {
          [styles.popUpContentMob]: isMobile,
        })}
      >
        {RETURN_USER_STAKING.yourStakingIsStillInProgress}
        <span className={styles.youHaveToWaitUntilTheVestingPeriod}>
          {RETURN_USER_STAKING.youHaveToWaitUntilTheVestingPeriod}
        </span>
      </div>
      <div
        className={cx(styles.popUpButtonDiv, {
          [styles.popUpButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={closeModal}
        >
          {RETURN_USER_STAKING.cancel}
        </ButtonRade>
      </div>
    </div>
  );
};

export default AlertForStakeInLock;
