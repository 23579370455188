export const LIQUIDATE_ENUM = {
  paymentMissedButton: "Payment Defaults",
  assetDevaluationButton: "Collateralization Ratio",
  userName: "User Name",
  borrowAndColl: "Borrowed /Coll.",
  loanBalance: "Loan Balance",
  collateralRatio: "Coll. Ratio",
  defaultValue: "Value of Default",
  liquidateCollateral: "Coll. to Liquidate",
  liquidateButton: "Liquidate",
  revitalizeFactor: "Revitalize factor",
  noPaymentDefaults: "There are no payments defaulted.",
  liquidationAmount: "Liquidation Amount",
  liquidationRatio: "There are no risky nests at the moment.",
  paymentDefaultNotification:
    "Nests hitting 120% CR or below will be subject to full liquidation. ",
  assetDevaluationNotification:
    "Help the protocol to maintain its health by liquidating risky Nests(loans) and get rewarded.",
  lowHealth: "Low Health",
  mediumHealth: "Medium Health",
  liquidatorNote:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  myNests: "My Nests",
  otherNests: "Protocol Nests",
  cancel: "Cancel",
  close: "Close",
  confirm: "Confirm",
  liquidationModalNote:
    "You are about to partially liquidate the nest and help the protocol to avoid risky loans and get reward. However, you agree to spend the gas for this transaction.",
};
