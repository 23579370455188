import React from "react";
import styles from "../Desktop/DocButton.module.scss";
import DocButton from "../../../assets/DocButton";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { Button } from "../..";
const DocsButton = () => {
  const { isMobile } = useContext(AppContext);
  return (
    <a
      href="https://docs-rade.rariti.io"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.docsLink}
    >
      <Button
        className={cx(styles.docButton, {
          [styles.docButtonMob]: isMobile,
        })}
      >
        <DocButton />
        Docs
      </Button>
    </a>
  );
};

export default DocsButton;
