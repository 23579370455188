import React from "react";
import styles from "./index.module.scss";

function ImagePreview({ src, alt, onClick }) {
  return (
    <img
      src={src}
      alt={alt}
      className={styles.previewImage}
      onClick={onClick}
    />
  );
}

export default ImagePreview;
