import { shortenAddress, useEthers } from "@usedapp/core";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MetaMaskIcon from "../../assets/Metamask Icon.svg";
import { AppContext, UserContext } from "../../context";
import { memo } from "react";
import { APP_ENUM } from "../../enums/appEnum";
import Modal from "react-modal";
import LogoutMetamask from "../NavBar/Mobile/LogOutMetaMask/LogOutMetaMaskModal";
import styles from "./index.module.scss";
const Button = styled.button`
  margin: auto 6px auto 0px;
  background: transparent;
  border: 1px solid white;
  cursor: pointer;
  padding: 3px 5px;
  border-radius: 2px;
  @media screen and (min-width: 993px) {
    padding: 3px 8px;
  }
  @media screen and (min-width: 1080px) {
    padding: 3px 10px;
  }
  @media screen and (min-width: 1193px) {
    padding: 3px 11px;
  }
  @media screen and (min-width: 1500px) {
    padding: 2px 12px;
  }
  &:hover {
    background: #fd5252;
    border: none;
  }
`;

const MetaIconElem = styled.img`
  margin: auto 0 auto 6px;
  height: 12px;
  width: 10px;
  @media screen and (min-width: 1500px) {
    width: 13px;
    height: 15px;
  }
  @media screen and (min-width: 1680px) {
    width: 17px;
    height: 15px;
  }
`;
const MetaLogout = styled.div`
  font-family: metrophobic;
  font-weight: 400;
  line-height: 125%;
  font-size: 11px;
  color: #f9fafa;
  letter-spacing: -0.02em;
  @media screen and (min-width: 993px) {
    font-size: 9px;
    padding: 0 5px 0 4px;
  }
  @media screen and (min-width: 1180px) {
    font-size: 11px;
    padding: 0 6px 0 6px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 12px;
    padding: 0 6px 0 6px;
  }
  @media screen and (min-width: 1500px) {
    font-size: 12px;
    padding: 0 6px 0 6px;
  }
  @media screen and (min-width: 1680px) {
    font-size: 13px;
    padding: 0 9px 0 9px;
  }
  @media screen and (min-width: 1740px) {
    font-size: 15px;
    padding: 0 12px 0 12px;
  }
`;

const MetaDiv = styled.div`
  display: flex;
  background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 0px;
  height: 33px;
  @media screen and (min-width: 993px) {
    width: 152px;
  }
  @media screen and (min-width: 1050px) {
    width: 160px;
  }
  @media screen and (min-width: 1180px) {
    width: 170px;
  }
  @media screen and (min-width: 1300px) {
    width: 179px;
  }
  @media screen and (min-width: 1500px) {
    width: 200px;
  }
  @media screen and (min-width: 1680px) {
    width: 215px;
  }
  @media screen and (min-width: 1740px) {
    width: 230px;
  }
`;
const MetaAddress = styled.span`
  font-family: "metrophobic";
  color: #f9fafa;
  font-weight: 400;
  align-self: center;
  padding: 0 9px 0 4px;
  letter-spacing: -0.02em;
  @media screen and (min-width: 993px) {
    font-size: 10px;
    padding: 0 5px 0 4px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1500px) {
    font-size: 13px;
    padding: 0 7px 0 7px;
  }
  @media screen and (min-width: 1740px) {
    font-size: 14px;
    padding: 0 7px 0 7px;
  }
`;

const HeaderWallerButton = () => {
  const [rendered, setRendered] = useState("");
  const { isMobile } = useContext(AppContext);

  const { ens } = useContext(UserContext);
  const { account, activateBrowserWallet } = useEthers();
  // State to set the Modal open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (ens) {
      setRendered(ens);
    } else if (account) {
      setRendered(shortenAddress(account));
    } else {
      setRendered("");
    }
  }, [account, ens, setRendered]);
  const handleClickCrossIcon = () => {
    setModalIsOpen(true);
  };
  if ((account || ens) && !isMobile) {
    return (
      <>
        <MetaDiv>
          <MetaIconElem src={MetaMaskIcon} alt="metaMask" />
          <MetaAddress>{rendered}</MetaAddress>
          <Button
            onClick={() => {
              if (!account) {
                activateBrowserWallet();
              } else {
                console.log("calling deactivate");
                handleClickCrossIcon();
              }
            }}
          >
            <MetaLogout>Logout</MetaLogout>
          </Button>
        </MetaDiv>
        <Modal
          isOpen={modalIsOpen}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
          <LogoutMetamask setModalIsOpen={setModalIsOpen} />
        </Modal>
      </>
    );
  }
  return null;
};

export default memo(HeaderWallerButton);
