import React from "react";
import cx from "classnames";
import { AppContext } from "../../../../context";
import styles from "./index.module.scss";
import { useState, useContext } from "react";
import { SETTINGS_ENUM } from "../../../../enums/settingsEnum";
import ButtonRade from "../../../../components/RadeButtons";
import { SettingsContext } from "../../SettingsContext";
import ShakeError from "../../../../components/ShakingErrorMessages/ShakingErrorMessages";
import AvatarList from "../AvatarList/AvatarList";
const ProfileImagePopUp = ({ setUpdateProfile, setModalIsOpen }) => {
  const { isMobile } = useContext(AppContext);
  // state to get the avatar in the profile section
  const [newUserInfo, setNewUserInfo] = useState(false);
  // state to for displaying error message if user isn't selected any avatar
  const [errorForAttach, setErrorForAttach] = useState(null);

  const handleClickConfirmButton = (e) => {
    if (!newUserInfo) {
      setErrorForAttach(true);
    } else {
      setUpdateProfile(newUserInfo);
      setErrorForAttach(false);
      closeModal();
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <SettingsContext.Provider value={{ setErrorForAttach }}>
        <div
          className={cx(styles.profileNamePopUp, {
            [styles.profileNamePopUpWeb]: !isMobile,
            [styles.profileNamePopUpMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.uploadFiles, {
              [styles.uploadFilesWeb]: !isMobile,
              [styles.uploadFilesMob]: isMobile,
            })}
          >
            {SETTINGS_ENUM.chooseYourAvatar}
          </div>
          {/* Calling the avatar list component */}
          <div className={styles.avatarList}>
            <AvatarList
              newUserInfo={newUserInfo}
              setUpdateProfile={setUpdateProfile}
              setErrorForAttach={setErrorForAttach}
              setNewUserInfo={setNewUserInfo}
            />
          </div>

          <div
            className={cx(styles.buttonDiv, {
              [styles.buttonDivWeb]: !isMobile,
              [styles.buttonDivMob]: isMobile,
            })}
          >
            <ButtonRade
              outline
              customStyling={cx(styles.cancelButton, {
                [styles.cancelButtonWeb]: !isMobile,
                [styles.cancelButtonMob]: isMobile,
              })}
              onClick={closeModal}
            >
              {" "}
              {SETTINGS_ENUM.cancelButton}
            </ButtonRade>
            <ButtonRade
              outline
              customStyling={cx(styles.attachFileButton, {
                [styles.attachFileButtonWeb]: !isMobile,
                [styles.attachFileButtonMob]: isMobile,
              })}
              onClick={handleClickConfirmButton}
            >
              {" "}
              {SETTINGS_ENUM.confirmButton}
            </ButtonRade>
          </div>
          {errorForAttach && (
            <div className={styles.alertSpanDiv}>
              <ShakeError
                errorMessage={SETTINGS_ENUM.errorForImageUpload}
                customStyling={styles.alertSpan}
              />
            </div>
          )}
        </div>
        ;
      </SettingsContext.Provider>
    </>
  );
};
export default ProfileImagePopUp;
