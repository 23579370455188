import React from "react";
import styles from "./HeaderMain.module.scss";
import { useContext } from "react";
import { AppContext } from "../../context";
import cx from "classnames";

const HeaderSpinner = () => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      className={cx(styles.spinnerMainContainer, {
        [styles.spinnerMainContainerMob]: isMobile,
      })}
    >
      <div className={styles.spinnerOverlay}>
        <div className={styles.spinnerContainer} />
      </div>
    </div>
  );
};
export default HeaderSpinner;
