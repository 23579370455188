import React from "react";
import styles from "./index.module.scss";
const ProfileNameUpdatePopUp = ({
  setNewUserName,
  newUserName,
  handleUsernameUpdate,
  setModalOpen,
  lastUsernameChangeDateError,
  setLastUsernameChangeDateError,
}) => {
  return (
    <div>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <p className={styles.enterUserName}>Username</p>
          <div className={styles.userNameInputBoxContainer}>
            {" "}
            <input
              type="text"
              placeholder="Enter Username"
              className={styles.userNameInputBox}
              value={newUserName}
              onChange={(e) => {
                setNewUserName(e.target.value);
                setLastUsernameChangeDateError(null);
              }}
            />
          </div>
          <div className={styles.noteText}>
            Note: Username changes are only allowed after a waiting period of 21
            days.
          </div>
          <p className={styles.lastUsernameChangeDateError}>
            {lastUsernameChangeDateError}
          </p>
          <div className={styles.buttonContainer}>
            {" "}
            <button
              onClick={() => {
                setModalOpen(false);
                setLastUsernameChangeDateError(null);
              }}
              className={styles.cancelButton}
            >
              Cancel
            </button>
            <button
              onClick={handleUsernameUpdate}
              className={styles.nextButton}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNameUpdatePopUp;
