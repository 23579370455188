import React, { useState } from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../ProceedBuyRudUsingUsd/index.module.scss";
import ButtonRade from "../../../components/RadeButtons";
import DetailsIcon from "../../../assets/DetailsIcon/DetailsUpIcon";
import DetailsUp from "../../../assets/DetailsIcon/DetailsUp";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
//import ProceedIcon from "../../../assets/ProceedIcon/proceedIcon";
import StripePayment from "./StripePayment/StripePayment";
const PERCENTAGE_CONVERSION = 100;

const ProceedBuyRudUsingUsd = ({ setUserModeSelection, setCanProceed }) => {
  const { isMobile } = useContext(AppContext);
  //State to set the paying USD amount by the user.
  const [USDPay, setUSDPay] = useState("");
  const [isPaymentProcess, setPaymentProcess] = useState(false);
  const accordionData = {
    detail: "Details",
  };
  //State to set the Accordion Div is active or not.
  const [isActiveAccordion, setIsActiveAcccordion] = useState(false);
  //State to set error message before proceed.
  const [errorForProceed, setErrorForProceed] = useState(false);
  const getUserEnteredValue = (e) => {
    const amount = e.target.value;
    setUSDPay(amount);
    setErrorForProceed(false);
  };
  // Function to activate the Proceed Button according to the state variable value
  const handleSubmitProceedButton = () => {
    if (!USDPay) {
      setErrorForProceed(true);
      setPaymentProcess(false);
    } else {
      setErrorForProceed(false);
      setPaymentProcess(true);
    }
  };
  const handleSubmitBackButton = () => {
    setUserModeSelection(null);
    setCanProceed(false);
  };
  const RAMP_FEE = 3.8;
  //Equivalent value of RUD to USD(provided a random digit, to be removed)
  const RUD_Get = USDPay - USDPay * (RAMP_FEE / PERCENTAGE_CONVERSION);

  const CardBody = () => (
    <div className={styles.rudBuyMainDiv}>
      {isPaymentProcess ? (
        <StripePayment
          amount={USDPay}
          isCancel={() => setPaymentProcess(false)}
        />
      ) : (
        <div className={styles.proceedContent}>
          <div
            className={cx(styles.getRUDAmountEnter, {
              [styles.getRUDAmountEnterMob]: isMobile,
            })}
          >
            {RUD_ENUM.getRUDAmount}
          </div>
          <div
            className={cx(styles.payUSD, {
              [styles.payUSDMob]: isMobile,
            })}
          >
            <div className={styles.payCryptoDiv}>
              <div
                className={cx(styles.payingUSD, {
                  [styles.payingUSDMob]: isMobile,
                })}
              >
                {RUD_ENUM.pay}
              </div>
            </div>
            <div
              className={cx(styles.payingUSDValue, {
                [styles.payingUSDValueMob]: isMobile,
              })}
            >
              <div
                className={cx(styles.dollarSymbol, {
                  [styles.dollarSymbolMob]: isMobile,
                })}
              >
                $
              </div>
              <input
                type="number"
                name="USDPay"
                value={USDPay}
                onChange={getUserEnteredValue}
                onKeyDown={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
                placeholder="0.00"
                min="0.00"
                step="0.01"
                max="1.00"
                presicion={2}
                autoFocus
                autoComplete="off"
                className={cx(styles.inputAmount, {
                  [styles.inputAmountMob]: isMobile,
                })}
              ></input>
              <div
                className={cx(styles.payingCrypto, {
                  [styles.payingCryptoMob]: isMobile,
                })}
              >
                USD
              </div>
            </div>
          </div>
          <div
            className={cx(styles.getRUD, {
              [styles.getRUDMob]: isMobile,
            })}
          >
            <div className={styles.recievingRUD}>{RUD_ENUM.get}</div>
            <div
              className={cx(styles.recievingRUDVal, {
                [styles.recievingRUDValMob]: isMobile,
              })}
            >
              <div
                className={cx(styles.equivalentRUD, {
                  [styles.equivalentRUDMob]: isMobile,
                })}
              >
                ${RUD_Get.toFixed(2)}
              </div>
              <div
                className={cx(styles.cryptoRUD, {
                  [styles.cryptoRUDMob]: isMobile,
                })}
              >
                RUD
              </div>
            </div>
          </div>
          {/*Error Message */}
          {errorForProceed && (
            <ShakeError
              errorMessage={RUD_ENUM.proceedError_BuyFlow}
              customStyling={cx(styles.alertBeforeProceed, {
                [styles.alertBeforeProceedMob]: isMobile,
              })}
            />
          )}
          {/*Accodion Div */}
          {!!USDPay && (
            <div
              className={cx(styles.accordionContainer, {
                [styles.accordionContainerMob]: isMobile,
              })}
            >
              <div
                className={cx(styles.accordionTitleContainer, {
                  [styles.accordionTitleContainerMob]: isMobile,
                })}
              >
                <div
                  className={cx(styles.accordionTitle, {
                    [styles.accordionTitleMob]: isMobile,
                  })}
                >
                  ${USDPay} is all you pay, fees included
                </div>
                <div className={styles.detailsDiv}>
                  <div
                    className={styles.detailsDiv}
                    onClick={() => setIsActiveAcccordion(!isActiveAccordion)}
                  >
                    <div
                      className={cx(styles.accordionTitleDetails, {
                        [styles.accordionTitleDetailsMob]: isMobile,
                      })}
                      style={{ color: isActiveAccordion ? "#02aab0" : "" }}
                    >
                      {accordionData.detail}
                      {isActiveAccordion ? <DetailsUp /> : <DetailsIcon />}
                    </div>
                  </div>
                </div>
              </div>
              {/*Expand the accordion div if the state "isActiveAccordian" is active*/}
              {isActiveAccordion && (
                <div>
                  <div
                    className={cx(styles.accordionContentOne, {
                      [styles.accordionContentOneMob]: isMobile,
                    })}
                  >
                    <div>
                      ${RUD_Get.toFixed(2)} RUD @ ${USDPay} USD
                    </div>

                    <div>${USDPay} </div>
                  </div>
                  <div
                    className={cx(styles.accordionContentTwo, {
                      [styles.accordionContentTwoMob]: isMobile,
                    })}
                  >
                    <div> Ramp fee </div>
                    <div>{RAMP_FEE}%</div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className={cx(styles.proceedButtonDiv, {
              [styles.proceedButtonDivMob]: isMobile,
            })}
          >
            {/* Custom styled back button */}
            <ButtonRade
              outline
              customStyling={cx(styles.backButton, {
                [styles.backButtonMob]: isMobile,
              })}
              onClick={handleSubmitBackButton}
            >
              {RUD_ENUM.backButton}
            </ButtonRade>
            {/* Custom styled button for proceed */}
            <ButtonRade
              outline
              customStyling={cx(styles.proceedButton, {
                [styles.proceedButtonMob]: isMobile,
              })}
              onClick={handleSubmitProceedButton}
            >
              {RUD_ENUM.proceed_button}
              {/* <ProceedIcon /> */}
            </ButtonRade>
          </div>
        </div>
      )}
    </div>
  );
  // calling as a function to avoid flickering issue
  return CardBody();
};
export default ProceedBuyRudUsingUsd;
