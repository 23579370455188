import React, { useState } from "react";
import cx from "classnames";
import { AppContext, UserContext } from "../../../../context";
import styles from "./index.module.scss";
import { useContext, useEffect } from "react";
import { SETTINGS_ENUM } from "../../../../enums/settingsEnum";
import ButtonRade from "../../../../components/RadeButtons";
import PersonalInformationStep2 from "../PersonalInformationStep2/PersonalInformationStep2";
import Emailverification from "../EmailVerification/EmailVerification";
import UploadIDProof from "../UplaodIDProof/UploadIDProof";
import KYCCompleted from "../../KYCCompleted/KYCCompleted";
import { db } from "../../../../firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import "./index.css";
import UserBankAccountDetails from "../UserBankAccountDetails/UserBankAccountDetails";

const PersonalInformation = ({
  setOnclickKYCButton,
  updateFirebaseUserProfile,
  KycStatus,
  setKycStatus,
}) => {
  const { account } = useContext(UserContext);

  const { isMobile } = useContext(AppContext);
  // state for storing firstname
  const [firstName, setFirstName] = useState("");
  // state for storing lastname
  const [lastName, setLastName] = useState("");
  // state for storing email
  const [email, setEmail] = useState("");
  // state for storing phone
  const [phoneNumber, setPhoneNum] = useState("");
  // state for user name
  const [userName, setuserName] = useState("");
  // state for storing DOB
  const [DOB, setDOB] = useState("");
  const [KYCPageNumber, setKYCPageNumber] = useState(false); //state to store KYC flow
  const [inputErrors, setInputErrors] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",

    DOB: "",
  }); //state to display error message
  const [country, setCountry] = useState(""); //state that stores the country of the user
  const [region, setRegion] = useState(""); //state that stores the region of the user
  const [addressLineOne, setAddressLineOne] = useState(""); //state that stores the address line 1 of the user
  const [addressLineTwo, setAddressLineTwo] = useState(""); //state that stores the address line 2 of the user
  const [city, setCity] = useState(""); //state that stores the city of the user
  const [zipCode, setZipCode] = useState(""); //state that stores the zipcode of the user
  // state for storing user holding pancard
  const [pancardHolding, setPancardHolding] = useState(null);
  // state for storing user holding aadhar
  const [aadharHolding, setAadharHolding] = useState(null);
  //state to set error for idBackSide id upload
  const [errorForPancardHold, setErrorForPancardHold] = useState(false);
  //state to set error for idFrontSide id upload
  const [errorForAadharHold, setErrorForAadharHold] = useState(false);
  // state for selected file name
  const [selectedFileName, setSelectedFileName] = useState("");
  //state for enabling the edit button
  //state to know whether any file is uploaded or not
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [idBackSideFileType, setIdBackSideFileType] = useState(""); //state to track id
  const [idFrontSideFileType, setIdFrontSideFileType] = useState(""); //state to track id
  //state to set error for id upload
  const [errorForID, setErrorForID] = useState(false);
  //state to set error for idBackSide id upload
  const [errorForPancard, setErrorForPancard] = useState(false);
  //state to set error for idFrontSide id upload
  const [errorForAadhar, setErrorForAadhar] = useState(false);
  // state for storing idBackSide id
  const [idBackSide, setIdBackSide] = useState(null);
  // state for storing idFrontSide card id
  const [idFrontSide, setIdFrontSide] = useState(null);
  const [reSubmitKyc, setResubmitKyc] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(""); // state for selected country code
  const [selectedCountry, setSelectedCountry] = useState(null); // State for storing selected country
  const [selectedRegion, setSelectedRegion] = useState(null); //state for storing selected region
  const [bankName, setBankName] = useState(null); //state for storing bank name
  const [bankAccountType, setBankAccountType] = useState(null); //state for storing bank account type
  const [ABARoutingNumber, setABARoutingNumber] = useState(null); //state for storing ABA routing number
  const [accountNumber, setAccountNumber] = useState(null); //state for storing account number

  const handleChangeCountryCode = (event) => {
    setSelectedCountryCode(event.target.value); // Update the selected country code when the user makes a selection
  };

  const validateInputs = () => {
    let isValid = true;
    const errors = {};

    if (!firstName) {
      errors.firstName = "Please enter your first name";
      isValid = false;
    } else if (!lastName) {
      errors.lastName = "Please enter your last name";
      isValid = false;
    } else if (!email) {
      errors.email = "Please enter your email";
      isValid = false;
    } else if (!DOB) {
      errors.DOB = "Please enter your date of birth";
      isValid = false;
    }

    setInputErrors(errors);

    return isValid;
  };
  //Fetch user details from firebase
  useEffect(() => {
    // Function to fetch the KYC status from the database
    async function fetchKYCStatus() {
      // Set loading to true to indicate that the data is being fetched

      // Create a reference to the "userData" collection in the database
      const usersRef = collection(db, "userData");

      // Get the document reference for the user with the given profileEmail
      const userDocRef = doc(usersRef, account);

      // Fetch the user document snapshot from the database
      const userDocSnapshot = await getDoc(userDocRef);

      // Check if the user document exists in the database
      if (userDocSnapshot.exists()) {
        // Extract user data from the document snapshot
        const userData = userDocSnapshot.data();

        // Get the KYC status from the user data
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setEmail(userData.email);
        setPhoneNum(userData.phone);
        setDOB(userData.DOB);
        setuserName(userData.userName);
        setAddressLineOne(userData.addressLine1);
        setAddressLineTwo(userData.addressLine2);
        setCity(userData.city);
        setCountry(userData.country);
        setSelectedRegion(userData.region);
        setZipCode(userData.pincode);
        setSelectedCountry(userData.country);
        setRegion(userData.region);
        setSelectedCountryCode(userData.selectedCountryCode);
        setPancardHolding(userData.holdingPassportBackside);
        setAadharHolding(userData.holdingPassport);
        setIdBackSide(userData.idBackSide);
        setIdFrontSide(userData.idFrontSide);
        setBankAccountType(userData.bankAccountType);
        setABARoutingNumber(userData.ABARoutingNumber);
        setBankName(userData.bankName);
        setAccountNumber(userData.accountNumber);

        // Check the KYC status and update the relevant states accordingly
      }
    }
    // Call the fetchKYCStatus function when KycStatus or confirmUpdateModalOpen changes
    // This ensures that the KYC status is fetched whenever there's an update to those dependencies
    // For example, when the user submits or updates the KYC status
    fetchKYCStatus();
  }, [KycStatus, KYCPageNumber]);
  // todo: Error handling
  const handleChangeDOB = (e) => {
    const inputDate = e.target.value;
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (regex.test(inputDate)) {
      setDOB(inputDate);
    }
  };

  const handleChangePhoneNumber = (event) => {
    const enteredPhoneNumber = event.target.value; // Get the entered phone number

    // Remove any non-numeric characters from the entered phone number
    const numericPhoneNumber = enteredPhoneNumber.replace(/\D/g, "");

    // Ensure the phone number does not exceed 10 digits
    const truncatedPhoneNumber = numericPhoneNumber.slice(0, 10);

    // Update the phoneNumber state with the formatted phone number
    setPhoneNum(truncatedPhoneNumber);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleChangeLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleSubmitCancelButton = () => {
    setOnclickKYCButton(false);
  };
  const handleSubmitNextButton = () => {
    if (!validateInputs()) {
      return;
    }
    setKYCPageNumber(0);
  };
  const CardBody = () => (
    <>
      <div
        className={cx(styles.personalInformationMainDiv, {
          [styles.personalInformationMainDivWeb]: !isMobile,
          [styles.personalInformationMainDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.butttonContent, {
            [styles.butttonContentMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.contentText, {
              [styles.contentTextMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton1, {
                [styles.stepsButton1Mob]: isMobile,
              })}
            >
              1
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton2, {
                [styles.stepsButton2Mob]: isMobile,
              })}
            >
              2
            </div>
          </div>
          <div className={styles.line}></div>

          <div>
            <div
              className={cx(styles.stepsButton3, {
                [styles.stepsButton3Mob]: isMobile,
              })}
            >
              3
            </div>
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton4, {
              [styles.stepsButton4Mob]: isMobile,
            })}
          >
            4
          </div>
          <div className={styles.line}></div>
          <div
            className={cx(styles.stepsButton5, {
              [styles.stepsButton5Mob]: isMobile,
            })}
          >
            5
          </div>
        </div>

        <div
          className={cx(styles.step1Text, {
            [styles.step1TextWeb]: !isMobile,
            [styles.step1TextMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.step1}
        </div>
        <div
          className={cx(styles.personalInfo, {
            [styles.personalInfoWeb]: !isMobile,
            [styles.personalInfoMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.personalInfo}
        </div>

        <div
          className={cx(styles.inputNameDiv, {
            [styles.inputNameDivWeb]: !isMobile,
            [styles.inputNameDivMob]: isMobile,
          })}
        >
          {isMobile && (
            <div
              className={cx(styles.firstName, {
                [styles.firstNameWeb]: !isMobile,
                [styles.firstNameMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.firstName}
            </div>
          )}
          <div className={styles.inputFirstNameDiv}>
            {!isMobile && (
              <div
                className={cx(styles.firstName, {
                  [styles.firstNameWeb]: !isMobile,
                  [styles.firstNameMob]: isMobile,
                })}
              >
                {SETTINGS_ENUM.firstName} *
              </div>
            )}
            <div>
              <input
                key="name"
                type="text"
                autoFocus
                placeholder="Enter your first name (as per your ID)"
                value={firstName}
                autoComplete="off"
                onChange={handleChangeFirstName}
                className={cx(styles.firstNameInput, {
                  [styles.firstNameInputWeb]: !isMobile,
                  [styles.firstNameInputMob]: isMobile,
                })}
              />
            </div>
          </div>
          {isMobile && (
            <div
              className={cx(styles.lastName, {
                [styles.lastNameWeb]: !isMobile,
                [styles.lastNameMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.lastName} *
            </div>
          )}
          <div className={styles.inputLastNameDiv}>
            {!isMobile && (
              <div
                className={cx(styles.lastName, {
                  [styles.lastNameWeb]: !isMobile,
                  [styles.lastNameMob]: isMobile,
                })}
              >
                {SETTINGS_ENUM.lastName} *
              </div>
            )}
            <div>
              <input
                type="text"
                autoComplete="off"
                placeholder="Enter your last name (as per your ID)"
                value={lastName}
                onChange={handleChangeLastName}
                className={cx(styles.lastNameInput, {
                  [styles.lastNameInputWeb]: !isMobile,
                  [styles.lastNameInputMob]: isMobile,
                })}
              />
            </div>
          </div>
        </div>
        {isMobile && (
          <div
            className={cx(styles.DOB, {
              [styles.DOBWeb]: !isMobile,
              [styles.DOBMob]: isMobile,
            })}
          >
            Email *
          </div>
        )}
        <div className={styles.inputBoxContainerEmail}>
          <div className={styles.emailDiv}>
            {!isMobile && (
              <div
                className={cx(styles.email, {
                  [styles.emailWeb]: !isMobile,
                  [styles.emailMob]: isMobile,
                })}
              >
                {SETTINGS_ENUM.email} *
              </div>
            )}
            <div>
              <input
                type="text"
                autoComplete="off"
                placeholder="Enter your email"
                value={email}
                onChange={handleChangeEmail}
                className={cx(styles.emailInput, {
                  [styles.emailInputWeb]: !isMobile,
                  [styles.emailInputMob]: isMobile,
                })}
              />
            </div>
          </div>
          {isMobile && (
            <div
              className={cx(styles.DOB, {
                [styles.DOBWeb]: !isMobile,
                [styles.DOBMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.DOB} *
            </div>
          )}
          <div
            className={cx(styles.inputDOBDiv, {
              [styles.inputDOBDivWeb]: !isMobile,
              [styles.inputDOBDivMob]: isMobile,
            })}
          >
            {!isMobile && (
              <div
                className={cx(styles.DOB, {
                  [styles.DOBWeb]: !isMobile,
                  [styles.DOBMob]: isMobile,
                })}
              >
                {SETTINGS_ENUM.DOB} *
              </div>
            )}

            <div
              className={cx(styles.DOBInput, {
                [styles.DOBInputWeb]: !isMobile,
                [styles.DOBInputMob]: isMobile,
              })}
            >
              <div className={styles.DOBdiv}>
                <input
                  type="date"
                  placeholder="Enter DOB"
                  value={DOB}
                  style={{ appearance: "none" }}
                  onChange={handleChangeDOB}
                  className={cx(styles.DOBInputField, {
                    [styles.DOBInputFieldWeb]: !isMobile,
                    [styles.DOBInputFieldMob]: isMobile,
                  })}
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx(styles.emailPhoneDiv, {
            [styles.emailPhoneDivWeb]: !isMobile,
            [styles.emailPhoneDivMob]: isMobile,
          })}
        >
          {isMobile && (
            <div
              className={cx(styles.phoneNum, {
                [styles.phoneNumWeb]: !isMobile,
                [styles.phoneNumMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.phone}
            </div>
          )}
          <div className={styles.inputPhoneDiv}>
            {!isMobile && (
              <div
                className={cx(styles.phoneNum, {
                  [styles.phoneNumWeb]: !isMobile,
                  [styles.phoneNumMob]: isMobile,
                })}
              >
                {SETTINGS_ENUM.phone}
              </div>
            )}
            <div>
              <select
                value={selectedCountryCode} // Bind the selected value to the state
                onChange={handleChangeCountryCode} // Handle changes to the selected value
                style={{ width: "auto" }}
                className={cx(styles.CountryCodeSelect, {
                  [styles.CountryCodeSelectWeb]: !isMobile,
                  [styles.CountryCodeSelectMob]: isMobile,
                })}
              >
                <option className={styles.option} value="+1">
                  +1
                </option>
                <option className={styles.option} value="+52">
                  +52
                </option>
                <option className={styles.option} value="+1-876">
                  +1-876
                </option>
                <option className={styles.option} value="+1-868">
                  +1-868
                </option>
                <option className={styles.option} value="+44">
                  +44
                </option>
                <option className={styles.option} value="+33">
                  +33
                </option>
                <option className={styles.option} value="+49">
                  +49
                </option>
                <option className={styles.option} value="+34">
                  +34
                </option>
                <option className={styles.option} value="+39">
                  +39
                </option>
                <option className={styles.option} value="+31">
                  +31
                </option>
                {/* Add more country codes as needed for North America and Europe */}
              </select>

              <input
                type="text"
                autoComplete="off"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={handleChangePhoneNumber}
                className={cx(styles.PhoneNumberInput, {
                  [styles.PhoneNumberInputWeb]: !isMobile,
                  [styles.PhoneNumberInputMob]: isMobile,
                })}
              />
            </div>
          </div>
        </div>

        {inputErrors.firstName && (
          <div className={styles.error}>{inputErrors.firstName}</div>
        )}
        {inputErrors.lastName && (
          <div className={styles.error}>{inputErrors.lastName}</div>
        )}
        {inputErrors.email && (
          <div className={styles.error}>{inputErrors.email}</div>
        )}

        {inputErrors.DOB && (
          <div className={styles.error}>{inputErrors.DOB}</div>
        )}
        {inputErrors.userName && (
          <div className={styles.error}>{inputErrors.userName}</div>
        )}
        <div
          className={cx(styles.bottomButtonDiv, {
            [styles.bottomButtonDivWeb]: !isMobile,
            [styles.bottomButtonDivMob]: isMobile,
          })}
        >
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonWeb]: !isMobile,
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={handleSubmitCancelButton}
          >
            {SETTINGS_ENUM.backButton}
          </ButtonRade>
          <ButtonRade
            outline
            customStyling={cx(styles.nextButton, {
              [styles.nextButtonWeb]: !isMobile,
              [styles.nextButtonMob]: isMobile,
            })}
            onClick={handleSubmitNextButton}
          >
            {SETTINGS_ENUM.nextButton}
          </ButtonRade>
        </div>
      </div>
    </>
  );
  return (
    <>
      {(() => {
        switch (KYCPageNumber) {
          case 0:
            return (
              <PersonalInformationStep2
                userName={userName}
                KYCPageNumber={KYCPageNumber}
                setKYCPageNumber={setKYCPageNumber}
                updateFirebaseUserProfile={updateFirebaseUserProfile}
                country={country}
                region={region}
                addressLineOne={addressLineOne}
                addressLineTwo={addressLineTwo}
                city={city}
                zipCode={zipCode}
                setCountry={setCountry}
                setRegion={setRegion}
                setAddressLineOne={setAddressLineOne}
                setAddressLineTwo={setAddressLineTwo}
                setCity={setCity}
                setZipCode={setZipCode}
                KycStatus={KycStatus}
                setKycStatus={setKycStatus}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
              />
            );
          case 1:
            return (
              <Emailverification
                KYCPageNumber={KYCPageNumber}
                setKYCPageNumber={setKYCPageNumber}
                KycStatus={KycStatus}
                setKycStatus={setKycStatus}
                email={email}
              />
            );
          case 2:
            return (
              <UploadIDProof
                userName={userName}
                idBackSide={idBackSide}
                setIdBackSide={setIdBackSide}
                idFrontSide={idFrontSide}
                setIdFrontSide={setIdFrontSide}
                setErrorForID={setErrorForID}
                KYCPageNumber={KYCPageNumber}
                setKYCPageNumber={setKYCPageNumber}
                updateFirebaseUserProfile={updateFirebaseUserProfile}
                firstName={firstName}
                lastName={lastName}
                setErrorForPancard={setErrorForPancard}
                email={email}
                phoneNumber={phoneNumber}
                DOB={DOB}
                country={country}
                region={region}
                addressLineOne={addressLineOne}
                addressLineTwo={addressLineTwo}
                city={city}
                zipCode={zipCode}
                idBackSideFileType={idBackSideFileType}
                setIdBackSideFileType={setIdBackSideFileType}
                idFrontSideFileType={idFrontSideFileType}
                errorForPancard={errorForPancard}
                errorForAadhar={errorForAadhar}
                setErrorForAadhar={setErrorForAadhar}
                errorForID={errorForID}
                setIdFrontSideFileType={setIdFrontSideFileType}
                selectedFileName={selectedFileName}
                setSelectedFileName={setSelectedFileName}
                pancardHolding={pancardHolding}
                setPancardHolding={setPancardHolding}
                aadharHolding={aadharHolding}
                setAadharHolding={setAadharHolding}
                errorForAadharHold={errorForAadharHold}
                setErrorForAadharHold={setErrorForAadharHold}
                errorForPancardHold={errorForPancardHold}
                setErrorForPancardHold={setErrorForPancardHold}
                isFileUploaded={isFileUploaded}
                setIsFileUploaded={setIsFileUploaded}
                reSubmitKyc={reSubmitKyc}
                setResubmitKyc={setResubmitKyc}
                KycStatus={KycStatus}
                setKycStatus={setKycStatus}
                aadhaarHolding={aadharHolding}
                selectedCountryCode={selectedCountryCode}
                setSelectedCountryCode={setSelectedCountryCode}
                selectedCountry={selectedCountry}
                setSelectedCountry={selectedRegion}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                bankName={bankName}
                setBankName={setBankName}
                bankAccountType={bankAccountType}
                setBankAccountType={setBankAccountType}
                ABARoutingNumber={ABARoutingNumber}
                setABARoutingNumber={setABARoutingNumber}
                accountNumber={accountNumber}
                setAccountNumber={setAccountNumber}
              />
            );
          case 3:
            return (
              <UserBankAccountDetails
                userName={userName}
                idBackSide={idBackSide}
                setIdBackSide={setIdBackSide}
                idFrontSide={idFrontSide}
                setIdFrontSide={setIdFrontSide}
                setErrorForID={setErrorForID}
                KYCPageNumber={KYCPageNumber}
                setKYCPageNumber={setKYCPageNumber}
                updateFirebaseUserProfile={updateFirebaseUserProfile}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phoneNumber={phoneNumber}
                DOB={DOB}
                country={country}
                region={region}
                addressLineOne={addressLineOne}
                addressLineTwo={addressLineTwo}
                city={city}
                zipCode={zipCode}
                idBackSideFileType={idBackSideFileType}
                setIdBackSideFileType={setIdBackSideFileType}
                idFrontSideFileType={idFrontSideFileType}
                errorForPancard={errorForPancard}
                errorForAadhar={errorForAadhar}
                setErrorForAadhar={setErrorForAadhar}
                errorForID={errorForID}
                setIdFrontSideFileType={setIdFrontSideFileType}
                selectedFileName={selectedFileName}
                setSelectedFileName={setSelectedFileName}
                pancardHolding={pancardHolding}
                setPancardHolding={setPancardHolding}
                aadharHolding={aadharHolding}
                setAadharHolding={setAadharHolding}
                errorForAadharHold={errorForAadharHold}
                setErrorForAadharHold={setErrorForAadharHold}
                errorForPancardHold={errorForPancardHold}
                setErrorForPancardHold={setErrorForPancardHold}
                isFileUploaded={isFileUploaded}
                setIsFileUploaded={setIsFileUploaded}
                reSubmitKyc={reSubmitKyc}
                setResubmitKyc={setResubmitKyc}
                KycStatus={KycStatus}
                setKycStatus={setKycStatus}
                aadhaarHolding={aadharHolding}
                selectedCountryCode={selectedCountryCode}
                setSelectedCountryCode={setSelectedCountryCode}
                selectedCountry={selectedCountry}
                setSelectedCountry={selectedRegion}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                bankName={bankName}
                setBankName={setBankName}
                bankAccountType={bankAccountType}
                setBankAccountType={setBankAccountType}
                ABARoutingNumber={ABARoutingNumber}
                setABARoutingNumber={setABARoutingNumber}
                accountNumber={accountNumber}
                setAccountNumber={setAccountNumber}
              />
            );
          case 4:
            return (
              <KYCCompleted
                userName={userName}
                idBackSide={idBackSide}
                setIdBackSide={setIdBackSide}
                idFrontSide={idFrontSide}
                setIdFrontSide={setIdFrontSide}
                setErrorForID={setErrorForID}
                KYCPageNumber={KYCPageNumber}
                setKYCPageNumber={setKYCPageNumber}
                idBackSideFileType={idBackSideFileType}
                setIdBackSideFileType={setIdBackSideFileType}
                idFrontSideFileType={idFrontSideFileType}
                errorForPancard={errorForPancard}
                errorForAadhar={errorForAadhar}
                setErrorForAadhar={setErrorForAadhar}
                errorForID={errorForID}
                setIdFrontSideFileType={setIdFrontSideFileType}
                selectedFileName={selectedFileName}
                setSelectedFileName={setSelectedFileName}
                pancardHolding={pancardHolding}
                setPancardHolding={setPancardHolding}
                aadharHolding={aadharHolding}
                setAadharHolding={setAadharHolding}
                errorForAadharHold={errorForAadharHold}
                setErrorForAadharHold={setErrorForAadharHold}
                errorForPancardHold={errorForPancardHold}
                setErrorForPancardHold={setErrorForPancardHold}
                isFileUploaded={isFileUploaded}
                setIsFileUploaded={setIsFileUploaded}
                reSubmitKyc={reSubmitKyc}
                setResubmitKyc={setResubmitKyc}
                KycStatus={KycStatus}
                setKycStatus={setKycStatus}
                aadhaarHolding={aadharHolding}
                selectedCountryCode={selectedCountryCode}
                bankName={bankName}
                setBankName={setBankName}
                bankAccountType={bankAccountType}
                setBankAccountType={setBankAccountType}
                ABARoutingNumber={ABARoutingNumber}
                setABARoutingNumber={setABARoutingNumber}
                accountNumber={accountNumber}
                setAccountNumber={setAccountNumber}
              />
            );
          default:
            return (
              <>
                {" "}
                {CardBody()}
                {/* <div className={styles.gasTrackerContainer}>
                  {isMobile && <GasTracker />}
                </div> */}
              </>
            );
        }
      })()}
    </>
  );
};

export default PersonalInformation;
