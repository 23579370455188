import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Outlet,
  useParams,
  useLocation,
} from "react-router-dom";
import Home from "./home";
import Stake from "./stake";
import Borrow from "./borrow";
import GetRUD from "./RUD/Rud";
import RudDummyBuy from "./RUD/RudDummyBuy";
import RudDummySell from "./RUD/RudDummySell";
import Liquidate from "./liquidate/liquidate";
import Settings from "./settings";
import RudDummy from "./RUD/RudDummy";
import RudBuy from "./RUD/RudBuy/RudBuy";
import RUDSell from "./RUD/RUDSell/RUDSell";
import Security from "./settings/Security/Security";
import KYC from "./settings/KYC/KYC";
import RudLandingPage from "./RUD/RUDLandingPage/RUDLandingPage";

const MainRoutes = () => {
  const location = useLocation();
  const [shouldRenderRUD, setShouldRenderRUD] = useState(true);
  const [shouldRenderSettings, setShouldRenderSettings] = useState(true);
  // state to switch to the RUD Landing Page
  const [showNextpage, setShowNextpage] = useState(false);
  const [titleButtonVal, setTitleButtonVal] = useState(null);
  useEffect(() => {
    // Check if the current location is exactly "/testing"
    setShouldRenderRUD(location.pathname === "/get_rud");
    setShouldRenderSettings(location.pathname === "/settings");
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/stake" element={<Stake />} />
      <Route path="/borrow" element={<Borrow />} />
      <Route
        path="/get_rud/*"
        element={
          <>
            {shouldRenderRUD && (
              <GetRUD
                showNextpage={showNextpage}
                setShowNextpage={setShowNextpage}
                titleButtonVal={titleButtonVal}
                setTitleButtonVal={setTitleButtonVal}
              />
            )}
            <Routes>
              <Route
                path="buy"
                element={
                  <RudLandingPage
                    showNextpage={showNextpage}
                    setShowNextpage={setShowNextpage}
                    titleButtonVal="BUY"
                    setTitleButtonVal={setTitleButtonVal}
                    setShouldRenderRUD={setShouldRenderRUD}
                  />
                }
              />
              <Route
                path="sell"
                element={
                  <RudLandingPage
                    showNextpage={showNextpage}
                    setShowNextpage={setShowNextpage}
                    titleButtonVal="SELL"
                    setTitleButtonVal={setTitleButtonVal}
                    setShouldRenderRUD={setShouldRenderRUD}
                  />
                }
              />
              {/* The index route matches "/get_rud", rendering Outlet */}
              <Route index element={<Outlet />} />
            </Routes>
          </>
        }
      />
      {/* Hide this for MVP */}
      {/* <Route path="/inbox" element={<WIP />} /> */}
      <Route path="/liquidate" element={<Liquidate />} />
      <Route
        path="/settings/*"
        element={
          <>
            {shouldRenderSettings && (
              <Settings
                shouldRenderSettings={shouldRenderSettings}
                titleButtonVal="KYC"
                setTitleButtonVal={setTitleButtonVal}
              />
            )}
            {/* <Settings shouldRenderSettings={shouldRenderSettings} /> */}
            <Routes>
              <Route
                path="SECURITY"
                element={
                  <Settings
                    // titleButtonVal={
                    //   titleButtonVal === null ? "SECURITY" : titleButtonVal
                    // }
                    titleButtonVal="SECURITY"
                    setTitleButtonVal={setTitleButtonVal}
                  />
                }
              />
              <Route
                path="KYC"
                element={
                  <Settings
                    titleButtonVal="KYC"
                    // titleButtonVal={
                    //   titleButtonVal === null ? "KYC" : titleButtonVal
                    // }
                    setTitleButtonVal={setTitleButtonVal}
                  />
                }
              />
              {/* The index route matches "/testing", rendering Outlet */}
              <Route index element={<Outlet />} />
            </Routes>
          </>
        }
      />
      {/* <Route path="/settings" element={<Settings />} /> */}
      {/* Hide this for MVP */}
      {/* <Route path="/admin" element={<AdminPortal />} /> */}
    </Routes>
  );
};

export default MainRoutes;
