import React, { useContext, useEffect, useState } from "react";
import { ROUTES_MAIN } from "../../../pages/routes";
import crossIcon from "../../../assets/Logout_cross.svg";
import menuIcon from "../../../assets/menu_icon.svg";
import MetaMaskIcon from "../../../assets/Metamask Icon.svg";
import styled from "styled-components";
import cx from "classnames";
import { CSSTransition } from "react-transition-group";
import styles from "./mobile.module.scss";
import { useRef } from "react";
import { shortenAddress } from "@usedapp/core";
import { AppContext, UserContext } from "../../../context";
import DocButton from "../Desktop/DocButton";
import FooterMain from "../../FooterMain";
import ProfileImage from "../Desktop/ProfileImage";
import KycSec from "../Desktop/KycSec";
import ProfileName from "../Desktop/ProfileName";
import { NavLink } from "react-router-dom";
import Statistics from "../../RightSidePanel/Statistics";
import Modal from "react-modal";
import LogoutMetamask from "./LogOutMetaMask/LogOutMetaMaskModal";
const NavDiv = styled.div`
  padding: 0 0px;
`;
const duration = 100;
const Button = styled.button`
  display: flex;
  justify-content: space-between;
  background: transparent;
  border: 1px solid #02aab0;
  border-radius: 5px;
  font-family: jura;
  color: white;
  cursor: pointer;
`;
const MetaIconElem = styled.img`
  margin: auto 4px;
  margin-right: 4px;
`;
const MetaAddress = styled.span`
  font-family: "metrophobic";
  font-size: 11px;
  align-self: center;
  padding: 0 9px;
`;
const NavBarMobile = () => {
  // default mobile view navbar
  const [rendered, setRendered] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const nodeRef = useRef();
  const transitionStyles = {
    enterActive: styles.menuBarEnterActive,
    enterDone: styles.menuBarEnter,
    exitActive: styles.menuBarExitActive,
    exitDone: styles.menuBarExit,
  };
  // State to set the Modal open or close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleOnClickButton = () => {
    setModalIsOpen(true);
  };
  const { ens, account } = useContext(UserContext);
  const { isMobile } = useContext(AppContext);
  const nonActive = styles.nav;
  const activeNav = styles.activeNav;
  useEffect(() => {
    if (ens) {
      setRendered(ens);
    } else if (account) {
      setRendered(shortenAddress(account));
    } else {
      setRendered("");
    }
  }, [account, ens, setRendered]);

  const handleNavLinkClick = () => {
    setShowMenu(false);
  };
  return (
    <>
      <div>
        <div className={styles.mobileButtons}>
          <Button onClick={handleOnClickButton}>
            <MetaIconElem src={MetaMaskIcon} alt="metaMask" />
            <MetaAddress>{rendered}</MetaAddress>
          </Button>
          <button
            className={cx(styles.hamburgerButton, {
              [styles.hbWhite]: !showMenu,
              [styles.hbGreen]: showMenu,
            })}
            onClick={() => setShowMenu((pre) => !pre)}
          >
            {showMenu ? (
              <img src={crossIcon} alt="cross" className={styles.menuIcon} />
            ) : (
              <img src={menuIcon} alt="menu icon" className={styles.menuIcon} />
            )}
          </button>
        </div>
        <CSSTransition
          nodeRef={nodeRef}
          in={showMenu}
          timeout={duration}
          classNames={transitionStyles}
          mountOnEnter
        >
          <div ref={nodeRef} className={styles.menuBar}>
            <div className={styles.ProfileSecMob}>
              <div className={styles.profileImageMob}>
                <ProfileImage />
              </div>
              <div className={styles.profileNameMob}>
                <ProfileName />
              </div>
            </div>
            <nav>
              <div
                className={cx(styles.MainmenuBar, {
                  [styles.MainMob]: isMobile,
                })}
              >
                <NavDiv
                  className={cx(styles.navBar, {
                    [styles.navBarM]: isMobile,
                  })}
                >
                  {ROUTES_MAIN.map((item) => (
                    <NavLink
                      end="/"
                      to={item.route}
                      key={item.route}
                      style={{ textDecoration: "none" }}
                      onClick={handleNavLinkClick}
                    >
                      {({ isActive }) => {
                        return (
                          <span className={isActive ? activeNav : nonActive}>
                            <div className={styles.ulDiv}>
                              <span className={styles.spanInNav}>
                                {item.label}
                              </span>
                            </div>
                          </span>
                        );
                      }}
                    </NavLink>
                  ))}
                </NavDiv>
              </div>
              <div className={styles.kYCMob}>
                <KycSec />
              </div>
            </nav>
            <div className={styles.StatisticsMob}>
              <Statistics />
            </div>
            <div></div>
            <div className={styles.docButttonMob}>
              <DocButton />
            </div>
            <div className={styles.footerMob}>
              <FooterMain />
            </div>
          </div>
        </CSSTransition>
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <LogoutMetamask setModalIsOpen={setModalIsOpen} />
      </Modal>
    </>
  );
};

export default NavBarMobile;
