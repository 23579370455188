import React from "react";
import cx from "classnames";
import { AppContext } from "../../../../context";
import styles from "./index.module.scss";
import { useContext } from "react";
import { SETTINGS_ENUM } from "../../../../enums/settingsEnum";
import ButtonRade from "../../../../components/RadeButtons";
const ProfileUpdatePopUp = ({ setModalProfileUpdate, confirmFunc }) => {
  const { isMobile } = useContext(AppContext);
  const closeModal = () => {
    setModalProfileUpdate(false);
  };

  return (
    <>
      <div
        className={cx(styles.profileConfirmPopUp, {
          [styles.profileConfirmPopUpWeb]: !isMobile,
          [styles.profileConfirmPopUpMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.confirmMessage, {
            [styles.confirmMessageWeb]: !isMobile,
            [styles.confirmMessageMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.confirmMessage}
        </div>
        <div className={styles.buttonDiv}>
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonWeb]: !isMobile,
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={closeModal}
          >
            {" "}
            {SETTINGS_ENUM.cancelButton}
          </ButtonRade>
          <ButtonRade
            outline
            customStyling={cx(styles.submitButton, {
              [styles.submitButtonWeb]: !isMobile,
              [styles.submitButtonMob]: isMobile,
            })}
            onClick={confirmFunc}
          >
            {SETTINGS_ENUM.submit}
          </ButtonRade>
        </div>
      </div>
      ;
    </>
  );
};
export default ProfileUpdatePopUp;
