import React, { useContext, useEffect } from "react";
import { Divider, Header } from "..";
import { AppContext, UserContext } from "../../context";
import NavBar from "../NavBar";
import Logo from "../../assets/logo_rade.svg";
import { useEthers } from "@usedapp/core";
import styles from "./HeaderMain.module.scss";
import cx from "classnames";
import SwitchNetworks from "../SwitchNetworks/SwitchNetworks";
import { APP_ENUM } from "../../enums/appEnum";
import { CSSTransition } from "react-transition-group";
import getNetworkDetails from "../NetworkDetails/NetworkDetails";
import radeTitle from "../../assets/radeTitle.svg";
import Spinner from "../Spinner/Spinner";
import HeaderSpinner from "./Loading";
//array of accepted networks
const acceptedNetworksList = [
  APP_ENUM.en.ethNetwork,
  APP_ENUM.en.goerliTestNetwork,
  APP_ENUM.en.hardhatNetwork,
  APP_ENUM.en.sepoliaNetwork,
  APP_ENUM.en.mumbaiNetwork,
];
const duration = 100;
let currNetwork = null;
const HeaderMain = (interval = null) => {
  const { ens, connectedNetworkName, setConnectedNetworkName } =
    useContext(UserContext);
  const { account } = useEthers();

  const { isMobile } = useContext(AppContext);
  //const [connectedNetworkName, setConnectedNetworkName] = useState("");
  const transitionStyles = {
    enterActive: styles.notificationBarEnterActive,
    enterDone: styles.notificationBarEnter,
    exitActive: styles.notificationBarExitActive,
    exitDone: styles.notificationBarExit,
  };
  //timer to check each 10sec on which metamask network is connected

  useEffect(() => {
    const connectedNetwork = async () => {
      const chainID = await window.ethereum.request({ method: "net_version" });
      const { networkName } = getNetworkDetails(chainID);
      setConnectedNetworkName(networkName);
    };
    if (connectedNetworkName !== currNetwork) {
      clearInterval(interval);
      currNetwork = connectedNetworkName;
    }

    interval = setInterval(connectedNetwork, 1000);
    return () => {
      clearInterval(interval);
      interval = null;
      currNetwork = null;
    };
  }, [connectedNetworkName]);

  return (
    <Header className={styles.border}>
      <div
        className={cx(styles.logoContainer, {
          [styles.logoContainerMob]: isMobile,
          [styles.headerSpinner]: connectedNetworkName === null,
        })}
      >
        {(ens || account) && connectedNetworkName && (
          <a
            href="https://www.rariti.io"
            target="_blank"
            rel="noreferrer"
            className={styles.redirectToLandingPage}
          >
            <div className={styles.logoNameContainer}>
              <img
                className={cx(styles.radeLogo, {
                  [styles.logoM]: isMobile,
                })}
                src={Logo}
                alt="rade_logo"
              />
              <img
                className={cx(styles.radeHeading, {
                  [styles.radeheadMob]: isMobile,
                })}
                src={radeTitle}
                alt="rade_title"
              />
            </div>
          </a>
        )}

        {/*display the component only if the currently connected network is defined in the accepted network array */}
        {(ens || account) && (
          <CSSTransition
            in={!acceptedNetworksList.includes(connectedNetworkName)}
            timeout={duration}
            classNames={transitionStyles}
            mountOnEnter
            unmountOnExit
          >
            {connectedNetworkName === null ? (
              <HeaderSpinner />
            ) : (
              <SwitchNetworks />
            )}
          </CSSTransition>
        )}
      </div>
      <div className={styles.addressContainer}>
        {(ens || account) && connectedNetworkName && <Divider />}
      </div>
      {(ens || account) && connectedNetworkName && <NavBar />}
    </Header>
  );
};

export default HeaderMain;
