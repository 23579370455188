import * as React from "react";
import styles from "../components/NavBar/Desktop/index.module.scss";
const LiquidateIcon = (props) => (
  <svg
    {...props}
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svgImage}
  >
    <rect
      x={1}
      y={1}
      width={23}
      height={23}
      rx={3}
      stroke="#A5A2B8"
      strokeWidth={2}
    />
    <path
      d="M12.5 19.4c-1.5 0-2.9-.6-3.9-1.6S7 15.4 7 13.9c.1-2 .8-3.8 2.1-5.4.2-.2.4-.5.5-.7.8-.9 1.7-1.8 2.6-2.5l.3-.3.3.2c.2.2 5.2 4 5.2 8.6 0 1.5-.6 2.9-1.6 3.9-1 1.1-2.4 1.7-3.9 1.7Zm0-13.2c-.7.7-1.5 1.4-2.1 2.2-.2.2-.4.4-.5.7-1.1 1.4-1.8 3-1.9 4.8 0 1.2.5 2.3 1.3 3.2.8.8 2 1.3 3.2 1.3 1.2 0 2.4-.5 3.2-1.3.9-.9 1.3-2 1.3-3.2.1-3.6-3.4-6.8-4.5-7.7Z"
      fill="#A5A2B8"
    />
    <path
      d="M12.5 17.5v-1c1.8 0 2-1.9 2-2.7h1c.1 2.3-1.1 3.7-3 3.7ZM15.6 11.898h-1v1.3h1v-1.3Z"
      fill="#A5A2B8"
    />
  </svg>
);

export default LiquidateIcon;
