const { ethers } = require("ethers");

const CurrentConfig = {
  rpc: {
    local: "http://localhost:8545",
    mainnet: "https://rpc.ankr.com/eth",
    georli: "https://rpc.ankr.com/eth_goerli",
    mumbaiL: "https://rpc.ankr.com/polygon",
  },
};
function getProvider(chainID) {
  if (chainID === "5") {
    return new ethers.providers.JsonRpcProvider(CurrentConfig.rpc.georli);
  } else if (chainID === "31337") {
    return new ethers.providers.JsonRpcProvider(CurrentConfig.rpc.mainnet);
  } else if (chainID === "1") {
    return new ethers.providers.JsonRpcProvider(CurrentConfig.rpc.mainnet);
  } else if (chainID === "6") {
    return new ethers.providers.JsonRpcProvider(CurrentConfig.rpc.mainnet);
  } else if (chainID === "80001") {
    return new ethers.providers.JsonRpcProvider(CurrentConfig.rpc.mainnet);
  }
}

module.exports = { getProvider };
