import React from "react";
import "./index.css";
const ShakeError = ({ errorMessage, customStyling }) => {
  return (
    <div className="animated shake">
      <div className={customStyling}>{errorMessage}</div>
    </div>
  );
};
export default ShakeError;
