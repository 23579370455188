import React, { useState } from "react";
import cx from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../RudBuy/index.module.scss";
import ButtonRade from "../../../components/RadeButtons";
import ProceedBuyRudUsingETH from "../ProceedBuyRudUsingETH/ProceedBuyRudUsingETH";
import ProceedBuyRudUsingUsd from "../ProceedBuyRudUsingUsd";
import Radio from "../../../components/RadioButtons/RadioButton";
import GetRUD from "../Rud";
import { RudContext } from "../RUDContext";
import { TOOL_TIP } from "../../../enums/homeEnum";
import { useNavigate } from "react-router-dom";

const RudBuy = ({
  setConfirmTransaction,
  setErrorMessage,
  setIsError,
  setWaitingConfirmation,
  setModalOpen,
  setShowNextpage,
  setShouldRenderRUD,
}) => {
  // const { setShowNextpage } = useContext(RudContext);
  const [userModeSelection, setUserModeSelection] = useState(null);
  const [returnRUDLandingPage, setReturnRUDLandingPage] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const { isMobile } = useContext(AppContext);
  let navigate = useNavigate();

  //State to set the error message before proceed if user hasn't selected any options.
  const [error, setError] = useState(false);
  // Currency Options
  const currencyListOne = [{ value: "ETH", label: "Ethereum" }];
  const renderTooltip2 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyleForMemberVouch}>
      {TOOL_TIP.comingSoon}
    </Tooltip>
  );
  const handleSubmitProceedButton = (e) => {
    if (!userModeSelection) {
      setError(true);
    } else {
      setError(false);
      setCanProceed(true);
    }
  };
  const handleSubmitCancelButton = () => {
    // setReturnRUDLandingPage(true);
    setShowNextpage(false);
    setShouldRenderRUD(false);
    navigate("/get_rud");
  };
  //Get the value choosen by the user (either Fiat or ETH)
  const handleChangeGettingRadioInput = (e) => {
    setError(false);
    setUserModeSelection(e.target.value);
  };

  const CardBody = () => (
    <>
      <div className={styles.buttonContentDiv}>
        <div
          className={cx(styles.currencyChoose, {
            [styles.currencyChooseMob]: isMobile,
          })}
        >
          {RUD_ENUM.currency_choose}
        </div>
        <div
          className={cx(styles.currencyOptionsDiv, {
            [styles.currencyOptionsDivMob]: isMobile,
          })}
        >
          <Radio
            name="currency"
            value={userModeSelection}
            options={currencyListOne}
            onChangeFunc={handleChangeGettingRadioInput}
            error={error}
          />
          <OverlayTrigger placement="top" overlay={renderTooltip2}>
            <div
              className={cx(styles.dummyMemberVouchRadioButton, {
                [styles.dummyMemberVouchRadioButtonMob]: isMobile,
              })}
            >
              <span className={styles.circle}></span>

              <div
                className={cx(styles.memberVouchButton, {
                  [styles.memberVouchButtonMob]: isMobile,
                })}
              >
                Fiat-USD
              </div>
            </div>
          </OverlayTrigger>
        </div>
        <div
          className={cx(styles.proceedButtonDiv, {
            [styles.proceedButtonDivMob]: isMobile,
          })}
        >
          {/* Custom styled cancel button */}
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={handleSubmitCancelButton}
          >
            {RUD_ENUM.cancelButton}
          </ButtonRade>
          {/* Custom styled button for proceed */}
          <ButtonRade
            outline
            customStyling={cx(styles.proceedButton, {
              [styles.proceedButtonMob]: isMobile,
            })}
            onClick={() => {
              handleSubmitProceedButton();
            }}
          >
            {RUD_ENUM.proceed_button}
            {/* <ProceedIcon /> */}
          </ButtonRade>
        </div>
      </div>
    </>
  );
  return (
    <RudContext.Provider value={{ setUserModeSelection, setCanProceed }}>
      {/* Update to next pages in the buy flow based on the currency that user selects */}
      {(() => {
        switch (userModeSelection) {
          case "Fiat":
            if (canProceed) {
              return (
                <ProceedBuyRudUsingUsd
                  setUserModeSelection={setUserModeSelection}
                  setCanProceed={setCanProceed}
                />
              );
            } else {
              // calling as a function to avoid flickering issue
              return CardBody();
            }
          case "ETH":
            if (canProceed) {
              return (
                <ProceedBuyRudUsingETH
                  setConfirmTransaction={setConfirmTransaction}
                  setErrorMessage={setErrorMessage}
                  setIsError={setIsError}
                  setWaitingConfirmation={setWaitingConfirmation}
                  setModalOpen={setModalOpen}
                  setUserModeSelection={setUserModeSelection}
                  setCanProceed={setCanProceed}
                />
              );
            } else {
              // calling as a function to avoid flickering issue
              return CardBody();
            }

          default:
            return returnRUDLandingPage ? <GetRUD /> : CardBody(); // calling as a function to avoid flickering issue
        }
      })()}
    </RudContext.Provider>
  );
};
export default RudBuy;
