import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm/CheckoutForm";
import styles from "./index.module.scss";

const stripePromise = loadStripe(
  "pk_test_51MdCeoSAuL1kdOPaadTOfsb0yS72PcUcCTe8y2GQmfkgq4UQyKWIjyJurKjPbdDQBnYY4KYtGPpArhBd9d9D6J7i00lnTX5wCC"
);

const StripePayment = ({ amount, isCancel }) => {
  const options = {
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Metrophobic",
      },
    ],
  };

  return (
    <div className={styles.stripe}>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm amount={amount} isCancel={isCancel} />
      </Elements>
    </div>
  );
};

export default StripePayment;
