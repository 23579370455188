import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "./index.module.scss";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
const ReturnUserConfirm = ({ isError, errorMessage }) => {
  const { isMobile } = useContext(AppContext);
  return (
    <>
      <div
        className={cx(styles.ConfirmDiv, {
          [styles.ConfirmDivMob]: isMobile,
        })}
      >
        <ul className={styles.loaderList}>
          <li>
            <div
              className={cx(styles.loaderCircle, {
                [styles.loaderCircleMob]: isMobile,
              })}
            >
              <span></span>
            </div>
          </li>
        </ul>
        <div
          className={cx(styles.confirmContent, {
            [styles.confirmContentMob]: isMobile,
          })}
        >
          {RUD_ENUM.confirm_content}
        </div>
        {isError && (
          <ShakeError
            errorMessage={errorMessage}
            customStyling={cx(styles.alertDeniedTransaction, {
              [styles.alertDeniedTransactionMob]: isMobile,
            })}
          />
        )}
      </div>
    </>
  );
};

export default ReturnUserConfirm;
