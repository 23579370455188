import { ethers } from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import StakingContract from "../../../src/artifacts/Contracts/stablecoin/RUDStaking.sol/RUDStaking.json";
const provider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum)
  : null;

const RudStakingContract = new ethers.Contract(
  StakingContract.address, //RudStaking deployed contract address
  StakingContract.abi, //RudStaking deployed contract abi
  provider
);
let currAcc = null;
const useStakedUserDetails = (account, interval = null) => {
  //state to set the connected user's staked details
  const [userStakedDetails, setUserStakedDetails] = useState(null);
  const [stakeContractBalance, setStakeContractBalance] = useState(null);

  //function to get the RUD balance using

  useEffect(() => {
    try {
      const getStakedUserDetails = async () => {
        if (RudStakingContract && account) {
          const stakingContractBalance =
            await RudStakingContract.getStakingBook();
          const stakedUserDetails = await RudStakingContract.getUserDetails(
            account
          );

          if (
            !userStakedDetails ||
            stakedUserDetails.totalStakedAmount !==
              userStakedDetails.totalStakedAmount
          ) {
            setUserStakedDetails(stakedUserDetails);
            setStakeContractBalance(stakingContractBalance);
          }
        } else {
          setUserStakedDetails(null);
          setStakeContractBalance(undefined);
        }
      };
      if (account !== currAcc) {
        clearInterval(interval);
        currAcc = account;
      }
      interval = setInterval(getStakedUserDetails, 1000);

      return () => {
        clearInterval(interval);
        interval = null;
        currAcc = null;
      };
    } catch (error) {
      console.log("error", error);
    }
  }, [account]);

  if (userStakedDetails) {
    return {
      userDetails: userStakedDetails,
      stakedRudBalance: ethers.utils.formatEther(
        userStakedDetails.totalStakedAmount
      ),
      contractStakedBalance: stakeContractBalance,
      loading: false,
    };
  } else {
    return {
      userDetails: null,
      stakedRudBalance: null,
      loading: true,
      contractStakedBalance: null,
    };
  }
};
export default useStakedUserDetails;
