import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "./index.module.scss";
import ButtonRade from "../../../components/RadeButtons";
import { STAKE_ENUM } from "../../../enums/stakeEnum";
const ReturnUserSuccess = ({ setModalIsOpen }) => {
  const { isMobile } = useContext(AppContext);
  //Function to close the modal
  const closeSuccessfullWindow = () => {
    setModalIsOpen(false);
  };

  return (
    <div
      className={cx(styles.transactionSubmitDiv, {
        [styles.transactionSubmitDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.transactionSubmit, {
          [styles.transactionSubmitMob]: isMobile,
        })}
      >
        {RUD_ENUM.unstakingSuccessful}
      </div>

      <div
        className={cx(styles.okayButtonDiv, {
          [styles.okayButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.closeButton, {
            [styles.closeButtonMob]: isMobile,
          })}
          onClick={closeSuccessfullWindow}
        >
          {STAKE_ENUM.closeButton}
        </ButtonRade>
      </div>
    </div>
  );
};

export default ReturnUserSuccess;
