import HomeIcon from "../assets/Home Icon";
import BorrowIcon from "../assets/Borrow";
import LendIcon from "../assets/Lend Icon";
import WIP from "./WIP/WIP";
// import AdminIcon from "../assets/AdminIcon";
// import AdminPortal from "./Admin/Admin";
// todo: import Inbox from "./inbox/inbox";
//import InboxIcon from "../assets/Inbox Icon";
import LiquidateIcon from "../assets/Liquidate";
import RUDIcon from "../assets/RUDIcon";
import SettingsIcon from "../assets/SettingsIcon";
import NextIconSideBar from "../assets/NextIconSideBar";

export const ROUTES_MAIN = [
  {
    label: "Home",
    route: "/",
    image: <HomeIcon />,
  },
  {
    label: "Stake",
    route: "/stake",
    image: <LendIcon />,
  },
  {
    label: "Borrow",
    route: "/borrow",
    image: <BorrowIcon />,
  },

  {
    label: "RUD",
    route: "/get_rud",
    image: <RUDIcon />,
    nextIcon: <NextIconSideBar />,

    subRoutes: [
      {
        label: "Buy",
        route: "buy",
      },
      {
        label: "Sell",
        route: "sell",
      },
    ],
  },
  // Hide this for MVP
  // {
  //   label: "Inbox",
  //   route: "/inbox",
  //   image: <InboxIcon />,
  //   component: <WIP />,
  // },
  {
    label: "Liquidate",
    route: "/liquidate",
    image: <LiquidateIcon />,
  },
  {
    label: "Settings",
    route: "/settings",
    image: <SettingsIcon />,
    nextIcon: <NextIconSideBar />,

    subRoutes: [
      {
        label: "KYC",
        route: "KYC",
      },
      {
        label: "Security",
        route: "Security",
      },
    ],
  },
  // Hide this for MVP
  // {
  //   label: "Admin",
  //   route: "/admin",
  //   image: <AdminIcon />,
  //   component: <AdminPortal />,
  // },
];
