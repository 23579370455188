import React from "react";

function RUDIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#A5A2B8"
        stroke="#A5A2B8"
        strokeWidth="0.5"
        d="M5.351 24.25H19.65a4.575 4.575 0 004.601-4.601V5.35A4.575 4.575 0 0019.649.75H5.35A4.575 4.575 0 00.75 5.351V19.65a4.575 4.575 0 004.601 4.601zM2.493 5.351a2.828 2.828 0 012.858-2.858H19.65a2.828 2.828 0 012.858 2.858V19.65a2.828 2.828 0 01-2.858 2.858H5.35a2.828 2.828 0 01-2.858-2.858V5.35z"
      ></path>
      <path
        fill="#A5A2B8"
        stroke="#A5A2B8"
        strokeWidth="0.5"
        d="M17.429 15.625h4.62c.824 0 1.482-.696 1.482-1.528v-3.194c0-.832-.658-1.528-1.482-1.528h-4.62c-1.687 0-3.023 1.398-3.023 3.125s1.336 3.125 3.023 3.125zM15.954 12.5c0-.835.641-1.539 1.475-1.539h4.555v3.078h-4.555c-.778 0-1.475-.71-1.475-1.539z"
      ></path>
    </svg>
  );
}

export default RUDIcon;
