import React from "react";
import cx from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AppContext, UserContext } from "../../../context";
import { useContext, useState, useEffect } from "react";
import styles from "./index.module.scss";
import ButtonRade from "../../../components/RadeButtons";
import { SETTINGS_ENUM } from "../../../enums/settingsEnum";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import { db } from "../../../firebase";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import KYCCompleted from "../KYCCompleted/KYCCompleted";
import KYCRejectedIcon from "../../../assets/KYC pending icon.svg";
import { TOOL_TIP } from "../../../enums/homeEnum";
const KYC = () => {
  // State for proceeding the KYCAML button
  const [onclickKYCButton, setOnclickKYCButton] = useState("");
  const { isMobile, KycStatus, setKycStatus } = useContext(AppContext);
  const [KYCRejectionReason, setKYCRejectionReason] = useState(null);
  const renderToolfeautureNotYetActivated = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.feautureNotYetActivated}
    </Tooltip>
  );
  const { account } = useContext(UserContext);
  // status to store KYC status
  const updateFirebaseUserProfile = async (
    firstName,
    lastName,
    userName,
    email,
    DOB,
    selectedCountryCode,
    phone,
    country,
    region,
    addressLine1,
    addressLine2,
    city,
    pincode,
    idFrontSide,
    idBackSide,
    holdingPassport,
    holdingPassportBackside,
    kycStatus,
    bankName,
    bankAccountType,
    ABARoutingNumber,
    accountNumber
  ) => {
    try {
      // Create a document reference in the 'userData' collection with the user's MetaMask account address
      const userDataCollection = collection(db, "userData");
      const userDataDocRef = doc(userDataCollection, account);
      // Update the 'userData' document with the new field values
      await updateDoc(userDataDocRef, {
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        email: email,
        DOB: DOB,
        selectedCountryCode: selectedCountryCode ? selectedCountryCode : "+1",
        phone: phone,
        country: country,
        region: region,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        pincode: pincode,
        idFrontSide: idFrontSide,
        idBackSide: idBackSide,
        holdingPassport: holdingPassport,
        holdingPassportBackside: holdingPassportBackside,
        kycStatus: kycStatus,
        bankName: bankName,
        bankAccountType: bankAccountType,
        ABARoutingNumber: ABARoutingNumber,
        accountNumber: accountNumber,
      });

      // Document updated successfully
      console.log("userData Document successfully updated!");
    } catch (error) {
      // Handle any errors
      console.error("Error updating userData document: ", error);
    }
  };
  useEffect(() => {
    // Function to fetch the KYC status from the database
    async function fetchKYCStatus() {
      // Set loading to true to indicate that the data is being fetched

      // Create a reference to the "userData" collection in the database
      const usersRef = collection(db, "userData");

      // Get the document reference for the user with the given profileEmail
      const userDocRef = doc(usersRef, account);

      // Fetch the user document snapshot from the database
      const userDocSnapshot = await getDoc(userDocRef);

      // Check if the user document exists in the database
      if (userDocSnapshot.exists()) {
        // Extract user data from the document snapshot
        const userData = userDocSnapshot.data();

        // Get the KYC status from the user data
        const kYCStatus = userData.kycStatus;
        setKYCRejectionReason(userData.KYCRejectionReason);
        setKycStatus(kYCStatus);
      } else {
        setKycStatus("Not initialized");
      }
    }

    // Call the fetchKYCStatus function when KycStatus or confirmUpdateModalOpen changes
    // This ensures that the KYC status is fetched whenever there's an update to those dependencies
    // For example, when the user submits or updates the KYC status
    fetchKYCStatus();
  }, [KycStatus]);
  const handleClickKYCAMLButton = () => {
    setOnclickKYCButton(true);
  };
  const CardBody = () => (
    <>
      {/* {KycStatus === "Not initialized" && ( */}
      <div
        className={cx(styles.KYCMainDiv, {
          [styles.KYCMainDivWeb]: !isMobile,
          [styles.KYCMainDivMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.KYCDiv, {
            [styles.KYCDivWeb]: !isMobile,
            [styles.KYCDivMob]: isMobile,
          })}
        >
          {SETTINGS_ENUM.profileIsNotverified}

          <br />
          {SETTINGS_ENUM.verifyYourProfile}
        </div>
        <OverlayTrigger
          placement="top"
          overlay={renderToolfeautureNotYetActivated}
        >
          <div>
            {" "}
            <ButtonRade
              outline
              disabled={true}
              customStyling={cx(styles.KYCAMLButton, {
                [styles.KYCAMLButtonWeb]: !isMobile,
                [styles.KYCAMLButtonMob]: isMobile,
              })}
              onClick={handleClickKYCAMLButton}
            >
              {SETTINGS_ENUM.KYCAMLButton}
            </ButtonRade>
          </div>
        </OverlayTrigger>
      </div>
      {/* )} */}
      {/* {KycStatus === "Rejected" && (
        <div className={styles.rejectedtextContainer}>
          <div
            className={cx(styles.KYCButtonDiv, {
              [styles.KYCButtonDivWeb]: !isMobile,
              [styles.KYCButtonDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.KYCButton, {
                [styles.KYCButtonWeb]: !isMobile,
                [styles.KYCButtonMob]: isMobile,
              })}
            >
              {SETTINGS_ENUM.KYC}

              <img
                src={KYCRejectedIcon}
                alt="KYC rejected"
                className={cx(styles.KYCInProgess, {
                  [styles.KYCInProgessWeb]: !isMobile,
                  [styles.KYCInProgessMob]: isMobile,
                })}
              />
            </div>
          </div>
          <p className={styles.statusText}>
            Status :{" "}
            <span className={styles.statusTextInRed}>
              Your application is rejected
            </span>
          </p>
          <p className={styles.statusText}>Reason: {KYCRejectionReason}</p>
          <ButtonRade
            onClick={handleClickKYCAMLButton}
            customStyling={cx(styles.reSubmitKYC, {
              [styles.reSubmitKYCWeb]: !isMobile,
              [styles.reSubmitKYCMob]: isMobile,
            })}
          >
            Re-Submit KYC
          </ButtonRade>
        </div>
      )} */}
    </>
  );
  // if (KycStatus === "Rejected" || KycStatus === "Not initialized") {
  return (
    <>
      {onclickKYCButton ? (
        <PersonalInformation
          setOnclickKYCButton={setOnclickKYCButton}
          onclickKYCButton={onclickKYCButton}
          updateFirebaseUserProfile={updateFirebaseUserProfile}
          KycStatus={KycStatus}
          setKycStatus={setKycStatus}
        />
      ) : (
        <CardBody />
      )}
    </>
  );
  // } else {
  //   return <KYCCompleted setKycStatus={setKycStatus} />;
  // }
};

export default KYC;
