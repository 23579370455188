import React from "react";
import styles from "./index.module.scss";
import { APP_ENUM } from "../../enums/appEnum";
import cx from "classnames";
import { useContext } from "react";
import { AppContext, UserContext } from "../../context";
import { useEthers } from "@usedapp/core";
import ButtonRade from "../RadeButtons";
import { useState } from "react";
import installMetamaskIcon from "../../assets/installMetamaskIcon.svg";
import ConnectMetamaskFlow from "../ConnectMetamaskFlow/ConnectMetamaskFlow";
import failedToConnectIcon from "../../assets/failedToConnectIcon.svg";
import MetaMaskOnboarding from "@metamask/onboarding";
const Failedmessage = (props) => {
  const { activateBrowserWallet } = useEthers();
  const { setIsMetamaskConnected } = useContext(UserContext);

  // state for storing accounts
  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef();
  React.useEffect(() => {
    if (!onboarding.current) {
      // Starts onboarding by opening the MetaMask download page and waiting for MetaMask to be installed.
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);
  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        activateBrowserWallet();
        // Stops onboarding
        onboarding.current.stopOnboarding();
      }
    }
  }, [activateBrowserWallet, accounts.length]);

  React.useEffect(() => {
    // Checking whether the metaMask provider is detected or not
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      activateBrowserWallet();
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((newAccounts) => setAccounts(newAccounts));
    }
  }, [activateBrowserWallet, accounts.length]);

  // state for the working of cancel button to go back to the previous step
  const [cancel, setCancel] = useState(false);
  const [retry, setRetry] = useState(false);
  // working of retry button
  const handleOnclickRetry = () => {
    setRetry(true);
    setIsMetamaskConnected(false);
  };
  // working of cancel button
  const handleOnclickCancel = () => {
    setCancel(true);
    setIsMetamaskConnected(false);
  };
  const onClickDownloadMetamask = () => {
    onboarding.current.startOnboarding();
    setIsMetamaskConnected(true);
  };
  const { isMobile } = useContext(AppContext);

  // Title that displays the heading in the connection failed section
  const CardTitle = () => (
    <div
      className={cx(styles.failedToConnectHeading, {
        [styles.failedToConnectHeadingMob]: isMobile,
      })}
    >
      {APP_ENUM.en.failedToConnect}
      <img
        src={failedToConnectIcon}
        className={styles.failedToConnectIcon}
        alt="Failed to connect icon"
      />
    </div>
  );
  // Card body
  const CardBody = () => (
    <div
      className={cx(styles.mainDiv, {
        [styles.mainDivMob]: isMobile,
      })}
    >
      <div
        className={cx(styles.installMetamask, {
          [styles.installMetamaskMob]: isMobile,
        })}
      >
        {APP_ENUM.en.installMetamask}
        <div className={styles.installMetamaskLink}>
          {/* Link to downlink the metamask extension*/}
          <button
            onClick={onClickDownloadMetamask}
            className={styles.getMetamaskLink}
          >
            {APP_ENUM.en.getMetamask}
            <img
              alt="installMetamask_Icon"
              src={installMetamaskIcon}
              className={styles.installMetamaskIcon}
            />
          </button>
        </div>
      </div>
      {/* Button component in the end */}
      <div className={styles.bottomButtonDiv}>
        <ButtonRade
          customStyling={cx(styles.cancelButton, {
            [styles.cancelButtonMob]: isMobile,
          })}
          onClick={handleOnclickCancel}
        >
          {APP_ENUM.en.cancelButton}
        </ButtonRade>
        <ButtonRade
          customStyling={cx(styles.retryButton, {
            [styles.retryButtonMob]: isMobile,
          })}
          onClick={handleOnclickRetry}
        >
          {APP_ENUM.en.retryButton}
        </ButtonRade>
      </div>
    </div>
  );

  return (
    <>
      {/* if user click cancel or retry button */}
      {cancel || retry ? (
        <ConnectMetamaskFlow
          setIsMetamaskConnected={props.setIsMetamaskConnected}
        />
      ) : (
        <div className={styles.failedMessageMainDiv}>
          <div
            className={cx(styles.confirmMessge, {
              [styles.confirmMessgeMob]: isMobile,
            })}
          >
            <CardTitle />

            <CardBody />
          </div>
        </div>
      )}
    </>
  );
};
export default Failedmessage;
