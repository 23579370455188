import React from "react";
import styles from "./index.module.scss";

function PdfPreview({ onClick, selectedFileName }) {
  return (
    <div className={styles.pdfPreview} onClick={onClick}>
      {selectedFileName ? (
        <>
          <div>{`Selected File: ${selectedFileName}`}</div>
          <div>Preview for PDF is not available.</div>
        </>
      ) : (
        <div>Preview for PDF is not available.</div>
      )}
    </div>
  );
}

export default PdfPreview;
