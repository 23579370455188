import React from "react";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useContext } from "react";
import { RUD_ENUM } from "../../../enums/rudEnum";
import styles from "../DepositRUDConfirmModal/index.module.scss";
import { STAKE_ENUM } from "../../../enums/stakeEnum";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
const DepositRUDConfirmModal = ({ value, isError, errorMessage }) => {
  const { isMobile } = useContext(AppContext);

  return (
    <>
      <div
        className={cx(styles.swapConfirmDiv, {
          [styles.swapConfirmDivMob]: isMobile,
        })}
      >
        {/* Animated Circle Loader*/}
        <ul className={styles.loaderList}>
          <li>
            <div
              className={cx(styles.loaderCircle, {
                [styles.loaderCircleMob]: isMobile,
              })}
            >
              <span></span>
            </div>
          </li>
        </ul>
        <div
          className={cx(styles.confirmContent, {
            [styles.confirmContentMob]: isMobile,
          })}
        >
          {RUD_ENUM.confirm_content}
        </div>
        <div
          className={cx(styles.liquidityPoolContent, {
            [styles.liquidityPoolContentMob]: isMobile,
          })}
        >
          {/* getting the user entered RUD amount in the previous input field using props*/}
          {STAKE_ENUM.addingRUD}
          {value} {STAKE_ENUM.addingRUD_in_LP}
        </div>
        {isError && (
          <ShakeError
            errorMessage={errorMessage}
            customStyling={cx(styles.alertDeniedTransaction, {
              [styles.alertDeniedTransactionMob]: isMobile,
            })}
          />
        )}
      </div>
    </>
  );
};
export default DepositRUDConfirmModal;
