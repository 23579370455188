import styles from "./NestDetails.index.module.scss";
import cx from "classnames";
import { useContext } from "react";
import { AppContext } from "../../context";
import { BORROW_ENUM } from "../../enums/borrowEnum";
import ButtonRade from "../../components/RadeButtons";
import UpArrowIcon from "../../assets/UpArrow";

const MAX_DECIMAL_PLACE = 2;
const ETHER_CONVERSION = 10 ** 18;
const NestDetails = ({
  setReturnUserBorrowFlowPage,
  nextPaymentAmount,
  collateralizationRatio,
  loanBalance,
  paymentSchedule,
  remainingTerms,
  nestCount,
  borrowedAmount,
  collateralAmount,
  loanDuration,
  nextPayment,
  toggleBorrowDetails,
  expandedRowIndex,
  nestId,
}) => {
  const { isMobile } = useContext(AppContext);
  const handleOnClickPay = () => {
    setReturnUserBorrowFlowPage(1);
  };
  const nextPaymentValue = nextPaymentAmount / ETHER_CONVERSION;
  const collateralETH = collateralAmount / ETHER_CONVERSION;
  const CollateralizationRatio = () => {
    return (
      <div
        className={cx(styles.nestDetails, {
          [styles.nestDetailsMob]: isMobile,
        })}
      >
        <div className={styles.nestDetailsHeading}>
          {BORROW_ENUM.collateralizationRatio}
        </div>
        <tr className={styles.nestDetailsValue}>{collateralizationRatio}%</tr>
      </div>
    );
  };
  const LoanBalance = () => {
    return (
      <>
        <div className={styles.nestDetails}>
          <div className={styles.nestDetailsHeading}>
            {BORROW_ENUM.loanBalance}
          </div>
          <tr className={styles.nestDetailsValue}>
            {Number(loanBalance).toFixed(MAX_DECIMAL_PLACE)}
          </tr>
        </div>
      </>
    );
  };
  const PaymentSchedule = () => {
    return (
      <>
        <div className={styles.nestDetails}>
          <div className={styles.nestDetailsHeading}>
            {BORROW_ENUM.paymentSchedule}
          </div>
          <tr className={styles.nestDetailsValue}>{paymentSchedule}</tr>
        </div>
      </>
    );
  };
  const RemainingNoOfTerms = () => {
    return (
      <>
        <div
          className={cx(styles.nestPaymentDetails, {
            [styles.nestPaymentDetailsMob]: isMobile,
          })}
        >
          <div className={styles.nestPaymentDetailsHeading}>
            {BORROW_ENUM.remainingNumberOfTerms}
          </div>
          <tr className={styles.nestDetailsValue}>{remainingTerms}</tr>
        </div>
      </>
    );
  };
  const handleOnClickArrow = () => {
    // Toggle the details for this row
    toggleBorrowDetails(nestId);
  };
  const CardBody = () => (
    <div
      className={cx(styles.bodyContainer, {
        [styles.bodyContainerWeb]: !isMobile,
        [styles.bodyContainerMob]: isMobile,
      })}
    >
      <div className={styles.headingContainer} onClick={handleOnClickArrow}>
        <div
          className={cx(styles.loanDetails, {
            [styles.loanDetailsWeb]: !isMobile,
            [styles.loanDetailsMob]: isMobile,
          })}
        >
          Nest {nestCount} Loan Details
        </div>
        <div>
          <UpArrowIcon />
        </div>
      </div>
      {!isMobile && (
        <div className={styles.loanDetailsMainDiv}>
          <div className={styles.borrowRUDPaymentSchedule}>
            <div className={styles.paymentSchedule}>
              <div className={styles.nestDetails}>
                <div className={styles.nestDetailsHeading}>
                  {BORROW_ENUM.borrowedRUDInNestDetails}
                </div>
                <tr className={styles.nestDetailsValue}>{borrowedAmount}</tr>
              </div>
              <PaymentSchedule />
            </div>
          </div>
          <div className={styles.nestDetailsDiv}>
            <div className={styles.nestDetails}>
              <div className={styles.nestDetailsHeading}>
                {BORROW_ENUM.collateral}
              </div>
              <tr className={styles.nestDetailsValue}>{collateralETH} ETH</tr>
            </div>
            <div>
              <CollateralizationRatio />
            </div>
          </div>
          <div className={styles.nestDetailsDiv}>
            <div>
              <LoanBalance />
            </div>
            <div className={styles.nestDetails}>
              <div className={styles.nestDetailsHeading}>
                {BORROW_ENUM.loanDuration}
              </div>
              <tr className={styles.nestDetailsValue}>{loanDuration}</tr>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <>
          <div className={styles.borrowRUDCollat}>
            <div className={styles.borrowRUDPaymentSchedule}>
              <div className={styles.paymentSchedule}>
                <div className={styles.nestDetails}>
                  <div className={styles.nestDetailsHeading}>
                    {BORROW_ENUM.borrowedRUD}
                  </div>
                  <tr className={styles.nestDetailsValue}>${borrowedAmount}</tr>
                </div>
              </div>
            </div>
            <div></div>
            <div className={styles.nestDetails}>
              <div className={styles.nestDetailsHeading}>
                {BORROW_ENUM.collateral}
              </div>
              <tr className={styles.nestDetailsValue}>{collateralETH} ETH</tr>
            </div>
          </div>
          <div className={styles.loanBalancePaymentSchedule}>
            <LoanBalance />
            <PaymentSchedule />
          </div>
          <div className={styles.collatMob}>
            <CollateralizationRatio />
          </div>
        </>
      )}
      <div
        className={cx(styles.paymentDetails, {
          [styles.paymentDetailsWeb]: !isMobile,
          [styles.paymentDetailsMob]: isMobile,
        })}
      >
        {BORROW_ENUM.paymentDetails}
      </div>
      <div
        className={cx(styles.nestTablePaymentHeading, {
          [styles.nestTablePaymentHeadingWeb]: !isMobile,
          [styles.nestTablePaymentHeadingMob]: isMobile,
        })}
      >
        <div className={styles.nestPaymentDetails}>
          <div className={styles.nestPaymentDetailsHeading}>
            {BORROW_ENUM.nextPaymentAmount}
          </div>
          <tr className={styles.nestDetailsValue}>
            {Number(nextPaymentValue).toFixed(MAX_DECIMAL_PLACE)}
          </tr>
        </div>
        <div className={styles.nestPaymentDetails}>
          <div className={styles.nestPaymentDetailsHeading}>
            {BORROW_ENUM.nextPaymentDue}
          </div>

          <tr
            className={cx(styles.nestDetailsValue, {
              [styles.nestDetailsValueWeb]: !isMobile,
              [styles.nestDetailsValueMob]: isMobile,
            })}
          >
            {nextPayment}
          </tr>
        </div>
        {!isMobile && <RemainingNoOfTerms />}
      </div>
      {isMobile && (
        <div className={styles.remainingNoTerm}>
          {" "}
          <RemainingNoOfTerms />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <ButtonRade
          outline
          customStyling={cx(styles.payButton, {
            [styles.payButtonWeb]: !isMobile,
            [styles.payButtonMob]: isMobile,
          })}
          onClick={handleOnClickPay}
        >
          {BORROW_ENUM.pay}
        </ButtonRade>
      </div>
    </div>
  );
  return (
    <div
      className={cx(styles.nestDetailsMainDiv, {
        [styles.nestDetailsMainDivWeb]: !isMobile,
        [styles.nestDetailsMainDivMob]: isMobile,
      })}
    >
      {CardBody()}
    </div>
  );
};

export default NestDetails;
