import React from "react";
import styles from "../Desktop/Profile.module.scss";
import Profile from "../../../pages/settings/Profile/Profile";
const ProfileImage = () => {
  return (
    <div className={styles.profileImage}>
      <Profile />
    </div>
  );
};

export default ProfileImage;
