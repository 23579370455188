import React from "react";
import styles from "./index.module.scss";
import { BORROW_ENUM } from "../../../enums/borrowEnum";
import ButtonRade from "../../../components/RadeButtons";
import cx from "classnames";
import { AppContext } from "../../../context";
import { useState } from "react";
import Card from "../../../components/Card";
import { useContext } from "react";
import QuestionMark from "../../../assets/QuestionMarkWithButton.svg";
import BellIcon from "../../../assets/BellIcon.svg";
import NextIcon from "../../../assets/NextIcon";
import Radio from "../../../components/RadioButtons/RadioButton";
import { TOOL_TIP } from "../../../enums/homeEnum";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ShakeError from "../../../components/ShakingErrorMessages/ShakingErrorMessages";
const CollateralizationMethod = ({ setBorrowFlowPageNumber }) => {
  // Functions for tooltip
  const renderTooltip1 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      {TOOL_TIP.nests}
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip {...props} className={styles.toolTipStyleForMemberVouch}>
      {TOOL_TIP.comingSoon}
    </Tooltip>
  );
  // State that stores the user's selected collateralization method
  const [userModeSelection, setUserModeSelection] = useState(null);
  // Function that handles the working of Cancel button
  const onClickCancelButton = () => {
    setBorrowFlowPageNumber(0);
  };

  const collateralizationMethodsOne = [
    { value: "cryptoCollateralize", label: "Crypto Collateralize" },
  ];

  const handleSubmitNextButton = () => {
    if (userModeSelection !== "cryptoCollateralize") {
      setErrorForNextButton(true);
    } else {
      setErrorForNextButton(false);
      setBorrowFlowPageNumber(2);
    }
  };
  const handleChangeGettingRadioInput = (e) => {
    setUserModeSelection(e.target.value);
    setErrorForNextButton(false);
  };

  const [errorForNextButton, setErrorForNextButton] = useState(false);
  const { isMobile } = useContext(AppContext);
  const CardTitle = () => (
    <>
      <div
        className={cx(styles.collateralizationheading, {
          [styles.collateralizationheadingMob]: isMobile,
        })}
      >
        {BORROW_ENUM.nest}
        <OverlayTrigger placement="right" overlay={renderTooltip1}>
          <img
            className={cx(styles.questionmarkInHeader, {
              [styles.questionmarkInHeaderMob]: isMobile,
            })}
            alt="ToolTip"
            src={QuestionMark}
          ></img>
        </OverlayTrigger>
        {/* Tool tip */}
      </div>
    </>
  );

  const CardBody = () => (
    <>
      <div>
        <div className={styles.ButttonContent}>
          <div
            className={cx(styles.contentText, {
              [styles.contentTextMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton1, {
                [styles.stepsButton1Mob]: isMobile,
              })}
            >
              1
            </div>
          </div>
          <div className={styles.line}></div>

          <div
            className={cx(styles.buyRUDButtonDiv, {
              [styles.buyRUDButtonDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton2, {
                [styles.stepsButton2Mob]: isMobile,
              })}
            >
              2
            </div>
          </div>
          <div className={styles.line}></div>

          <div
            className={cx(styles.buyRUDButtonDiv, {
              [styles.buyRUDButtonDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.stepsButton3, {
                [styles.stepsButton3Mob]: isMobile,
              })}
            >
              3
            </div>
          </div>
        </div>

        <div
          className={cx(styles.step1, {
            [styles.step1Mob]: isMobile,
          })}
        >
          {BORROW_ENUM.step1}
        </div>

        <div
          className={cx(styles.step1SelectBorrow, {
            [styles.step1SelectBorrowMob]: isMobile,
          })}
        >
          {BORROW_ENUM.select}
        </div>
        <div
          className={cx(styles.radioButtonDiv, {
            [styles.radioButtonDivMob]: isMobile,
          })}
        >
          <Radio
            name="collateralizationMethodOne"
            value={userModeSelection}
            options={collateralizationMethodsOne}
            onChangeFunc={handleChangeGettingRadioInput}
          />
          <OverlayTrigger placement="top" overlay={renderTooltip2}>
            <div
              className={cx(styles.dummyMemberVouchRadioButton, {
                [styles.dummyMemberVouchRadioButtonMob]: isMobile,
              })}
            >
              <span className={styles.circle}></span>

              <div
                className={cx(styles.memberVouchButton, {
                  [styles.memberVouchButtonMob]: isMobile,
                })}
              >
                {BORROW_ENUM.memberVouchButton}
              </div>
            </div>
          </OverlayTrigger>
        </div>
        {errorForNextButton && (
          <ShakeError
            errorMessage={BORROW_ENUM.errorMessage}
            customStyling={cx(styles.errorMessage, {
              [styles.errorMessageMob]: isMobile,
            })}
          />
        )}
        <div
          className={cx(styles.bottomSectionButton, {
            [styles.bottomSectionButtonMob]: isMobile,
          })}
        >
          <ButtonRade
            onClick={handleSubmitNextButton}
            outline
            customStyling={cx(styles.nextButton, {
              [styles.nextButtonMob]: isMobile,
            })}
          >
            {BORROW_ENUM.nextButton} <NextIcon />
          </ButtonRade>
          <ButtonRade
            outline
            customStyling={cx(styles.cancelButton, {
              [styles.cancelButtonMob]: isMobile,
            })}
            onClick={onClickCancelButton}
          >
            {BORROW_ENUM.cancelButton}
          </ButtonRade>
        </div>
      </div>
    </>
  );
  return (
    <>
      {/* calling as a function to avoid flickering issue */}
      <Card titleComponent={<CardTitle />} children={CardBody()} />
    </>
  );
};

export default CollateralizationMethod;
