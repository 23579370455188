import React from "react";
import ButtonRade from "../RadeButtons";
import ArroWIconNotificationTopBar from "../../assets/ArroWIconNotificationTopBar.svg";
import styles from "../SwitchNetworks/index.module.scss";
import { HOME_ENUM } from "../../enums/homeEnum";

const SwitchNetworks = () => {
  const handleNetworkSwitch = async () => {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
  };

  return (
    <>
      <div className={styles.errorMessageToSwitchNetwork}>
        {HOME_ENUM.en.errorMessageToSwitchNetwork}.
        <ButtonRade
          customStyling={styles.switchNetworkButton}
          onClick={() => handleNetworkSwitch()}
        >
          {HOME_ENUM.en.switchNetworkButton}
          <img
            src={ArroWIconNotificationTopBar}
            className={styles.arrowIcon}
            alt="networkSwitchArrow"
          />
        </ButtonRade>
      </div>
    </>
  );
};
export default SwitchNetworks;
