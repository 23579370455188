import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "../RadioButtons/index.module.scss";
import ShakeError from "../ShakingErrorMessages/ShakingErrorMessages";
import { RUD_ENUM } from "../../enums/rudEnum";

const Radio = (props) => {
  const inputProps = {
    type: "radio",
    name: props.name,
    className: props.className,
  };
  const radioLabelStyle = {
    padding: props.padding,
    width: props.width,
  };
  const currencyChooseStyle = {
    gap: props.gap,
  };
  return (
    <div>
      <div className={styles.currencyChooseDiv} style={currencyChooseStyle}>
        {props.options.map((RadioItem, i) => (
          <label
            key={i}
            htmlFor={RadioItem.value}
            className={
              RadioItem.value === props.value
                ? styles.activeRadioButton
                : styles.radioButton
            }
            style={radioLabelStyle}
          >
            <input
              checked={RadioItem.value === props.value}
              type="radio"
              {...inputProps}
              id={RadioItem.value}
              value={RadioItem.value}
              onChange={(e) => props.onChangeFunc(e, props)}
            />
            {RadioItem.label}
          </label>
        ))}
      </div>
      {props.error && (
        <ShakeError
          errorMessage={RUD_ENUM.error_for_radioButtons}
          customStyling={styles.alertSpan}
        />
      )}
    </div>
  );
};

Radio.defaultProps = {
  name: "",
  className: "form-check-input",
  outerClassName: "mb-2",
  value: "",
  options: [],
  onChangeFunc: () => {},
  isReq: null,
  onValidateFunc: () => {},
  error: false,
  padding: "10px 11px", // Default padding, you can change it as needed
};

Radio.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  outerClassName: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChangeFunc: PropTypes.func,
  isReq: PropTypes.bool,
  error: PropTypes.bool,
  onValidateFunc: PropTypes.func,
  padding: PropTypes.string,
  gap: PropTypes.string,
  width: PropTypes.string,
};

export default memo(Radio);
