import React, { useEffect } from "react";
import { useEthers } from "@usedapp/core";
import MetaMaskOnboarding from "@metamask/onboarding";
import styles from "./index.module.scss";
import { AppContext, UserContext } from "../../context";
import { APP_ENUM } from "../../enums/appEnum";
import crossIcon from "../../assets/crossIconConnectMetamask.svg";
import { useState, useContext } from "react";
import Failedmessage from "../FailedMessage/FailedMessage";
import cx from "classnames";
const ConnectMetamaskFlow = (props) => {
  const { activateBrowserWallet } = useEthers();

  // state for storing address of the metamask account
  const [accounts, setAccounts] = React.useState([]);
  const onboarding = React.useRef();
  React.useEffect(() => {
    if (!onboarding.current) {
      // Starts onboarding by opening the MetaMask download page and waiting for MetaMask to be installed.
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);
  React.useEffect(() => {
    // Checking whether the metaMask provider is detected or not
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        activateBrowserWallet();

        // Stops onboarding
        onboarding.current.stopOnboarding();
      }
    }
  }, [accounts, activateBrowserWallet]);
  React.useEffect(() => {
    // If metamask is installed
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      activateBrowserWallet();
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((newAccounts) => setAccounts(newAccounts));
    } else {
      setFailedToConnect(true);
    }
  }, [accounts, activateBrowserWallet]);

  // state for connecting to metamask after 3000 ms

  const { isMobile } = useContext(AppContext);

  // State for checking whether the connection the successful or not
  const [failedToConnect, setFailedToConnect] = useState(false);
  //Cross icon icon for closing the window
  const handleClickCrossIcon = () => {
    props.setIsMetamaskConnected(false);
  };

  return (
    <>
      {/* if connection is unsuccessfull display FailedMessageComponent */}
      {failedToConnect ? (
        <Failedmessage setIsMetamaskConnected={props.setIsMetamaskConnected} />
      ) : (
        <div className={styles.mainDiv}>
          <div
            className={cx(styles.confirmMessageMainDiv, {
              [styles.confirmMessageMainDivMob]: isMobile,
            })}
          >
            <div
              className={cx(styles.confirmMessage, {
                [styles.confirmMessageMob]: isMobile,
              })}
            >
              {APP_ENUM.en.confirmAuthentication}
              {!isMobile && (
                <img
                  src={crossIcon}
                  className={styles.crossIcon}
                  alt="CrossIcon"
                  onClick={handleClickCrossIcon}
                />
              )}
            </div>

            {!isMobile && (
              <div
                className={cx(styles.confirmMessageContent, {
                  [styles.confirmMessageContentMob]: isMobile,
                })}
              >
                {APP_ENUM.en.confirmMessage}
              </div>
            )}
            <div className={styles.confirmLoader}>
              {!isMobile && (
                <div>
                  <ul className={styles.loaderList}>
                    <li>
                      <div className={styles.loaderCircle}>
                        <span></span>
                      </div>
                    </li>
                  </ul>
                </div>
              )}

              <div
                className={cx(styles.loaderContent, {
                  [styles.loaderContentMob]: isMobile,
                })}
              >
                {!isMobile && (
                  <>
                    <div> {APP_ENUM.en.waitingConfirmMessage}</div>
                    <div>{APP_ENUM.en.remainder}</div>
                  </>
                )}
                {isMobile && (
                  <>
                    <div className={styles.mobileConfirmMessage}>
                      {APP_ENUM.en.mobileWaitingForConfirmMessage}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ConnectMetamaskFlow;
